import { BsSearch } from "react-icons/bs";
import { Button, Form, Input, Popover, Table, Tooltip } from "antd";
import {
  RiFileExcel2Line,
  RiFilterOffLine,
  RiMailSendLine,
} from "react-icons/ri";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import PageToolnav from "../../../components/toolnav/PageToolnav";
import SideMenu from "../components/SideMenu";
import fetchDataReq from "../../../services/fetchDataReq";
import { BiLoader } from "react-icons/bi";
import { FiEdit } from "react-icons/fi";
import { IoTrashBinOutline } from "react-icons/io5";
import CreateItem from "./components/CreateItem";
import { AiOutlineEye } from "react-icons/ai";
import { AuthContext } from "../../../contexts/AuthContext";
import { ExportToExcel } from "../../../utils/exportExcel";
import UpdateItem from "./components/UpdateItem";

export default function Stocks() {
  const { auth } = useContext(AuthContext);
  const [stock, setStock] = useState<any>([]);
  const [selected, setSelected] = useState<any>({});
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isFetching, setFetching] = useState<boolean>(false);
  const [isMailLoading, setMailLoading] = useState<boolean>(false);
  const [messageModal, toggleMessageModal] = useState(false);
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [isSearched, setSearched] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState(null);

  const [form] = Form.useForm();

  const [emailForm] = Form.useForm();

  const initial = {
    current: 1,
    pageSize: 10,
    limit: 10,
  };
  const [pagination, setPagination] = useState(initial);

  const handleFetchStock = async ({ pagination }: any) => {
    setLoading(true);
    const { current, limit } = pagination;
    const skip = current * limit - limit;
    const uri = `api/v1/stockitems?skip=${skip}&limit=${limit}`;
    const response = await fetchDataReq({ uri, token: auth?._id });
    if (response) {
      setStock(response?.payload?.records);
      setPagination({ total: response?.payload?.count, ...pagination });
    }
    setLoading(false);
  };

  const handleSearchStock = async ({ pagination }: any) => {
    if (!searchKey) return;
    setSearched(true);
    setLoading(true);
    const { current, limit } = pagination;
    const skip = current * limit - limit;
    const uri = `api/v1/stockitems?skip=${skip}&limit=${limit}&name=${searchKey}`;
    const response = await fetchDataReq({ uri, token: auth?._id });
    if (response?.success) {
      setStock(response?.payload?.records);
      setPagination({ total: response?.payload?.count, ...pagination });
    }
    setLoading(false);
  };

  const handleRefetch = () => {
    handleFetchStock({
      pagination,
    });
  };

  const handlePageChange = (pagination: any) => {
    handleFetchStock({
      pagination,
    });
  };

  const columns = [
    {
      title: "S/N",
      width: 40,
      render: (record: any, text: any, index: any) => (
        <span className="text-gray-700 ml-2">{index + 1}</span>
      ),
    },
    {
      title: "Name",
      width: 140,
      render: (record: any) => {
        return (
          <span className="text-gray-700">
            {record?.name ? record?.name : "-"}
          </span>
        );
      },
    },
    {
      title: "Category",
      width: 80,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.category ? record?.category?.name : "-"}
        </span>
      ),
    },
    {
      title: "Subcategory",
      width: 70,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.subCategory ? record?.subCategory?.name : "-"}
        </span>
      ),
    },
    {
      title: "Item Class",
      width: 100,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.itemClass?.name ? record?.itemClass?.name : "-"}
        </span>
      ),
    },
    {
      title: "Item Family",
      width: 100,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.itemFamily?.name ? record?.itemFamily?.name : "-"}
        </span>
      ),
    },
    {
      title: "Generic",
      width: 50,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.isGeneric ? <span>Yes</span> : <span>No</span>}
        </span>
      ),
    },

    {
      title: "Action",
      width: 80,
      render: (record: any) => (
        <div className="flex justify-end">
          <button
            onClick={() => {
              setSelected({
                ...record,
              });
            }}
            className="flex justify-center items-center text-gray-700 border rounded-md p-2 bg-blue-100 hover:bg-blue-200 cursor-pointer"
          >
            <AiOutlineEye size={18} />
          </button>
          <button
            onClick={() => {
              setSelected({
                ...record,
              });
              setUpdateModal(true);
            }}
            className="flex justify-center items-center ml-1 text-gray-700 border rounded-md p-2 bg-yellow-100 hover:bg-yellow-200 cursor-pointer"
          >
            <FiEdit />
          </button>

          <button
            onClick={() => {
              setSelected({
                ...record,
              });
              setDeleteModal(true);
            }}
            className="flex justify-center items-center ml-1 text-gray-700 border rounded-md p-2 bg-red-100 hover:bg-red-200 cursor-pointer"
          >
            <IoTrashBinOutline />
          </button>
        </div>
      ),
    },
  ];

  const sendMail = async (values: any) => {
    setMailLoading(true);
    stock?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Name: item?.name === null ? "-" : item?.name,
        Email: item?.email === null ? "-" : item?.email,
        Mobile: item?.mobile === null ? "-" : item?.mobile,
        Website: item?.website === null ? "-" : item?.website,
        Created: moment(item?.createdAt).format("DD/MM/YYYY"),
      };
    });
  };

  const fetchAllUsers = async () => {
    const response = await fetchDataReq({
      uri: "api/v1/users",
      token: auth?._id,
    });
    if (response?.success) {
      ExportToExcel(
        response?.payload?.records?.map((item: any, index: number) => {
          return {
            "S/N": index + 1,
            Name: item?.name === null ? "-" : item?.name,
            Email: item?.email === null ? "-" : item?.email,
            Mobile: item?.mobile === null ? "-" : item?.mobile,
            Website: item?.website === null ? "-" : item?.website,
            Created: moment(item?.createdAt).format("DD/MM/YYYY"),
          };
        }),
        "Users.xlsx"
      );
    }
  };

  const handleFetchAll = async () => {
    setFetching(true);
    await fetchAllUsers();
    setFetching(false);
  };

  useEffect(() => {
    handleFetchStock({
      pagination,
    });
  }, []);

  return (
    <div className="flex">
      <SideMenu />
      <div className="flex flex-1 flex-col">
        <PageToolnav
          link={{ name: "Stock", to: "/manage/stock" }}
          title={"Stock"}
          actions={
            <div className="flex">
              <div className="flex mr-2">
                <Form layout="inline" form={form}>
                  <Form.Item name="name">
                    <Input
                      name="searchKey"
                      onChange={(e: any) => {
                        setSearchKey(e.target.value);
                        setSearched(false);
                      }}
                      type="text"
                      placeholder="Search by name"
                    />
                  </Form.Item>
                </Form>
                <Button
                  className="-ml-4 focus:outline-none"
                  onClick={() => {
                    if (isSearched) {
                      setSearched(false);
                      form.resetFields();
                      setSearchKey(null);
                      handleFetchStock({ pagination: initial });
                    } else {
                      handleSearchStock({
                        pagination: initial,
                      });
                    }
                  }}
                >
                  {isSearched ? (
                    <RiFilterOffLine color={"#f87171"} />
                  ) : (
                    <BsSearch color={searchKey ? "#16a34a" : "#9ca3af"} />
                  )}
                </Button>
              </div>
              <Popover
                content={
                  <>
                    <Form
                      name="basic"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                      initialValues={{ remember: true }}
                      form={emailForm}
                      onFinish={sendMail}
                      autoComplete="off"
                    >
                      <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                          { required: true, message: "Email is Required" },
                        ]}
                      >
                        <Input placeholder="Email" />
                      </Form.Item>
                      <Form.Item
                        label="Subject"
                        name="subject"
                        rules={[
                          { required: true, message: "Subject is Required" },
                        ]}
                      >
                        <Input placeholder="Email Subject" />
                      </Form.Item>
                      <div className="flex flex-row justify-between">
                        <Form.Item wrapperCol={{ offset: 12, span: 8 }}>
                          <Button type="primary" htmlType="submit">
                            {isMailLoading ? "Sending ..." : "Submit"}
                          </Button>
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 0, span: 8 }}>
                          <Button
                            onClick={() => {
                              toggleMessageModal(false);
                            }}
                          >
                            Cancel
                          </Button>
                        </Form.Item>
                      </div>
                    </Form>
                  </>
                }
                title="Message Content"
                trigger="focus"
                placement="left"
                visible={messageModal}
              >
                <Tooltip title="Send Email">
                  <button
                    className="flex bg-white mr-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                    onClick={async () => {
                      toggleMessageModal(true);
                    }}
                  >
                    <RiMailSendLine size={21} />
                  </button>
                </Tooltip>
              </Popover>
              <Tooltip title="Export To Excel">
                <button
                  color="#E50000"
                  className="flex bg-white mx-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                  onClick={() => handleFetchAll()}
                >
                  {isFetching ? (
                    <BiLoader size={20} />
                  ) : (
                    <RiFileExcel2Line size={20} />
                  )}
                </button>
              </Tooltip>
              <Button
                className="bg-blue-200"
                onClick={() => setCreateModal(true)}
              >
                + New
              </Button>
            </div>
          }
        />
        <div className="h-screen flex">
          <div className="flex flex-col mx-4 my-2">
            <div className="flex flex-col w-full">
              <div className="grid gap-x-0 grid-cols-12">
                <div className="col-span-12 sm:col-span-12 md:col-span-12 border bg-white">
                  <Table
                    size="middle"
                    dataSource={isLoading ? [] : stock}
                    columns={columns}
                    pagination={pagination}
                    scroll={{ y: 660, x: 0 }}
                    onChange={handlePageChange}
                    rowKey="id"
                    loading={isLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CreateItem
        isVisible={createModal}
        setVisible={setCreateModal}
        refetch={handleRefetch}
      />

      <UpdateItem
        isVisible={updateModal}
        setVisible={setUpdateModal}
        selected={selected}
        refetch={handleRefetch}
      />
    </div>
  );
}
