import { Button } from "antd";
import { AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import { useQuery } from "@tanstack/react-query";
import { handleFetchClusters } from "../../../../api/clusters";
import Something from "../../../../components/shared/Something";
import CreateCluster from "./CreateCluster";
import { useState } from "react";
import { FiEdit } from "react-icons/fi";
import UpdateCluster from "./UpdateCluster";
import DeleteCluster from "./DeleteCluster";

export default function Clusters() {
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>({});

  const {
    data: clusters,
    refetch,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ["clusters"],
    queryFn: handleFetchClusters,
  });

  return (
    <div className="border bg-white mb-2 px-4">
      <div className="flex justify-between items-center py-4 border-b mb-2">
        <div className="flex font-bold">Clusters</div>
        <Button onClick={() => setCreateModal(true)}>
          <AiOutlinePlus />
        </Button>
      </div>
      <div className="mb-2">
        {clusters?.payload?.records?.length === 0 ? (
          <div className="flex justify-center items-center h-14">
            <div className="text-gray-400 text-sm">No Data Found</div>
          </div>
        ) : (
          <div className="">
            {isError ? (
              <Something refetch={refetch} isFetching={isFetching} />
            ) : (
              <div className="-mt-2">
                <div className="flex justify-between bg-gray-100 p-2">
                  <div className="flex">
                    <div className="w-10 mr-2">S/N.</div>
                    <div className="w-44 mr-2">Name</div>
                  </div>
                  <div className="">Action</div>
                </div>
                {clusters?.payload?.records?.map((item: any, index: number) => (
                  <div
                    className="flex justify-between px-2 py-1 border-t"
                    key={index}
                  >
                    <div className="flex">
                      <div className="w-10 mr-2">{index + 1}.</div>
                      <div className="w-44">{item?.name}</div>
                    </div>
                    <div className="flex items-center">
                      <button
                        onClick={() => {
                          setSelected(item);
                          setUpdateModal(true);
                        }}
                        className="flex justify-center items-center bg-yellow-100 hover:bg-yellow-200 h-8 w-8 border mx-2"
                      >
                        <FiEdit />
                      </button>

                      <button
                        onClick={() => {
                          setSelected(item);
                          setDeleteModal(true);
                        }}
                        className="flex justify-center items-center bg-red-100 h-8 w-8 border"
                      >
                        <AiOutlineDelete />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>

      <CreateCluster isVisible={createModal} setVisible={setCreateModal} />
      <UpdateCluster
        isVisible={updateModal}
        setVisible={setUpdateModal}
        selected={selected}
      />
      <DeleteCluster
        isVisible={deleteModal}
        setVisible={setDeleteModal}
        selected={selected}
      />
    </div>
  );
}
