import { NavLink, useNavigate } from "react-router-dom";
import Moment from "react-moment";
import formatMoney from "../../../utils/formatMoney";
import { BsEye } from "react-icons/bs";
import { Button, Form, Input, Popover, Table, Tooltip } from "antd";
import { RiFileExcel2Line, RiMailSendLine } from "react-icons/ri";
import moment from "moment";
import { ExportToExcel } from "../../../utils/exportExcel";
import { useContext, useEffect, useState } from "react";
import PageToolnav from "../../../components/toolnav/PageToolnav";
import fetchDataReq from "../../../services/fetchDataReq";
import { AuthContext } from "../../../contexts/AuthContext";
import SideMenu from "../components/SideMenu";
import { colors } from "../../../utils/data";
import { GrStatusCriticalSmall } from "react-icons/gr";

export default function Orders() {
  const { auth } = useContext(AuthContext);
  const [orders, setOrders] = useState<any>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isMailLoading, setMailLoading] = useState<boolean>(false);
  const [messageModal, toggleMessageModal] = useState(false);

  const navigate = useNavigate();

  const [emailForm] = Form.useForm();

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    limit: 10,
  });

  const handleFetchData = async ({ pagination }: any) => {
    setLoading(true);
    const { current, limit } = pagination;
    const skip = current * limit - limit;

    const uri = `api/v1/orders?skip=${skip}&limit=${limit}`;
    const response = await fetchDataReq({ uri, token: auth?._id });
    if (response?.success) {
      setOrders(response?.payload?.records);
      setPagination({ total: response?.payload?.count, ...pagination });
    }
    setLoading(false);
  };

  const handlePageChange = (pagination: any) => {
    handleFetchData({
      pagination,
    });
  };

  const getTotalAmount = (items: any) => {
    let total = 0;
    items?.forEach((item: any) => {
      total = total + item?.quantity * (item?.price - item?.discount);
    });
    return isNaN(total) ? 0 : total;
  };

  const columns = [
    {
      title: "S/N",
      width: 40,
      render: (record: any, text: any, index: any) => (
        <span className="text-gray-700 ml-2">{index + 1}</span>
      ),
    },
    {
      title: "LPO Creation Date",
      width: 120,
      render: (record: any) => {
        return (
          <span className="text-gray-700">
            <Moment format="Do, MMM YYYY">{record?.dates?.created}</Moment>
          </span>
        );
      },
    },
    {
      title: "LPO No.",
      width: 40,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.orderId ? record?.orderId : "-"}
        </span>
      ),
    },
    {
      title: "Requisition No.",
      width: 70,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.requisition?.requisitionNumber
            ? record?.requisition?.requisitionNumber
            : "-"}
        </span>
      ),
    },
    {
      title: "Supplier",
      width: 140,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.supplier?.name ? record?.supplier?.name : "-"}
        </span>
      ),
    },

    {
      title: "Amount",
      width: 70,
      render: (record: any) => (
        <span className="text-gray-700">
          {formatMoney(getTotalAmount(record?.items))}
        </span>
      ),
    },
    {
      title: "Status",
      width: 70,
      render: (record: any) => (
        <div className="flex items-center">
          <div className="">
            <GrStatusCriticalSmall color={`${colors[record?.status]}`} />
          </div>
          <div className="ml-1" style={{ color: `${colors[record?.status]}` }}>
            {record?.flags?.isCompleted ? "Approved" : record?.status}
          </div>
        </div>
      ),
    },
  ];

  const sendMail = async (values: any) => {
    setMailLoading(true);
    const data = orders?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Customer: item?.customer?.name === null ? "-" : item?.customer?.name,
        "Vehicle Registration":
          item?.vehicle?.registration === null
            ? "-"
            : item?.vehicle?.registration,
        "Vehicle Model":
          item?.vehicle?.model?.name === null
            ? "-"
            : item?.vehicle?.model?.name,
        Technician:
          item?.technician?.name === null ? "-" : item?.technician?.name,
        "Transaction Id":
          item?.transactionId === null ? "-" : item?.transactionId,
        Created: moment(item?.createdAt).format("DD/MM/YYYY"),
        Status: item?.status === null ? "-" : item?.status,
        Amount: formatMoney(300),
      };
    });
  };

  useEffect(() => {
    handleFetchData({
      pagination,
    });
  }, []);
  return (
    <div className="flex">
      <SideMenu />
      <div className="flex flex-1 flex-col">
        <PageToolnav
          link={{ name: "All LPOs", to: "/manage/orders" }}
          title={"LPOs"}
          actions={
            <div className="flex">
              <Popover
                content={
                  <>
                    <Form
                      name="basic"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                      initialValues={{ remember: true }}
                      form={emailForm}
                      onFinish={sendMail}
                      autoComplete="off"
                    >
                      <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                          { required: true, message: "Email is Required" },
                        ]}
                      >
                        <Input placeholder="Email" />
                      </Form.Item>
                      <Form.Item
                        label="Subject"
                        name="subject"
                        rules={[
                          { required: true, message: "Subject is Required" },
                        ]}
                      >
                        <Input placeholder="Email Subject" />
                      </Form.Item>
                      <div className="flex flex-row justify-between">
                        <Form.Item wrapperCol={{ offset: 12, span: 8 }}>
                          <Button type="primary" htmlType="submit">
                            {isMailLoading ? "Sending ..." : "Submit"}
                          </Button>
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 0, span: 8 }}>
                          <Button
                            onClick={() => {
                              toggleMessageModal(false);
                            }}
                          >
                            Cancel
                          </Button>
                        </Form.Item>
                      </div>
                    </Form>
                  </>
                }
                title="Message Content"
                trigger="focus"
                placement="left"
                visible={messageModal}
              >
                <Tooltip title="Send Email">
                  <button
                    className="flex mr-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                    onClick={async () => {
                      toggleMessageModal(true);
                    }}
                  >
                    <RiMailSendLine size={21} />
                  </button>
                </Tooltip>
              </Popover>
              <Tooltip title="Export To Excel">
                <button
                  color="#E50000"
                  className="flex justify-center items-center border px-4 py-1 hover:bg-gray-100"
                  onClick={() =>
                    ExportToExcel(
                      orders?.map((item: any, index: number) => {
                        return {
                          "S/N": index + 1,
                          Customer:
                            item?.customer?.name === null
                              ? "-"
                              : item?.customer?.name,
                          "Vehicle Registration":
                            item?.vehicle?.registration === null
                              ? "-"
                              : item?.vehicle?.registration,
                          "Vehicle Model":
                            item?.vehicle?.model?.name === null
                              ? "-"
                              : item?.vehicle?.model?.name,
                          Technician:
                            item?.technician?.name === null
                              ? "-"
                              : item?.technician?.name,
                          "Transaction Id":
                            item?.transactionId === null
                              ? "-"
                              : item?.transactionId,
                          Created: moment(item?.createdAt).format("DD/MM/YYYY"),
                          Status: item?.status === null ? "-" : item?.status,
                          Amount: formatMoney(200),
                        };
                      }),
                      "LPOs.xlsx"
                    )
                  }
                >
                  <RiFileExcel2Line size={20} />
                </button>
              </Tooltip>
            </div>
          }
        />
        <div className="h-screen flex">
          <div className="flex flex-col mx-4 my-2">
            <div className="flex flex-col w-full">
              <div className="grid gap-x-0 grid-cols-12">
                <div className="col-span-12 sm:col-span-12 md:col-span-12 border bg-white">
                  <Table
                    size="middle"
                    dataSource={isLoading ? [] : orders}
                    columns={columns}
                    pagination={pagination}
                    scroll={{ y: 660, x: 0 }}
                    onChange={handlePageChange}
                    rowKey="id"
                    onRow={(record) => {
                      return {
                        onClick: () => {
                          navigate(`/manage/orders/${record?._id}`);
                        },
                      };
                    }}
                    rowClassName={(record) =>
                      `hover:cursor-pointer hover:bg-blue-50`
                    }
                    loading={isLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
