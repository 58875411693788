import { Outlet } from "react-router";
import { AiOutlineProfile } from "react-icons/ai";
import { HiOutlineHome, HiOutlineCog } from "react-icons/hi";
import AdminLink from "../components/shared/AdminLink";

import logo from "../assets/images/karimjee_white.png";
import { FiUsers } from "react-icons/fi";
import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";

export default function MainLayout() {
  const { auth, menu } = useContext(AuthContext);
  const managers = ["V", "C"];
  const menus = [
    {
      id: 1,
      to: "/dashboard",
      name: "Dashboard",
      icon: <HiOutlineHome size={28} color={"#FFFFFF"} />,
    },
    {
      id: 2,
      to: "/suppliers",
      name: "Suppliers",
      icon: <FiUsers size={28} color={"#FFFFFF"} />,
    },
    {
      id: 3,
      to: "/requisitions",
      name: "Requisitions",
      icon: <AiOutlineProfile size={28} color={"#FFFFFF"} />,
    },
    {
      id: 4,
      to: "/orders",
      name: "LPOs",
      icon: <AiOutlineProfile size={28} color={"#FFFFFF"} />,
    },

    {
      id: 4,
      to: "/assets",
      name: "Assets",
      icon: <AiOutlineProfile size={28} color={"#FFFFFF"} />,
    },
  ];

  const supper = [
    {
      id: 5,
      to: "/manage",
      name: "Manage",
      icon: <HiOutlineCog size={28} color={"#FFFFFF"} />,
    },
  ];
  return (
    <div className="flex bg-toyota h-full font-toyota">
      <div className="w-full">
        <div className="flex">
          {menu ? (
            <div className="">
              <div className="w-24 h-full">
                <div className="h-16 bg-toyota flex flex-row justify-between items-center pr-5 border-r border-blue-900">
                  <div className="flex flex-row items-center  ">
                    <div className="flex justify-center items-center w-24">
                      <img src={logo} alt="Logo" height={15} width={35} />
                    </div>
                  </div>
                </div>
                <div className="">
                  {menus?.map((menu: any, index: number) => (
                    <AdminLink to={`${menu?.to}`} icon={menu?.icon} key={index}>
                      {menu?.name}
                    </AdminLink>
                  ))}
                </div>
                {managers?.includes(auth?.level) && (
                  <div className="">
                    {supper?.map((menu: any, index: number) => (
                      <AdminLink
                        to={`${menu?.to}`}
                        icon={menu?.icon}
                        key={index}
                      >
                        {menu?.name}
                      </AdminLink>
                    ))}
                  </div>
                )}
              </div>
            </div>
          ) : null}
          <div className="flex-1 bg-gray-100">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
