import { useQuery } from "@tanstack/react-query";
import PageToolnav from "../../../components/toolnav/PageToolnav";
import getCompanies from "../../../services/getCompanies";
import SideMenu from "../components/SideMenu";
import moment from "moment";

import loading from "../../../assets/spinner.svg";
import { useContext, useEffect, useState } from "react";
import { RiFileExcel2Line, RiMailSendLine } from "react-icons/ri";
import { Button, Form, Input, Popover, Tooltip } from "antd";
import formatMoney from "../../../utils/formatMoney";
import { ExportToExcel } from "../../../utils/exportExcel";
import DetailsPanel from "./components/DetailsPanel";
import fetchDataReq from "../../../services/fetchDataReq";
import CreateCompany from "./components/CreateCompany";
import { AuthContext } from "../../../contexts/AuthContext";

export default function AllCompanies() {
  const [createCompanyModal, setCreateCompanyModal] = useState<boolean>(false);
  const [companies, setCompanies] = useState<any>([]);
  const [selected, setSelected] = useState<any>({});
  const [messageModal, toggleMessageModal] = useState(false);
  const [detailPanel, setDetailPanel] = useState(false);
  const [isMailLoading, setMailLoading] = useState<boolean>(false);
  const { auth } = useContext(AuthContext);
  const { isLoading, refetch } = useQuery({
    queryKey: ["companies"],
    queryFn: getCompanies,
  });
  const [emailForm] = Form.useForm();

  const handleFetchCompanies = async () => {
    const response = await fetchDataReq({
      uri: "api/v1/companies",
      token: auth?._id,
    });
    if (response?.success) {
      setCompanies(response?.payload?.records);
    }
  };

  const sendMail = async (values: any) => {
    setMailLoading(true);
    const data = []?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Customer: item?.customer?.name === null ? "-" : item?.customer?.name,
        "Vehicle Registration":
          item?.vehicle?.registration === null
            ? "-"
            : item?.vehicle?.registration,
        "Vehicle Model":
          item?.vehicle?.model?.name === null
            ? "-"
            : item?.vehicle?.model?.name,
        Technician:
          item?.technician?.name === null ? "-" : item?.technician?.name,
        "Transaction Id":
          item?.transactionId === null ? "-" : item?.transactionId,
        Created: moment(item?.createdAt).format("DD/MM/YYYY"),
        Status: item?.status === null ? "-" : item?.status,
        Amount: formatMoney(300),
      };
    });
  };

  useEffect(() => {
    handleFetchCompanies();
  }, []);

  return (
    <div className="flex h-full min-h-screen">
      <SideMenu />
      <div className="flex flex-1 flex-col">
        <PageToolnav
          link={{ name: "Companies", to: "/manage/companies" }}
          title={"Companies"}
          actions={
            <div className="flex">
              <Popover
                content={
                  <>
                    <Form
                      name="basic"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                      initialValues={{ remember: true }}
                      form={emailForm}
                      onFinish={sendMail}
                      autoComplete="off"
                    >
                      <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                          { required: true, message: "Email is Required" },
                        ]}
                      >
                        <Input placeholder="Email" />
                      </Form.Item>
                      <Form.Item
                        label="Subject"
                        name="subject"
                        rules={[
                          { required: true, message: "Subject is Required" },
                        ]}
                      >
                        <Input placeholder="Email Subject" />
                      </Form.Item>
                      <div className="flex flex-row justify-between">
                        <Form.Item wrapperCol={{ offset: 12, span: 8 }}>
                          <Button type="primary" htmlType="submit">
                            {isMailLoading ? "Sending ..." : "Submit"}
                          </Button>
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 0, span: 8 }}>
                          <Button
                            onClick={() => {
                              toggleMessageModal(false);
                            }}
                          >
                            Cancel
                          </Button>
                        </Form.Item>
                      </div>
                    </Form>
                  </>
                }
                title="Message Content"
                trigger="focus"
                placement="left"
                visible={messageModal}
              >
                <Tooltip title="Send Email">
                  <button
                    className="flex ml-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                    onClick={async () => {
                      toggleMessageModal(true);
                    }}
                  >
                    <RiMailSendLine size={21} />
                  </button>
                </Tooltip>
              </Popover>
              <Tooltip title="Export To Excel">
                <button
                  color="#E50000"
                  className="flex mx-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                  onClick={() =>
                    ExportToExcel(
                      []?.map((item: any, index: number) => {
                        return {
                          "S/N": index + 1,
                          Customer:
                            item?.customer?.name === null
                              ? "-"
                              : item?.customer?.name,
                          "Vehicle Registration":
                            item?.vehicle?.registration === null
                              ? "-"
                              : item?.vehicle?.registration,
                          "Vehicle Model":
                            item?.vehicle?.model?.name === null
                              ? "-"
                              : item?.vehicle?.model?.name,
                          Technician:
                            item?.technician?.name === null
                              ? "-"
                              : item?.technician?.name,
                          "Transaction Id":
                            item?.transactionId === null
                              ? "-"
                              : item?.transactionId,
                          Created: moment(item?.createdAt).format("DD/MM/YYYY"),
                          Status: item?.status === null ? "-" : item?.status,
                          Amount: formatMoney(200),
                        };
                      }),
                      "Suppliers.xlsx"
                    )
                  }
                >
                  <RiFileExcel2Line size={20} />
                </button>
              </Tooltip>
              <Button onClick={() => setCreateCompanyModal(true)}>+ New</Button>
            </div>
          }
        />
        <div className="flex">
          {isLoading ? (
            <div className="flex justify-center mt-16">
              <img src={loading} width={30} />
            </div>
          ) : (
            <div className="flex mx-4 my-2">
              <div className="grid grid-cols-12 gap-2">
                {companies?.map((item: any, index: number) => (
                  <div
                    onClick={() => {
                      setSelected(item);
                      setDetailPanel(true);
                    }}
                    className="col-span-6 sm:col-span-2  border bg-white hover:cursor-pointer"
                    key={index}
                  >
                    <div className="flex flex-col justify-center items-center p-4">
                      <div className="w-full h-32 bg-white flex justify-center items-center mb-2 rounded-lg">
                        <div className="text-gray-100">
                          <img
                            src={item?.logo}
                            alt=""
                            style={{ objectFit: "cover", width: 100 }}
                          />
                        </div>
                      </div>
                      <div className="border-t mt-2 w-full p-2">
                        {item?.name}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          <DetailsPanel
            isVisible={detailPanel}
            setVisible={setDetailPanel}
            selected={selected}
            refetch={handleFetchCompanies}
            isEditable={true}
          />
          <CreateCompany
            isVisible={createCompanyModal}
            setVisible={setCreateCompanyModal}
            refetch={handleFetchCompanies}
          />
        </div>
      </div>
    </div>
  );
}



