import { Button, Form, Row, Col, Input, message, Space, Drawer } from "antd";
import { AiOutlineClose } from "react-icons/ai";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handleCreateContact } from "../../../api/suppliers";
import formatMobile from "../../../utils/formatMobile";

export default function CreateContact({ isVisible, setVisible, supplier }: any) {
  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { mutate, isPending } = useMutation({
    mutationFn: handleCreateContact,
    onSuccess: () => {
      message.success("Created Successfully");
      form.resetFields();
      queryClient.invalidateQueries({
        queryKey: ["suppliers", supplier?._id],
      });
      setVisible(false);
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const { name, email, title, mobile } = await form.getFieldsValue();

    const { mob, inValid } = await formatMobile(mobile);
    if (inValid || mob === null) {
      message.error("Please provide a valid number");
      return;
    }

    const data = {
      name,
      email,
      mobile: mob,
      title,
    };

    await mutate({ _id: supplier?._id, data });
  };

  const clearForm = async () => {
    form.resetFields();
  };

  return (
    <Drawer
      open={isVisible}
      title={`ADD CONTACT PERSON`}
      placement={"right"}
      width={800}
      onClose={() => setVisible(!isVisible)}
      closable={false}
      extra={
        <Space className="flex justify-center items-center">
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <Form
        id="createSupplier"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Please enter name",
                },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="title"
              label="Job Title"
              rules={[
                {
                  required: true,
                  message: "Please enter  title",
                },
              ]}
            >
              <Input placeholder="Title" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: "Please enter email address",
                },
              ]}
            >
              <Input placeholder="Email address" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="mobile"
              label="Phone"
              rules={[
                {
                  required: true,
                  message: "Please enter phone number",
                },
              ]}
            >
              <Input placeholder="Phone number" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="mr-2">
            {isPending ? "Submiting ..." : "Submit"}
          </Button>
          <Button htmlType="button" onClick={() => clearForm()}>
            Clear
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
