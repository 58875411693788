import { DatePicker, Checkbox, Button, Form } from "antd";
import moment from "moment";
import { Popover } from "@mantine/core";
import { useContext, useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { BsCalendarCheck, BsChevronDown } from "react-icons/bs";
import { RiFilterOffLine } from "react-icons/ri";
import Profile from "../../../components/layouts/Header/components/Profile";
import { AuthContext } from "../../../contexts/AuthContext";

const { RangePicker } = DatePicker;

export default function Header({
  stateHandler,
  reports,
  report,
  setReport,
}: any) {
  const { hideMenu } = useContext(AuthContext);
  const [isVisible, setVisible] = useState(false);
  const [showRange, setShowRange] = useState(false);
  const [selected, setSelected] = useState<any>(null);
  const [form] = Form.useForm();
  const [opened, setOpened] = useState(false);

  const onRangeChange = (date: any) => {
    stateHandler({
      startDate: moment(new Date(date[0])).format("YYYYMMDD"),
      endDate: moment(new Date(date[1])).format("YYYYMMDD"),
      isRange: true,
    });
  };

  const periods = [
    { id: 1, name: "Weekly" },
    { id: 2, name: "Monthly" },
    { id: 3, name: "Yearly" },
  ];

  const onSelectPeriod = (period: any) => {
    if (period?.name === "Weekly") {
      stateHandler({
        startDate: moment().startOf("isoWeek").format("YYYYMMDD"),
        endDate: moment().endOf("isoWeek").format("YYYYMMDD"),
        isRange: true,
      });
    }
    if (period?.name === "Monthly") {
      stateHandler({
        startDate: moment().startOf("month").format("YYYYMMDD"),
        endDate: moment().endOf("month").format("YYYYMMDD"),
        isRange: true,
      });
    }
    if (period?.name === "Yearly") {
      stateHandler({
        startDate: moment().startOf("year").format("YYYYMMDD"),
        endDate: moment().endOf("year").format("YYYYMMDD"),
        isRange: true,
      });
    }
  };

  const onDateChange = (date: any) => {
    stateHandler({
      startDate: moment(new Date(date)).format("YYYYMMDD"),
      endDate: moment(new Date(date)).format("YYYYMMDD"),
      isRange: false,
    });
  };

  const clearDate = () => {
    const period = { id: 1, name: "Weekly" };
    form.resetFields();
    setSelected(period);
    stateHandler({
      startDate: moment(new Date()).format("YYYYMMDD"),
      endDate: moment(new Date()).format("YYYYMMDD"),
      isRange: false,
    });
    setSelected(null);
  };

  return (
    <div className="">
      <div className="h-16 bg-blue-50 flex flex-row justify-between items-center pr-4">
        <div className="flex flex-row items-center">
          <div className="ml-4 text-toyota text-2xl font-bold hidden sm:block md:text-2xl">
            Dashboard
          </div>
          <span className="ml-4 block sm:hidden text-2xl font-bold text-toyota">
            <div className="flex items-center justify-center">
              <button className="" onClick={() => hideMenu()}>
                <AiOutlineMenu color="#4145A8" />
              </button>
              <div className="ml-2 -mt-1">Dashboard</div>
            </div>
          </span>
        </div>
        <div className="flex flex-row items-center">
          <div className="flex items-center mx-2">
            <Popover
              className="cursor:pointer focus:outline-none hover:text-toyota"
              opened={opened}
              onClose={() => setOpened(false)}
              target={
                <div
                  onClick={() => setOpened((o) => !o)}
                  className="cursor-pointer hover:font-extrabold"
                >
                  <div className="flex flex-row justify-center items-center hover:text-blue-800 text-toyota">
                    <div className="">{report?.name}</div>
                    <div className="ml-2">
                      <BsChevronDown color={"#4145A8"} />
                    </div>
                  </div>
                </div>
              }
              width={260}
              position="bottom"
              withArrow
            >
              <div className="flex flex-col">
                {reports?.map((site: any) => (
                  <button
                    className="cursor-pointer hover:text-toyota text-left focus:outline-none py-1"
                    onClick={() => {
                      setReport(site);
                      setOpened((o) => !o);
                    }}
                    key={site.id}
                  >
                    {site?.name}
                  </button>
                ))}
              </div>
            </Popover>
          </div>
          <div className="z-50">
            <Profile isVisible={isVisible} setVisible={setVisible} />
          </div>
        </div>
      </div>

      <header className="bg-gray-50 px-4 sm:px-4 lg:px-4 border-t py-2 border-b border-gray-200 flex flex-col items-center  sm:flex-row  sm:justify-between">
        <div className="flex mb-2 sm:mb-0">
          <Form layout="inline" form={form}>
            {showRange ? (
              <div className="flex justify-center items-center">
                <Form.Item name="ranger">
                  <RangePicker
                    allowClear={true}
                    onChange={onRangeChange}
                    format={"DD-MM-YYYY"}
                  />
                </Form.Item>

                <Checkbox onChange={() => setShowRange(!showRange)}>
                  <span className="text-gray-500">Date as Range</span>
                </Checkbox>
              </div>
            ) : (
              <div className="flex justify-center items-center">
                <Form.Item name="picker">
                  <DatePicker
                    onChange={onDateChange}
                    format={"DD-MM-YYYY"}
                    placeholder="Specific date"
                  />
                </Form.Item>
                <Checkbox onChange={() => setShowRange(!showRange)}>
                  <span className="text-gray-500">Date as Range</span>
                </Checkbox>
              </div>
            )}
            <Button className="" onClick={() => clearDate()}>
              <RiFilterOffLine color="#6b7280" />
            </Button>
          </Form>
        </div>
        <div className="flex">
          <div className="flex flex-row">
            {periods?.map((period: any, index: number) => (
              <button
                className={`flex justify-center items-center border text-sm border-gray-300 py-1 px-4 ml-2 ${
                  selected?.id === period.id ? `bg-red-100` : ``
                }`}
                key={index}
                onClick={() => {
                  setSelected(period);
                  onSelectPeriod(period);
                }}
              >
                <span>
                  <BsCalendarCheck />
                </span>
                <span className="ml-2">{period.name}</span>
              </button>
            ))}
          </div>
        </div>
      </header>
    </div>
  );
}
