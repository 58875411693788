import { useContext, useState } from "react";
import { Button, Modal, Form, message } from "antd";
import { AuthContext } from "../../../contexts/AuthContext";
import { BsCheck2 } from "react-icons/bs";
import TextArea from "antd/lib/input/TextArea";
import patchDataReq from "../../../services/patchDataReq";

export default function OverrideOrder({
  isVisible,
  setVisible,
  refetch,
  order,
}: any) {
  const { auth } = useContext(AuthContext);
  const [approval, setApproval] = useState<any>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  const approvals = [
    { id: 1, name: "Approve" },
    { id: -1, name: "Reject" },
  ];

  const onSubmit = async () => {
    setLoading(true);
    const { remarks } = form.getFieldsValue();
    const data = { remarks };
    const action = approval.id === -1 ? "reject" : "approve";
    const uri = `api/v1/orders/${order?._id}/override/${action}`;
    const response = await patchDataReq({ data, uri, token: auth?._id });
    if (response.success) {
      message.success("Updated Successfully");
      await refetch();
      setLoading(false);
    }else{
      message.error("Something went wrong");
    }
    setVisible(false);
    setLoading(false);
  };

  const hideModal = async () => {
    setVisible(false);
  };

  return (
    <Modal
      open={isVisible}
      title="Override Approve LPO"
      onCancel={hideModal}
      footer={[
        <Button danger key="back" onClick={hideModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="approveReq"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
        >
          Submit
        </Button>,
      ]}
    >
      <Form id="approveReq" form={form} onFinish={onSubmit} layout="vertical">
        <div className="px-4">
          <div className="flex justify-between items-center mb-4">
            {approvals?.map((item: any, index: number) => (
              <div
                key={index}
                onClick={() => setApproval(item)}
                className="flex items-center hover:bg-gray-100 hover:cursor-pointer py-2"
              >
                {approval?.id === item?.id ? (
                  <div className="flex justify-center items-center h-8 w-8 border bg-green-100">
                    <BsCheck2 />
                  </div>
                ) : (
                  <div className="h-8 w-8 border"></div>
                )}
                <div className="ml-2">{item?.name}</div>
              </div>
            ))}
          </div>
          {approval?.id === -1 ? (
            <Form.Item
              name="remarks"
              label={"Remarks"}
              rules={[
                {
                  required: true,
                  message: "Please enter remarks",
                },
              ]}
            >
              <TextArea placeholder="Remarks" className="w-full" />
            </Form.Item>
          ) : (
            <Form.Item
              name="remarks"
              label={"Remarks ( Optional )"}
              rules={[
                {
                  required: false,
                  message: "Please enter remarks",
                },
              ]}
            >
              <TextArea placeholder="Remarks" className="w-full" />
            </Form.Item>
          )}
        </div>
      </Form>
    </Modal>
  );
}
