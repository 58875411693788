import { useEffect } from "react";
import { Button, Modal, Form, message } from "antd";
import postDataTrace from "../../../../services/postDataTrace";

export default function DeleteUser({
  user,
  isVisible,
  setVisible,
  refetch,
}: any) {
  const [form] = Form.useForm();

  const onSubmit = async () => {
    message.error("Sorry, you cant delete user account");
    return;
    const uri = `users/${user?._id}`;
    const data = { _id: user?._id };
    const response = await postDataTrace(data, uri);
    if (response.success) {
      message.success("User deleted successfully");
      refetch();
    }
    setVisible(false);
  };

  const hideModal = async () => {
    setVisible(false);
  };
  useEffect(() => {}, [user]);

  return (
    <Modal
      open={isVisible}
      title="Delete User"
      onCancel={hideModal}
      footer={[
        <Button danger key="back" onClick={hideModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="DeleteUser"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          danger
        >
          Delete
        </Button>,
      ]}
    >
      <Form id="DeleteUser" form={form} onFinish={onSubmit}>
        <div>
          <p className="text-center font-bold">
            Are you sure you want to delete account for
            <p className="text-red-500 ml-1">{user?.name}</p>
          </p>
        </div>
      </Form>
    </Modal>
  );
}
