import { Button, Form, message, Space, Drawer } from "antd";
import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handleDeleteUnit } from "../../../../api/units";
import { useNavigate } from "react-router";

export default function DeleteUnit({ isVisible, setVisible, unit }: any) {
  const [isLoading, setLoading] = useState(false);

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const { mutate } = useMutation({
    mutationFn: handleDeleteUnit,
    onSuccess: () => {
      message.success("Deleted Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["units"] });
      setVisible(false);
      navigate(-1);
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    setLoading(true);
    const { name, segment } = await form.getFieldsValue();
    const data = {
      name,
      segment,
    };
    await mutate({ _id: unit?._id, data });
    setLoading(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [unit]);

  return (
    <Drawer
      open={isVisible}
      title={`DELETE UNIT`}
      placement={"right"}
      width={800}
      onClose={() => setVisible(!isVisible)}
      closable={false}
      extra={
        <Space className="flex justify-center items-center">
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <Form
        id="deleteUnit"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div>
          <p className="text-start font-bold">
            Are you sure you want to delete this Business Unit
          </p>
          <p className="text-start text-red-500 font-bold ml-1">{unit?.name}</p>
        </div>

        <Form.Item>
          <Button danger type="primary" htmlType="submit" className="mr-2">
            {isLoading ? "Deleting ..." : "Delete"}
          </Button>
          <Button danger htmlType="button" onClick={() => setVisible(false)}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
