import { Button, Modal, Form, Row, Col, Input, message } from "antd";
import { useState } from "react";
import getBase64 from "../../../utils/getBase64";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handleCreateSupplier } from "../../../api/suppliers";
import formatMobile from "../../../utils/formatMobile";

export default function CreateSupplier({ isVisible, setVisible, pagination, params }: any) {
  const [isLoading, setLoading] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedFile, setSelectedFile]: any = useState();
  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const changeHandler = (event: any) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const { mutate } = useMutation({
    mutationFn: handleCreateSupplier,
    onSuccess: () => {
      message.success("Created Successfully");
      form.resetFields();
      queryClient.invalidateQueries({
        queryKey: ["suppliers", { pagination, params }],
      });
      setVisible(false);
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    setLoading(true);
    const { name, website, email, mobile, address, tin, vrn } =
      await form.getFieldsValue();

    const { mob, inValid } = await formatMobile(mobile);
    if (inValid || mob === null) {
      message.error("Please provide a valid number");
      return;
    }

    let base64 = null;
    if (selectedFile) {
      base64 = await getBase64(selectedFile);
    }
    const data = {
      isActive: true,
      name,
      logo: base64,
      website,
      email,
      mobile: mob,
      address,
      tin,
      vrn,
    };

    await mutate({ data });
    setLoading(false);
  };

  const clearForm = async () => {
    form.resetFields();
    setLoading(false);
  };

  return (
    <Modal
      open={isVisible}
      title="New Supplier"
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="createSupplier"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="createSupplier"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Please enter supplier name",
                },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="website"
              label="Website"
              rules={[
                {
                  required: false,
                  message: "Please enter website",
                },
              ]}
            >
              <Input placeholder="Website" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: "Please enter email address",
                },
              ]}
            >
              <Input placeholder="Email address" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="mobile"
              label="Phone"
              rules={[
                {
                  required: true,
                  message: "Please enter phone number",
                },
              ]}
            >
              <Input placeholder="Phone number" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="address"
              label="Address"
              rules={[
                {
                  required: false,
                  message: "Please enter physical address",
                },
              ]}
            >
              <Input placeholder="Address" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="tin"
              label="TIN"
              rules={[
                {
                  required: false,
                  message: "Please enter TIN",
                },
              ]}
            >
              <Input placeholder="TIN" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="vrn"
              label="VRN"
              rules={[
                {
                  required: false,
                  message: "Please enter VRN",
                },
              ]}
            >
              <Input placeholder="Email address" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="file"
              label="Supplier Logo"
              rules={[
                {
                  required: false,
                  message: "Please select file",
                },
              ]}
            >
              <Input
                type="file"
                accept="image/png, image/gif, image/jpeg"
                placeholder="Document"
                onChange={changeHandler}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
