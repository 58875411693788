import { BsSearch } from "react-icons/bs";
import { Button, Form, Input, Popover, Table, Tooltip, message } from "antd";
import {
  RiFileExcel2Line,
  RiFilterOffLine,
  RiMailSendLine,
} from "react-icons/ri";
import moment from "moment";
import { useEffect, useState } from "react";
import PageToolnav from "../../components/toolnav/PageToolnav";
import { BiLoader } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import {
  ExportToExcel,
  blobToDataUrl,
  getExportData,
} from "../../utils/exportExcel";
import { useQuery } from "@tanstack/react-query";
import { handleFetchSuppliers } from "../../api/suppliers";
import postDataMail from "../../services/postDataMail";
import getAllSuppliers from "../../services/getAllSuppliers";
import Something from "../../components/shared/Something";

export default function AllSuppliers() {
  const [isMailLoading, setMailLoading] = useState<boolean>(false);
  const [messageModal, toggleMessageModal] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  const navigate = useNavigate();

  const [form] = Form.useForm();

  const [emailForm] = Form.useForm();

  const params = `&name=${searchKey}`;

  const initial = {
    page: 1,
    skip: 0,
    pageSize: 10,
    total: 0,
  };
  const [pagination, setPagination] = useState(initial);

  const { data, isFetched, isLoading, isFetching, isError, refetch }: any =
    useQuery({
      queryKey: ["suppliers", { pagination, params }],
      queryFn: () => handleFetchSuppliers(pagination, params),
    });

  const onChange = (params: any) => {
    const skip = params?.current * params?.pageSize - params?.pageSize;
    setPagination({
      ...pagination,
      page: params?.current,
      pageSize: params?.pageSize,
      skip: skip,
      total: data?.payload?.count || pagination.total,
    });
  };

  const columns = [
    {
      title: "S/N",
      width: 40,
      render: (record: any, text: any, index: any) => (
        <span className="text-gray-700 ml-2">
          {pagination?.page * pagination?.pageSize -
            pagination?.pageSize +
            index +
            1}
        </span>
      ),
    },
    {
      title: "Name",
      width: 90,
      render: (record: any) => {
        return <span className="text-gray-700">{record?.name || "-"}</span>;
      },
    },
    {
      title: "Email",
      width: 110,
      render: (record: any) => (
        <span className="text-gray-700">{record?.email || "-"}</span>
      ),
    },
    {
      title: "Mobile",
      width: 70,
      render: (record: any) => (
        <span className="text-gray-700">{record?.mobile || "-"}</span>
      ),
    },
    {
      title: "Address",
      width: 70,
      render: (record: any) => (
        <span className="text-gray-700">{record?.address || "-"}</span>
      ),
    },
    {
      title: "TIN",
      width: 70,
      render: (record: any) => (
        <span className="text-gray-700">{record?.tin || "-"}</span>
      ),
    },
    {
      title: "VRN",
      width: 70,
      render: (record: any) => (
        <span className="text-gray-700">{record?.vrn || "-"}</span>
      ),
    },
    {
      title: "Website",
      width: 140,
      render: (record: any) => (
        <span className="text-gray-700">{record?.website || "-"}</span>
      ),
    },
  ];

  const sendMail = async (values: any) => {
    setMailLoading(true);
    const { payload } =
      await getAllSuppliers();

    if (!payload) {
      return;
    }
    const data = payload?.records?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Name: item?.name || "-",
        Email: item?.email || "-",
        Mobile: item?.mobile || "-",
        Address: item?.address || "-",
        TIN: item?.tin || "-",
        VRN: item?.vrn || "-",
        Website: item?.website || "-",
        Created: moment(item?.createdAt).format("DD/MM/YYYY"),
      };
    });

    const raw = getExportData(data);
    const uri = await blobToDataUrl(raw);

    const maildata = {
      email: values.email,
      subject: values.subject,
      data: uri,
    };

    const mailuri = "mail";

    await postDataMail(maildata, mailuri);
    setMailLoading(false);

    message.success("Email Sent Successfully");
    emailForm.resetFields();
    toggleMessageModal(false);
  };

  const fetchAllSuppliers = async () => {
    const { payload } =
      await getAllSuppliers();
    if (payload) {
      ExportToExcel(
        payload?.records?.map((item: any, index: number) => {
          return {
            "S/N": index + 1,
            Name: item?.name || "-",
            Email: item?.email || "-",
            Mobile: item?.mobile || "-",
            Website: item?.website || "-",
            Address: item?.address || "-",
            TIN: item?.tin || "-",
            VRN: item?.vrn || "-",
            Created: moment(item?.createdAt).format("DD/MM/YYYY"),
          };
        }),
        "Suppliers.xlsx"
      );
    }
  };

  useEffect(() => {
    if (data?.payload?.count) {
      setPagination({
        ...pagination,
        total: data?.payload?.count,
      });
    }
  }, [isFetched]);

  return (
    <div className="flex">
      <div className="flex flex-1 flex-col">
        <PageToolnav
          link={{ name: "Suppliers", to: "/suppliers" }}
          title={"Suppliers"}
          actions={
            <div className="flex">
              <div className="flex mr-2">
                <Form layout="inline" form={form}>
                  <Form.Item name="name">
                    <Input
                      name="searchKey"
                      onChange={(e: any) => {
                        setSearchKey(e.target.value);
                      }}
                      type="text"
                      placeholder="Search by name"
                    />
                  </Form.Item>
                </Form>
                <Button
                  className="-ml-4 focus:outline-none"
                  onClick={() => {
                    if (searchKey) {
                      form.resetFields();
                      setSearchKey("");
                      handleFetchSuppliers(pagination, params);
                    }
                  }}
                >
                  {searchKey ? (
                    <RiFilterOffLine color={"#f87171"} />
                  ) : (
                    <BsSearch color={"#9ca3af"} />
                  )}
                </Button>
              </div>
              <Popover
                content={
                  <>
                    <Form
                      name="basic"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                      initialValues={{ remember: true }}
                      form={emailForm}
                      onFinish={sendMail}
                      autoComplete="off"
                    >
                      <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                          { required: true, message: "Email is Required" },
                        ]}
                      >
                        <Input placeholder="Email" />
                      </Form.Item>
                      <Form.Item
                        label="Subject"
                        name="subject"
                        rules={[
                          { required: true, message: "Subject is Required" },
                        ]}
                      >
                        <Input placeholder="Email Subject" />
                      </Form.Item>
                      <div className="flex flex-row justify-between">
                        <Form.Item wrapperCol={{ offset: 12, span: 8 }}>
                          <Button type="primary" htmlType="submit">
                            {isMailLoading ? "Sending ..." : "Submit"}
                          </Button>
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 0, span: 8 }}>
                          <Button
                            onClick={() => {
                              toggleMessageModal(false);
                            }}
                          >
                            Cancel
                          </Button>
                        </Form.Item>
                      </div>
                    </Form>
                  </>
                }
                title="Message Content"
                trigger="focus"
                placement="left"
                open={messageModal}
              >
                <Tooltip title="Send Email">
                  <button
                    className="flex bg-white mr-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                    onClick={async () => {
                      toggleMessageModal(true);
                    }}
                  >
                    <RiMailSendLine size={21} />
                  </button>
                </Tooltip>
              </Popover>
              <Tooltip title="Export To Excel">
                <button
                  color="#E50000"
                  className="flex bg-white mx-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                  onClick={() => fetchAllSuppliers()}
                >
                  {isFetching ? (
                    <BiLoader size={20} />
                  ) : (
                    <RiFileExcel2Line size={20} />
                  )}
                </button>
              </Tooltip>
            </div>
          }
        />
        <div className="min-h-screen">
          {isError ? (
            <Something refetch={refetch} isFetching={isFetching} />
          ) : (
            <div className="h-screen flex">
              <div className="flex flex-col mx-4 my-2">
                <div className="flex flex-col w-full">
                  <div className="grid gap-x-0 grid-cols-12">
                    <div className="col-span-12 sm:col-span-12 md:col-span-12 border bg-white">
                      <Table
                        size="middle"
                        dataSource={isLoading ? [] : data?.payload?.records}
                        columns={columns}
                        scroll={{ y: 660, x: 0 }}
                        rowKey="id"
                        onRow={(record) => {
                          return {
                            onClick: () => {
                              navigate(`/suppliers/${record?._id}`);
                            },
                          };
                        }}
                        rowClassName={"hover:cursor-pointer hover:bg-blue-50"}
                        loading={isLoading}
                        pagination={{
                          current: pagination?.page,
                          pageSize: pagination?.pageSize,
                          total: data?.payload?.count || pagination?.total,
                        }}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
