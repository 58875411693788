import PropTypes from "prop-types";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Button } from "antd";
import LineItem from "./LineItem";

function LineItems(props: any) {
  const handleDragEnd = (result: any) => {
    if (!result.destination) return;
    const reorder = (list: any, startIndex: any, endIndex: any) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    };
    const lineItems = reorder(
      props.items,
      result.source.index,
      result.destination.index
    );
    props.reorderHandler(lineItems);
  };

  const {
    items,
    accounts,
    deleteHandler,
    addHandler,
    reorderHandler,
    ...functions
  } = props;

  return (
    <form>
      <div className="text-xs">
        <div className="grid grid-cols-12 border-b">
          <div className="col-span-1 p-2 font-bold border-r  text-center">
            S/n
          </div>
          <div className="col-span-3 p-2 font-bold border-r  text-left">
            Item Name / Code
          </div>
          <div className="col-span-4 p-2 font-bold border-r  text-left">
            Item Description
          </div>
          <div className="col-span-2 p-2 font-bold border-r  text-left">
            Purpose
          </div>
          <div className="col-span-1 p-2 font-bold border-r  text-center">
            Qty
          </div>
          <div className="col-span-1 p-2 font-bold  text-right"></div>
        </div>

        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div ref={provided.innerRef} className="border-b">
                {props.items.map((item: any, i: any) => (
                  <Draggable
                    key={item.sn}
                    draggableId={item._id || item?.sn}
                    index={i}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={provided.draggableProps.style}
                        className={""}
                      >
                        <LineItem
                          style={{ color: "red" }}
                          key={i + item.sn}
                          index={i}
                          item={item}
                          stockitems={props.stockitems}
                          changeHandler={props.changeHandler}
                          deleteHandler={props.deleteHandler}
                          {...functions}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      <div className="flex justify-end m-3">
        <Button onClick={addHandler}>+ Add</Button>
      </div>
    </form>
  );
}

export default LineItems;

LineItems.propTypes = {
  items: PropTypes.any,
  stockitems: PropTypes.any,
  currencyFormatter: PropTypes.func.isRequired,
  addHandler: PropTypes.func.isRequired,
  changeHandler: PropTypes.func.isRequired,
  focusHandler: PropTypes.func.isRequired,
  deleteHandler: PropTypes.func.isRequired,
  reorderHandler: PropTypes.func.isRequired,
};
