import { useEffect, useState } from "react";
import formatMoney from "../../../utils/formatMoney";
import { RiDeleteBin4Line } from "react-icons/ri";
import { IoCloudUploadOutline } from "react-icons/io5";
import { Button, message } from "antd";
import { FiEdit } from "react-icons/fi";
import pdf_avatar from "../../../assets/images/pdf.png";
import { BsCheck2, BsDownload } from "react-icons/bs";
import formatSize from "../../../utils/formatSize";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  handleOrderPrice,
  handleOrderRemarks,
  handleSelectSupplier,
} from "../../../api/orders";
import AssignSupplier from "./AssignSupplier";

export default function AmendOrder({ order }: any) {
  const [isExempted, setExempted] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [type, setType] = useState<boolean>(false);

  const [supplierModal, setSupplierModal] = useState<boolean>(false);
  const [uploadFileModal, setUploadFileModal] = useState<boolean>(false);
  const [deleteFileModal, setDeleteFileModal] = useState<boolean>(false);
  const [totals, setTotals] = useState<any>({});
  const queryClient = useQueryClient();

  const { mutate: selectOrderSupplier } = useMutation({
    mutationFn: handleSelectSupplier,
    onSuccess: () => {
      message.success("Assigned Successfully");
      queryClient.invalidateQueries({ queryKey: ["orders", order?._id] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const { mutate: updateOrderPrice } = useMutation({
    mutationFn: handleOrderPrice,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["orders", order?._id] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const { mutate: updateOrderRemarks } = useMutation({
    mutationFn: handleOrderRemarks,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["orders", order?._id] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const selectSupplier = async ({ type, supplier_id, supplier_name }: any) => {
    const data = { type, supplier_id, supplier_name };
    await selectOrderSupplier({ data, _id: order?._id, supplier_id });
  };

  const updatePrice = async ({ item_id, type, price }: any) => {
    const data = { type, price };
    await updateOrderPrice({ data, _id: order?._id, item_id });
  };

  const updateRemarks = async ({ item_id, remarks }: any) => {
    const data = { type, remarks };
    await updateOrderRemarks({ data, _id: order?._id, item_id });
  };

  const getTotalAmount = (items: any) => {
    let nettotal = 0;
    let vat = 0;
    let grandtotal = 0;

    if (items) {
      items?.forEach((item: any) => {
        if (!item.isConverted) {
          nettotal =
            nettotal +
            item?.quantity * Number(item?.price) * (1 - item?.discount / 100);
        }
      });
      vat = isExempted ? 0 : nettotal * 0.18;
      grandtotal = nettotal + vat;

      return { nettotal, vat, grandtotal };
    }
  };

  const handleGetTotals = (items: any) => {
    const num = getTotalAmount(items);
    setTotals({ net: num?.nettotal, vat: num?.vat, grand: num?.grandtotal });
  };

  useEffect(() => {
    handleGetTotals(order?.items);
  }, [order?.items]);

  return (
    <div className="">
      <div className="col-span-12 sm:col-span-12 md:col-span-12 bg-white">
        <div className="flex justify-between mt-2 w-full">
          <div className="w-1/2 flex  bg-gray-50">
            <SupplierItem
              type={"l1"}
              editMode={editMode}
              handleAssignSupplier={selectSupplier}
              setSupplierModal={setSupplierModal}
              setUploadFileModal={setUploadFileModal}
              setDeleteFileModal={setDeleteFileModal}
              setType={setType}
              supplier={order?.suppliers?.l1}
            />
          </div>
          <div className="w-1/2 flex bg-gray-50 mx-2">
            <SupplierItem
              type={"l2"}
              editMode={editMode}
              handleAssignSupplier={selectSupplier}
              setSupplierModal={setSupplierModal}
              setUploadFileModal={setUploadFileModal}
              setDeleteFileModal={setDeleteFileModal}
              setType={setType}
              supplier={order?.suppliers?.l2}
            />
          </div>
          <div className="w-1/2 flex  bg-gray-50">
            <SupplierItem
              type={"l3"}
              editMode={editMode}
              handleAssignSupplier={selectSupplier}
              setSupplierModal={setSupplierModal}
              setUploadFileModal={setUploadFileModal}
              setDeleteFileModal={setDeleteFileModal}
              setType={setType}
              supplier={order?.suppliers?.l3}
            />
          </div>
        </div>
      </div>
      <div className="">
        <div className="grid grid-cols-12 border border-black font-bold">
          <div className="col-span-2  border-r border-black px-1 flex">
            <div className="border-r border-black px-1 w-10">S/N.</div>
            <div className="flex-1 pl-1">Item Code</div>
          </div>
          <div className="col-span-3  border-r border-black px-1">
            Item Description
          </div>
          <div className="col-span-1  border-r border-black px-1 text-center">
            Qty
          </div>
          <div className="col-span-1  border-r border-black px-1 text-center">
            L1
          </div>
          <div className="col-span-1  border-r border-black px-1 text-center">
            L2
          </div>
          <div className="col-span-1  border-r border-black px-1 text-center">
            L3
          </div>
          <div className="col-span-1  border-r border-black px-1 text-center">
            Selected
          </div>
          <div className="col-span-2 px-1">Remarks</div>
        </div>
        {order?.items?.map((item: any, index: number) => (
          <div key={index} className="grid grid-cols-12 border border-black">
            <div className="col-span-2  border-r border-black px-1 flex">
              <div className="border-r border-black px-1 w-10 text-center">
                {index + 1}.
              </div>
              <div className="flex-1 pl-1"> {item?.name}</div>
            </div>
            <div className="col-span-3  border-r border-black p-1">
              {item?.description}
            </div>
            <div className="col-span-1  border-r border-black p-1 text-center">
              {item?.quantity}
            </div>
            <div className="col-span-1  border-r border-black p-1 text-center">
              <SupplierPrice
                type={"l1"}
                item={item.l1}
                price={item?.price}
                index={index}
                exists={order?.suppliers?.l1?.name ? true : false}
                item_id={item?._id}
                updatePrice={updatePrice}
              />
            </div>
            <div className="col-span-1  border-r border-black p-1 text-center">
              <SupplierPrice
                type={"l2"}
                item={item.l2}
                price={item?.price}
                item_id={item?._id}
                index={index}
                exists={order?.suppliers?.l2?.name ? true : false}
                updatePrice={updatePrice}
              />
            </div>
            <div className="col-span-1  border-r border-black p-1 text-center">
              <SupplierPrice
                type={"l3"}
                item={item.l3}
                price={item?.price}
                item_id={item?._id}
                index={index}
                exists={order?.suppliers?.l3?.name ? true : false}
                updatePrice={updatePrice}
              />
            </div>
            <div className="col-span-1  border-r border-black p-1 text-center uppercase">
              {item?.supplier}
            </div>
            <div className="col-span-2 p-1 text-right">
              <SupplierRemark item={item} updateRemarks={updateRemarks} />
            </div>
          </div>
        ))}
        <div className="grid grid-cols-12 border border-black">
          <div className="col-span-1"></div>
          <div className="col-span-1"></div>
          <div className="col-span-4"></div>
          <div className="col-span-1"></div>
          <div className="col-span-2 border-r border-black"></div>
          <div className="col-span-1 border-r border-black px-1"></div>
          <div className="col-span-2 text-right px-1 text-white">-</div>
        </div>
        <div className="grid grid-cols-12 border border-black">
          <div className="col-span-1"></div>
          <div className="col-span-1"></div>
          <div className="col-span-4"></div>
          <div className="col-span-1"></div>
          <div className="col-span-2 border-r border-black"></div>
          <div className="col-span-1 border-r border-black px-1">Net Total</div>
          <div className="col-span-2 text-right px-1">
            {formatMoney(isNaN(totals?.net) ? 0 : totals?.net)}
          </div>
        </div>
        {!isExempted && (
          <div className="grid grid-cols-12 border-l border-r border-b border-black">
            <div className="col-span-1"></div>
            <div className="col-span-4"></div>
            <div className="col-span-2"></div>
            <div className="col-span-2 border-r border-black"></div>
            <div className="col-span-1 border-r border-black px-1">Add VAT</div>
            <div className="col-span-2 text-right px-1">
              {formatMoney(isNaN(totals?.vat) ? 0 : totals?.vat)}
            </div>
          </div>
        )}
        <div className="grid grid-cols-12 border border-black">
          <div className="col-span-1 p-1"></div>
          <div className="col-span-4 p-1"></div>
          <div className="col-span-2 p-1"></div>
          <div className="col-span-2 p-1 border-r border-black"></div>
          <div className="col-span-1 border-r border-black p-1 font-bold">
            Grand Total
          </div>
          <div className="col-span-2 p-1 text-right">
            <div className="col-span-2 p-1 text-right">
              {formatMoney(isNaN(totals?.grand) ? 0 : totals?.grand)}
            </div>
          </div>
        </div>
      </div>

      <AssignSupplier
        isVisible={supplierModal}
        setVisible={setSupplierModal}
        order={order}
        type={type}
      />
    </div>
  );
}

const SupplierItem = ({
  type,
  supplier,
  setType,
  setSupplierModal,
  setUploadFileModal,
  setDeleteFileModal,
  handleAssignSupplier,
}: any) => {
  return (
    <div
      className={`px-4 py-2 mb-2 w-full border ${
        supplier?.isSelected ? "bg-green-100" : ""
      }`}
    >
      <div className="flex justify-between border-b pb-2">
        <button
          onClick={() =>
            handleAssignSupplier({
              type,
              supplier_id: supplier?.id,
              supplier_name: supplier?.name,
            })
          }
          className="flex items-center flex-1"
        >
          <div className="w-4 h-4 border border-gray-400 flex justify-center items-center">
            {supplier?.isSelected ? <BsCheck2 size={18} color={"green"} /> : ""}
          </div>
          <div className="flex ml-1 items-center">
            <span className="uppercase">{type}:</span>{" "}
            <span>{supplier?.name || ""}</span>
          </div>
        </button>
        <Button
          onClick={() => {
            setType(type);
            setSupplierModal(true);
          }}
        >
          <FiEdit />
        </Button>
      </div>
      {supplier?.name ? (
        <div className="mt-2">
          {supplier?.attachment ? (
            <div className="flex justify-between">
              <button
                onClick={() => {
                  setType(type);
                  setUploadFileModal(true);
                }}
                className="flex"
              >
                <div className="mr-2">
                  <img src={pdf_avatar} alt="Logo" height={15} width={28} />
                </div>
                <div className="flex flex-col items-start">
                  <div className="">Quotation</div>
                  {supplier?.attachment?.size ? (
                    <div className="text-xs">
                      {formatSize(supplier?.attachment?.size)}
                    </div>
                  ) : (
                    <div className="text-xs">-</div>
                  )}
                </div>
              </button>
              <div className="flex justify-end items-center">
                <a
                  href={`${supplier?.attachment?.link}`}
                  target="_blank"
                  className="ml-4 hover:text-green-600 text-black"
                >
                  <BsDownload />
                </a>

                <div className="ml-2">
                  <button
                    className="flex items-center rounded-none h-5 hover:bg-red-50"
                    style={{ fontSize: 12 }}
                    onClick={() => {
                      setType(type);
                      setDeleteFileModal(true);
                    }}
                  >
                    <RiDeleteBin4Line size={17} color="#FF251A" />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <button
              onClick={() => {
                setType(type);
                setUploadFileModal(true);
              }}
              className="px-2 py-2 flex items-center w-40 text-xs mt-1"
            >
              <div className="">
                <IoCloudUploadOutline />
              </div>
              <div className="ml-1">Upload Quote</div>
            </button>
          )}
        </div>
      ) : (
        <div className=""></div>
      )}
    </div>
  );
};

const SupplierPrice = ({ item, item_id, updatePrice, type, exists }: any) => {
  return (
    <div className="flex flex-col">
      {exists && (
        <div className="flex items-center justify-between">
          <div className="">
            <input
              type="number"
              placeholder=""
              defaultValue={item?.price}
              onChange={(e) =>
                setTimeout(() => {
                  updatePrice({
                    item_id: item_id,
                    type,
                    price: e.target.value,
                  });
                }, 1000)
              }
              // onChange={handleLineItemChange(
              //   index,
              //   type,
              //   item?.isSelected,
              //   price
              // )}
              style={{ width: "100%" }}
              className="border border-gray-300 rounded-sm focus:outline-none text-right"
            />
          </div>
        </div>
      )}
    </div>
  );
};

const SupplierRemark = ({ item, updateRemarks }: any) => {
  return (
    <div className="">
      <input
        type="text"
        placeholder=""
        defaultValue={item?.remarks}
        onChange={(e) =>
          setTimeout(() => {
            updateRemarks({
              item_id: item?._id,
              remarks: e.target.value,
            });
          }, 1000)
        }
        style={{ width: "100%" }}
        className="border border-gray-300 rounded-sm focus:outline-none"
      />
    </div>
  );
};
