import { BsCheck2Circle } from "react-icons/bs";

export default function FotDetails({ isPaid, isPosted }: any) {
  return (
    <div>
      <div className="flex items-center border rounded-md">
        <div className="border-r">
          <div className="p-3">
            <div
              className={`flex justify-center items-center h-8 w-8 border rounded-md`}
              style={{ borderColor: isPaid ? "#16a34a" : "#eab308" }}
            >
              <BsCheck2Circle color={isPaid ? "#16a34a" : "#eab308"} />
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="px-2 pb-1 border-b font-bold text-sm">
            Autoline Status
          </div>
          <div className="flex px-2 border-b text-xs">
            <div className="w-36 border-r py-1">Posted on Autoline</div>
            <div
              className="px-4 py-1"
              style={{ color: isPosted ? "#16a34a" : "#eab308" }}
            >
              {isPosted ? "YES" : "NO"}
            </div>
          </div>
          <div className="flex px-2 text-xs">
            <div className="w-36 border-r py-1">Payment on Autoline</div>
            <div
              className="px-4 py-1"
              style={{ color: isPaid ? "#16a34a" : "#eab308" }}
            >
              {isPaid ? "Fully Paid" : "Not Paid"}
            </div>
          </div>
        </div>
      </div>
      <div className="text-xs mt-4">
        <div className="font-bold">Note :</div>
        <div className="mt-1">
          Please check the details line by line and the Sum amount for fully
          paid LPOs.
        </div>
      </div>
    </div>
  );
}
