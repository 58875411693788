import { memo, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import { FiLoader } from "react-icons/fi";
import { useLocation } from "react-router-dom";
import MenuLink from "./MenuLink";

const SideMenu = () => {
  const [isSearching, setSearching] = useState<boolean>(false);
  const [keyword, setKeyword] = useState<string>("");

  let location = useLocation();

  const handleSearchData = async () => {
    setSearching(true);
    setTimeout(() => {
      setSearching(false);
    }, 4000);
  };

  return (
    <div className="w-56 bg-gray-200">
      <div className="flex flex-col justify-center h-16  bg-blue-50 border-r">
        <div className="flex items-center justify-center h-10 mx-4 p-2 bg-blue-50  rounded-lg">
          <input
            type="text"
            name="keyword"
            value={keyword}
            onChange={(e: any) => setKeyword(e.target.value)}
            className="ml-2 mb-1 bg-blue-50 focus:outline-none text-black w-full"
            placeholder="Search ..."
          />
          {keyword && (
            <>
              {isSearching ? (
                <div className="hover:text-red-800 hover:cursor-pointer text-green-600 mr-2">
                  <FiLoader size={14} onClick={() => setKeyword("")} />
                </div>
              ) : (
                <div className="hover:text-red-800 hover:cursor-pointer text-red-600 mr-2">
                  <AiOutlineClose size={14} onClick={() => setKeyword("")} />
                </div>
              )}
            </>
          )}
          {keyword ? (
            <div
              onClick={() => handleSearchData()}
              className="hover:cursor-pointer hover:text-green-600 text-black border-l border-gray-400 pl-2"
            >
              <BsSearch size={15} />
            </div>
          ) : (
            <BsSearch size={15} color="gray" />
          )}
        </div>
      </div>
      <div className="mx-4">
        <MenuLink
          title={"Structure"}
          to="/manage/structure"
          pathname={location.pathname}
        />
        {/* <MenuLink
          title={"Companies"}
          to="/manage/companies"
          pathname={location.pathname}
        /> */}
        <MenuLink
          title={"Suppliers"}
          to="/manage/suppliers"
          pathname={location.pathname}
        />
        <MenuLink
          title={"Categories"}
          to="/manage/categories"
          pathname={location.pathname}
        />
        <MenuLink
          title={"Make"}
          to="/manage/make"
          pathname={location.pathname}
        />
        <MenuLink
          title={"Stock"}
          to="/manage/stock"
          pathname={location.pathname}
        />
        <MenuLink
          title={"Requisitions"}
          to="/manage/requisitions"
          pathname={location.pathname}
        />
        <MenuLink
          title={"LPOs"}
          to="/manage/orders"
          pathname={location.pathname}
        />
        <MenuLink
          title={"Budget"}
          to="/manage/budget"
          pathname={location.pathname}
        />
        <MenuLink
          title={"Users"}
          to="/manage/users"
          pathname={location.pathname}
        />
      </div>
    </div>
  );
};
export default memo(SideMenu);
