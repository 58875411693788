import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import { BsCheck2Square } from "react-icons/bs";
import { Button, Form, Input, Popover, Table, Tooltip } from "antd";
import { RiFileExcel2Line, RiMailSendLine } from "react-icons/ri";
import { CgCloseR } from "react-icons/cg";
import moment from "moment";
import { ExportToExcel } from "../../utils/exportExcel";
import { useContext, useEffect, useState } from "react";
import fetchDataReq from "../../services/fetchDataReq";
import PageToolnav from "../../components/toolnav/PageToolnav";
import CreateRequisition from "./components/CreateRequisition";
import { AuthContext } from "../../contexts/AuthContext";
import { colors } from "../../utils/data";
import { GrStatusCriticalSmall } from "react-icons/gr";

export default function AllRequisitions() {
  const [requisitions, setRequisitions] = useState<any>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isMailLoading, setMailLoading] = useState<boolean>(false);
  const [messageModal, toggleMessageModal] = useState(false);
  const [createModal, setCreateModal] = useState<boolean>(false);
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    limit: 10,
  });

  const [emailForm] = Form.useForm();

  const handleFetchData = async ({ pagination }: any) => {
    setLoading(true);
    const { current, limit } = pagination;
    const skip = current * limit - limit;

    const uri = `api/v1/requisitions?skip=${skip}&limit=${limit}`;
    const response = await fetchDataReq({ uri, token: auth?._id });
    if (response?.success) {
      setRequisitions(response?.payload?.records);
      setPagination({ total: response?.payload?.count, ...pagination });
    }
    setLoading(false);
  };

  const handleRefetch = () => {
    handleFetchData({
      pagination,
    });
  };

  const handlePageChange = (pagination: any) => {
    handleFetchData({
      pagination,
    });
  };

  const columns = [
    {
      title: "S/N",
      width: 30,
      render: (record: any, text: any, index: any) => (
        <span className="text-gray-700 ml-2">{index + 1}</span>
      ),
    },
    {
      title: "Date",
      width: 50,
      render: (record: any) => {
        return (
          <span className="text-gray-700">
            <Moment format="Do, MMM YYYY">{record?.dates?.created}</Moment>
          </span>
        );
      },
    },
    {
      title: "Req No.",
      width: 40,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.requisitionId ? record?.requisitionId : "-"}
        </span>
      ),
    },

    {
      title: "Company",
      width: 80,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.company ? record?.company?.name : "-"}
        </span>
      ),
    },
    {
      title: "Department",
      width: 50,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.department ? record?.department?.name : "-"}
        </span>
      ),
    },

    {
      title: "Business Unit",
      width: 50,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.unit ? record?.unit?.name : "-"}
        </span>
      ),
    },

    {
      title: "Created By",
      width: 70,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.participants?.creator?.name || "-"}
        </span>
      ),
    },

    {
      title: "Advisor",
      width: 70,
      render: (record: any) => (
        <span className="text-gray-700">{record?.advisor?.name || "-"}</span>
      ),
    },
    {
      title: "Quotation",
      width: 40,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.quotation ? "View" : "-"}
        </span>
      ),
    },
    {
      title: "Status",
      width: 40,
      render: (record: any) => (
        <div className="flex items-center">
          <div className="">
            <GrStatusCriticalSmall color={`${colors[record?.status]}`} />
          </div>
          <div className="ml-1" style={{ color: `${colors[record?.status]}` }}>
            {record?.status}
          </div>
        </div>
      ),
    },
    {
      title: "Converted",
      width: 40,
      render: (record: any) => (
        <div className="flex items-center">
          {record?.flags?.isConverted ? (
            <div className="flex justify-center items-center">
              <BsCheck2Square color="#16a34a" />
              <span className="ml-1 text-green-600">Yes</span>
            </div>
          ) : (
            <div className="flex justify-center items-center">
              <CgCloseR color="#dc2626" />
              <span className="ml-1 text-red-600">No</span>
            </div>
          )}
        </div>
      ),
    },
  ];

  const sendMail = async (values: any) => {
    setMailLoading(true);
    requisitions?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Date:
          item?.dates?.created === null
            ? "-"
            : moment(item?.dates?.created).format("DD/MM/YYYY"),
        Company: item?.company?.name === null ? "-" : item?.company?.name,
        Department: item?.company?.name === null ? "-" : item?.company?.name,
        "Business Unit": item?.unit?.name === null ? "-" : item?.unit?.name,
        "Created By":
          item?.participants?.creator?.name === null
            ? "-"
            : item?.participants?.creator?.name,
        Status: item?.status === null ? "-" : item?.status,
      };
    });
  };

  useEffect(() => {
    handleFetchData({
      pagination,
    });
  }, []);
  return (
    <div className="flex">
      <div className="flex flex-1 flex-col">
        <PageToolnav
          link={{ name: "All Requisitions", to: "/requisitions" }}
          title={"Requisitions"}
          actions={
            <div className="flex">
              <Popover
                content={
                  <>
                    <Form
                      name="basic"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                      initialValues={{ remember: true }}
                      form={emailForm}
                      onFinish={sendMail}
                      autoComplete="off"
                    >
                      <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                          { required: true, message: "Email is Required" },
                        ]}
                      >
                        <Input placeholder="Email" />
                      </Form.Item>
                      <Form.Item
                        label="Subject"
                        name="subject"
                        rules={[
                          { required: true, message: "Subject is Required" },
                        ]}
                      >
                        <Input placeholder="Email Subject" />
                      </Form.Item>
                      <div className="flex flex-row justify-between">
                        <Form.Item wrapperCol={{ offset: 12, span: 8 }}>
                          <Button type="primary" htmlType="submit">
                            {isMailLoading ? "Sending ..." : "Submit"}
                          </Button>
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 0, span: 8 }}>
                          <Button
                            onClick={() => {
                              toggleMessageModal(false);
                            }}
                          >
                            Cancel
                          </Button>
                        </Form.Item>
                      </div>
                    </Form>
                  </>
                }
                title="Message Content"
                trigger="focus"
                placement="left"
                visible={messageModal}
              >
                <Tooltip title="Send Email">
                  <button
                    className="flex mr-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                    onClick={async () => {
                      toggleMessageModal(true);
                    }}
                  >
                    <RiMailSendLine size={21} />
                  </button>
                </Tooltip>
              </Popover>
              <Tooltip title="Export To Excel">
                <button
                  color="#E50000"
                  className="flex justify-center items-center border px-4 py-1 mr-2 hover:bg-gray-100"
                  onClick={() =>
                    ExportToExcel(
                      requisitions?.map((item: any, index: number) => {
                        return {
                          "S/N": index + 1,
                          Date:
                            item?.dates?.created === null
                              ? "-"
                              : moment(item?.dates?.created).format(
                                  "DD/MM/YYYY"
                                ),
                          Company:
                            item?.company?.name === null
                              ? "-"
                              : item?.company?.name,
                          Department:
                            item?.company?.name === null
                              ? "-"
                              : item?.company?.name,
                          "Business Unit":
                            item?.unit?.name === null ? "-" : item?.unit?.name,
                          "Created By":
                            item?.participants?.creator?.name === null
                              ? "-"
                              : item?.participants?.creator?.name,
                          Status: item?.status === null ? "-" : item?.status,
                        };
                      }),
                      "Requisitions.xlsx"
                    )
                  }
                >
                  <RiFileExcel2Line size={20} />
                </button>
              </Tooltip>
              <Button
                className="bg-blue-200"
                onClick={() => setCreateModal(true)}
              >
                + New
              </Button>
            </div>
          }
        />
        <div className="h-screen flex">
          <div className="flex flex-col my-2 mx-4">
            <div className="flex flex-col w-full">
              <div className="grid gap-x-0 grid-cols-12">
                <div className="col-span-12 sm:col-span-12 md:col-span-12 border bg-white">
                  <Table
                    size="middle"
                    rowClassName={(record) =>
                      `${
                        colors[record?.status]
                      } hover:cursor-pointer hover:bg-blue-50`
                    }
                    dataSource={isLoading ? [] : requisitions}
                    columns={columns}
                    pagination={pagination}
                    scroll={{ y: 660, x: 0 }}
                    onChange={handlePageChange}
                    onRow={(record) => {
                      return {
                        onClick: () => {
                          navigate(`/requisitions/${record?._id}`);
                        },
                      };
                    }}
                    rowKey="id"
                    loading={isLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CreateRequisition
        isVisible={createModal}
        setVisible={setCreateModal}
        refetch={handleRefetch}
      />
    </div>
  );
}
