import { IoWarningOutline } from "react-icons/io5";

export default function BadQRCode() {
  return (
    <div className="flex flex-col items-center  pt-20">
      <IoWarningOutline size={40} />
      <div className="text-red-600 font-bold">Sorry</div>
      <div className="text-gray-600">Invalid QR Code</div>
    </div>
  );
}
