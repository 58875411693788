import { Button, Modal, Form, Row, Col, Input, message } from "antd";
import { useContext, useState } from "react";
import { AuthContext } from "../../../../contexts/AuthContext";
import postDataReq from "../../../../services/postDataReq";
import getBase64 from "../../../../utils/getBase64";

export default function CreateCompany({ isVisible, setVisible, refetch }: any) {
  const { auth } = useContext(AuthContext);
  const [isLoading, setLoading] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedFile, setSelectedFile]: any = useState();

  const [form] = Form.useForm();

  const changeHandler = (event: any) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const onSubmit = async () => {
    setLoading(true);
    const values = await form.getFieldsValue();
    let base64 = null;
    if (selectedFile) {
      base64 = await getBase64(selectedFile);
    }
    const data = {
      logo: base64,
      ...values,
    };

    const uri = `api/v1/companies`;
    const response = await postDataReq({ data, uri, token: auth?._id });
    if (response.success) {
      message.success("Created Successfully");
      refetch();
      form.resetFields();

      setVisible(false);
    } else {
      message.error(response.message);
    }
    setLoading(false);
  };

  const clearForm = async () => {
    form.resetFields();
    setLoading(false);
  };

  return (
    <Modal
      open={isVisible}
      title="New Company"
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="createCompany"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="createCompany"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Please enter name",
                },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: false,
                  message: "Please enter email",
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="mobile"
              label="Mobile"
              rules={[
                {
                  required: false,
                  message: "Please enter mobile",
                },
              ]}
            >
              <Input placeholder="Mobile" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="website"
              label="Website"
              rules={[
                {
                  required: false,
                  message: "Please enter website",
                },
              ]}
            >
              <Input placeholder="Website" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="address"
              label="Address"
              rules={[
                {
                  required: false,
                  message: "Please enter address",
                },
              ]}
            >
              <Input placeholder="Address" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="box"
              label="P.O Box"
              rules={[
                {
                  required: false,
                  message: "Please enter postal address",
                },
              ]}
            >
              <Input placeholder="P.O Box" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="telephone"
              label="Telephone"
              rules={[
                {
                  required: false,
                  message: "Please enter telephone",
                },
              ]}
            >
              <Input placeholder="Telephone" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="fax"
              label="Fax"
              rules={[
                {
                  required: false,
                  message: "Please enter fax",
                },
              ]}
            >
              <Input placeholder="Fax" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="tin"
              label="TIN"
              rules={[
                {
                  required: false,
                  message: "Please enter TIN",
                },
              ]}
            >
              <Input placeholder="TIN" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="vrn"
              label="VRN"
              rules={[
                {
                  required: false,
                  message: "Please enter VRN",
                },
              ]}
            >
              <Input placeholder="VRN" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="file"
              label="Logo"
              rules={[
                {
                  required: false,
                  message: "Please select file",
                },
              ]}
            >
              <Input
                type="file"
                accept="image/png, image/gif, image/jpeg"
                placeholder="Document"
                onChange={changeHandler}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
