import Cookies from "js-cookie";
import { REQUI_BASE_URL } from "../utils/urls";

const getAllSuppliers = async () => {
  const token = Cookies.get("token");
  const response = await fetch(`${REQUI_BASE_URL}/api/v1/suppliers`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  const result = await response.json();
  return result;
};

export default getAllSuppliers;
