import { useEffect, useState } from "react";
import { BsCheck2, BsCheck2All } from "react-icons/bs";
import { CgMoreAlt } from "react-icons/cg";
import Moment from "react-moment";
import { VscDash } from "react-icons/vsc";
import fetchDataReq from "../../services/fetchDataReq";
import OrListMetrics from "./components/OrListMetrics";
import OrWeekChart from "./components/OrWeekChart";
import OrMainChart from "./components/OrMainChart";
import OrStatusWidget from "./components/OrStatusWidget";
import OrExpenseChart from "./components/OrExpenseChart";

export default function OrDashboard({ dates, auth }: any) {
  const [isLoading, setLoading] = useState<boolean>(false);

  const [initiatedReq, setInitiatedReq] = useState<any>({
    name: "Initiated",
    count: 0,
    data: [],
  });
  const [rejectedReq, setRejectedReq] = useState<any>({
    name: "Rejected",
    count: 0,
    data: [],
  });
  const [m1Req, setM1Req] = useState<any>({
    name: "M1",
    count: 0,
    data: [],
  });

  const [m2Req, setM2Req] = useState<any>({
    name: "M2",
    count: 0,
    data: [],
  });
  const [m3Req, setM3Req] = useState<any>({
    name: "M3",
    count: 0,
    data: [],
  });
  const [m4Req, setM4Req] = useState<any>({
    name: "M4",
    count: 0,
    data: [],
  });

  const getTotalRecords = (records: any) => {
    let total = 0;
    let count = 0;
    records?.forEach((record: any) => {
      count = count + 1;
      let intotal = 0;
      const isExempted = record?.isExempted;

      const items = record?.items;

      items?.forEach((item: any) => {
        intotal =
          intotal + item?.quantity * item?.price * (1 - item?.discount / 100);
      });
      let sum = isNaN(intotal) ? 0 : intotal;
      let inclusive = isExempted ? sum : sum * 1.18;
      total = inclusive + total;
    });
    return total;
  };

  const status = [
    {
      id: 1,
      name: "Initiated",
      icon_color: "#42d6cc",
      text_color: "#323232",
      background: "#00D0C1",
      count: initiatedReq?.count,
      data: initiatedReq?.data,
      icon: <CgMoreAlt color="#FFFFFF" size={30} />,
    },
    {
      id: 1,
      name: "Rejected",
      icon_color: "#e0262c",
      text_color: "#FFFFFF",
      background: "#d52127",
      count: rejectedReq?.count,
      data: rejectedReq?.data,
      icon: <VscDash color="#FFFFFF" size={30} />,
    },
    {
      id: 1,
      name: "M1",
      icon_color: "#f08e59",
      text_color: "#FFFFFF",
      background: "#EF854B",
      count: m1Req?.count,
      data: m1Req?.data,
      icon: <BsCheck2 color="#FFFFFF" size={30} />,
    },
    {
      id: 1,
      name: "M2",
      icon_color: "#c42dbf",
      text_color: "#FFFFFF",
      background: "#B319AD",
      count: m2Req?.count,
      data: m2Req?.data,
      icon: <BsCheck2All color="#FFFFFF" size={30} />,
    },

    {
      id: 1,
      name: "M3",
      icon_color: "#6182ed",
      text_color: "#FFFFFF",
      background: "#4C71F0",
      count: m3Req?.count,
      data: m3Req?.data,
      icon: <BsCheck2All color="#FFFFFF" size={30} />,
    },
    {
      id: 1,
      name: "M4",
      icon_color: "#08c45c",
      text_color: "#FFFFFF",
      background: "#05B151",
      count: m4Req?.count,
      data: m4Req?.data,
      icon: <BsCheck2 color="#FFFFFF" size={30} />,
    },
  ];

  const handleFetchStatus = async (dates: any) => {
    setLoading(true);
    const response = await fetchDataReq({
      uri: `api/v1/reports/orders?start=${dates?.startDate}&end=${dates?.endDate}`,
      token: auth?._id,
    });

    if (response?.success) {
      const initiated = response.payload.filter(
        (item: any) => item?._id === "Initiated"
      );

      setInitiatedReq({
        name: "Initiated",
        count: getTotalRecords(initiated[0]?.records),
        data: initiated[0] || {},
      });

      const rejected = response.payload.filter(
        (item: any) => item?._id === "Rejected"
      );

      setRejectedReq({
        name: "Rejected",
        count: getTotalRecords(rejected[0]?.records),
        data: rejected[0] || {},
      });

      const m1 = response.payload.filter((item: any) => item?._id === "M1");
      setM1Req({
        name: "M1",
        count: getTotalRecords(m1[0]?.records),
        data: m1[0] || {},
      });

      const m2 = response.payload.filter((item: any) => item?._id === "M2");
      setM2Req({
        name: "M2",
        count: getTotalRecords(m2[0]?.records),
        data: m2[0] || {},
      });

      const m3 = response.payload.filter((item: any) => item?._id === "M3");

      setM3Req({
        name: "M3",
        count: getTotalRecords(m3[0]?.records),
        data: m3[0] || {},
      });

      const m4 = response.payload.filter((item: any) => item?._id === "M4");
      setM4Req({
        name: "M4",
        count: getTotalRecords(m4[0]?.records),
        data: m4[0] || {},
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    handleFetchStatus(dates);
  }, [dates]);

  return (
    <div className="min-h-screen w-full mb-4">
      <div className="p-4 h-auto">
        <div className="grid grid-cols-12 gap-2">
          {status?.map((item: any, index: number) => (
            <OrStatusWidget
              item={item}
              key={index}
              dates={dates}
              isLoading={isLoading}
            />
          ))}
        </div>
        <div className="grid grid-cols-12 gap-x-2 mt-4">
          <div className="col-span-12 sm:col-span-6 border rounded-md bg-white">
            <div className="border-b p-4">Weekly Metrics : LPOs</div>
            <div className="h-80 w-full">
              <OrWeekChart dates={dates} />
            </div>
          </div>
          <div className="col-span-12 sm:col-span-6 border rounded-md bg-white">
            <div className="border-b p-4">
              <div className="flex items-center text-gray-800">
                <div className="text-xl mr-2">Budget : </div>
                {dates?.isRange ? (
                  <div className="text-gray-400">
                    <Moment format="Do MMM, YYYY">{dates?.startDate}</Moment>
                    <span className="px-2">-</span>
                    <Moment format="Do MMM, YYYY">{dates?.endDate}</Moment>
                  </div>
                ) : (
                  <div className="text-gray-400">
                    <Moment format="Do MMM, YYYY">{dates?.startDate}</Moment>
                  </div>
                )}
              </div>
            </div>
            <div className="w-full h-80 pt-6">
              <OrExpenseChart dates={dates} />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-12 gap-x-2 mt-4">
          <div className="col-span-12 sm:col-span-12 border rounded-md bg-white">
            <div className="border-b p-4">Monthly Metrics : LPOs</div>
            <div className="h-auto w-full">
              <OrMainChart dates={dates} />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-12 gap-x-2 mt-4">
          <div className="col-span-12 sm:col-span-6 border rounded-md bg-white">
            <div className="border-b p-4">Companies : LPOs</div>
            <div className="h-auto w-full">
              <OrListMetrics
                dates={dates}
                loading={isLoading}
                type={"company"}
                title={"Company"}
              />
            </div>
          </div>
          <div className="col-span-12 sm:col-span-6 border rounded-md bg-white">
            <div className="border-b p-4">Departments : LPOs</div>
            <div className="h-auto w-full">
              <OrListMetrics
                dates={dates}
                loading={isLoading}
                type={"department"}
                title={"Department"}
              />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-12 gap-x-2 mt-4">
          <div className="col-span-12 sm:col-span-6 border rounded-md bg-white">
            <div className="border-b p-4">Business Units : LPOs</div>
            <div className="h-auto w-full">
              <OrListMetrics
                dates={dates}
                loading={isLoading}
                type={"unit"}
                title={"Business Unit"}
              />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-12 gap-x-2 mt-4">
          <div className="col-span-12 sm:col-span-12 border rounded-md bg-white">
            <div className="bg-blue-100 border-b border-blue-800">
              <div className="border-b p-4 font-bold">Suppliers : LPOs</div>
            </div>
            <div className="h-auto w-full">
              <OrListMetrics
                dates={dates}
                loading={isLoading}
                type={"supplier"}
                title={"Supplier"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
