import { REQUI_BASE_URL } from "../utils/urls";

const fetchDataReq = async ({ uri, token }: any) => {
  const response = await fetch(`${REQUI_BASE_URL}/${uri}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  const result = await response.json();
  return result;
};

export default fetchDataReq;
