import {
  deleteData,
  fetchData,
  patchData,
  postData,
} from "../services/requisition";

const handleFetchDivisions = async () => {
  const uri = `api/v1/business/divisions`;
  const response = fetchData({ uri });
  return response;
};

const handleCreateDivision = async (data: any) => {
  const uri = `api/v1/business/divisions`;
  const response = postData({ data, uri });
  return response;
};
const handleUpdateDivision = async ({ data, _id }: any) => {
  const uri = `api/v1/business/divisions/${_id}`;
  const response = patchData({ data, uri });
  return response;
};

const handleDeleteDivision = async ({ _id }: any) => {
  const uri = `api/v1/business/divisions/${_id}`;
  const response = deleteData({ uri });
  return response;
};

export {
  handleFetchDivisions,
  handleCreateDivision,
  handleUpdateDivision,
  handleDeleteDivision,
};
