import { Button, Modal, Form, Row, Col, Input, message } from "antd";
import { useContext, useState } from "react";
import { AuthContext } from "../../../../contexts/AuthContext";
import postDataReq from "../../../../services/postDataReq";

export default function CreateCategory({
  isVisible,
  setVisible,
  refetch,
}: any) {
  const { auth } = useContext(AuthContext);
  const [isLoading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const onSubmit = async () => {
    setLoading(true);
    const { name } = await form.getFieldsValue();
    const data = {
      name,
    };
    const uri = `api/v1/categories`;
    const response = await postDataReq({ data, uri, token: auth?._id });
    if (response.success) {
      message.success("Created Successfully");
      console.log("GRE-");
      refetch();
      form.resetFields();

      setVisible(false);
    } else {
      message.error(response.message);
    }
    setLoading(false);
  };

  const clearForm = async () => {
    form.resetFields();
    setLoading(false);
  };

  return (
    <Modal
      open={isVisible}
      title="New Category"
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="createCategory"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="createCategory"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Please enter category name",
                },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
