import { useEffect } from "react";
import { Button, Modal, Form, message } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handleDeleteContact } from "../../../api/suppliers";

export default function DeleteContact({ supplier, contact, isVisible, setVisible }: any) {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: handleDeleteContact,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({
        queryKey: ["suppliers", supplier?._id],
      });
      setVisible(false);
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    await mutate({ _id: supplier?._id, contact_id: contact?._id });
    setVisible(false);
  };

  const hideModal = async () => {
    setVisible(false);
  };
  useEffect(() => {}, [contact]);

  return (
    <Modal
      open={isVisible}
      title="Delete Contact"
      onCancel={hideModal}
      footer={[
        <Button danger key="back" onClick={hideModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="deleteContact"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          danger
        >
          {isPending ? "Deleting ..." : "Delete"}
        </Button>,
      ]}
    >
      <Form id="deleteContact" form={form} onFinish={onSubmit}>
        <div>
          <p className="text-center font-bold">
            Are you sure you want to delete this contact person
          </p>
          <p className="text-center text-red-500 font-bold ml-1">
            {contact?.name}
          </p>
        </div>
      </Form>
    </Modal>
  );
}
