import { useContext, useRef, useState } from "react";
import { AiOutlinePrinter } from "react-icons/ai";
import { BsCheck2Square } from "react-icons/bs";
import { GrStatusCriticalSmall } from "react-icons/gr";
import { useParams } from "react-router";
import ReactToPrint from "react-to-print";
import OrderWrapper from "../../components/docum/OrderWrapper";
import TermsWrapper from "../../components/docum/TermsWrapper";
import BackToolnav from "../../components/toolnav/BackToolnav";
import { AuthContext } from "../../contexts/AuthContext";
import { colors } from "../../utils/data";
import ApproveOrder from "./components/ApproveOrder";
import SubmitOrder from "./components/SubmitOrder";
import Timeline from "./components/Timeline";
import Comperative from "./components/Comperative";
import { useQuery } from "@tanstack/react-query";
import { handleFetchOrder } from "../../api/orders";
import OverrideOrder from "./components/OverrideOrder";
import { FiEdit } from "react-icons/fi";
import AmendOrder from "./components/AmendOrder";
import EditToggle from "../../components/shared/EditToggle";
import ConfirmAmend from "./components/ConfirmAmend";

export default function OneOrder() {
  const { id } = useParams();
  const [approveModal, setApproveModal] = useState<boolean>(false);
  const [overrideModal, setOverrideModal] = useState<boolean>(false);
  const [submitModal, setSubmitModal] = useState<boolean>(false);
  const [amendModal, setAmendModal] = useState<boolean>(false);
  const { auth } = useContext(AuthContext);

  const { data }: any = useQuery({
    queryKey: ["orders", id],
    queryFn: () => handleFetchOrder({ _id: id }),
  });


  console.log(data, ":::::::: DATA :::::::")


  const V = data?.payload?.approvals?.validator?.user?.email;
  const M1 = data?.payload?.approvals?.M1?.user?.email;
  const M2 = data?.payload?.approvals?.M2?.user?.email;
  const M3 = data?.payload?.approvals?.M3?.user?.email || "";
  const M4 = data?.payload?.approvals?.M4?.user?.email || "";

  const [editable, setEditable] = useState(false);
  const [amend, setAmend] = useState(false);
  const docRef = useRef(null);

  const submitable = ["Initiated", "Amended"];
  const submitter = ["CH"];
  const approver = ["M1", "M2", "M3", "M4"];
  const approvals = [V, M1, M2, M3, M4];

  return (
    <div className="flex">
      <div className="flex flex-1 flex-col">
        <BackToolnav
          title={"LPOs"}
          actions={
            <>
              {data?.payload?.flags?.isCompleted && (
                <ReactToPrint
                  trigger={() => {
                    return (
                      <button className="flex justify-center border px-2 py-1 items-center focus:outline-none  mr-2 text-sm hover:text-toyota">
                        <AiOutlinePrinter className="mr-1" /> Print LPO
                      </button>
                    );
                  }}
                  content={() => docRef.current}
                  documentTitle={`LPO`}
                />
              )}

              {auth?.canOverride && (
                <button
                  onClick={() => setOverrideModal(true)}
                  className={`flex justify-center items-center border px-2 py-1 ml-2 bg-gray-100
                   `}
                >
                  <div className="">
                    <BsCheck2Square />
                  </div>
                  <div className="ml-1 mb-1">Override Approve</div>
                </button>
              )}
              {data?.payload?.progress === "Invalid" && (
                <>
                  <EditToggle editMode={amend} setEditMode={setAmend} />
                  <button
                    onClick={() => setAmendModal(true)}
                    className={`${
                      amend ? "bg-green-100" : "bg-gray-100"
                    } flex justify-center items-center border px-2 py-1 ml-2 `}
                  >
                    <div className="">
                      <FiEdit />
                    </div>
                    <div className="ml-1 mb-1">Submit</div>
                  </button>
                </>
              )}

              {submitter.includes(auth?.level) && (
                <>
                  {submitable.includes(data?.payload?.status) && (
                    <>
                      {approvals.includes(auth?.email) && (
                        <button
                          onClick={() => setApproveModal(true)}
                          className={`flex justify-center items-center border px-2 py-1 ml-2 bg-gray-100
                `}
                        >
                          <div className="">
                            <BsCheck2Square />
                          </div>
                          <div className="ml-1 mb-1">Approve</div>
                        </button>
                      )}
                    </>
                  )}
                </>
              )}
              {approver.includes(auth?.level) && (
                <>
                  {data?.payload?.status === auth?.level && (
                    <>
                      {approvals.includes(auth?.email) ? (
                        <button
                          onClick={() => setApproveModal(true)}
                          className={`flex justify-center items-center border px-2 py-1 ml-2 bg-gray-100`}
                        >
                          <div className="">
                            <BsCheck2Square />
                          </div>
                          <div className="ml-1 mb-1">Approve</div>
                        </button>
                      ) : null}
                    </>
                  )}
                </>
              )}
            </>
          }
        />

        <div className="my-2 mx-4 min-h-screen">
          <div className="grid grid-cols-12 gap-x-0">
            <div className="col-span-10 sm:col-span-10 md:col-span-10 border bg-white">
              <div className="border-b p-3 w-full flex justify-between items-center">
                <div className="">{data?.payload?.company?.name}</div>
              </div>
              <div className="bg-gray-100">
                <div className="bg-white flex items-start">
                  {data?.payload?.flags?.isCompleted && (
                    <div className="-mt-8 mx-2">
                      <div className="" ref={docRef}>
                        <OrderWrapper
                          order={data?.payload}
                          jobno={1}
                          refetch={handleFetchOrder}
                          editable={editable}
                          setEditable={setEditable}
                        />
                      </div>
                      <TermsWrapper
                        company={data?.payload?.company?.name}
                        supplier={data?.payload?.supplier?.name}
                      />
                    </div>
                  )}
                  <div className="w-full mx-6">
                    {amend ? (
                      <AmendOrder order={data?.payload} />
                    ) : (
                      <Comperative order={data?.payload} />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-2 sm:col-span-2 md:col-span-2 border-t border-b border-r bg-white">
              <div className="border-b p-3 w-full mb-4 flex justify-between">
                <div className="">LPO Timeline</div>
                <div className="text-sm flex items-center justify-center">
                  <div className="">
                    <GrStatusCriticalSmall
                      color={`${colors[data?.payload?.progress]}`}
                    />
                  </div>
                  <div
                    className="ml-1"
                    style={{ color: `${colors[data?.payload?.progress]}` }}
                  >
                    {data?.payload?.progress.split(" ")[0]}
                  </div>
                </div>
              </div>
              <div className="mx-4">
                <Timeline data={data?.payload?.approvals?.validator} />
                <Timeline data={data?.payload?.approvals?.M1} />
                <Timeline
                  data={data?.payload?.approvals?.M2}
                  last={M3 === "" ? true : false}
                />
                {data?.payload?.approvals?.M3 ? (
                  <Timeline
                    data={data?.payload?.approvals?.M3}
                    last={M4 === "" ? true : false}
                  />
                ) : null}
                {data?.payload?.approvals?.M4 ? (
                  <Timeline
                    data={data?.payload?.approvals?.M4}
                    last={M4 === "" ? true : false}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      <SubmitOrder
        isVisible={submitModal}
        setVisible={setSubmitModal}
        refetch={handleFetchOrder}
        order={data?.payload}
      />

      <ApproveOrder
        isVisible={approveModal}
        setVisible={setApproveModal}
        refetch={handleFetchOrder}
        order={data?.payload}
      />

      <ConfirmAmend
        isVisible={amendModal}
        setVisible={setAmendModal}
        order={data?.payload}
      />

      <OverrideOrder
        isVisible={overrideModal}
        setVisible={setOverrideModal}
        refetch={handleFetchOrder}
        order={data?.payload}
      />
    </div>
  );
}
