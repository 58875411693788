const operators: any = {
  " 113 ": "Samweli Kitomari",
  " 69 ": "Anna Mzori",
  " 5 ": "Fatuma Suleiman",
  " 104 ": "Noela Soka",
  " 6 ": "Anna Chaulo",
  " 27 ": "Diana Laizer",
  " 4 ": "Juliana Sendwa",
  " 48 ": "Consolata Begina",
  " 112 ": "Ruth Kulindwa",
  "0113": "Samweli Kitomari",
  "0069": "Anna Mzori",
  "0005": "Fatuma Suleiman",
  "0104": "Noela Soka",
  "0006": "Anna Chaulo",
  "0027": "Diana Laizer",
  "0004": "Juliana Sendwa",
  "0048": "Consolata Begina",
  "0112": "Ruth Kulindwa",
  "0008": "Amit",
  "0009": "Abeid",
  "0010": "zmmbando",
  "0013": "Rukia",
  "0014": "Fransie",
  "0015": "Alinda",
  "0016": "Magayane",
  "0019": "Divesh",
  "0020": "Peter",
  "0022": "Bakari Miraji",
  "0024": "anold",
  "0025": "Korosho",
  "0026": "gloria barnabas",
  "0028": "Khamisi",
  "0029": "Sandeep",
  "0030": "",
  "0031": "",
  "0032": "",
  "0033": "",
  "0034": "",
  "0035": "",
  "0036": "",
  "0037": "",
  "0038": "Malele GGM",
  "0039": "Catherine",
  "0040": "",
  "0044": "",
  "0045": "",
  "0046": "",
  "0047": "Zuhra Mohamed",
  "0049": "Mercy Msangi",
  "0051": "",
  "0053": "",
  "0054": "",
  "0059": "",
  "0070": "Sophia Pangamawe",
  "0071": "",
  "0077": "",
  "0081": "",
  "0088": "",
  "0095": "",
  "0101": "",
  "0114": "",
  "0115": "",
  "0116": "",
  "0117": "",
  "0118": "",
  "0119": "",
};

  // Blue #5766ED
  // Green #2CB663
  // Yellow #FFDB45
  // Red #F04D6A

const colors: any = {
  Created: "#4b5563",
  Initiated: "#eab308",
  Accepted: "#16a34a",
  Advise: "#c026d3",
  Advised: "#0284c7",
  Amended: "#5F46C6",
  M1: "#16a34a",
  M2: "#16a34a",
  M3: "#16a34a",
  M4: "#16a34a",
  NEW: "#9ca3af",

  APPROVED: "#16a34a",
  "Approved M1": "#16a34a",
  "Approved M2": "#16a34a",
  "Approved M3": "#16a34a",
  "Approved M4": "#16a34a",

  Rejected: "#dc2626",
  "Rejected M1": "#dc2626",
  "Rejected M2": "#dc2626",
  "Rejected M3": "#dc2626",
  "Rejected M4": "#dc2626",
  REJECTED: "#ef4444",

  Invalid: "#dc2626",
  Valid: "#16a34a",
};


export { operators, colors };
