import {
  Button,
  Form,
  Row,
  Col,
  Input,
  message,
  Space,
  Drawer,
  Select,
} from "antd";
import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { handleFetchSegments } from "../../../../api/segments";
import { handleUpdateDivision } from "../../../../api/divisions";

const { Option } = Select;

export default function UpdateDivision({
  isVisible,
  setVisible,
  selected,
}: any) {
  const [isLoading, setLoading] = useState(false);

  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { data: segments } = useQuery({
    queryKey: ["segments"],
    queryFn: handleFetchSegments,
  });

  const { mutate } = useMutation({
    mutationFn: handleUpdateDivision,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["divisions"] });
      setVisible(false);
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    setLoading(true);
    const { name, segment } = await form.getFieldsValue();
    const data = {
      name,
      segment,
    };
    await mutate({ _id: selected?._id, data });
    setLoading(false);
  };

  const segmentOptions = segments?.payload?.records?.map(
    (segment: any, index: number) => (
      <Option value={segment?._id} key={index}>
        {`${segment?.name} ( Cluster: ${segment?.cluster?.name} )`}
      </Option>
    )
  );

  useEffect(() => {
    form.resetFields();
  }, [selected]);

  return (
    <Drawer
      open={isVisible}
      title={`UPDATE DIVISION`}
      placement={"right"}
      width={800}
      onClose={() => setVisible(!isVisible)}
      closable={false}
      extra={
        <Space className="flex justify-center items-center">
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <Form
        id="updateDivision"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="name"
              label={"Business Unit"}
              initialValue={selected?.name}
              rules={[
                {
                  required: true,
                  message: "Please enter unit name",
                },
              ]}
            >
              <Input type="text" placeholder="Business unit name" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="segment"
              label="Select Segment"
              initialValue={selected?.division?._id}
              rules={[
                {
                  required: true,
                  message: "Please select segment",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select Segment"
                optionFilterProp="children"
                defaultValue={selected?.segment?._id}
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {segmentOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button type="primary" htmlType="submit" className="mr-2">
            {isLoading ? "Updating ..." : "Update"}
          </Button>
          <Button htmlType="button" onClick={() => setVisible(false)}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
