import { Button } from "antd";
import { useState } from "react";
import { BsTrash } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { MdCorporateFare } from "react-icons/md";
import { useParams } from "react-router";
import BackToolnav from "../../../components/toolnav/BackToolnav";
import CreateContact from "../../suppliers/components/CreateContact";
import DeleteContact from "../../suppliers/components/DeleteContact";
import DeleteSupplier from "../../suppliers/components/DeleteSupplier";
import UpdateContact from "../../suppliers/components/UpdateContact";
import UpdateSupplier from "../../suppliers/components/UpdateSupplier";
import SideMenu from "../components/SideMenu";
import user from "../../../assets/user.png";
import { handleFetchSupplier } from "../../../api/suppliers";
import { useQuery } from "@tanstack/react-query";
import Something from "../../../components/shared/Something";

export default function Supplier({}: any) {
  const [updateSupplierPanel, setUpdateSupplierPanel] =
    useState<boolean>(false);
  const [deleteSupplierPanel, setDeleteSupplierPanel] =
    useState<boolean>(false);
  const [createContactPanel, setCreateContactPanel] = useState<boolean>(false);
  const [updateContactPanel, setUpdateContactPanel] = useState<boolean>(false);
  const [deleteContactPanel, setDeleteContactPanel] = useState<boolean>(false);
  const [selectedContact, setSelectedContact] = useState<any>({});

  const { id } = useParams();

  const {
    data: { payload: supplier } = {} as any,
    isError,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ["suppliers", id],
    queryFn: () => handleFetchSupplier({ _id: id }),
  });

  return (
    <div className="flex">
      <SideMenu />
      <div className="flex flex-1 flex-col">
        <BackToolnav title={"Supplier"} actions={<></>} />
        <div className="h-screen">
          {isError ? (
            <Something refetch={refetch} isFetching={isFetching} />
          ) : (
            <div className=" flex flex-col bg-white mx-4 my-2 p-4 border">
              <div className="flex justify-between pb-2">
                <div className="flex">
                  <div className="flex justify-center items-center w-20 h-20 bg-gray-200 rounded-lg">
                    {supplier?.isCompany ? (
                      <div className="flex flex-col justify-center items-center">
                        <MdCorporateFare size={40} />
                      </div>
                    ) : (
                      <div className="flex flex-col justify-center items-center">
                        <MdCorporateFare size={40} />
                      </div>
                    )}
                  </div>
                  <div className="ml-4 flex">
                    <div className="">
                      <div className="text-lg ">
                        <span className="font-bold mr-1">Supplier :</span>
                        <span>{supplier?.name}</span>
                      </div>
                      <div className="">
                        <div className="flex items-center">
                          <div className="">
                            Email :
                            <span className="ml-1">{supplier?.email}</span>
                          </div>
                        </div>
                        <div className="">
                          <div className="">
                            Mobile :
                            <span className="ml-1">
                              {supplier?.mobile ? supplier?.mobile : "-"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ml-8">
                      <div className="">
                        <div className="">
                          Address :
                          <span className="ml-1">
                            {supplier?.address ? supplier?.address : "-"}
                          </span>
                        </div>
                      </div>
                      <div className="">
                        <div className="">
                          TIN :
                          <span className="ml-1">
                            {supplier?.tin ? supplier?.tin : "-"}
                          </span>
                        </div>
                      </div>
                      <div className="">
                        <div className="">
                          VRN :
                          <span className="ml-1">
                            {supplier?.vrn ? supplier?.vrn : "-"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <button
                    onClick={() => setUpdateSupplierPanel(true)}
                    className="flex items-center px-2 py-1 hover:bg-gray-100"
                  >
                    <FiEdit />
                  </button>
                  <button
                    onClick={() => setDeleteSupplierPanel(true)}
                    className="flex items-center px-2 py-1 hover:bg-gray-100 text-red-500"
                  >
                    <BsTrash />
                  </button>
                </div>
              </div>
              <div className="mt-2">
                <div className="flex justify-between items-center border-b pb-2">
                  <div className="">CONTACT PERSON</div>
                  <Button onClick={() => setCreateContactPanel(true)}>
                    + Add
                  </Button>
                </div>
                <div className="ml-4 mt-4">
                  {supplier?.contactPerson?.map((item: any, index: number) => (
                    <div className="flex" key={index}>
                      <div className="">
                        <div className="flex justify-center items-center rounded-full bg-gray-200 mr-2 p-1">
                          <img
                            src={user}
                            alt=""
                            width={40}
                            className="rounded-full h-12 w-12"
                          />
                        </div>
                      </div>
                      <div className="flex flex-1 justify-between mb-6 border-l px-2">
                        <div className="">
                          <div className="w-36 font-bold text-black">
                            {item?.name}
                          </div>
                          <div className="w-56 text-gray-500">
                            {item?.title}
                          </div>
                          <div className="w-36">{item?.mobile}</div>
                          <div className="w-44">{item?.email}</div>
                        </div>
                        <div className="flex">
                          <button
                            onClick={() => {
                              setSelectedContact({ ...item });
                              setUpdateContactPanel(true);
                            }}
                            className="flex items-center px-2 py-1 hover:bg-gray-100"
                          >
                            <FiEdit />
                          </button>
                          <button
                            onClick={() => {
                              setSelectedContact({ ...item });
                              setDeleteContactPanel(true);
                            }}
                            className="flex items-center px-2 py-1 hover:bg-gray-100 text-red-500"
                          >
                            <BsTrash />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <UpdateSupplier
                isVisible={updateSupplierPanel}
                setVisible={setUpdateSupplierPanel}
                refetch={handleFetchSupplier}
                supplier={supplier}
                fetch={handleFetchSupplier}
              />
              <DeleteSupplier
                isVisible={deleteSupplierPanel}
                setVisible={setDeleteSupplierPanel}
                refetch={handleFetchSupplier}
                fetch={handleFetchSupplier}
                supplier={supplier}
                contact={selectedContact}
              />
              <CreateContact
                isVisible={createContactPanel}
                setVisible={setCreateContactPanel}
                supplier={supplier}
                refetch={handleFetchSupplier}
                fetch={handleFetchSupplier}
              />
              <UpdateContact
                isVisible={updateContactPanel}
                setVisible={setUpdateContactPanel}
                refetch={handleFetchSupplier}
                fetch={handleFetchSupplier}
                supplier={supplier}
                contact={selectedContact}
              />
              <DeleteContact
                isVisible={deleteContactPanel}
                setVisible={setDeleteContactPanel}
                refetch={handleFetchSupplier}
                fetch={handleFetchSupplier}
                supplier={supplier}
                contact={selectedContact}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
