import { useContext, useEffect, useState } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Label,
  LabelList,
  Bar,
  Tooltip,
  BarChart,
} from "recharts";
import { AuthContext } from "../../../contexts/AuthContext";
import fetchDataGate from "../../../services/fetchDataReq";
import { year_dummy } from "../../../utils/contants";
import dataFormater from "../../../utils/dataFormater";
import formatMoney from "../../../utils/formatMoney";
import formatShort from "../../../utils/formatShort";

export default function OrMainChart({ dates }: any) {
  const { auth } = useContext(AuthContext);
  const [data, setData] = useState<any>(year_dummy);

  const getTotalRecords = (records: any) => {
    let total = 0;
    let count = 0;
    records?.forEach((record: any) => {
      count = count + 1;
      let intotal = 0;
      const isExempted = record?.isExempted;

      const items = record?.items;

      items?.forEach((item: any) => {
        intotal =
          intotal + item?.quantity * item?.price * (1 - item?.discount / 100);
      });
      let sum = isNaN(intotal) ? 0 : intotal;
      let inclusive = isExempted ? sum : sum * 1.18;
      total = inclusive + total;
    });
    return total;
  };

  const labelFormatter = (value: any) => {
    let amount = formatShort(value);
    return amount;
  };

  const handleFetchData = async () => {
    let jan = { name: "Jan", month: "January", count: 0, data: [] };
    let feb = {
      name: "Feb",
      month: "February",
      count: 0,
      data: [],
    };
    let mar = {
      name: "Mar",
      month: "March",
      count: 0,
      data: [],
    };
    let apr = {
      name: "Apr",
      month: "April",
      count: 0,
      data: [],
    };
    let may = {
      name: "May",
      month: "May",
      count: 0,
      data: [],
    };
    let jun = {
      name: "Jun",
      month: "June",
      count: 0,
      data: [],
    };
    let jul = {
      name: "Jul",
      month: "July",
      count: 0,
      data: [],
    };
    let aug = {
      name: "Aug",
      month: "August",
      count: 0,
      data: [],
    };
    let sep = {
      name: "Sep",
      month: "September",
      count: 0,
      data: [],
    };
    let oct = {
      name: "Oct",
      month: "October",
      count: 0,
      data: [],
    };
    let nov = {
      name: "Nov",
      month: "November",
      count: 0,
      data: [],
    };
    let dec = {
      name: "Dec",
      month: "December",
      count: 0,
      data: [],
    };
    const response = await fetchDataGate({
      uri: `api/v1/reports/orders/year?start=${dates?.startDate}&end=${dates?.endDate}`,
      token: auth?._id,
    });
    if (response?.success) {
      response?.payload?.forEach((item: any) => {
        switch (item?._id) {
          case "01":
            jan.count = getTotalRecords(item?.records);
            jan.data = item?.records;
            break;
          case "02":
            feb.count = getTotalRecords(item?.records);
            feb.data = item?.records;
            break;
          case "03":
            mar.count = getTotalRecords(item?.records);
            mar.data = item?.records;
            break;
          case "04":
            apr.count = getTotalRecords(item?.records);
            apr.data = item?.records;
            break;
          case "05":
            may.count = getTotalRecords(item?.records);
            may.data = item?.records;
            break;
          case "06":
            jun.count = getTotalRecords(item?.records);
            jun.data = item?.records;
            break;
          case "07":
            jul.count = getTotalRecords(item?.records);
            jul.data = item?.records;
            break;
          case "08":
            aug.count = getTotalRecords(item?.records);
            aug.data = item?.records;
            break;
          case "09":
            sep.count = getTotalRecords(item?.records);
            sep.data = item?.records;
            break;
          case "10":
            oct.count = getTotalRecords(item?.records);
            oct.data = item?.records;
            break;

          case "11":
            nov.count = getTotalRecords(item?.records);
            nov.data = item?.records;
            break;
          case "12":
            dec.count = getTotalRecords(item?.records);
            dec.data = item?.records;
            break;
          default:
        }
      });
    }
    setData([jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec]);
  };

  const DataFormater: any = (number: any) => {
    if (number > 1000000000) {
      return (number / 1000000000).toString() + "B";
    } else if (number > 1000000) {
      return (number / 1000000).toString() + "M";
    } else if (number > 1000) {
      return (number / 1000).toString() + "K";
    } else {
      return number.toString();
    }
  };

  useEffect(() => {
    handleFetchData();
  }, [dates]);

  return (
    <div style={{ width: "100%" }}>
      <ResponsiveContainer width="100%" height={350}>
        <BarChart
          width={730}
          height={250}
          data={data}
          margin={{ top: 25, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }}>
            <Label offset={0} position="insideBottom" />
          </XAxis>
          <YAxis tickFormatter={DataFormater} />
          <Tooltip />
          <Bar
            dataKey="count"
            fill="#2CB663"
            barSize={20}
            background={{ fill: "#eee" }}
          >
            <LabelList
              dataKey="count"
              position="top"
              formatter={labelFormatter}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
