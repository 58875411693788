import { Button, Form, Row, Col, Input, message, Space, Drawer } from "antd";
import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handleUpdateCluster } from "../../../../api/clusters";

export default function UpdateCluster({
  isVisible,
  setVisible,
  selected,
}: any) {
  const [isLoading, setLoading] = useState(false);

  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { mutate } = useMutation({
    mutationFn: handleUpdateCluster,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["clusters"] });
      setVisible(false);
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    setLoading(true);
    const { name } = await form.getFieldsValue();
    const data = {
      name,
    };
    await mutate({ _id: selected?._id, data });
    setLoading(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [selected]);

  return (
    <Drawer
      open={isVisible}
      title={`UPDATE CLUSTER`}
      placement={"right"}
      width={800}
      onClose={() => setVisible(!isVisible)}
      closable={false}
      extra={
        <Space className="flex justify-center items-center">
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <Form
        id="updateSegment"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="name"
              label={"Name"}
              initialValue={selected?.name}
              rules={[
                {
                  required: true,
                  message: "Please enter name",
                },
              ]}
            >
              <Input type="text" placeholder="Name" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button type="primary" htmlType="submit" className="mr-2">
            {isLoading ? "Updating ..." : "Update"}
          </Button>
          <Button htmlType="button" onClick={() => setVisible(false)}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
