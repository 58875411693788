import { FiChevronRight } from "react-icons/fi";
import { IoCashOutline } from "react-icons/io5";
// import { IoIosTimer } from "react-icons/io";
import Moment from "react-moment";
import Spinner from "../../../components/shared/Spinner";
import formatShort from "../../../utils/formatShort";

export default function StatusWidget({ item, dates, isLoading }: any) {
  return (
    <div
      className="col-span-12 md:col-span-6 sm:col-span-3 lg:col-span-3 rounded-lg"
      style={{
        borderLeftColor: `${item?.icon_color}`,
        borderBottomColor: `${item?.icon_color}`,
        backgroundColor: `${item?.background}`,
      }}
    >
      <div className="p-4">
        <div className="flex justify-between">
          <div className="flex flex-col text-white">
            <div className="">{item?.name}</div>

            <div className="font-bold text-3xl">
              {isLoading ? <Spinner /> : formatShort(item?.count)}
            </div>
          </div>
          <div
            className="flex justify-center items-center border border-gray-100 rounded-lg h-12 w-12"
            style={{ backgroundColor: `${item?.icon_color}` }}
          >
            <IoCashOutline size={30} color={"#FFFFFF"} />
          </div>
        </div>
        {/* <div className="border-t border-gray-300 py-1 mt-2">
          <div className="text-white">Description of the widget</div>
        </div> */}
      </div>
      {/* <div className="flex flex-col border rounded-md">
        <div className="flex items-center px-4 pt-4">
          <div className="flex justify-center items-center">
            <div className="flex justify-center items-center h-12 w-12 rounded-full bg-gray-100">
              <div
                className="flex justify-center items-center h-10 w-10 rounded-full"
                style={{ backgroundColor: `${item?.icon_color}` }}
              >
                {item?.icon}
              </div>
            </div>
          </div>
          <div className="flex flex-col ml-2">
            <div className="text-gray-800">
              <div className="text-xl">{item?.name}</div>
              {dates?.isRange ? (
                <div className="text-gray-500">
                  <Moment format="Do MMM, YYYY">{dates?.startDate}</Moment>
                  <span className="px-2">-</span>
                  <Moment format="Do MMM, YYYY">{dates?.endDate}</Moment>
                </div>
              ) : (
                <div className="text-gray-500">
                  <Moment format="Do MMM, YYYY">{dates?.startDate}</Moment>
                </div>
              )}
            </div>
            <div className="font-bold text-3xl">
              {isLoading ? <Spinner /> : item?.count}
            </div>
          </div>
        </div>
        <div className="px-4 pb-4 pt-2 flex items-center">
          <div className="">Show details</div>
          <div className="ml-2">
            <FiChevronRight />
          </div>
        </div>
      </div> */}
    </div>
  );
}
