import { Button, Modal, Form, Row, Col, Select, message } from "antd";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import fetchDataReq from "../../../services/fetchDataReq";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { handleFetchUsers } from "../../../api/users";
import { handleAssignAdvisor } from "../../../api/requisitions";

const { Option } = Select;

export default function AssignAdviser({
  isVisible,
  setVisible,
  requisition,
  type,
}: any) {
  const { auth } = useContext(AuthContext);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { data: users } = useQuery({
    queryKey: ["users"],
    queryFn: handleFetchUsers,
  });

  const { mutate } = useMutation({
    mutationFn: handleAssignAdvisor,
    onSuccess: () => {
      message.success("Assigned Successfully");
      form.resetFields();
      queryClient.invalidateQueries({
        queryKey: ["requisition", requisition?._id],
      });
      setVisible(false);
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const handleFetchSuppliers = async () => {
    const response = await fetchDataReq({
      uri: "api/v1/suppliers",
      token: auth?._id,
    });
    if (response?.success) {
      setData(response?.payload?.records);
    }
  };

  const onSubmit = async () => {
    const { user } = form.getFieldsValue();
    await mutate({ data: { user }, _id: requisition?._id });
  };

  const hideModal = () => {
    form.resetFields();
    setVisible(false);
  };

  const adviserOptions = users?.payload?.records?.map(
    (user: any, index: number) => (
      <Option value={user?._id} key={index}>
        {user?.name}
      </Option>
    )
  );

  useEffect(() => {
    form.resetFields();
    handleFetchSuppliers();
  }, []);

  return (
    <Modal
      open={isVisible}
      title={<div>Assign Advicer</div>}
      onCancel={hideModal}
      footer={[
        <Button key="back" onClick={hideModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form={`${type}`}
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
        >
          Assign
        </Button>,
      ]}
    >
      <Form
        id={`${type}`}
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="user"
              label="Adviser"
              rules={[
                {
                  required: true,
                  message: "Please select adviser",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Search and select adviser"
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {adviserOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
