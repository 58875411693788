import { REQUI_BASE_URL } from "../utils/urls";

const postDataReq = async ({ data, uri, token }: any) => {
  const response = await fetch(`${REQUI_BASE_URL}/${uri}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });
  const result = await response.json();
  return result;
};

export default postDataReq;
