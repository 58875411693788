import {
  BarChartOutlined,
  CheckSquareTwoTone,
  CloseSquareTwoTone,
} from "@ant-design/icons";
import { useContext, useState } from "react";
import { Button } from "antd";
import { FiEdit, FiLock } from "react-icons/fi";
import { AuthContext } from "../../contexts/AuthContext";
import UpdatePassword from "./components/UpdatePassword";
import PageToolnav from "../../components/toolnav/PageToolnav";

export default function Profile() {
  const [passwordModal, setPasswordModal] = useState<boolean>(false);
  const { user } = useContext(AuthContext);
  const shortname =
    user.firstname === null
      ? "--"
      : `${user.firstname.charAt(0)}${user.lastname.charAt(0)} `;

  return (
    <div className="flex flex-1 flex-col min-h-screen">
      <PageToolnav
        link={{ name: "My Profile", to: "/account/profile" }}
        title={"Account"}
        actions={
          <div className="">
            <Button className="">
              <div className="flex items-center">
                <FiEdit />
                <div className="ml-1">Details</div>
              </div>
            </Button>
            <Button className="ml-2 " onClick={() => setPasswordModal(true)}>
              <div className="flex justify-center items-center">
                <FiLock />
                <div className="ml-1">Password</div>
              </div>
            </Button>
          </div>
        }
      />
      <div className="flex bg-white mx-4 my-2 border">
        <div className="grid grid-cols-12 gap-4 m-4 w-full h-auto">
          <div className="col-span-12 sm:col-span-6 md:col-span-3 bg-white border mb-4">
            <div className="flex flex-col justify-between items-center py-2">
              <div className="flex items-center justify-center h-24 w-24 rounded-full bg-gray-300">
                <span className="text-lg text-blue-900">{shortname}</span>
              </div>
              <span className="text-blue-800 text-sm mt-2 font-bold">
                {user.firstname} {user.lastname}
              </span>
              <span className="text-gray-600 text-sm ">{user.role?.name}</span>
              <span className="text-gray-600 text-sm ">{user?.mobile}</span>
              <h3>
                <span className="font-bold my-1">{user.site?.name} </span>
              </h3>
              <span className="text-blue-500 text-sm bg-gray-200 rounded-lg px-4 py-1 my-2">
                {user.email}
              </span>
            </div>
            <div className="flex justify-between items-center border-t bg-gray-100 rounded-br-lg rounded-bl-lg">
              <div className="flex items-center justify-center py-2 w-full text-center text-xs">
                <span className="mr-2">Account Status:</span>
                {user.isActive ? (
                  <div className="flex justify-center items-center">
                    <div className="flex justify-center items-center">
                      <CheckSquareTwoTone twoToneColor="#52c41a" />
                    </div>
                    <div className="ml-1 text-green-500">Active</div>
                  </div>
                ) : (
                  <div className="flex justify-center items-center">
                    <div className="flex justify-center items-center">
                      <CloseSquareTwoTone twoToneColor="#ff0000" />
                    </div>
                    <div className="ml-1 text-red-500">In Active</div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-span-12 sm:col-span-12 md:col-span-9 bg-white border flex flex-col justify-center items-center mb-4">
            <div className="flex flex-col justify-center items-center text-gray-600 p-8">
              <BarChartOutlined />
              <div className="">Account Metrics</div>
            </div>
          </div>
        </div>
        <UpdatePassword
          isVisible={passwordModal}
          setVisible={setPasswordModal}
        />
      </div>
    </div>
  );
}
