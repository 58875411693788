import { Button, Form, Row, Col, Input, message, Space, Drawer } from "antd";
import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import getBase64 from "../../../utils/getBase64";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handleUpdateSupplier } from "../../../api/suppliers";
import formatMobile from "../../../utils/formatMobile";

export default function UpdateSupplier({ isVisible, setVisible, supplier }: any) {
  const [isSelected, setIsSelected] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile]: any = useState();

  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const changeHandler = (event: any) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const { mutate, isPending } = useMutation({
    mutationFn: handleUpdateSupplier,
    onSuccess: () => {
      message.success("Created Successfully");
      form.resetFields();
      queryClient.invalidateQueries({
        queryKey: ["suppliers", supplier?._id],
      });
      setVisible(false);
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const values = await form.getFieldsValue();

    const { mob, inValid } = await formatMobile(values?.mobile);
    if (inValid || mob === null) {
      message.error("Please provide a valid number");
      return;
    }
    let base64 = null;
    if (selectedFile) {
      base64 = await getBase64(selectedFile);
    }
    const data = {
      isActive: true,
      logo: base64,
      ...values,
      mobile: mob,
    };

    await mutate({ _id: supplier?._id, data });
  };

  useEffect(() => {
    form.resetFields();
  }, [supplier]);

  const clearForm = async () => {
    form.resetFields();
  };

  return (
    <Drawer
      open={isVisible}
      title={`UPDATE DETAILS`}
      placement={"right"}
      width={800}
      onClose={() => setVisible(!isVisible)}
      closable={false}
      extra={
        <Space className="flex justify-center items-center">
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <Form
        id="createSupplier"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="name"
              label="Name"
              initialValue={supplier?.name}
              rules={[
                {
                  required: true,
                  message: "Please enter supplier name",
                },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="address"
              label="Address"
              initialValue={supplier?.address}
              rules={[
                {
                  required: false,
                  message: "Please enter address",
                },
              ]}
            >
              <Input placeholder="Address" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="email"
              label="Email"
              initialValue={supplier?.email}
              rules={[
                {
                  required: true,
                  message: "Please enter email address",
                },
              ]}
            >
              <Input placeholder="Email address" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="mobile"
              label="Phone"
              initialValue={supplier?.mobile}
              rules={[
                {
                  required: true,
                  message: "Please enter phone number",
                },
              ]}
            >
              <Input placeholder="Phone number" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="website"
              label="Website"
              initialValue={supplier?.website}
              rules={[
                {
                  required: false,
                  message: "Please enter website",
                },
              ]}
            >
              <Input placeholder="Website" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="tin"
              label="TIN"
              initialValue={supplier?.tin}
              rules={[
                {
                  required: false,
                  message: "Please enter tin",
                },
              ]}
            >
              <Input placeholder="TIN" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="vrn"
              label="VRN"
              initialValue={supplier?.vrn}
              rules={[
                {
                  required: false,
                  message: "Please enter VRN",
                },
              ]}
            >
              <Input placeholder="VRN" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="file"
              label="Supplier Logo"
              rules={[
                {
                  required: false,
                  message: "Please select file",
                },
              ]}
            >
              <Input
                type="file"
                accept="image/png, image/gif, image/jpeg"
                placeholder="Logo"
                onChange={changeHandler}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button type="primary" htmlType="submit" className="mr-2">
            {isPending ? "Submiting ..." : "Update"}
          </Button>
          <Button htmlType="button" onClick={() => setVisible(false)}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
