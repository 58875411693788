import { BsSearch } from "react-icons/bs";
import { Button, Form, Input, message, Popover, Tooltip } from "antd";
import {
  RiFileExcel2Line,
  RiFilterOffLine,
  RiMailSendLine,
} from "react-icons/ri";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import PageToolnav from "../../../components/toolnav/PageToolnav";
import SideMenu from "../components/SideMenu";
import fetchDataReq from "../../../services/fetchDataReq";
import DetailsPanel from "../../suppliers/components/DetailsPanel";
import { BiLoader } from "react-icons/bi";
import {
  AiOutlineCaretDown,
  AiOutlineDelete,
  AiOutlineEdit,
} from "react-icons/ai";
import CreateCategory from "./components/CreateCategory";
import postDataReq from "../../../services/postDataReq";
import UpdateCategory from "./components/UpdateCategory";
import DeleteCategory from "./components/DeleteCategory";
import UpdateSubcat from "./components/UpdateSubcat";
import DeleteSubcat from "./components/DeleteSubcat";
import UpdateClass from "./components/UpdateClass";
import DeleteClass from "./components/DeleteClass";
import UpdateFamily from "./components/UpdateFamily";
import DeleteFamily from "./components/DeleteFamily";
import { AuthContext } from "../../../contexts/AuthContext";
import { ExportToExcel } from "../../../utils/exportExcel";

export default function Categories() {
  const { auth } = useContext(AuthContext);
  const [categories, setCategories] = useState<any>([]);
  const [selected, setSelected] = useState<any>({});
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isAdding, setAdding] = useState<boolean>(false);
  const [isFetching, setFetching] = useState<boolean>(false);
  const [isMailLoading, setMailLoading] = useState<boolean>(false);
  const [messageModal, toggleMessageModal] = useState(false);
  const [createCatModal, setCreateCatModal] = useState<boolean>(false);
  const [updateCatModal, setUpdateCatModal] = useState<boolean>(false);
  const [deleteCatModal, setDeleteCatModal] = useState<boolean>(false);

  const [updateSubcatModal, setUpdateSubcatModal] = useState<boolean>(false);
  const [deleteSubcatModal, setDeleteSubcatModal] = useState<boolean>(false);

  const [updateClassModal, setUpdateClassModal] = useState<boolean>(false);
  const [deleteClassModal, setDeleteClassModal] = useState<boolean>(false);

  const [updateFamilyModal, setUpdateFamilyModal] = useState<boolean>(false);
  const [deleteFamilyModal, setDeleteFamilyModal] = useState<boolean>(false);

  const [detailPanel, setDetailPanel] = useState<boolean>(false);
  const [isSearched, setSearched] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState(null);

  const [selectedCat, setSelectedCat] = useState<any>({});
  const [subcats, setSubcats] = useState<any>([]);
  const [subcat, setSubcat] = useState<any>("");

  const [selectedSubcat, setSelectedSubcat] = useState<any>({});
  const [itemClasses, setItemClasses] = useState<any>([]);
  const [itemClass, setItemClass] = useState<any>("");

  const [selectedClass, setSelectedClass] = useState<any>({});
  const [itemFamilies, setItemFamilies] = useState<any>([]);
  const [itemFamily, setItemFamily] = useState<any>("");

  const [selectedFamily, setSelectedFamily] = useState<any>({});

  const [form] = Form.useForm();
  const [subcatform] = Form.useForm();
  const [classform] = Form.useForm();
  const [familyform] = Form.useForm();

  const [emailForm] = Form.useForm();

  const handleFetchCategories = async () => {
    setLoading(true);
    const uri = `api/v1/categories`;
    const response = await fetchDataReq({ uri, token: auth?._id });
    if (response?.success) {
      setCategories(response?.payload?.records);
    }
    setLoading(false);
  };

  const handleAddSubcat = async () => {
    setAdding(true);
    const uri = `api/v1/subcategories`;
    const data = { name: subcat, category: selectedCat?._id };
    const response = await postDataReq({ data, uri, token: auth?._id });
    if (response.success) {
      message.success("Added Successfully");
      handleFetchCategory(selectedCat?._id);
      setSubcat(null);
      subcatform.resetFields();
    }
    setAdding(false);
  };

  const handleAddClass = async () => {
    setAdding(true);
    const uri = `api/v1/classes`;
    const data = { name: itemClass, subcategory: selectedSubcat?._id };
    const response = await postDataReq({ data, uri, token: auth?._id });
    if (response.success) {
      message.success("Added Successfully");
      handleFetchSubcat(selectedSubcat?._id);
      setItemClass(null);
      classform.resetFields();
      setItemClass("");
    }
    setAdding(false);
  };

  const handleAddFamily = async () => {
    setAdding(true);
    const uri = `api/v1/families`;
    const data = { name: itemFamily, item_class: selectedClass?._id };
    const response = await postDataReq({ data, uri, token: auth?._id });
    if (response.success) {
      message.success("Added Successfully");
      handleFetchClass(selectedClass?._id);
      setItemFamily(null);
      familyform.resetFields();
      setItemFamily("");
    }
    setAdding(false);
  };

  const handleFetchCategory = async (id: any) => {
    setLoading(true);
    const uri = `api/v1/categories/${id}`;
    const response = await fetchDataReq({ uri, token: auth?._id });
    if (response?.success) {
      setSubcats(response?.results?.subCategories);
    }
    setLoading(false);
  };

  const handleFetchSubcat = async (id: any) => {
    setLoading(true);
    const uri = `api/v1/subcategories/${id}`;
    const response = await fetchDataReq({ uri, token: auth?._id });
    if (response?.success) {
      setItemClasses(response?.results?.itemClass);
    }
    setLoading(false);
  };

  const handleFetchClass = async (id: any) => {
    setLoading(true);
    const uri = `api/v1/classes/${id}`;
    const response = await fetchDataReq({ uri, token: auth?._id });
    if (response?.success) {
      setItemFamilies(response?.results?.itemFamily);
    }
    setLoading(false);
  };

  const sendMail = async (values: any) => {
    setMailLoading(true);
    categories?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Name: item?.name === null ? "-" : item?.name,
        Email: item?.email === null ? "-" : item?.email,
        Mobile: item?.mobile === null ? "-" : item?.mobile,
        Website: item?.website === null ? "-" : item?.website,
        Created: moment(item?.createdAt).format("DD/MM/YYYY"),
      };
    });
  };

  const fetchAllSuppliers = async () => {
    const response = await fetchDataReq({
      uri: "api/v1/suppliers",
      token: auth?._id,
    });
    if (response?.success) {
      ExportToExcel(
        response?.payload?.records?.map((item: any, index: number) => {
          return {
            "S/N": index + 1,
            Name: item?.name === null ? "-" : item?.name,
            Email: item?.email === null ? "-" : item?.email,
            Mobile: item?.mobile === null ? "-" : item?.mobile,
            Website: item?.website === null ? "-" : item?.website,
            Created: moment(item?.createdAt).format("DD/MM/YYYY"),
          };
        }),
        "Suppliers.xlsx"
      );
    }
  };

  const handleFetchAll = async () => {
    setFetching(true);
    await fetchAllSuppliers();
    setFetching(false);
  };

  useEffect(() => {
    handleFetchCategories();
  }, []);

  return (
    <div className="flex">
      <SideMenu />
      <div className="flex flex-1 flex-col">
        <PageToolnav
          link={{ name: "Categories", to: "/manage/categories" }}
          title={"Categories"}
          actions={
            <div className="flex">
              <div className="flex mr-2">
                <Form layout="inline" form={form}>
                  <Form.Item name="name">
                    <Input
                      name="searchKey"
                      onChange={(e: any) => {
                        setSearchKey(e.target.value);
                        setSearched(false);
                      }}
                      type="text"
                      placeholder="Search by name"
                    />
                  </Form.Item>
                </Form>
                <Button className="-ml-4 focus:outline-none">
                  {isSearched ? (
                    <RiFilterOffLine color={"#f87171"} />
                  ) : (
                    <BsSearch color={searchKey ? "#16a34a" : "#9ca3af"} />
                  )}
                </Button>
              </div>
              <Popover
                content={
                  <>
                    <Form
                      name="basic"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                      initialValues={{ remember: true }}
                      form={emailForm}
                      onFinish={sendMail}
                      autoComplete="off"
                    >
                      <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                          { required: true, message: "Email is Required" },
                        ]}
                      >
                        <Input placeholder="Email" />
                      </Form.Item>
                      <Form.Item
                        label="Subject"
                        name="subject"
                        rules={[
                          { required: true, message: "Subject is Required" },
                        ]}
                      >
                        <Input placeholder="Email Subject" />
                      </Form.Item>
                      <div className="flex flex-row justify-between">
                        <Form.Item wrapperCol={{ offset: 12, span: 8 }}>
                          <Button type="primary" htmlType="submit">
                            {isMailLoading ? "Sending ..." : "Submit"}
                          </Button>
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 0, span: 8 }}>
                          <Button
                            onClick={() => {
                              toggleMessageModal(false);
                            }}
                          >
                            Cancel
                          </Button>
                        </Form.Item>
                      </div>
                    </Form>
                  </>
                }
                title="Message Content"
                trigger="focus"
                placement="left"
                open={messageModal}
              >
                <Tooltip title="Send Email">
                  <button
                    className="flex bg-white mr-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                    onClick={async () => {
                      toggleMessageModal(true);
                    }}
                  >
                    <RiMailSendLine size={21} />
                  </button>
                </Tooltip>
              </Popover>
              <Tooltip title="Export To Excel">
                <button
                  color="#E50000"
                  className="flex bg-white mx-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                  onClick={() => handleFetchAll()}
                >
                  {isFetching ? (
                    <BiLoader size={20} />
                  ) : (
                    <RiFileExcel2Line size={20} />
                  )}
                </button>
              </Tooltip>
              <Button
                className="bg-blue-200"
                onClick={() => setCreateCatModal(true)}
              >
                + New
              </Button>
            </div>
          }
        />
        <div className="h-screen">
          <div className="flex flex-col mx-4 my-2">
            {categories?.map((item: any, index: number) => (
              <div key={index}>
                <div
                  className={`border-b flex justify-between items-center bg-white hover:bg-blue-50 hover:cursor-pointer ${
                    selectedCat?._id === item?._id ? "bg-blue-50" : ""
                  }`}
                >
                  <div
                    className="flex-1 p-2"
                    onClick={() => {
                      if (selectedCat?._id === item?._id) {
                        setSelectedCat({});
                      } else {
                        setSelectedCat(item);
                        handleFetchCategory(item?._id);
                      }
                    }}
                  >
                    {index + 1}. {item?.name}
                  </div>
                  <div className="flex p-2">
                    <div
                      className="flex justify-center items-center bg-yellow-100 h-8 w-8 border mx-2"
                      onClick={() => {
                        setSelectedCat(item);
                        setUpdateCatModal(true);
                      }}
                    >
                      <AiOutlineEdit />
                    </div>
                    <div
                      className="flex justify-center items-center bg-red-100 h-8 w-8 border mr-4"
                      onClick={() => {
                        setSelectedCat(item);
                        setDeleteCatModal(true);
                      }}
                    >
                      <AiOutlineDelete />
                    </div>
                    <div
                      className="bg-gray-200 h-8 w-8 flex justify-center items-center"
                      onClick={() => {
                        if (selectedCat?._id === item?._id) {
                          setSelectedCat({});
                        } else {
                          setSelectedCat(item);
                          handleFetchCategory(item?._id);
                        }
                      }}
                    >
                      <AiOutlineCaretDown />
                    </div>
                  </div>
                </div>
                {selectedCat?._id === item?._id ? (
                  <div className="pl-8 bg-white min-h-max">
                    <div className="">
                      {subcats?.map((item: any, index: number) => (
                        <div className="border-l" key={index}>
                          <div
                            className={`border-b flex justify-between items-center bg-white hover:bg-blue-50 hover:cursor-pointer ${
                              selectedSubcat?._id === item?._id
                                ? "bg-blue-50"
                                : ""
                            }`}
                          >
                            <div
                              className="flex-1 p-2"
                              onClick={() => {
                                if (item?._id === selectedSubcat?._id) {
                                  setSelectedSubcat({});
                                } else {
                                  setSelectedSubcat(item);
                                  handleFetchSubcat(item?._id);
                                }
                              }}
                            >
                              {index + 1}. {item?.name}
                            </div>
                            <div className="flex p-2">
                              <div
                                className="flex justify-center items-center bg-yellow-100 h-8 w-8 border mx-2"
                                onClick={() => {
                                  setSelectedSubcat(item);
                                  setUpdateSubcatModal(true);
                                }}
                              >
                                <AiOutlineEdit />
                              </div>
                              <div
                                className="flex justify-center items-center bg-red-100 h-8 w-8 border mr-4"
                                onClick={() => {
                                  setSelectedSubcat(item);
                                  setDeleteSubcatModal(true);
                                }}
                              >
                                <AiOutlineDelete />
                              </div>
                              <div
                                className="bg-gray-200 h-8 w-8 flex justify-center items-center"
                                onClick={() => {
                                  if (selectedCat?._id === item?._id) {
                                    setSelectedSubcat({});
                                  } else {
                                    setSelectedSubcat(item);
                                    handleFetchSubcat(item?._id);
                                  }
                                }}
                              >
                                <AiOutlineCaretDown />
                              </div>
                            </div>
                          </div>
                          {selectedSubcat?._id === item?._id ? (
                            <div className="pl-8 bg-white min-h-max">
                              <div className="">
                                {itemClasses?.map(
                                  (item: any, index: number) => (
                                    <div className="" key={index}>
                                      <div
                                        className={`border-b flex justify-between items-center bg-white hover:bg-blue-50 hover:cursor-pointer border-l ${
                                          selectedClass?._id === item?._id
                                            ? "bg-blue-50"
                                            : ""
                                        }`}
                                      >
                                        <div
                                          className="flex-1 p-2"
                                          onClick={() => {
                                            if (
                                              selectedClass?._id === item?._id
                                            ) {
                                              setSelectedClass({});
                                            } else {
                                              setSelectedClass(item);
                                              handleFetchClass(item?._id);
                                            }
                                          }}
                                        >
                                          {index + 1}. {item?.name}
                                        </div>
                                        <div className="flex p-2">
                                          <div
                                            className="flex justify-center items-center bg-yellow-100 h-8 w-8 border mx-2"
                                            onClick={() => {
                                              setSelectedClass(item);
                                              setUpdateClassModal(true);
                                            }}
                                          >
                                            <AiOutlineEdit />
                                          </div>
                                          <div
                                            className="flex justify-center items-center bg-red-100 h-8 w-8 border mr-4"
                                            onClick={() => {
                                              setSelectedClass(item);
                                              setDeleteClassModal(true);
                                            }}
                                          >
                                            <AiOutlineDelete />
                                          </div>
                                          <div
                                            className="bg-gray-200 h-8 w-8 flex justify-center items-center"
                                            onClick={() => {
                                              if (
                                                selectedCat?._id === item?._id
                                              ) {
                                                setSelectedClass({});
                                              } else {
                                                setSelectedClass(item);
                                                handleFetchClass(item?._id);
                                              }
                                            }}
                                          >
                                            <AiOutlineCaretDown />
                                          </div>
                                        </div>
                                      </div>
                                      {selectedClass?._id === item?._id ? (
                                        <div className="pl-8 bg-white min-h-max border-l">
                                          {itemFamilies?.map(
                                            (item: any, index: number) => (
                                              <div
                                                className={`border-b flex justify-between items-center bg-white hover:bg-blue-50 hover:cursor-pointer border-l ${
                                                  selectedClass?._id ===
                                                  item?._id
                                                    ? "bg-blue-50"
                                                    : ""
                                                }`}
                                              >
                                                <div className="flex-1 p-2">
                                                  {index + 1}. {item?.name}
                                                </div>
                                                <div className="flex p-2">
                                                  <div
                                                    className="flex justify-center items-center bg-yellow-100 h-8 w-8 border mx-2"
                                                    onClick={() => {
                                                      setSelectedFamily(item);
                                                      setUpdateFamilyModal(
                                                        true
                                                      );
                                                    }}
                                                  >
                                                    <AiOutlineEdit />
                                                  </div>
                                                  <div
                                                    className="flex justify-center items-center bg-red-100 h-8 w-8 border"
                                                    onClick={() => {
                                                      setSelectedFamily(item);
                                                      setDeleteFamilyModal(
                                                        true
                                                      );
                                                    }}
                                                  >
                                                    <AiOutlineDelete />
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          )}
                                          {/* ADD FAMILY */}
                                          <div className="py-2">
                                            <Form
                                              layout="inline"
                                              form={familyform}
                                            >
                                              <Form.Item
                                                name="itemfamily"
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: "Name is Required",
                                                  },
                                                ]}
                                              >
                                                <Input
                                                  onChange={(e: any) =>
                                                    setItemFamily(
                                                      e.target.value
                                                    )
                                                  }
                                                  placeholder={`1. New Item Family`}
                                                />
                                              </Form.Item>
                                              <Form.Item>
                                                <Button
                                                  onClick={() =>
                                                    handleAddFamily()
                                                  }
                                                  type="primary"
                                                  htmlType="submit"
                                                >
                                                  + Add
                                                </Button>
                                              </Form.Item>
                                            </Form>
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>
                                  )
                                )}
                                {/* ADD CLASS */}
                                <div className="flex pt-2">
                                  <Form layout="inline" form={classform}>
                                    <Form.Item
                                      name="itemclass"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Name is Required",
                                        },
                                      ]}
                                    >
                                      <Input
                                        onChange={(e: any) =>
                                          setItemClass(e.target.value)
                                        }
                                        placeholder={`${
                                          itemClasses.length + 1
                                        }. New item class`}
                                      />
                                    </Form.Item>
                                    <Form.Item>
                                      <Button
                                        onClick={() => handleAddClass()}
                                        type="primary"
                                        htmlType="submit"
                                      >
                                        + Add
                                      </Button>
                                    </Form.Item>
                                  </Form>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      ))}

                      {/* ADD SUBCATEGORY */}
                      <div className="flex pt-2 pb-4">
                        <Form layout="inline" form={subcatform}>
                          <Form.Item
                            name="subcategory"
                            rules={[
                              {
                                required: true,
                                message: "Name is Required",
                              },
                            ]}
                          >
                            <Input
                              onChange={(e: any) => setSubcat(e.target.value)}
                              placeholder={`${
                                subcats.length + 1
                              }. New Subcategory`}
                            />
                          </Form.Item>
                          <Form.Item>
                            <Button
                              onClick={() => handleAddSubcat()}
                              type="primary"
                              htmlType="submit"
                            >
                              + Add
                            </Button>
                          </Form.Item>
                        </Form>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            ))}
            <CreateCategory
              isVisible={createCatModal}
              setVisible={setCreateCatModal}
              refetch={handleFetchCategories}
            />
            <UpdateCategory
              isVisible={updateCatModal}
              setVisible={setUpdateCatModal}
              refetch={handleFetchCategory}
              selected={selectedCat}
            />
            <DeleteCategory
              isVisible={deleteCatModal}
              setVisible={setDeleteCatModal}
              refetch={handleFetchCategory}
              selected={selectedCat}
            />

            <UpdateSubcat
              isVisible={updateSubcatModal}
              setVisible={setUpdateSubcatModal}
              refetch={handleFetchCategory}
              category={selectedCat}
              selected={selectedSubcat}
            />
            <DeleteSubcat
              isVisible={deleteSubcatModal}
              setVisible={setDeleteSubcatModal}
              refetch={handleFetchCategory}
              category={selectedCat}
              selected={selectedSubcat}
            />

            <UpdateClass
              isVisible={updateClassModal}
              setVisible={setUpdateClassModal}
              refetch={handleFetchSubcat}
              subcategory={selectedSubcat}
              selected={selectedClass}
            />
            <DeleteClass
              isVisible={deleteClassModal}
              setVisible={setDeleteClassModal}
              refetch={handleFetchSubcat}
              subcategory={selectedSubcat}
              selected={selectedClass}
            />

            <UpdateFamily
              isVisible={updateFamilyModal}
              setVisible={setUpdateFamilyModal}
              refetch={handleFetchClass}
              itemclass={selectedClass}
              selected={selectedFamily}
            />
            <DeleteFamily
              isVisible={deleteFamilyModal}
              setVisible={setDeleteFamilyModal}
              refetch={handleFetchClass}
              itemclass={selectedClass}
              selected={selectedFamily}
            />

            <DetailsPanel
              isVisible={detailPanel}
              setVisible={setDetailPanel}
              selected={selected}
              refetch={handleFetchCategories}
              isEditable={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
