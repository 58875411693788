import spin from "../../../../assets/spingray.svg";

export default function Verifying() {
  return (
    <div className="flex flex-col items-center  pt-20">
      <div className="">
        <div className="text-red-700">
          <span>
            <img src={spin} alt="spin" height="40px" width="40px" />
          </span>
        </div>
      </div>
      <div className="text-lg">Verifiying ....</div>
      <div className="text-gray-600">Please wait</div>
    </div>
  );
}
