import Moment from "react-moment";
import formatMoney from "../../../../utils/formatMoney";

const OrdDetails = ({ order }: any) => {
  const getTotalAmount = ({ items }: any) => {
    let total = 0;
    items?.forEach((item: any) => {
      total = total + item?.quantity * item.price * (1 - item?.discount / 100);
    });
    return isNaN(total) ? 0 : total;
  };

  const num: any = getTotalAmount({ items: order?.items });
  return (
    <div className={`mb-4`}>
      <div className="text-center mb-4">
        -------------- LPO No. {order?.orderId} --------------
      </div>
      <div className="mb-1 border-b border-gray-400 pb-1">
        <div className="flex justify-between">
          <div className="text-toyota">LPO: {order?.orderId}</div>
          <div className="text-toyota">{order?.unit?.name}</div>
        </div>
        <div className="flex justify-between">
          <div className="">{order?.company?.name}</div>
          <div className="text-green-600">
            {order?.flags?.isCompleted ? "Completed" : order?.progress}
          </div>
        </div>
        <div className="flex justify-between">
          <div className="flex">
            Expense Type :
            <div className="">
              {order?.isCapex ? (
                <div className="ml-1">Capex</div>
              ) : (
                <div className="ml-1">
                  Opex,
                  {order?.isDirect ? " Direct" : " Indirect"}
                </div>
              )}
            </div>
          </div>
          <div className="font-bold">{formatMoney(num * 1.18)}</div>
        </div>
        <div className="text-gray-500 font-bold">
          Supplier : {order?.supplier?.name}
        </div>
        <div className="flex justify-between">
          <div className="">
            Date : <Moment format="Do MMM YYYY">{order?.dates?.created}</Moment>
          </div>
          <div className="font-bold">{order?.department?.name}</div>
        </div>
      </div>

      <div className="flex justify-between items-center mb-2">
        <div className="">
          <span className="text-gray-400">Created :</span>{" "}
          <Moment fromNow>{order?.dates?.created}</Moment>
        </div>
        <div className="text-gray-600 text-xs">
          {order?.participants?.creator?.name}
        </div>
      </div>

      <div className="border border-gray-400">
        <div className="flex justify-between py-1">
          <div className="mx-2">
            <div className="uppercase">LPO & Requisition System</div>
          </div>
        </div>
      </div>
      <div className="border border-gray-400 text-xs">
        <div className="grid grid-cols-12 border-b  border-gray-400">
          <div className="col-span-1 py-1  font-bold text-center">Qty</div>
          <div className="col-span-6 py-1 px-2  font-bold border-r border-l border-gray-400  text-left">
            Description
          </div>
          <div className="col-span-5 py-1 px-2  font-bold   text-right">
            Total Price
          </div>
        </div>
        {order?.items?.map((item: any, index: number) => (
          <div
            key={index}
            className="grid grid-cols-12 border-t border-gray-400 items-center text-gray-700"
          >
            <div className="col-span-1  truncate text-center">
              {item?.quantity}
            </div>
            <div className="col-span-6 px-2  border-r border-l border-gray-400">
              {item?.name}{" "}
              {item?.isGeneric ? <span>{item?.description}</span> : null}
            </div>
            <div className="col-span-5 px-2 truncate text-right">
              {formatMoney(item?.price ? item?.price * item?.quantity : 0)}
            </div>
          </div>
        ))}

        <div className="grid grid-cols-12 items-center border-t border-gray-400">
          <div className="col-span-1 text-white text-right">-</div>
          <div className="col-span-6 px-1  border-r border-l border-gray-400 text-left">
            Sub-Total
          </div>
          <div className="col-span-5 px-2 text-black  text-right">
            {formatMoney(order?.isExempted ? num : num)}
          </div>
        </div>
        {order?.isExempted ? null : (
          <div className="grid grid-cols-12 items-center border-t border-gray-400">
            <div className="col-span-1 px-1 text-white text-right">-</div>
            <div className="col-span-6 px-1  border-r border-l border-gray-400 text-left">
              VAT ( 18% )
            </div>
            <div className="col-span-5 px-2 text-black text-right">
              {formatMoney(order?.isExempted ? 0 : num * 0.18)}
            </div>
          </div>
        )}
        <div className="grid grid-cols-12 items-center border-t-2 border-black">
          <div className="col-span-1 px-1 text-white text-right">-</div>
          <div className="col-span-6 px-1  border-r border-l border-gray-400 text-left">
            Grand Total (TZS)
          </div>
          <div className="col-span-5 px-2 text-black text-right">
            {formatMoney(order?.isExempted ? num : num * 1.18)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrdDetails;
