import PropTypes from "prop-types";
import { Link } from "react-router-dom";

function ReadItem(props: any) {
  const { index, item } = props;

  return (
    <div className="grid grid-cols-12 border-t justify-center items-center">
      <div className="col-span-1 p-2  border-r text-center ">{index + 1}</div>
      <div className="col-span-4 p-2  border-r">{props?.name}</div>
      <div className="col-span-3 p-2  border-r">{props?.description}</div>
      <div className="col-span-2 p-2  border-r">{props?.purpose}</div>
      <div className="col-span-1 p-2  border-r text-center ">
        {props?.quantity}
      </div>
      <div className="col-span-1 p-2  text-center ">
        {item?.order?.orderNumber ? (
          <Link to={`/orders/${item?.order?.lpo}`} className="">
            <span className="text-toyota underline">
              {item?.order?.orderNumber}
            </span>
          </Link>
        ) : (
          <span className="text-red-600">-</span>
        )}
      </div>
    </div>
  );
}

export default ReadItem;

ReadItem.propTypes = {
  index: PropTypes.number.isRequired,
  name: PropTypes.string,
  description: PropTypes.string,
  quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
