import {
  deleteData,
  fetchData,
  patchData,
  postData,
} from "../services/requisition";

const handleFetchOrders = async ({ queryKey }: any) => {
  const [
    ,
    {
      pagination: { skip = 1, limit = 20 },
    },
  ] = queryKey;
  const uri = `api/v1/orders?skip=${skip}&limit=${limit}`;
  const response = fetchData({ uri });
  return response;
};

const handleFetchOrder = async ({ _id }: any) => {
  const uri = `api/v1/orders/${_id}`;
  const response = fetchData({ uri });
  return response;
};

const handleCreateOrder = async (data: any) => {
  const uri = `api/v1/orders`;
  const response = postData({ data, uri });
  return response;
};
const handleUpdateOrder = async ({ data, _id }: any) => {
  const uri = `api/v1/orders/${_id}`;
  const response = patchData({ data, uri });
  return response;
};

const handleDeleteOrder = async ({ _id }: any) => {
  const uri = `api/v1/orders/${_id}`;
  const response = deleteData({ uri });
  return response;
};

const handleApproveOrder = async ({ _id, data }: any) => {
  const uri = `api/v1/orders/${_id}/approve`;
  const response = patchData({ data, uri });
  return response;
};

const handleRejectOrder = async ({ _id, data }: any) => {
  const uri = `api/v1/orders/${_id}/reject`;
  const response = patchData({ data, uri });
  return response;
};


const handleAmendOrder = async ({ _id, data }: any) => {
  const uri = `api/v1/orders/${_id}/amend`;
  const response = patchData({ data, uri });
  return response;
};

const handleAssignSupplier = async ({ _id, data }: any) => {
  const uri = `api/v1/orders/${_id}/suppliers/assign`;
  const response = patchData({ data, uri });
  return response;
};


const handleSelectSupplier = async ({ _id,supplier_id,data }: any) => {
  const uri = `api/v1/orders/${_id}/suppliers/${supplier_id}/select`;
  const response = patchData({ data, uri });
  return response;
};

const handleOrderPrice = async ({ _id, item_id, data }: any) => {
  const uri = `api/v1/orders/${_id}/items/${item_id}/price`;
  const response = patchData({ data, uri });
  return response;
};

const handleOrderRemarks = async ({ _id, item_id, data }: any) => {
  const uri = `api/v1/orders/${_id}/items/${item_id}/remarks`;
  const response = patchData({ data, uri });
  return response;
};

export {
  handleFetchOrders,
  handleFetchOrder,
  handleCreateOrder,
  handleUpdateOrder,
  handleDeleteOrder,
  handleApproveOrder,
  handleRejectOrder,
  handleOrderPrice,
  handleAmendOrder,
  handleOrderRemarks,
  handleAssignSupplier,
  handleSelectSupplier
};
