import { useEffect, useState } from "react";
import { Button, Modal, Form, message } from "antd";
import { useNavigate } from "react-router";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handleDeleteSupplier } from "../../../api/suppliers";

export default function DeleteSupplier({
  supplier,
  isVisible,
  setVisible,
}: any) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: handleDeleteSupplier,
    onSuccess: () => {
      message.success("Deleted Successfully");
      form.resetFields();
      queryClient.invalidateQueries({
        queryKey: ["suppliers"],
      });

      setVisible(false);
      navigate(-1);
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    setLoading(true);
    await mutate({ _id: supplier?._id });
    setLoading(false);
    setVisible(false);
  };

  const hideModal = async () => {
    setVisible(false);
  };

  useEffect(() => {}, [supplier]);

  return (
    <Modal
      open={isVisible}
      title="Delete Supplier"
      onCancel={hideModal}
      footer={[
        <Button danger key="back" onClick={hideModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="deleteSupplier"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Delete
        </Button>,
      ]}
    >
      <Form id="deleteSupplier" form={form} onFinish={onSubmit}>
        <div>
          <p className="text-center font-bold">
            Are you sure you want to delete this supplier
          </p>
          <p className="text-center text-red-500 font-bold ml-1">
            {supplier?.name}
          </p>
        </div>
      </Form>
    </Modal>
  );
}
