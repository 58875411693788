import * as React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

/*
|---------------------------------------------------------------------
|                             Main
|---------------------------------------------------------------------
*/
import AuthLayout from "./layouts/AuthLayout";
import HomeLayout from "./layouts/HomeLayout";
import MainLayout from "./layouts/MainLayout";
import Signin from "./pages/auth/Signin";

import "antd/dist/antd.min.css";
import "./App.css";
import "./custom.css";
import { AuthContext, AuthProvider } from "./contexts/AuthContext";
import AllRequisitions from "./pages/requisitions/AllRequisitions";
import AllOrders from "./pages/orders/AllOrders";
import Dashboard from "./pages/dashboard";
import Manage from "./pages/manage";
import Forgot from "./pages/auth/Forgot";
import Reset from "./pages/auth/Reset";
import Page404 from "./pages/404";
import OneRequisition from "./pages/requisitions/OneRequisition";
import OneOrder from "./pages/orders/OneOrder";
import AllSuppliers from "./pages/suppliers/AllSuppliers";
import OneSupplier from "./pages/suppliers/OneSupplier";
import AllCompanies from "./pages/manage/companies/AllCompanies";
import Suppliers from "./pages/manage/suppliers/Suppliers";
import Users from "./pages/manage/users/Users";
import Categories from "./pages/manage/category/Categories";
import AllAssets from "./pages/assets/AllAssets";
import Stocks from "./pages/manage/stock/Stocks";
import Makes from "./pages/manage/make/Makes";
import Requisitions from "./pages/manage/requisition/Requisitions";
import Orders from "./pages/manage/orders/Orders";
import Budgets from "./pages/manage/budget/Budgets";
import Supplier from "./pages/manage/suppliers/Supplier";
import Denied from "./pages/auth/Denied";
import Units from "./pages/manage/units/Units";
import Order from "./pages/manage/orders/Order";
import Requisition from "./pages/manage/requisition/Requisition";
import Unit from "./pages/manage/units/Unit";
import Structure from "./pages/manage/structure/Structure";
import Profile from "./pages/account/Profile";
import VerifyLPO from "./pages/readonly/verify/VerifyLPO";

const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Routes>
          <Route element={<AuthLayout />}>
            <Route path="/" element={<Signin />} />
            <Route path="/forgot" element={<Forgot />} />
            <Route path="/reset" element={<Reset />} />
            <Route path="/denied" element={<Denied />} />
          </Route>
          <Route element={<HomeLayout />}>
            <Route path="/orders/verify/:code" element={<VerifyLPO />} />
          </Route>
          <Route element={<MainLayout />}>
            <Route
              path="/dashboard"
              element={
                <RequireAuth>
                  <Dashboard />
                </RequireAuth>
              }
            />

            <Route
              path="/requisitions"
              element={
                <RequireAuth>
                  <AllRequisitions />
                </RequireAuth>
              }
            />

            <Route
              path="/requisitions/:id"
              element={
                <RequireAuth>
                  <OneRequisition />
                </RequireAuth>
              }
            />
            <Route
              path="/orders"
              element={
                <RequireAuth>
                  <AllOrders />
                </RequireAuth>
              }
            />
            <Route
              path="/account/profile"
              element={
                <RequireAuth>
                  <Profile />
                </RequireAuth>
              }
            />
            <Route
              path="/orders/:id"
              element={
                <RequireAuth>
                  <OneOrder />
                </RequireAuth>
              }
            />

            <Route
              path="/suppliers"
              element={
                <RequireAuth>
                  <AllSuppliers />
                </RequireAuth>
              }
            />
            <Route
              path="/assets"
              element={
                <RequireAuth>
                  <AllAssets />
                </RequireAuth>
              }
            />
            <Route
              path="/suppliers/:id"
              element={
                <RequireAuth>
                  <OneSupplier />
                </RequireAuth>
              }
            />
            <Route
              path="/manage"
              element={
                <RequireAuth>
                  <Manage />
                </RequireAuth>
              }
            />

            <Route
              path="/manage/companies"
              element={
                <RequireAuth>
                  <AllCompanies />
                </RequireAuth>
              }
            />
            <Route
              path="/manage/suppliers"
              element={
                <RequireAuth>
                  <Suppliers />
                </RequireAuth>
              }
            />
            <Route
              path="/manage/suppliers/:id"
              element={
                <RequireAuth>
                  <Supplier />
                </RequireAuth>
              }
            />
            <Route
              path="/manage/categories"
              element={
                <RequireAuth>
                  <Categories />
                </RequireAuth>
              }
            />
            <Route
              path="/manage/make"
              element={
                <RequireAuth>
                  <Makes />
                </RequireAuth>
              }
            />

            <Route
              path="/manage/stock"
              element={
                <RequireAuth>
                  <Stocks />
                </RequireAuth>
              }
            />
            <Route
              path="/manage/requisitions"
              element={
                <RequireAuth>
                  <Requisitions />
                </RequireAuth>
              }
            />
            <Route
              path="/manage/requisitions/:id"
              element={
                <RequireAuth>
                  <Requisition />
                </RequireAuth>
              }
            />
            <Route
              path="/manage/orders"
              element={
                <RequireAuth>
                  <Orders />
                </RequireAuth>
              }
            />
            <Route
              path="/manage/orders/:id"
              element={
                <RequireAuth>
                  <Order />
                </RequireAuth>
              }
            />
            <Route
              path="/manage/budget"
              element={
                <RequireAuth>
                  <Budgets />
                </RequireAuth>
              }
            />
            <Route
              path="/manage/structure"
              element={
                <RequireAuth>
                  <Structure />
                </RequireAuth>
              }
            />
            <Route
              path="/manage/structure/units/:id"
              element={
                <RequireAuth>
                  <Unit />
                </RequireAuth>
              }
            />
            <Route
              path="/manage/users"
              element={
                <RequireAuth>
                  <Users />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="*" element={<Page404 />} />
        </Routes>
      </AuthProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = React.useContext(AuthContext);
  let location = useLocation();

  if (!auth.token || auth === undefined) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}
