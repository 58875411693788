import { useContext, useState } from "react";
import Header from "./components/Header";
import ReDashboard from "./redashboard";
import OrDashboard from "./ordashboard";
import moment from "moment";
import { AuthContext } from "../../contexts/AuthContext";
export default function Dashboard() {
  const { auth } = useContext(AuthContext);
  const [dates, setDates] = useState<any>({
    startDate: moment(new Date()).format("YYYYMMDD"),
    endDate: moment(new Date()).format("YYYYMMDD"),
    isRange: false,
  });
  const [report, setReport] = useState<any>({
    id: 1,
    name: "LPO",
  });

  const reports = [
    { id: 1, name: "LPO" },
    { id: 2, name: "Requisition" },
  ];

  const handleDateChange = async (data: any) => {
    setDates(data);
  };

  const dashboard: any =
    report?.id === 1 ? (
      <OrDashboard dates={dates} auth={auth} />
    ) : (
      <ReDashboard dates={dates} auth={auth} />
    );

  return (
    <div className="min-h-screen w-full">
      <Header
        stateHandler={handleDateChange}
        reports={reports}
        report={report}
        setReport={setReport}
      />
      <div className="h-auto">{dashboard}</div>
    </div>
  );
}
