import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { message } from "antd";
import { useParams } from "react-router";
import { FiEdit } from "react-icons/fi";
import BackToolnav from "../../../components/toolnav/BackToolnav";
import getRequisition from "../../../services/getRequisition";
import formatCurrency from "../../../utils/currency";

import fetchDataReq from "../../../services/fetchDataReq";
import patchDataReq from "../../../services/patchDataReq";
import { BsCheck2Square } from "react-icons/bs";
import { MdApproval, MdPublishedWithChanges } from "react-icons/md";
import { IoSaveOutline } from "react-icons/io5";
import { AuthContext } from "../../../contexts/AuthContext";
import LineItems from "../../requisitions/components/LineItems";
import ReadItems from "../../requisitions/components/ReadItems";
import SubmitRequisition from "../../requisitions/components/SubmitRequisition";
import ApproveRequisition from "../../requisitions/components/ApproveRequisition";
import ConvertRequisition from "../../requisitions/components/ConvertRequisition";
import SideMenu from "../components/SideMenu";
import { GrStatusCriticalSmall } from "react-icons/gr";
import { colors } from "../../../utils/data";
export default function Requisition({}) {
  const { id } = useParams();
  const { auth } = useContext(AuthContext);
  const [editable, setEditable] = useState<boolean>(false);
  const [submitModal, setSubmitModal] = useState<boolean>(false);
  const [approveModal, setApproveModal] = useState<boolean>(false);
  const [convertModal, setConvertModal] = useState<boolean>(false);

  const { data, refetch } = useQuery({
    queryKey: ["requisition", id],
    queryFn: () => getRequisition(id, auth?._id),
  });

  const { data: stockitems } = useQuery({
    queryKey: ["stockitems"],
    queryFn: () => fetchDataReq({ uri: "api/v1/stockitems", token: auth?._id }),
  });

  const edditable = ["Created", "Rejected"];
  const convertable = ["Accepted"];
  const submittable = ["Created", "Rejected"];
  const approvable = ["Initiated"];
  const approver = ["V", "M1", "M2", "M3", "M4"];

  const [state, setState] = useState<any>({
    extems: [
      {
        sn: uuidv4(),
        name: "",
        description: "",
        purpose: "",
        estimatedPrice: 0,
        stockReference: null,
        quantity: 1,
      },
    ],
  });

  useEffect(() => {
    const items =
      data?.payload?.items.length !== 0
        ? data?.payload?.items
        : [
            {
              sn: uuidv4(),
              name: "",
              purpose: "",
              description: "",
              estimatedPrice: 0,
              stockReference: null,
              quantity: 1,
            },
          ];

    setState({
      extems: items,
    });
    setEditable(data?.payload?.status === "Created" ? true : false);
  }, [data]);

  localStorage.setItem(
    `requisition-${id}`,
    JSON.stringify({ extems: state.extems })
  );

  const handleAddLineItem = (event: any) => {
    setState({
      extems: state.extems.concat([
        {
          sn: uuidv4(),
          name: "",
          purpose: "",
          description: "",
          estimatedPrice: 0,
          stockReference: null,
          quantity: 1,
        },
      ]),
    });
  };

  const handleLineItemChange = (elementIndex: any) => (event: any) => {
    let extems = state.extems.map((item: any, i: any) => {
      if (elementIndex !== i) return item;
      if (event.target === undefined) {
        const el: any = stockitems?.payload?.records?.filter(
          (prod: any) => prod?._id === event
        );
        return {
          ...item,
          stockReference: el[0]?._id,
          name: el[0]?.name,
          description: el[0]?.name,
        };
      }
      return { ...item, [event.target.name]: event.target.value };
    });
    setState({ extems });
  };

  const handleSaveRequisition = async () => {
    const data = { items: state.extems };
    const uri = `api/v1/requisitions/${id}`;
    const response = await patchDataReq({ data, uri, token: auth?._id });
    if (response.success) {
      message.success("Saved Successfully");
    }
  };

  const handleRemoveLineItem = (elementIndex: any) => (event: any) => {
    setState({
      extems: state.extems.filter((item: any, i: any) => {
        return elementIndex !== i;
      }),
    });
  };

  const handleReorderLineItems = (newextems: any) => {
    setState({
      extems: newextems,
    });
  };

  const handleFocusSelect = (event: any) => {
    event.target.select();
  };

  const calcLineItemsTotal = () => {
    return state.extems.reduce(
      (prev: any, cur: any) => prev + cur.quantity * cur.amount,
      0
    );
  };

  return (
    <div className="flex">
      <SideMenu />
      <div className="h-screen flex flex-1 flex-col">
        <BackToolnav
          title={"Requisitions"}
          actions={
            <div className="flex" style={{ fontSize: 14 }}>
              {submittable.includes(data?.payload?.status) && (
                <>
                  <button
                    className="flex justify-center items-center border px-2 py-1 "
                    onClick={() => handleSaveRequisition()}
                  >
                    <div className="">
                      <IoSaveOutline />
                    </div>
                    <div className="ml-1 mb-1">Save</div>
                  </button>
                  <button
                    className={`flex justify-center items-center ml-2 border px-2 py-1 ${
                      editable ? "bg-yellow-100" : ""
                    }`}
                    onClick={() => setEditable(!editable)}
                  >
                    <div className="flex items-center justify-center">
                      <div className="">
                        <FiEdit color="black" />
                      </div>
                      <div className="ml-1 mb-1">Edit Mode</div>
                    </div>
                  </button>
                </>
              )}
              {convertable.includes(data?.payload?.status) && (
                <div className="">
                  {auth?.level === "C" && (
                    <button
                      onClick={() => setConvertModal(true)}
                      className={`flex justify-center items-center border px-2 py-1 ml-2 
                `}
                    >
                      <div className="">
                        <MdPublishedWithChanges />
                      </div>
                      <div className="ml-1">Convert to LPO</div>
                    </button>
                  )}
                </div>
              )}

              {submittable.includes(data?.payload?.status) && (
                <button
                  onClick={() => setSubmitModal(true)}
                  className={`flex justify-center items-center border px-2 py-1 ml-2 bg-red-100
              `}
                >
                  <div className="">
                    <BsCheck2Square />
                  </div>
                  <div className="ml-1 mb-1">Submit</div>
                </button>
              )}

              {approvable.includes(data?.payload?.status) && (
                <>
                  {approver.includes(auth?.level) && (
                    <button
                      onClick={() => setApproveModal(true)}
                      className="flex justify-center items-center border px-2 py-1 ml-2 bg-blue-100"
                    >
                      <div className="">
                        <MdApproval />
                      </div>
                      <div className="ml-1 mb-1">Approve</div>
                    </button>
                  )}
                </>
              )}
            </div>
          }
        />

        <div className="my-2 mx-4">
          <div className="grid grid-cols-12 gap-x-0">
            <div className="col-span-10 sm:col-span-10 md:col-span-10 border bg-white">
              <div className="border-b p-3 w-full flex justify-between">
                <div className="">{data?.payload?.company?.name}</div>
                <div className="text-xs flex items-center justify-center">
                  <div className="">
                    <GrStatusCriticalSmall
                      color={`${colors[data?.payload?.status]}`}
                    />
                  </div>
                  <div
                    className="ml-1"
                    style={{ color: `${colors[data?.payload?.status]}` }}
                  >
                    {data?.payload?.status}
                  </div>
                </div>
              </div>
              <div className="">
                {editable ? (
                  <LineItems
                    items={state?.extems === undefined ? [] : state?.extems}
                    stockitems={
                      stockitems === undefined
                        ? []
                        : stockitems?.payload?.records
                    }
                    currencyFormatter={formatCurrency}
                    addHandler={handleAddLineItem}
                    changeHandler={handleLineItemChange}
                    focusHandler={handleFocusSelect}
                    deleteHandler={handleRemoveLineItem}
                    reorderHandler={handleReorderLineItems}
                  />
                ) : (
                  <ReadItems
                    items={state?.extems === undefined ? [] : state?.extems}
                    stockitems={
                      stockitems === undefined
                        ? []
                        : stockitems?.payload?.records
                    }
                    currencyFormatter={formatCurrency}
                    addHandler={handleAddLineItem}
                    changeHandler={handleLineItemChange}
                    focusHandler={handleFocusSelect}
                    deleteHandler={handleRemoveLineItem}
                    reorderHandler={handleReorderLineItems}
                  />
                )}
              </div>
            </div>
            <div className="col-span-2 sm:col-span-2 md:col-span-2 border-t border-b border-r bg-white">
              <div className="border-b p-3 w-full">
                <div className="">Comments</div>
              </div>
              <div className=""></div>
            </div>
          </div>
        </div>
      </div>
      <SubmitRequisition
        isVisible={submitModal}
        setVisible={setSubmitModal}
        refetch={refetch}
        requisition={data?.payload}
      />
      <ApproveRequisition
        isVisible={approveModal}
        setVisible={setApproveModal}
        refetch={refetch}
        requisition={data?.payload}
      />
      <ConvertRequisition
        isVisible={convertModal}
        setVisible={setConvertModal}
        refetch={refetch}
        requisition={data?.payload}
      />
    </div>
  );
}
