import { Button, Modal, Form, Row, Col, Input, message } from "antd";
import { useContext, useState } from "react";
import { AuthContext } from "../../../../contexts/AuthContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handleCreateCluster } from "../../../../api/clusters";

export default function CreateCluster({ isVisible, setVisible }: any) {
  const [isLoading, setLoading] = useState(false);
  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { mutate } = useMutation({
    mutationFn: handleCreateCluster,
    onSuccess: () => {
      message.success("Created Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["clusters"] });
      setVisible(false);
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const { name } = await form.getFieldsValue();
    const data = { name };
    await mutate(data);
  };

  return (
    <Modal
      open={isVisible}
      title="New Cluster"
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="createCluster"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="createCluster"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Please enter category name",
                },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
