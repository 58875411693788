import { Button, Drawer, Space, Tag } from "antd";
import { useState } from "react";
import { AiOutlineClose, AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import CreateDepartment from "./CreateDepartment";
import DeleteCompany from "./DeleteCompany";
import DeleteDepartment from "./DeleteDepartment";
import UpdateCompany from "./UpdateCompany";
import UpdateDepartment from "./UpdateDepartment";
import UpdateClusters from "./UpdateClusters";
import { useQuery } from "@tanstack/react-query";
import { handleFetchCompany } from "../../../../api/companies";

export default function DetailsPanel({ setVisible, isVisible, selected, isEditable }: any) {
  const [updateCompanyModal, setUpdateCompanyModal] = useState<boolean>(false);
  const [deleteCompanyModal, setDeleteCompanyModal] = useState<boolean>(false);
  const [updateClustersModal, setUpdateClustersModal] =
    useState<boolean>(false);

  const [createDepartPanel, setCreateDepartPanel] = useState<boolean>(false);
  const [updateDepartPanel, setUpdateDepartPanel] = useState<boolean>(false);
  const [deleteDepartPanel, setDeleteDepartPanel] = useState<boolean>(false);
  const [selectedDepartment, setSelectedDepartment] = useState<any>({});

  const company_id = selected?._id;
  const { data: company, refetch } = useQuery({
    queryKey: ["companies", company_id],
    queryFn: () => handleFetchCompany(company_id),
  });


  return (
    <Drawer
      open={isVisible}
      title={`COMPANY DETAILS`}
      placement={"right"}
      width={800}
      onClose={() => setVisible(!isVisible)}
      closable={false}
      extra={
        <Space className="flex justify-center items-center">
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <div className="flex justify-between pb-2">
        <div className="flex">
          <div className="">
            <div className="text-lg ">
              <span>{company?.payload?.name}</span>
            </div>
            <div className="">
              <div className="flex items-center">
                <div className="">
                  Email :<span className="ml-1">{company?.payload?.email}</span>
                </div>
              </div>
              <div className="">
                <div className="">
                  Mobile :
                  <span className="ml-1">
                    {company?.payload?.mobile || "-"}
                  </span>
                </div>
              </div>
              <div className="">
                <div className="">
                  Address :
                  <span className="ml-1">
                    {company?.payload?.address || "-"}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="ml-8">
            <div className="">
              <div className="flex items-center">
                <div className="">
                  P.o Box :
                  <span className="ml-1">{company?.payload?.box || "-"}</span>
                </div>
              </div>
              <div className="flex items-center">
                <div className="">
                  Fax :
                  <span className="ml-1">{company?.payload?.fax || "-"}</span>
                </div>
              </div>
              <div className="">
                <div className="">
                  TIN :
                  <span className="ml-1">{company?.payload?.tin || "-"}</span>
                </div>
              </div>
              <div className="">
                <div className="">
                  VRN :
                  <span className="ml-1">{company?.payload?.vrn || "-"}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isEditable && (
          <div className="flex">
            <button
              onClick={() => {
                setUpdateCompanyModal(true);
              }}
              className="flex justify-center items-center bg-yellow-100 h-8 w-8 border mx-2"
            >
              <AiOutlineEdit />
            </button>
            <button
              onClick={() => {
                setDeleteCompanyModal(true);
              }}
              className="flex justify-center items-center bg-red-100 h-8 w-8 border"
            >
              <AiOutlineDelete />
            </button>
          </div>
        )}
      </div>

      <div className="flex justify-between my-4">
        <div className="flex-col">
          <div className="">Cluster(s)</div>
          <Space size={[0, 12]} wrap>
            {company?.payload?.clusters?.map((item: any, index: number) => (
              <Tag color="magenta" key={index}>
                {item?.name}
              </Tag>
            ))}
          </Space>
        </div>
        <div className="">
          <button
            onClick={() => {
              setUpdateClustersModal(true);
            }}
            className="flex justify-center items-center bg-yellow-100 h-8 w-8 border mx-2"
          >
            <AiOutlineEdit />
          </button>
        </div>
      </div>
      <div className="mt-2">
        <div className="flex justify-between items-center border-b pb-2">
          <div className="">DEPARTMENTS</div>
          <Button onClick={() => setCreateDepartPanel(true)}>+ Add</Button>
        </div>
        <div className="ml-4">
          {company?.payload?.departments?.map((item: any, index: number) => (
            <div className="flex justify-between my-2" key={index}>
              <div className="">
                <div className="w-36">{item?.name}</div>
                <div className="w-36">{item?.phone}</div>
                <div className="w-44">{item?.email}</div>
              </div>
              {isEditable && (
                <div className="flex">
                  <button
                    onClick={() => {
                      setSelectedDepartment(item);
                      setUpdateDepartPanel(true);
                    }}
                    className="flex justify-center items-center bg-yellow-100 h-6 w-6 border mx-2"
                  >
                    <AiOutlineEdit />
                  </button>
                  <button
                    onClick={() => {
                      setSelectedDepartment(item);
                      setDeleteDepartPanel(true);
                    }}
                    className="flex justify-center items-center bg-red-100 h-6 w-6 border"
                  >
                    <AiOutlineDelete />
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
        <UpdateCompany
          isVisible={updateCompanyModal}
          setVisible={setUpdateCompanyModal}
          company={company?.payload}
          refetch={refetch}
          fetch={handleFetchCompany}
        />
        <CreateDepartment
          isVisible={createDepartPanel}
          setVisible={setCreateDepartPanel}
          company={company?.payload}
          refetch={refetch}
          fetch={handleFetchCompany}
        />
        <DeleteCompany
          isVisible={deleteCompanyModal}
          setVisible={setDeleteCompanyModal}
          mainModel={setVisible}
          company={company?.payload}
          refetch={refetch}
          fetch={handleFetchCompany}
        />
        <UpdateDepartment
          isVisible={updateDepartPanel}
          setVisible={setUpdateDepartPanel}
          company={company?.payload}
          department={selectedDepartment}
          refetch={refetch}
          fetch={handleFetchCompany}
        />
        <DeleteDepartment
          isVisible={deleteDepartPanel}
          setVisible={setDeleteDepartPanel}
          company={company?.payload}
          department={selectedDepartment}
          refetch={refetch}
          fetch={handleFetchCompany}
        />
      </div>

      <UpdateClusters
        isVisible={updateClustersModal}
        setVisible={setUpdateClustersModal}
        company={company?.payload}
        refetch={refetch}
      />
    </Drawer>
  );
}
