import { Button } from "antd";
import { AiOutlinePlus } from "react-icons/ai";
import { useQuery } from "@tanstack/react-query";
import Something from "../../../../components/shared/Something";
import CreateCompany from "./CreateCompany";
import { useState } from "react";
import { handleFetchCompanies } from "../../../../api/companies";
import DetailsPanel from "../../companies/components/DetailsPanel";
import Cookies from "js-cookie";

export default function Companies() {
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [detailPanel, setDetailPanel] = useState(false);
  const [selected, setSelected] = useState<any>({});

  const {
    data: companies,
    refetch,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ["companies"],
    queryFn: handleFetchCompanies,
  });

  return (
    <div className="border bg-white mb-2 px-4">
      <div className="flex justify-between items-center py-4 border-b mb-2">
        <div className="flex font-bold">Legal Entities</div>
        <Button onClick={() => setCreateModal(true)}>
          <AiOutlinePlus />
        </Button>
      </div>
      <div className="mb-2">
        {companies?.payload?.records?.length === 0 ? (
          <div className="flex justify-center items-center h-14">
            <div className="text-gray-400 text-sm">No Data Found</div>
          </div>
        ) : (
          <div className="">
            {isError ? (
              <Something refetch={refetch} isFetching={isFetching} />
            ) : (
              <div className="grid grid-cols-12 gap-2">
                {companies?.payload?.records?.map(
                  (item: any, index: number) => (
                    <div
                      onClick={() => {
                        setSelected(item);
                        setDetailPanel(true);
                      }}
                      className="col-span-6 sm:col-span-3  border bg-white hover:cursor-pointer"
                      key={index}
                    >
                      <div className="flex flex-col justify-center items-center p-4">
                        <div className="w-full h-32 bg-white flex justify-center items-center mb-2 rounded-lg">
                          <div className="text-gray-100">
                            <img
                              src={item?.logo}
                              alt=""
                              style={{ objectFit: "cover", width: 100 }}
                            />
                          </div>
                        </div>
                        <div className="border-t mt-2 w-full p-2">
                          {item?.name}
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            )}
          </div>
        )}
      </div>

      <CreateCompany
        isVisible={createModal}
        setVisible={setCreateModal}
        refetch={refetch}
      />

      <DetailsPanel
        isVisible={detailPanel}
        setVisible={setDetailPanel}
        selected={selected}
        refetch={refetch}
        isEditable={true}
      />
    </div>
  );
}
