import { Button } from "antd";
import { FiEdit } from "react-icons/fi";

export default function Stakeholder({ data, showModal }: any) {
  return (
    <div className="">
      <div className="flex justify-between items-center border-b border-gray-300 px-2 pb-2">
        <div className="font-bold">{data?.level}</div>
        <Button onClick={() => showModal({ data })}>
          <div className="flex justify-center items-center">
            <FiEdit />
          </div>
        </Button>
      </div>
      <div className="bg-white">
        <div className="p-2">
          <div className="">
            {!data.user ? (
              <div className="flex justify-between items-center">
                <div className="text-gray-400">-</div>
              </div>
            ) : (
              <div className="flex justify-between items-center">
                <div className="">{data?.user?.name}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
