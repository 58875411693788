import { BsEye } from "react-icons/bs";
import { Button, Form, Input, Popover, Table, Tooltip } from "antd";
import { RiFileExcel2Line, RiMailSendLine } from "react-icons/ri";
import { useContext, useEffect, useState } from "react";
import PageToolnav from "../../../components/toolnav/PageToolnav";
import fetchDataReq from "../../../services/fetchDataReq";
import SideMenu from "../components/SideMenu";
import { AuthContext } from "../../../contexts/AuthContext";

export default function Budgets() {
  const { auth } = useContext(AuthContext);
  const [units, setUnits] = useState<any>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [isMailLoading, setMailLoading] = useState<boolean>(false);
  const [messageModal, toggleMessageModal] = useState(false);

  const [emailForm] = Form.useForm();

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    limit: 10,
  });

  const handleFetchData = async ({ pagination }: any) => {
    setLoading(true);
    const { current, limit } = pagination;
    const skip = current * limit - limit;
    const uri = `api/v1/budgets?offset=${skip}&limit=${limit}`;
    const response = await fetchDataReq({ uri, token: auth?._id });
    if (response?.success) {
      setUnits(response?.payload?.records);
      setPagination({ total: response?.payload?.count, ...pagination });
    }
    setLoading(false);
  };

  const handlePageChange = (pagination: any) => {
    handleFetchData({
      pagination,
    });
  };

  const columns = [
    {
      title: "S/N",
      width: 40,
      render: (record: any, text: any, index: any) => (
        <span className="text-gray-700 ml-2">{index + 1}</span>
      ),
    },
    {
      title: "Business Unit",
      width: 80,
      render: (record: any) => {
        return (
          <span className="text-gray-700">
            {record?.name ? record?.name : "-"}
          </span>
        );
      },
    },
    {
      title: "Capex",
      width: 80,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.category?.name ? record?.category?.name : "-"}
        </span>
      ),
    },
    {
      title: "Opex",
      width: 70,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.subCategory?.name ? record?.subCategory?.name : "-"}
        </span>
      ),
    },
    {
      title: "Amount",
      width: 140,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.minimunQuantity ? record?.minimunQuantity : "-"}
        </span>
      ),
    },

    {
      title: "Details",
      width: 40,
      render: (record: any) => (
        <div className="flex justify-center items-center ml-1 text-gray-700 border rounded-md px-2 py-1 bg-gray-100 hover:bg-gray-200 cursor-pointer">
          <BsEye /> <span className="ml-1">Details</span>
        </div>
      ),
    },
  ];

  const sendMail = async (values: any) => {
    setMailLoading(true);
  };

  useEffect(() => {
    // handleFetchData({
    //   pagination,
    // });
  }, []);
  return (
    <div className="flex">
      <SideMenu />
      <div className="flex flex-1 flex-col">
        <PageToolnav
          link={{ name: "Budgets", to: "/manage/budgets" }}
          title={"Budgets"}
          actions={
            <div className="flex">
              <Popover
                content={
                  <>
                    <Form
                      name="basic"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                      initialValues={{ remember: true }}
                      form={emailForm}
                      onFinish={sendMail}
                      autoComplete="off"
                    >
                      <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                          { required: true, message: "Email is Required" },
                        ]}
                      >
                        <Input placeholder="Email" />
                      </Form.Item>
                      <Form.Item
                        label="Subject"
                        name="subject"
                        rules={[
                          { required: true, message: "Subject is Required" },
                        ]}
                      >
                        <Input placeholder="Email Subject" />
                      </Form.Item>
                      <div className="flex flex-row justify-between">
                        <Form.Item wrapperCol={{ offset: 12, span: 8 }}>
                          <Button type="primary" htmlType="submit">
                            {isMailLoading ? "Sending ..." : "Submit"}
                          </Button>
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 0, span: 8 }}>
                          <Button
                            onClick={() => {
                              toggleMessageModal(false);
                            }}
                          >
                            Cancel
                          </Button>
                        </Form.Item>
                      </div>
                    </Form>
                  </>
                }
                title="Message Content"
                trigger="focus"
                placement="left"
                visible={messageModal}
              >
                <Tooltip title="Send Email">
                  <button
                    className="flex mr-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                    onClick={async () => {}}
                  >
                    <RiMailSendLine size={21} />
                  </button>
                </Tooltip>
              </Popover>
              <Tooltip title="Export To Excel">
                <button
                  color="#E50000"
                  className="flex mr-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                  onClick={() => {}}
                >
                  <RiFileExcel2Line size={20} />
                </button>
              </Tooltip>
              <Button onClick={() => setCreateModal(true)}>+ New</Button>
            </div>
          }
        />
        <div className="h-screen flex">
          <div className="flex flex-col mx-4 my-2 border">
            <div className="flex flex-col w-full">
              <div className="grid gap-x-0 grid-cols-12">
                <div className="col-span-12 sm:col-span-12 md:col-span-12 bg-white">
                  <Table
                    size="middle"
                    dataSource={isLoading ? [] : units}
                    columns={columns}
                    pagination={pagination}
                    scroll={{ y: 660, x: 0 }}
                    onChange={handlePageChange}
                    rowKey="id"
                    loading={isLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
