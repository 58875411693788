import { IoWarningOutline } from "react-icons/io5";

export default function Invalid() {
  return (
    <div className="flex flex-col items-center pt-20">
      <div className="">
        <div className="flex justify-center items-center h-16 w-16 rounded-xl border border-red-600">
          <IoWarningOutline color="#dc2626" size={40} />
        </div>
      </div>
      <div className="text-red-600 mt-4 mb-1">INVALID</div>
      <div className="text-gray-600 text-xs">Invalid Local Purchase Order</div>
    </div>
  );
}
