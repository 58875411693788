import { Button, Drawer, Form, Space, Input, Tooltip, Popover } from "antd";
import moment from "moment";
import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { BiExport } from "react-icons/bi";
import {
  RiFileExcel2Line,
  RiFilterOffLine,
  RiMailSendLine,
} from "react-icons/ri";
import Moment from "react-moment";
import { ExportToExcel } from "../../../utils/exportExcel";

const { Search } = Input;

export default function ReListPanel({
  isVisible,
  setVisible,
  data,
  title,
  dates,
}: any) {
  const [isMailLoading, setMailLoading] = useState<boolean>(false);
  const [messageModal, toggleMessageModal] = useState(false);

  const [search, setSearch] = useState("");
  const [form] = Form.useForm();

  const [emailForm] = Form.useForm();

  const handleClearSearch = () => {
    setSearch("");
    form.resetFields();
  };

  const sendMail = async (values: any) => {
    setMailLoading(true);
    data?.records?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Date:
          item?.dates?.created === null
            ? "-"
            : moment(item?.dates?.created).format("DD/MM/YYYY"),
        Company: item?.company?.name === null ? "-" : item?.company?.name,
        Department: item?.company?.name === null ? "-" : item?.company?.name,
        "Business Unit": item?.unit?.name === null ? "-" : item?.unit?.name,
        "Created By":
          item?.participants?.creator?.name === null
            ? "-"
            : item?.participants?.creator?.name,
        Status: item?.status === null ? "-" : item?.status,
      };
    });
  };

  return (
    <Drawer
      title={
        <div className="flex items-center">
          <span className="mr-2 text-lg">
            <span className="font-bold">{title} </span>:{data?._id}{" "}
          </span>
          <div className="flex text-gray-500 px-2">
            [
            {dates?.isRange ? (
              <div className="text-gray-500">
                <Moment format="Do MMM, YYYY">{dates?.startDate}</Moment>
                <span className="px-2">-</span>
                <Moment format="Do MMM, YYYY">{dates?.endDate}</Moment>
              </div>
            ) : (
              <div className="text-gray-500">
                <Moment format="Do MMM, YYYY">{dates?.startDate}</Moment>
              </div>
            )}
            ]
          </div>
        </div>
      }
      placement={"right"}
      width={1000}
      onClose={() => setVisible(!isVisible)}
      open={isVisible}
      closable={false}
      extra={
        <Space>
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <div className="">
        <div className="flex justify-between items-center border-b border-gray-300 pb-2 -mt-4 bg-white">
          <div className="flex">
            <Form layout="inline" form={form}>
              <Form.Item name="registration">
                <Search
                  placeholder="Search by creator"
                  allowClear
                  onChange={(val: any) => {
                    setSearch(val.target.value);
                  }}
                  onSearch={(value: any) => {
                    setSearch(value);
                  }}
                />
              </Form.Item>

              <Button className="" onClick={() => handleClearSearch()}>
                <RiFilterOffLine color="#6b7280" />
              </Button>
            </Form>
          </div>
          <div className="flex ">
            {/* <button
              color="#E50000"
              className="flex justify-center items-center border p-1 hover:bg-green-100"
            >
              <BiExport size={24} />
              <span
                className="ml-2"
                onClick={() =>
                  ExportToExcel(
                    data?.records?.map((record: any, index: number) => {
                      return {
                        "S/N": index + 1,
                        Registration:
                          record?.registration === null
                            ? "-"
                            : record?.registration,
                        "Date Due In":
                          record?.date_due_in === null
                            ? "-"
                            : moment(record?.date_due_in.toString()).format(
                                "Do MMM YYYY"
                              ),
                        Customer:
                          record?.customer === null ? "-" : record?.customer,
                        Model: record?.model === null ? "-" : record?.model,
                        WIP: record?.wip === null ? "-" : record?.wip,
                      };
                    }),
                    "Booked.xlsx"
                  )
                }
              >
                Export To Excel
              </span>
            </button> */}

            <Popover
              content={
                <>
                  <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    form={emailForm}
                    onFinish={sendMail}
                    autoComplete="off"
                  >
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[{ required: true, message: "Email is Required" }]}
                    >
                      <Input placeholder="Email" />
                    </Form.Item>
                    <Form.Item
                      label="Subject"
                      name="subject"
                      rules={[
                        { required: true, message: "Subject is Required" },
                      ]}
                    >
                      <Input placeholder="Email Subject" />
                    </Form.Item>
                    <div className="flex flex-row justify-between">
                      <Form.Item wrapperCol={{ offset: 12, span: 8 }}>
                        <Button type="primary" htmlType="submit">
                          {isMailLoading ? "Sending ..." : "Submit"}
                        </Button>
                      </Form.Item>

                      <Form.Item wrapperCol={{ offset: 0, span: 8 }}>
                        <Button
                          onClick={() => {
                            toggleMessageModal(false);
                          }}
                        >
                          Cancel
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                </>
              }
              title="Message Content"
              trigger="focus"
              placement="left"
              visible={messageModal}
            >
              <Tooltip title="Send Email">
                <button
                  className="flex mr-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                  onClick={async () => {
                    toggleMessageModal(true);
                  }}
                >
                  <RiMailSendLine size={21} />
                </button>
              </Tooltip>
            </Popover>
            <Tooltip title="Export To Excel">
              <button
                color="#E50000"
                className="flex justify-center items-center border px-4 py-1 mr-2 hover:bg-gray-100"
                onClick={() =>
                  ExportToExcel(
                    data?.records?.map((item: any, index: number) => {
                      return {
                        "S/N": index + 1,
                        Date:
                          item?.dates?.created === null
                            ? "-"
                            : moment(item?.dates?.created).format("DD/MM/YYYY"),
                        Company:
                          item?.company?.name === null
                            ? "-"
                            : item?.company?.name,
                        Department:
                          item?.company?.name === null
                            ? "-"
                            : item?.company?.name,
                        "Business Unit":
                          item?.unit?.name === null ? "-" : item?.unit?.name,
                        "Created By":
                          item?.participants?.creator?.name === null
                            ? "-"
                            : item?.participants?.creator?.name,
                        Status: item?.status === null ? "-" : item?.status,
                      };
                    }),
                    "Requisitions.xlsx"
                  )
                }
              >
                <RiFileExcel2Line size={20} />
              </button>
            </Tooltip>
          </div>
        </div>
        <div className="flex py-1 border-b border-gray-300 bg-gray-100">
          <div className="mr-2 w-16 text-center font-bold">S/N.</div>
          <div className="flex justify-between items-center flex-1">
            <div className="flex items-center">
              <div className="w-24 font-bold mr-4">Date</div>
              <div className="w-24 font-bold mr-2 text-center">Req.No</div>
              <div className="w-40 truncate font-bold">Created By</div>
              <div className="w-56 truncate font-bold">Company</div>
            </div>
            <div className="w-24 flex items-center justify-center  text-center font-bold">
              Status
            </div>
          </div>
        </div>
        {data?.records
          ?.filter((record: any) =>
            record?.participants?.creator?.name?.includes(search)
          )
          .map((item: any, index: number) => (
            <div className="flex py-1 border-b border-gray-300" key={index}>
              <div className="mr-2 w-16 text-center">{index + 1}.</div>
              <div className="flex justify-between items-center flex-1">
                <div className="flex items-center">
                  <div className="w-24 truncate text-left mr-4">
                    {item?.dates?.created === null ? (
                      "-"
                    ) : (
                      <span>
                        {moment(item?.dates?.created.toString()).format(
                          "Do MMM YYYY"
                        )}
                      </span>
                    )}
                  </div>
                  <div className="w-24 mr-2 truncate text-center">
                    {item?.requisitionId ? item?.requisitionId : "-"}
                  </div>
                  <div className="w-40 truncate">
                    {item?.participants?.creator
                      ? item?.participants?.creator?.name
                      : "-"}
                  </div>
                  <div className="w-56 truncate">
                    {item?.company ? item?.company?.name : "-"}
                  </div>
                </div>
                <div className="w-24 flex items-center justify-center bg-gray-100 text-center">
                  {item?.status}
                </div>
              </div>
            </div>
          ))}
        <div className="flex justify-between items-start border-t-2 border-b-2 border-gray-500 py-2">
          <div className="font-bold">Total Requisitions</div>
          <div className="font-bold">
            {
              data?.records?.filter((record: any) =>
                record?.status?.includes(search)
              ).length
            }
          </div>
        </div>
      </div>
    </Drawer>
  );
}
