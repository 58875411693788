import { NavLink, useMatch, useResolvedPath } from "react-router-dom";
import { BarsOutlined } from "@ant-design/icons";
import { useState } from "react";
import Profile from "../layouts/Header/components/Profile";

export default function PageToolnav({ link, title, actions }: any) {
  const [isVisible, setVisible] = useState(false);
  let resolved = useResolvedPath(link.to);
  let match = useMatch({ path: resolved.pathname, end: true });
  return (
    <>
      <div className="h-16 bg-blue-50 flex flex-row justify-between items-center pr-4">
        <div className="flex flex-row items-center">
          <div className="ml-4 text-toyota text-2xl font-bold hidden sm:block md:text-2xl">
            {title}
          </div>
          <span className="ml-4 block sm:hidden text-2xl font-normal text-toyota">
            {title}
          </span>
        </div>
        <div className="flex flex-row items-center">
          <div className="z-50">
            <Profile isVisible={isVisible} setVisible={setVisible} />
          </div>
        </div>
      </div>
      <header className="bg-gray-50 px-4 sm:px-4 lg:px-4 border-t border-b border-gray-200 flex justify-between">
        <div className="flex flex-row items-center ">
          <NavLink
            to={`${link.to}`}
            className={`flex items-center justify-center text-sm text-gray-800 py-3 hover:text-toyota ${
              match ? "border-b border-toyota" : ""
            }`}
          >
            <BarsOutlined /> <span className="ml-1">{link?.name}</span>
          </NavLink>
        </div>
        <div className="flex justify-center items-center">{actions}</div>
      </header>
    </>
  );
}
