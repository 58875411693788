import { Button, Drawer, Form, Space, Input, Tooltip, Popover } from "antd";
import moment from "moment";
import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { BiExport } from "react-icons/bi";
import {
  RiFileExcel2Line,
  RiFilterOffLine,
  RiMailSendLine,
} from "react-icons/ri";
import Moment from "react-moment";
import { ExportToExcel } from "../../../utils/exportExcel";
import formatMoney from "../../../utils/formatMoney";

const { Search } = Input;

export default function OrListPanel({
  isVisible,
  setVisible,
  data,
  title,
  dates,
}: any) {
  const [isMailLoading, setMailLoading] = useState<boolean>(false);
  const [messageModal, toggleMessageModal] = useState(false);

  const [search, setSearch] = useState("");
  const [form] = Form.useForm();

  const [emailForm] = Form.useForm();

  const handleClearSearch = () => {
    setSearch("");
    form.resetFields();
  };

  const getTotal = () => {
    let total = 0;
    data?.forEach((item: any) => {
      let sum = getTotalRecords(item?.records);
      total = total + sum;
    });
    return total;
  };

  const getTotalRecords = (records: any) => {
    let total = 0;
    let count = 0;
    records?.forEach((record: any) => {
      count = count + 1;
      let intotal = 0;
      const isExempted = record?.isExempted;

      const items = record?.items;

      items?.forEach((item: any) => {
        intotal =
          intotal + item?.quantity * item?.price * (1 - item?.discount / 100);
      });
      let sum = isNaN(intotal) ? 0 : intotal;
      let inclusive = isExempted ? sum : sum * 1.18;
      total = inclusive + total;
    });
    return total;
  };

  const getTotalAmount = ({ items, isExempted }: any) => {
    let total = 0;
    items?.forEach((item: any) => {
      total = total + item?.quantity * item?.price * (1 - item?.discount / 100);
    });
    let sum = isNaN(total) ? 0 : total;
    let inclusive = isExempted ? sum : sum * 1.18;
    return inclusive;
  };

  const sendMail = async (values: any) => {
    setMailLoading(true);
    data?.records?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Supplier: item?.supplier?.name === null ? "-" : item?.supplier?.name,
        Date:
          item?.dates?.created === null
            ? "-"
            : moment(item?.dates?.created).format("DD/MM/YYYY"),
        "LPO No.": item?.orderId === null ? "-" : item?.orderId,
        "Requision No.":
          item?.requisition?.requisitionNumber === null
            ? "-"
            : item?.requisition?.requisitionNumber,
        Status: item?.status === null ? "-" : item?.status,
        Amount: formatMoney(
          getTotalAmount({
            items: item?.items,
            isExempted: item?.isExempted,
          })
        ),
      };
    });
  };

  return (
    <Drawer
      title={
        <div className="flex items-center">
          <span className="mr-2 text-lg">
            <span className="font-bold">{title} </span>:{data?._id}{" "}
          </span>
          <div className="flex text-gray-500 px-2">
            [
            {dates?.isRange ? (
              <div className="text-gray-500">
                <Moment format="Do MMM, YYYY">{dates?.startDate}</Moment>
                <span className="px-2">-</span>
                <Moment format="Do MMM, YYYY">{dates?.endDate}</Moment>
              </div>
            ) : (
              <div className="text-gray-500">
                <Moment format="Do MMM, YYYY">{dates?.startDate}</Moment>
              </div>
            )}
            ]
          </div>
        </div>
      }
      placement={"right"}
      width={1100}
      onClose={() => setVisible(!isVisible)}
      open={isVisible}
      closable={false}
      extra={
        <Space>
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <div className="">
        <div className="flex justify-between items-center border-b border-gray-300 pb-2 -mt-4 bg-white">
          <div className="flex">
            <Form layout="inline" form={form}>
              <Form.Item name="registration">
                <Search
                  placeholder="Search by creator"
                  allowClear
                  onChange={(val: any) => {
                    setSearch(val.target.value);
                  }}
                  onSearch={(value: any) => {
                    setSearch(value);
                  }}
                />
              </Form.Item>

              <Button className="" onClick={() => handleClearSearch()}>
                <RiFilterOffLine color="#6b7280" />
              </Button>
            </Form>
          </div>
          <div className="flex ">
            <Popover
              content={
                <>
                  <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    form={emailForm}
                    onFinish={sendMail}
                    autoComplete="off"
                  >
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[{ required: true, message: "Email is Required" }]}
                    >
                      <Input placeholder="Email" />
                    </Form.Item>
                    <Form.Item
                      label="Subject"
                      name="subject"
                      rules={[
                        { required: true, message: "Subject is Required" },
                      ]}
                    >
                      <Input placeholder="Email Subject" />
                    </Form.Item>
                    <div className="flex flex-row justify-between">
                      <Form.Item wrapperCol={{ offset: 12, span: 8 }}>
                        <Button type="primary" htmlType="submit">
                          {isMailLoading ? "Sending ..." : "Submit"}
                        </Button>
                      </Form.Item>

                      <Form.Item wrapperCol={{ offset: 0, span: 8 }}>
                        <Button
                          onClick={() => {
                            toggleMessageModal(false);
                          }}
                        >
                          Cancel
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                </>
              }
              title="Message Content"
              trigger="focus"
              placement="left"
              visible={messageModal}
            >
              <Tooltip title="Send Email">
                <button
                  className="flex mr-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                  onClick={async () => {
                    toggleMessageModal(true);
                  }}
                >
                  <RiMailSendLine size={21} />
                </button>
              </Tooltip>
            </Popover>
            <Tooltip title="Export To Excel">
              <button
                color="#E50000"
                className="flex justify-center items-center border px-4 py-1 mr-2 hover:bg-gray-100"
                onClick={() =>
                  ExportToExcel(
                    data?.records?.map((item: any, index: number) => {
                      return {
                        "S/N": index + 1,
                        Supplier:
                          item?.supplier?.name === null
                            ? "-"
                            : item?.supplier?.name,
                        Date:
                          item?.dates?.created === null
                            ? "-"
                            : moment(item?.dates?.created).format("DD/MM/YYYY"),
                        "LPO No.": item?.orderId === null ? "-" : item?.orderId,
                        "Requision No.":
                          item?.requisition?.requisitionNumber === null
                            ? "-"
                            : item?.requisition?.requisitionNumber,

                        Status: item?.status === null ? "-" : item?.status,
                        Amount: formatMoney(
                          getTotalAmount({
                            items: item?.items,
                            isExempted: item?.isExempted,
                          })
                        ),
                      };
                    }),
                    "LPOs.xlsx"
                  )
                }
              >
                <RiFileExcel2Line size={20} />
              </button>
            </Tooltip>
          </div>
        </div>
        <div className="flex py-1 border-b border-gray-300 bg-gray-100">
          <div className="mr-2 w-16 text-center font-bold">S/N.</div>
          <div className="flex justify-between items-center flex-1">
            <div className="flex items-center">
              <div className="w-24 font-bold mr-4">Date</div>
              <div className="w-16 font-bold mr-2 text-center">Req.No</div>
              <div className="w-16 font-bold mr-2 text-center">LPO.No</div>
              <div className="w-40 truncate font-bold">Created By</div>
              <div className="w-40 truncate font-bold">Company</div>
              <div className="w-40 truncate font-bold">Supplier</div>
              <div className="w-20 truncate font-bold text-center ml-2">
                Status
              </div>
            </div>
            <div className="w-36  text-right font-bold">Amount</div>
          </div>
        </div>
        {data?.records
          ?.filter((record: any) =>
            record?.participants?.creator?.name?.includes(search)
          )
          .map((item: any, index: number) => (
            <div className="flex py-1 border-b border-gray-300" key={index}>
              <div className="mr-2 w-16 text-center">{index + 1}.</div>
              <div className="flex justify-between items-center flex-1">
                <div className="flex items-center">
                  <div className="w-24 truncate text-left mr-4">
                    {item?.dates?.created === null ? (
                      "-"
                    ) : (
                      <span>
                        {moment(item?.dates?.created.toString()).format(
                          "Do MMM YYYY"
                        )}
                      </span>
                    )}
                  </div>
                  <div className="w-16 mr-2 truncate text-center">
                    {item?.requisition?.requisitionNumber
                      ? item?.requisition?.requisitionNumber
                      : "-"}
                  </div>
                  <div className="w-16 mr-2 truncate text-center">
                    {item?.orderId ? item?.orderId : "-"}
                  </div>
                  <div className="w-40 truncate">
                    {item?.participants?.creator
                      ? item?.participants?.creator?.name
                      : "-"}
                  </div>
                  <div className="w-40 truncate">
                    {item?.company ? item?.company?.name : "-"}
                  </div>
                  <div className="w-40 truncate">
                    {item?.supplier ? item?.supplier?.name : "-"}
                  </div>

                  <div className="w-20 ml-2 flex items-center justify-center bg-gray-100 text-center">
                    {item?.status}
                  </div>
                </div>
                <div className="w-36 truncate text-right">
                  <span className="text-gray-700">
                    {formatMoney(
                      getTotalAmount({
                        items: item?.items,
                        isExempted: item?.isExempted,
                      })
                    )}
                  </span>
                </div>
              </div>
            </div>
          ))}
        <div className="flex justify-between items-start border-t-2 border-b-2 border-gray-500 py-2">
          <div className="font-bold">Total Requisitions</div>
          <div className="flex font-bold">
            <div className="mx-1">
              {formatMoney(getTotalRecords(data?.records))}
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
}
