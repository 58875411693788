import { BsCheck2 } from 'react-icons/bs'

export default function EditToggle({editMode, setEditMode}: any) {
  return (
    <div className="">
    {editMode ? (
      <div
        onClick={() => setEditMode(false)}
        className="bg-blue-200 h-8 flex items-center rounded-lg cursor-pointer"
      >
        <div className="flex px-1">
          <div className="rounded-lg h-6 mx-1">Edit</div>
          <div className="rounded-lg bg-white h-6 w-6 flex justify-center items-center">
            <BsCheck2 />
          </div>
        </div>
      </div>
    ) : (
      <div
        onClick={() => setEditMode(true)}
        className="bg-gray-200 h-8 flex items-center rounded-lg cursor-pointer"
      >
        <div className="flex px-1">
          <div className="rounded-lg bg-white h-6 w-6 flex justify-center items-center">
            <BsCheck2 />
          </div>
          <div className="rounded-lg h-6 mx-1">Edit</div>
        </div>
      </div>
    )}
  </div>
  )
}
