import {
  deleteData,
  fetchData,
  patchData,
  postData,
} from "../services/requisition";

const handleFetchAllSuppliers = async () => {
  const uri = `api/v1/suppliers`;
  const response = fetchData({ uri });
  return response;
};

const handleFetchSuppliers = async (pagination: any, params: any) => {
  const { skip, pageSize } = pagination;
  const uri = `api/v1/suppliers?skip=${skip}&limit=${pageSize}${params}`;
  const response = fetchData({ uri });
  return response;
};

const handleFetchSupplier = async ({ _id }: any) => {
  const uri = `api/v1/suppliers/${_id}`;
  const response = fetchData({ uri });
  return response;
};

const handleCreateSupplier = async ({ data }: any) => {
  const uri = `api/v1/suppliers`;
  const response = postData({ data, uri });
  return response;
};
const handleUpdateSupplier = async ({ data, _id }: any) => {
  const uri = `api/v1/suppliers/${_id}`;
  const response = patchData({ data, uri });
  return response;
};

const handleDeleteSupplier = async ({ _id }: any) => {
  const uri = `api/v1/suppliers/${_id}`;
  const response = deleteData({ uri });
  return response;
};

const handleCreateContact = async ({ data, _id }: any) => {
  const uri = `api/v1/suppliers/${_id}/contact`;
  const response = postData({ data, uri });
  return response;
};
const handleUpdateContact = async ({ data, _id, contact_id }: any) => {
  const uri = `api/v1/suppliers/${_id}/contact/${contact_id}`;
  const response = patchData({ data, uri });
  return response;
};

const handleDeleteContact = async ({ _id, contact_id }: any) => {
  const uri = `api/v1/suppliers/${_id}/contact/${contact_id}`;
  const response = deleteData({ uri });
  return response;
};

export {
  handleFetchAllSuppliers,
  handleFetchSuppliers,
  handleFetchSupplier,
  handleCreateSupplier,
  handleUpdateSupplier,
  handleDeleteSupplier,
  handleCreateContact,
  handleUpdateContact,
  handleDeleteContact,
};
