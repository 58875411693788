import {
  deleteData,
  fetchData,
  patchData,
  postData,
} from "../services/requisition";


const handleFetchUnits = async () => {
  const uri = `api/v1/units`;
  const response = fetchData({ uri });
  return response;
};

const handleCreateUnit = async (data: any) => {
  const uri = `api/v1/units`;
  const response = postData({ data, uri });
  return response;
};
const handleUpdateUnit = async ({ data, _id }: any) => {
  const uri = `api/v1/units/${_id}`;
  const response = patchData({ data, uri });
  return response;
};

const handleDeleteUnit = async ({ _id }: any) => {
  const uri = `api/v1/units/${_id}`;
  const response = deleteData({ uri });
  return response;
};

export {
  handleFetchUnits,
  handleCreateUnit,
  handleUpdateUnit,
  handleDeleteUnit,
};
