import {
  deleteData,
  fetchData,
  patchData,
  postData,
} from "../services/requisition";

const handleFetchUsers = async () => {
  const uri = `api/v1/users`;
  const response = fetchData({ uri });
  return response;
};

const handleCreateUser = async (data: any) => {
  const uri = `api/v1/users`;
  const response = postData({ data, uri });
  return response;
};
const handleUpdateUser = async ({ data, _id }: any) => {
  const uri = `api/v1/users/${_id}`;
  const response = patchData({ data, uri });
  return response;
};


const handleUpdateOverride = async ({ data, _id }: any) => {
  const uri = `api/v1/users/${_id}/update/override`;
  const response = patchData({ data, uri });
  return response;
};

const handleDeleteUser = async ({ _id }: any) => {
  const uri = `api/v1/users/${_id}`;
  const response = deleteData({ uri });
  return response;
};

export {
  handleFetchUsers,
  handleCreateUser,
  handleUpdateUser,
  handleDeleteUser,
  handleUpdateOverride
};
