import { useEffect } from "react";
import { useNavigate } from "react-router";
import PageToolnav from "../../components/toolnav/PageToolnav";
import SideMenu from "./components/SideMenu";

export default function Manage() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/manage/structure");
  });
  return (
    <div className="h-screen flex">
      <SideMenu />
      <div className="flex flex-1 flex-col">
        <PageToolnav
          link={{ name: "Companies", to: "/companies" }}
          title={"Companies"}
        />
      </div>
    </div>
  );
}
