import { Button, Form, Row, Col, Input, message, Space, Drawer } from "antd";
import { useContext, useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { AuthContext } from "../../../../contexts/AuthContext";
import patchDataReq from "../../../../services/patchDataReq";

export default function UpdateDepartment({
  isVisible,
  setVisible,
  company,
  department,
  refetch,
  fetch,
}: any) {
  const [isLoading, setLoading] = useState(false);
  const { auth } = useContext(AuthContext);

  const [form] = Form.useForm();

  const onSubmit = async () => {
    setLoading(true);
    const { name } = await form.getFieldsValue();

    const data = {
      name,
      company: {
        id: company?._id,
        name: company?.name,
      },
    };

    const uri = `api/v1/departments/${department?._id}`;
    const response = await patchDataReq({ data, uri, token: auth?._id });
    if (response.success) {
      message.success("Updated Successfully");
      refetch();
      fetch();
      form.resetFields();

      setVisible(false);
    } else {
      message.error(response.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [department]);

  return (
    <Drawer
      open={isVisible}
      title={`UPDATE DEPARTMENT`}
      placement={"right"}
      width={800}
      onClose={() => setVisible(!isVisible)}
      closable={false}
      extra={
        <Space className="flex justify-center items-center">
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <Form
        id="updateDepartment"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="name"
              label="Name"
              initialValue={department?.name}
              rules={[
                {
                  required: true,
                  message: "Please enter name",
                },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="mr-2">
            {isLoading ? "Updating ..." : "Update"}
          </Button>
          <Button htmlType="button" onClick={() => setVisible(false)}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
