import { useContext, useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  LabelList,
  CartesianGrid,
  Tooltip,
  Label,
  ResponsiveContainer,
} from "recharts";
import { AuthContext } from "../../../contexts/AuthContext";
import fetchDataReq from "../../../services/fetchDataReq";
import formatMoney from "../../../utils/formatMoney";
import formatShort from "../../../utils/formatShort";

export default function OrWeekChart({ dates }: any) {
  const { auth } = useContext(AuthContext);
  const [data, setData] = useState<any>([]);
  const labelFormatter = (value: any) => {
    let amount = formatShort(value);
    return amount;
  };

  const getTotalRecords = (records: any) => {
    let total = 0;
    let count = 0;
    records?.forEach((record: any) => {
      count = count + 1;
      let intotal = 0;
      const isExempted = record?.isExempted;

      const items = record?.items;

      items?.forEach((item: any) => {
        intotal =
          intotal + item?.quantity * item?.price * (1 - item?.discount / 100);
      });
      let sum = isNaN(intotal) ? 0 : intotal;
      let inclusive = isExempted ? sum : sum * 1.18;
      total = inclusive + total;
    });
    return total;
  };

  const handleFetchData = async () => {
    let mon = {
      name: "Monday",
      count: 0,
      data: [],
    };
    let tue = {
      name: "Tuesday",
      count: 0,
      data: [],
    };
    let wed = {
      name: "Wednesday",
      count: 0,
      data: [],
    };
    let thr = {
      name: "Thursday",
      count: 0,
      data: [],
    };
    let fri = {
      name: "Friday",
      count: 0,
      data: [],
    };
    const response = await fetchDataReq({
      uri: `api/v1/reports/orders/week?start=${dates?.startDate}&end=${dates?.endDate}`,
      token: auth?._id,
    });
    if (response?.success) {
      response?.payload?.forEach((item: any) => {
        switch (item?._id) {
          case "1":
            mon.count = getTotalRecords(item?.records);
            mon.data = item?.records;
            break;
          case "2":
            tue.count = getTotalRecords(item?.records);
            tue.data = item?.records;
            break;
          case "3":
            wed.count = getTotalRecords(item?.records);
            wed.data = item?.records;
            break;
          case "4":
            thr.count = getTotalRecords(item?.records);
            thr.data = item?.records;
            break;
          case "5":
            fri.count = getTotalRecords(item?.records);
            fri.data = item?.records;
            break;
          default:
        }
      });
    }
    setData([mon, tue, wed, thr, fri]);
  };

  const DataFormater: any = (number: any) => {
    if (number > 1000000000) {
      return (number / 1000000000).toString() + "B";
    } else if (number > 1000000) {
      return (number / 1000000).toString() + "M";
    } else if (number > 1000) {
      return (number / 1000).toString() + "K";
    } else {
      return number.toString();
    }
  };

  useEffect(() => {
    handleFetchData();
  }, [dates]);
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{ top: 25, right: 30, left: 20, bottom: 5 }}
        barSize={20}
      >
        <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }}>
          <Label offset={0} position="insideBottom" />
        </XAxis>
        <YAxis tickFormatter={DataFormater} />
        <Tooltip />
        <CartesianGrid strokeDasharray="3 3" />
        <Bar dataKey="count" fill="#5766ED" background={{ fill: "#eee" }}>
          <LabelList
            dataKey="count"
            position="top"
            formatter={labelFormatter}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
