// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/ToyotaType-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-spin-dot-item {
  background-color: red;
}

@font-face {
  font-family: "Toyota";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

body {
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  font: 12pt "Tahoma";
  font-family: "Toyota";
}




* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.page {
  width: 21cm;
  min-height: 29.7cm;
  padding: 2cm;
  margin: 1cm auto;
  background: #ffffff;
  box-shadow: inset 0 0 5px #ccc;
}

.subpage {
  position: relative;
  border: 0px black solid;
  height: 270mm;
}

@page {
  size: A4;
  margin: 0;
}

@media print {
  @font-face {
  font-family: "Toyota";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
    font-family: none;
  }
  .subpage {
    position: relative;
    border: 0px black solid;
    height: 256mm;
  }
  .subpage .footer {
    margin-top: 10;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
  }
}

`, "",{"version":3,"sources":["webpack://./src/custom.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;EACrB,4CAAiD;AACnD;;AAEA;EACE,SAAS;EACT,UAAU;EACV,yBAAyB;EACzB,mBAAmB;EACnB,qBAAqB;AACvB;;;;;AAKA;EACE,sBAAsB;EACtB,2BAA2B;AAC7B;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,kBAAkB;EAClB,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,QAAQ;EACR,SAAS;AACX;;AAEA;EACE;EACA,qBAAqB;EACrB,4CAAiD;AACnD;EACE;IACE,SAAS;IACT,eAAe;IACf,sBAAsB;IACtB,cAAc;IACd,mBAAmB;IACnB,mBAAmB;IACnB,mBAAmB;IACnB,wBAAwB;IACxB,iBAAiB;EACnB;EACA;IACE,kBAAkB;IAClB,uBAAuB;IACvB,aAAa;EACf;EACA;IACE,cAAc;IACd,kBAAkB;IAClB,SAAS;IACT,QAAQ;IACR,OAAO;EACT;AACF","sourcesContent":[".ant-spin-dot-item {\n  background-color: red;\n}\n\n@font-face {\n  font-family: \"Toyota\";\n  src: url(\"./assets/fonts/ToyotaType-Regular.ttf\");\n}\n\nbody {\n  margin: 0;\n  padding: 0;\n  background-color: #ffffff;\n  font: 12pt \"Tahoma\";\n  font-family: \"Toyota\";\n}\n\n\n\n\n* {\n  box-sizing: border-box;\n  -moz-box-sizing: border-box;\n}\n\n.page {\n  width: 21cm;\n  min-height: 29.7cm;\n  padding: 2cm;\n  margin: 1cm auto;\n  background: #ffffff;\n  box-shadow: inset 0 0 5px #ccc;\n}\n\n.subpage {\n  position: relative;\n  border: 0px black solid;\n  height: 270mm;\n}\n\n@page {\n  size: A4;\n  margin: 0;\n}\n\n@media print {\n  @font-face {\n  font-family: \"Toyota\";\n  src: url(\"./assets/fonts/ToyotaType-Regular.ttf\");\n}\n  .page {\n    margin: 0;\n    border: initial;\n    border-radius: initial;\n    width: initial;\n    min-height: initial;\n    box-shadow: initial;\n    background: initial;\n    page-break-after: always;\n    font-family: none;\n  }\n  .subpage {\n    position: relative;\n    border: 0px black solid;\n    height: 256mm;\n  }\n  .subpage .footer {\n    margin-top: 10;\n    position: absolute;\n    bottom: 0;\n    right: 0;\n    left: 0;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
