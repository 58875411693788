import { Button, Form, Row, Col, message, Space, Drawer, Select } from "antd";
import { useEffect, useState } from "react";
import { AiOutlineClose, AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { handleFetchClusters } from "../../../../api/clusters";
import {
  handleDeleteCompanyCluster,
  handleUpdateCompanyClusters,
} from "../../../../api/companies";

const { Option } = Select;

export default function UpdateClusters({
  isVisible,
  setVisible,
  company,
}: any) {
  const [clusters, setClusters] = useState<any>(company?.clusters || []);

  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { data: allClusters } = useQuery({
    queryKey: ["clusters"],
    queryFn: handleFetchClusters,
  });

  const { mutate: createCluster } = useMutation({
    mutationFn: handleUpdateCompanyClusters,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["clusters"] });
      queryClient.invalidateQueries({ queryKey: ["companies", company?._id] });
      setVisible(false);
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const { mutate: deleteCluster } = useMutation({
    mutationFn: handleDeleteCompanyCluster,
    onSuccess: () => {
      message.success("Deleted Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["clusters"] });
      queryClient.invalidateQueries({ queryKey: ["companies"] });
      setVisible(false);
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const { cluster } = await form.getFieldsValue();
    clusters.push(cluster);
    const data = { clusters: [cluster] };
    await createCluster({ _id: company?._id, data });
  };

  const onDelete = async ({ cluster_id }: any) => {
    await deleteCluster({ _id: company?._id, cluster_id });
  };

  const clusterOptions = allClusters?.payload?.records
    ?.filter((item: any) => !clusters.includes(item))
    .map((cluster: any, index: number) => (
      <Option value={cluster?._id} key={index}>
        {`${index + 1}. ${cluster?.name}`}
      </Option>
    ));

  useEffect(() => {
    form.resetFields();
  }, [company]);

  return (
    <Drawer
      title={`UPDATE CLUSTERS`}
      placement={"right"}
      width={800}
      onClose={() => setVisible(!isVisible)}
      open={isVisible}
      closable={false}
      extra={
        <Space className="flex justify-center items-center">
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <div className="">
        {company?.clusters?.map((item: any, index: number) => (
          <div className="flex justify-between mb-1 py-1" key={index}>
            <div className="">{item?.name}</div>
            <div className="">
              <button
                onClick={() => {
                  onDelete({ cluster_id: item?._id });
                }}
                className="flex justify-center items-center bg-red-100 h-6 w-6 border"
              >
                <AiOutlineDelete />
              </button>
            </div>
          </div>
        ))}
      </div>
      <Form
        id="updateClusters"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="mt-4">
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 20 }} lg={{ span: 22 }}>
              <Form.Item
                name="cluster"
                rules={[
                  {
                    required: true,
                    message: "Please select cluster",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Search and select cluster"
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    option?.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {clusterOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 2 }} lg={{ span: 2 }}>
              <Button
                onClick={() => {
                  onSubmit();
                }}
                type="primary"
                htmlType="button"
                className="bg-gray-400"
              >
                <AiOutlinePlus />
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </Drawer>
  );
}
