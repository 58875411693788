import { useContext, useState } from "react";
import { Button, Modal, Form, message } from "antd";
import postDataReq from "../../../services/postDataReq";
import { AuthContext } from "../../../contexts/AuthContext";

export default function SubmitOrder({
  isVisible,
  setVisible,
  refetch,
  order,
}: any) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { auth } = useContext(AuthContext);

  const onSubmit = async () => {
    setLoading(true);
    const data = {};
    const uri = `api/v1/orders/${order?._id}/submit`;
    const response = await postDataReq({ data, uri, token: auth?._id });
    if (response.success) {
      message.success("Submited Successfully");
      await refetch();
      setLoading(false);
    }
    setVisible(false);
    setLoading(false);
  };

  const hideModal = async () => {
    setVisible(false);
  };

  return (
    <Modal
      open={isVisible}
      title="Submit LPO"
      onCancel={hideModal}
      footer={[
        <Button danger key="back" onClick={hideModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="submitLPO"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
        >
          Submit
        </Button>,
      ]}
    >
      <Form id="submitLPO" form={form} onFinish={onSubmit} layout="vertical">
        <div className="flex flex-col justify-center items-center px-4">
          <div className="mb-4">
            Are you sure you want to
            <span className="font-bold mx-1">submit</span>
            this LPO?
          </div>
        </div>
      </Form>
    </Modal>
  );
}
