import { Button } from "antd";
import { useContext, useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { useParams } from "react-router";
import BackToolnav from "../../../components/toolnav/BackToolnav";
import { AuthContext } from "../../../contexts/AuthContext";
import fetchDataReq from "../../../services/fetchDataReq";
import formatMoney from "../../../utils/formatMoney";
import SideMenu from "../components/SideMenu";
import Stakeholder from "./components/Stakeholder";
import UpdateAprv from "./components/UpdateAprv";
import UpdateSign from "./components/UpdateSign";
import UpdateUnit from "./components/UpdateUnit";
import DeleteUnit from "../structure/components/DeleteUnit";

export default function Unit({ refetch }: any) {
  const { id } = useParams();
  const { auth } = useContext(AuthContext);
  const [unit, setUnit] = useState<any>({});
  const [updateUnitModal, setUpdateUnitModal] = useState<boolean>(false);
  const [deleteUnitModal, setDeleteUnitModal] = useState<boolean>(false);
  const [selectedAprv, setSelectedAprv] = useState<any>({});
  const [selectedLevl, setSelectedLevl] = useState<any>({});
  const [selected, setSelected] = useState<any>({});

  const [updateAprvPanel, setUpdateAprvPanel] = useState<boolean>(false);
  const [updateSignPanel, setUpdateSignPanel] = useState<boolean>(false);

  const handleFetchUnit = async () => {
    const response = await fetchDataReq({
      uri: `api/v1/units/${id}`,
      token: auth?._id,
    });
    if (response.success) {
      setUnit(response?.results);
    }
  };

  const handleShowModal = ({ data }: any) => {
    setSelected({ ...data });
    setUpdateAprvPanel(true);
  };

  useEffect(() => {
    handleFetchUnit();
  }, [id]);

  return (
    <div className="flex">
      <SideMenu />
      <div className="flex flex-1 flex-col">
        <BackToolnav
          title={"Business Units"}
          actions={
            <>
              <div className=""></div>
            </>
          }
        />
        <div className="my-2 mx-4 bg-white border">
          <div className="flex flex-col p-2  w-full">
            <div className="flex justify-between">
              <div className="mb-4">
                <div className="text-lg ">
                  <span className="font-bold">Business Unit: {unit?.name}</span>
                </div>
                <div className="">
                  <div className="flex items-center">
                    <div className="">
                      Division :
                      <span className="ml-1">{unit?.division?.name}</span>
                    </div>
                  </div>
                  <div className="">
                    Budget :
                    <div className="ml-4">
                      <div className="flex">
                        <div className="w-16">Capex:</div>
                        <div className="">
                          {unit?.budget?.capex
                            ? formatMoney(unit?.budget?.capex)
                            : "-"}
                        </div>
                      </div>
                      <div className="flex">
                        <div className="w-16">Opex:</div>
                        <div className="">
                          {unit?.budget?.opex
                            ? formatMoney(unit?.budget?.opex)
                            : "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex  m-4">
                <button
                  onClick={() => {
                    setUpdateUnitModal(true);
                  }}
                  className="flex justify-center items-center bg-yellow-100 h-8 w-8 border mx-2"
                >
                  <FiEdit />
                </button>
                <button
                  onClick={() => {
                    setDeleteUnitModal(true);
                  }}
                  className="flex justify-center items-center bg-red-100 h-8 w-8 border"
                >
                  <AiOutlineDelete />
                </button>
              </div>
            </div>
            <div className="">
              <div className="flex justify-between items-center border-b  border-t border-gray-300 p-2 bg-green-50">
                <div className="font-bold">REQUISITION</div>
              </div>
              <div className="flex py-4 ml-12">
                <div className="grid grid-cols-12 w-full">
                  <div className="col-span-2">
                    <div className="bg-gray-100 p-1 mx-2">
                      <Stakeholder
                        data={{
                          user: unit?.approvals?.requisition?.validator,
                          isCapex: false,
                          isLpo: false,
                          isDirect: false,
                          level: "Validator",
                        }}
                        showModal={handleShowModal}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-center border-b border-t border-gray-300 p-2 bg-green-50">
                <div className="font-bold">LPO</div>
              </div>
              <div className="ml-4">
                <div className="">
                  <div className="flex justify-between items-center border-b border-gray-300 p-2 ">
                    <div className="font-bold">A: OPEX</div>
                  </div>
                  <div className="ml-8 mt-2">
                    <div className="flex justify-between items-center border-b border-gray-300 p-2">
                      <div className="font-bold">DIRECT</div>
                    </div>
                    <div className="flex py-4">
                      <div className="grid grid-cols-12 w-full">
                        <div className="col-span-2">
                          <div className="bg-gray-100 p-1 mx-2">
                            <Stakeholder
                              data={{
                                user: unit?.approvals?.opex?.direct?.validator,
                                isCapex: false,
                                isLpo: true,
                                isDirect: true,
                                level: "Validator",
                              }}
                              showModal={handleShowModal}
                            />
                          </div>
                        </div>
                        <div className="col-span-2">
                          <div className="bg-gray-100 p-1 mx-2">
                            <Stakeholder
                              data={{
                                user: unit?.approvals?.opex?.direct?.M1,
                                isCapex: false,
                                isLpo: true,
                                isDirect: true,
                                level: "M1",
                              }}
                              showModal={handleShowModal}
                            />
                          </div>
                        </div>
                        <div className="col-span-2">
                          <div className="bg-gray-100 p-1 mx-2">
                            <Stakeholder
                              data={{
                                user: unit?.approvals?.opex?.direct?.M2,
                                isCapex: false,
                                isLpo: true,
                                isDirect: true,
                                level: "M2",
                              }}
                              showModal={handleShowModal}
                            />
                          </div>
                        </div>
                        <div className="col-span-2">
                          <div className="bg-gray-100 p-1 mx-2">
                            <Stakeholder
                              data={{
                                user: unit?.approvals?.opex?.direct?.M3,
                                isCapex: false,
                                isLpo: true,
                                isDirect: true,
                                level: "M3",
                              }}
                              showModal={handleShowModal}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ml-8 mt-2">
                    <div className="flex justify-between items-center border-b border-gray-300 p-2">
                      <div className="font-bold">INDIRECT</div>
                    </div>
                    <div className="flex py-4">
                      <div className="grid grid-cols-12 w-full">
                        <div className="col-span-2">
                          <div className="bg-gray-100 p-1 mx-2">
                            <Stakeholder
                              data={{
                                user: unit?.approvals?.opex?.indirect
                                  ?.validator,
                                isCapex: false,
                                isLpo: true,
                                isDirect: false,
                                level: "Validator",
                              }}
                              showModal={handleShowModal}
                            />
                          </div>
                        </div>
                        <div className="col-span-2">
                          <div className="bg-gray-100 p-1 mx-2">
                            <Stakeholder
                              data={{
                                user: unit?.approvals?.opex?.indirect?.M1,
                                isCapex: false,
                                isLpo: true,
                                isDirect: false,
                                level: "M1",
                              }}
                              showModal={handleShowModal}
                            />
                          </div>
                        </div>
                        <div className="col-span-2">
                          <div className="bg-gray-100 p-1 mx-2">
                            <Stakeholder
                              data={{
                                user: unit?.approvals?.opex?.indirect?.M2,
                                isCapex: false,
                                isLpo: true,
                                isDirect: false,
                                level: "M2",
                              }}
                              showModal={handleShowModal}
                            />
                          </div>
                        </div>
                        <div className="col-span-2">
                          <div className="bg-gray-100 p-1 mx-2">
                            <Stakeholder
                              data={{
                                user: unit?.approvals?.opex?.indirect?.M3,
                                isCapex: false,
                                isLpo: true,
                                isDirect: false,
                                level: "M3",
                              }}
                              showModal={handleShowModal}
                            />
                          </div>
                        </div>
                        <div className="col-span-2">
                          <div className="bg-gray-100 p-1 mx-2">
                            <Stakeholder
                              data={{
                                user: unit?.approvals?.opex?.indirect?.M4,
                                isCapex: false,
                                isLpo: true,
                                isDirect: false,
                                level: "M4",
                              }}
                              showModal={handleShowModal}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex justify-between items-center border-b border-gray-300 p-2 ">
                  <div className="font-bold">B: CAPEX</div>
                </div>
                <div className="flex py-4 ml-8">
                  <div className="grid grid-cols-12 w-full">
                    <div className="col-span-2">
                      <div className="bg-gray-100 p-1 mx-2">
                        <Stakeholder
                          data={{
                            user: unit?.approvals?.capex?.validator,
                            isCapex: true,
                            isLpo: true,
                            isDirect: false,
                            level: "Validator",
                          }}
                          showModal={handleShowModal}
                        />
                      </div>
                    </div>
                    <div className="col-span-2">
                      <div className="bg-gray-100 p-1 mx-2">
                        <Stakeholder
                          data={{
                            user: unit?.approvals?.capex?.M1,
                            isCapex: true,
                            isLpo: true,
                            isDirect: false,
                            level: "M1",
                          }}
                          showModal={handleShowModal}
                        />
                      </div>
                    </div>
                    <div className="col-span-2">
                      <div className="bg-gray-100 p-1 mx-2">
                        <Stakeholder
                          data={{
                            user: unit?.approvals?.capex?.M2,
                            isCapex: true,
                            isLpo: true,
                            isDirect: false,
                            level: "M2",
                          }}
                          showModal={handleShowModal}
                        />
                      </div>
                    </div>
                    <div className="col-span-2">
                      <div className="bg-gray-100 p-1 mx-2">
                        <Stakeholder
                          data={{
                            user: unit?.approvals?.capex?.M3,
                            isCapex: true,
                            isLpo: true,
                            isDirect: false,
                            level: "M3",
                          }}
                          showModal={handleShowModal}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="border-t">
                  <div className="font-bold my-2 border-b pb-2">Signatory</div>

                  <div className="grid grid-cols-12">
                    <div className="col-span-2">
                      <div className="bg-gray-100 p-1 mx-2">
                        <div className="flex justify-between items-center border-b border-gray-300 px-2 pb-2">
                          <div className="font-bold">Signatory</div>
                          <Button onClick={() => setUpdateSignPanel(true)}>
                            <div className="flex justify-center items-center">
                              <FiEdit />
                            </div>
                          </Button>
                        </div>
                        <div className="bg-white">
                          <div className="p-2">
                            <div className="">
                              {unit?.signature?.url === "" ? (
                                <div className="flex justify-between items-center">
                                  <div className="text-gray-400">-</div>
                                </div>
                              ) : (
                                <div className="flex justify-between items-center">
                                  <div className="">
                                    <span>
                                      {unit?.signature?.name
                                        ? unit?.signature?.name
                                        : "-"}
                                    </span>
                                    <img src={unit?.signature?.url} alt="" />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <UpdateUnit
              isVisible={updateUnitModal}
              setVisible={setUpdateUnitModal}
              refetch={handleFetchUnit}
              fetch={refetch}
              unit={unit}
            />

            <UpdateAprv
              isVisible={updateAprvPanel}
              setVisible={setUpdateAprvPanel}
              unit={unit}
              selected={selected}
              approver={selectedAprv}
              level={selectedLevl}
              refetch={handleFetchUnit}
              fetch={refetch}
            />

            <UpdateSign
              isVisible={updateSignPanel}
              setVisible={setUpdateSignPanel}
              unit={unit}
              selected={selected}
              approver={selectedAprv}
              level={selectedLevl}
              refetch={handleFetchUnit}
              fetch={refetch}
            />

            <DeleteUnit
              isVisible={deleteUnitModal}
              setVisible={setDeleteUnitModal}
              unit={unit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
