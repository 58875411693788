import { useContext, useEffect, useRef, useState } from "react";
import { GrStatusCriticalSmall } from "react-icons/gr";
import { useParams } from "react-router";
import OrderWrapper from "../../../components/docum/OrderWrapper";
import TermsWrapper from "../../../components/docum/TermsWrapper";
import BackToolnav from "../../../components/toolnav/BackToolnav";
import { AuthContext } from "../../../contexts/AuthContext";
import fetchDataReq from "../../../services/fetchDataReq";
import { colors } from "../../../utils/data";
import SideMenu from "../components/SideMenu";

export default function OneOrder() {
  const { id } = useParams();
  const [order, setOrder] = useState<any>({});
  const { auth } = useContext(AuthContext);

  const [editable, setEditable] = useState(false);
  const docRef = useRef(null);

  const handleFetchOrder = async () => {
    const response = await fetchDataReq({
      uri: `api/v1/orders/${id}`,
      token: auth?._id,
    });
    setOrder(response?.payload);
  };

  useEffect(() => {
    handleFetchOrder();
  }, [id]);

  return (
    <div className="flex">
      <SideMenu />
      <div className="flex flex-1 flex-col">
        <BackToolnav title={"LPOs"} />

        <div className="my-2 mx-4">
          <div className="grid grid-cols-12 gap-x-0">
            <div className="col-span-10 sm:col-span-10 md:col-span-10 border bg-white">
              <div className="border-b p-3 w-full flex justify-between">
                <div className="">{order?.company?.name}</div>
                <div className="text-xs flex items-center justify-center">
                  <div className="">
                    <GrStatusCriticalSmall color={`${colors[order?.status]}`} />
                  </div>
                  <div
                    className="ml-1"
                    style={{ color: `${colors[order?.status]}` }}
                  >
                    {order?.status}
                  </div>
                </div>
              </div>
              <div className="bg-gray-100">
                <div className="bg-gray-100 flex flex-col items-start">
                  <div className="-mt-8 mx-2" ref={docRef}>
                    <OrderWrapper
                      order={order}
                      jobno={1}
                      refetch={handleFetchOrder}
                      editable={editable}
                      setEditable={setEditable}
                    />
                    <TermsWrapper
                      company={order?.company?.name}
                      supplier={order?.supplier?.name}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-2 sm:col-span-2 md:col-span-2 border-t border-b border-r bg-white">
              <div className="border-b p-3 w-full">
                <div className="">LPO Timeline</div>
              </div>
              <div className=""></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
