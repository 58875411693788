import { Button, Modal, Form, message } from "antd";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../contexts/AuthContext";
import deleteDataReq from "../../../../services/deleteDataReq";

export default function DeleteMake({
  isVisible,
  setVisible,
  selected,
  refetch,
}: any) {
  const { auth } = useContext(AuthContext);
  const [isLoading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const onSubmit = async () => {
    setLoading(true);
    const uri = `api/v1/makes/${selected?._id}`;
    const response = await deleteDataReq({ data: {}, uri, token: auth?._id });
    if (response.success) {
      message.success("Deleted Successfully");
      refetch();
      form.resetFields();

      setVisible(false);
    } else {
      message.error(response.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [selected]);

  return (
    <Modal
      open={isVisible}
      title="Delete Make"
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={() => setVisible(false)}>
          No
        </Button>,
        <Button
          key="submit"
          form="deleteMake"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
        >
          Yes
        </Button>,
      ]}
    >
      <Form
        id="deleteMake"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div>
          <p className="text-center font-bold">
            Are you sure you want to delete this make
          </p>
          <p className="text-center text-red-500 font-bold ml-1">
            {selected?.name}
          </p>
        </div>
      </Form>
    </Modal>
  );
}
