import { Button, Form, Row, Col, Input, message, Space, Drawer } from "antd";
import { useContext, useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { AuthContext } from "../../../../contexts/AuthContext";
import patchDataReq from "../../../../services/patchDataReq";

export default function UpdateCompany({
  isVisible,
  setVisible,
  company,
  refetch,
  fetch,
}: any) {
  const [isLoading, setLoading] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedFile, setSelectedFile]: any = useState();
  const { auth } = useContext(AuthContext);

  const [form] = Form.useForm();

  const onSubmit = async () => {
    setLoading(true);
    const values = await form.getFieldsValue();

    const uri = `api/v1/companies/${company?._id}`;
    const response = await patchDataReq({
      data: values,
      uri,
      token: auth?._id,
    });
    if (response.success) {
      message.success("Created Successfully");
      refetch();
      fetch();
      form.resetFields();

      setVisible(false);
    } else {
      message.error(response.message);
    }
    setLoading(false);
  };

  const changeHandler = (event: any) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  useEffect(() => {
    form.resetFields();
  }, [company]);

  return (
    <Drawer
      open={isVisible}
      title={`UPDATE COMPANY`}
      placement={"right"}
      width={800}
      onClose={() => setVisible(!isVisible)}
      closable={false}
      extra={
        <Space className="flex justify-center items-center">
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <Form
        id="updateCompany"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="name"
              label="Name"
              initialValue={company?.name}
              rules={[
                {
                  required: true,
                  message: "Please enter name",
                },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="email"
              label="Email"
              initialValue={company?.email}
              rules={[
                {
                  required: false,
                  message: "Please enter email",
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="mobile"
              label="Mobile"
              initialValue={company?.mobile}
              rules={[
                {
                  required: false,
                  message: "Please enter mobile",
                },
              ]}
            >
              <Input placeholder="Mobile" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="website"
              label="Website"
              initialValue={company?.website}
              rules={[
                {
                  required: false,
                  message: "Please enter website",
                },
              ]}
            >
              <Input placeholder="Website" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="address"
              label="Address"
              initialValue={company?.address}
              rules={[
                {
                  required: false,
                  message: "Please enter address",
                },
              ]}
            >
              <Input placeholder="Address" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="box"
              label="P.O Box"
              initialValue={company?.box}
              rules={[
                {
                  required: false,
                  message: "Please enter postal address",
                },
              ]}
            >
              <Input placeholder="P.O Box" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="telephone"
              label="Telephone"
              initialValue={company?.telephone}
              rules={[
                {
                  required: false,
                  message: "Please enter telephone",
                },
              ]}
            >
              <Input placeholder="Telephone" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="fax"
              label="Fax"
              initialValue={company?.fax}
              rules={[
                {
                  required: false,
                  message: "Please enter fax",
                },
              ]}
            >
              <Input placeholder="Fax" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="tin"
              label="TIN"
              initialValue={company?.tin}
              rules={[
                {
                  required: false,
                  message: "Please enter TIN",
                },
              ]}
            >
              <Input placeholder="TIN" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="vrn"
              label="VRN"
              initialValue={company?.vrn}
              rules={[
                {
                  required: false,
                  message: "Please enter VRN",
                },
              ]}
            >
              <Input placeholder="VRN" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="logo"
              label="Logo url"
              initialValue={company?.logo}
              rules={[
                {
                  required: false,
                  message: "Please enter logo url",
                },
              ]}
            >
              <Input placeholder="Logo url" />
            </Form.Item>
          </Col>
          {/* <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="file"
              label="Logo"
              rules={[
                {
                  required: false,
                  message: "Please select file",
                },
              ]}
            >
              <Input
                type="file"
                accept="image/png, image/gif, image/jpeg"
                placeholder="Document"
                onChange={changeHandler}
              />
            </Form.Item>
          </Col> */}
        </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="mr-2">
            {isLoading ? "Submiting ..." : "Update"}
          </Button>
          <Button htmlType="button" onClick={() => setVisible(false)}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
