import { useContext, useEffect, useState } from "react";
import { BsChevronRight } from "react-icons/bs";
import { AuthContext } from "../../../contexts/AuthContext";
import fetchDataReq from "../../../services/fetchDataReq";
import formatMoney from "../../../utils/formatMoney";
import OrListPanel from "./OrListPanel";

export default function OrListMetrics({ dates, type, title }: any) {
  const { auth } = useContext(AuthContext);
  const [isVisible, setVisible] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>({});
  const [data, setData] = useState<any>([]);
  const handleFetchData = async () => {
    const response = await fetchDataReq({
      uri: `api/v1/reports/orders/${type}?start=${dates?.startDate}&end=${dates?.endDate}`,
      token: auth?._id,
    });
    if (response?.success) {
      setData(response?.payload);
    }
  };

  const getTotal = () => {
    let total = 0;
    data?.forEach((item: any) => {
      let sum = getTotalRecords(item?.records);
      total = total + sum;
    });
    return total;
  };

  const getTotalRecords = (records: any) => {
    let total = 0;
    let count = 0;
    records?.forEach((record: any) => {
      count = count + 1;
      let intotal = 0;
      const isExempted = record?.isExempted;

      const items = record?.items;

      items?.forEach((item: any) => {
        intotal =
          intotal + item?.quantity * item?.price * (1 - item?.discount / 100);
      });
      let sum = isNaN(intotal) ? 0 : intotal;
      let inclusive = isExempted ? sum : sum * 1.18;
      total = inclusive + total;
    });
    return total;
  };

  useEffect(() => {
    handleFetchData();
  }, [dates]);
  return (
    <>
      <div className="px-4">
        {data?.map((item: any) => (
          <div
            className="flex justify-between border-b p-1 cursor-pointer"
            onClick={() => {
              setSelected(item);
              setVisible(true);
            }}
          >
            <div className="">{item?._id}</div>
            <div className="flex items-center">
              {/* <div className="">{item?.records?.length}</div> */}
              <div className="">
                {formatMoney(getTotalRecords(item?.records))}
              </div>
              <div className="ml-2">
                <BsChevronRight />
              </div>
            </div>
          </div>
        ))}

        <div className="flex justify-between border-t-2 border-gray-700 p-1">
          <div className="">Total</div>
          <div className="flex items-center">
            <div className="mr-6">{formatMoney(getTotal())}</div>
          </div>
        </div>
      </div>
      <OrListPanel
        isVisible={isVisible}
        setVisible={setVisible}
        dates={dates}
        data={selected}
        title={title}
      />
    </>
  );
}
