import { slice } from "lodash";
import formatMoney from "../../utils/formatMoney";
import LineItem from "./LineItem";

export default function LineItems({ items, isExempted }: any) {
  const data = new Array(22).fill({ id: 1 });
  let few = slice(data, 0, 22 - items?.length);

  const getTotalAmount = ({ items }: any) => {
    let total = 0;
    items?.forEach((item: any) => {
      total = total + item?.quantity * item?.price * (1 - item?.discount / 100);
    });
    return isNaN(total) ? 0 : total;
  };

  const num: any = getTotalAmount({ items });

  return (
    <div className="border border-black  mt-2">
      <div className="grid grid-cols-12 border-b  border-black">
        <div className="col-span-1 py-1 px-2 font-bold border-r border-black  text-center truncate">
          Item
        </div>
        <div className="col-span-1 py-1 px-2  font-bold border-r border-black  text-center">
          Qty
        </div>
        <div className="col-span-4 py-1 px-2  font-bold border-r border-black  text-left">
          Description
        </div>
        <div className="col-span-2 py-1 px-2  font-bold border-r border-black  text-right">
          Unit Price
        </div>
        <div className="col-span-2 py-1 px-2  font-bold border-r border-black  text-center">
          Discount (%)
        </div>
        <div className="col-span-2 py-1 px-2  font-bold text-right">
          Total Price
        </div>
      </div>
      <div className="">
        {items?.map((item: any, i: any) => (
          <LineItem
            style={{ color: "red" }}
            key={i + item._id}
            index={i}
            item={item}
          />
        ))}
      </div>
      {few.map((_: any, index: number) => (
        <div
          className="grid grid-cols-12 items-center border-t border-black"
          key={index}
        >
          <div className="col-span-1 px-1 border-r text-white  border-black  text-center">
            -
          </div>
          <div className="col-span-1 px-1 text-white border-r border-black text-center">
            -
          </div>
          <div className="col-span-4 px-1 text-white border-r border-black text-right">
            -
          </div>
          <div className="col-span-2 px-1 text-white border-r border-black text-right">
            -
          </div>
          <div className="col-span-2 px-1 text-white border-r border-black text-right">
            -
          </div>
          <div className="col-span-2 px-1 text-white text-right">-</div>
        </div>
      ))}

      <div className="grid grid-cols-12 items-center border-t border-black">
        <div className="col-span-1 px-1 border-r text-white  border-black  text-center">
          -
        </div>
        <div className="col-span-1 px-1 text-white border-r border-black text-right">
          -
        </div>
        <div className="col-span-4 px-1  border-r border-black text-left">
          Sub-Total
        </div>
        <div className="col-span-2 px-1 text-white border-r border-black text-right">
          -
        </div>
        <div className="col-span-2 px-1 text-white border-r border-black text-right">
          -
        </div>
        <div className="col-span-2 px-1 text-black text-right">
          {formatMoney(isExempted ? num : num)}
        </div>
      </div>
      {isExempted ? null : (
        <div className="grid grid-cols-12 items-center border-t border-black">
          <div className="col-span-1 px-1 border-r text-white  border-black  text-center">
            -
          </div>
          <div className="col-span-1 px-1 text-white border-r border-black text-right">
            -
          </div>
          <div className="col-span-4 px-1  border-r border-black text-left">
            VAT ( 18% )
          </div>
          <div className="col-span-2 px-1 text-white border-r border-black text-right">
            -
          </div>
          <div className="col-span-2 px-1 text-white border-r border-black text-right">
            -
          </div>
          <div className="col-span-2 px-1 text-black text-right">
            {formatMoney(isExempted ? 0 : num * 0.18)}
          </div>
        </div>
      )}
      <div className="grid grid-cols-12 items-center border-t-2 border-black">
        <div className="col-span-1 px-1 border-r text-white  border-black  text-center">
          -
        </div>
        <div className="col-span-1 px-1 text-white border-r border-black text-right">
          -
        </div>
        <div className="col-span-4 px-1  border-r border-black text-left">
          Grand Total (TZS)
        </div>
        <div className="col-span-2 px-1 text-white border-r border-black text-right">
          -
        </div>
        <div className="col-span-2 px-1 text-white border-r border-black text-right">
          -
        </div>
        <div className="col-span-2 px-1 text-black text-right">
          {formatMoney(isExempted ? num : num * 1.18)}
        </div>
      </div>
    </div>
  );
}
