import { useNavigate } from "react-router-dom";
import { BsChevronLeft } from "react-icons/bs";
import Profile from "../layouts/Header/components/Profile";
import { useState } from "react";

export default function BackToolnav({ title, actions }: any) {
  const [isVisible, setVisible] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <div className="h-16 bg-blue-50 flex flex-row justify-between items-center pr-5">
        <div className="flex flex-row items-center  ">
          <div className="ml-4 text-toyota text-2xl font-bold hidden sm:block md:text-2xl">
            {title}
          </div>
          <span className="ml-4 block sm:hidden text-2xl font-normal text-toyota">
            {title}
          </span>
        </div>
        <div className="flex flex-row items-center">
          <div className="z-50">
            <Profile isVisible={isVisible} setVisible={setVisible} />
          </div>
        </div>
      </div>
      <header className="bg-gray-50 px-4 sm:px-4 lg:px-4 border-t border-b border-gray-200 flex justify-between">
        <div className="flex flex-row items-center ">
          <button
            onClick={() => navigate(-1)}
            className="flex items-center justify-center text-sm text-gray-600 py-3"
          >
            <BsChevronLeft />
            <span className="text-sm">Back</span>
          </button>
        </div>
        <div className="flex justify-center items-center">{actions}</div>
      </header>
    </>
  );
}
