import { BsChevronRight } from "react-icons/bs";
import { NavLink, useMatch, useResolvedPath } from "react-router-dom";

export default function MenuLink({ title, to }: any) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: false });

  return (
    <NavLink
      to={to}
      className={`flex justify-between items-center mt-2 p-2 text-gray-900 hover:bg-white hover:text-toyota  ${
        match
          ? "bg-white border-l-2 border-toyota"
          : "bg-gray-100 border-l-2 border-gray-100 text-gray-600"
      }`}
    >
      <div className="">{title}</div>
      <BsChevronRight />
    </NavLink>
  );
}
