import {
  Button,
  Modal,
  Form,
  Row,
  Col,
  Input,
  message,
  Select,
  Checkbox,
} from "antd";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../contexts/AuthContext";
import fetchDataReq from "../../../../services/fetchDataReq";
import fetchDataTrace from "../../../../services/fetchDataTrace";
import postDataReq from "../../../../services/postDataReq";
import AddEstimate from "./AddEstimate";

const { Option } = Select;

export default function CreateItem({ isVisible, setVisible, refetch }: any) {
  const [isLoading, setLoading] = useState(false);
  const [isGeneric, setGeneric] = useState<boolean>(false);
  const [estimates, setEstimates] = useState<any>([]);
  const [estimateModal, setEstimateModal] = useState<boolean>(false);
  const [users, setUsers] = useState<any>([]);
  const { auth } = useContext(AuthContext);

  const [cats, setCats] = useState<any>([]);
  const [subs, setSubs] = useState<any>([]);
  const [clas, setClas] = useState<any>([]);
  const [fams, setFams] = useState<any>([]);

  const [selectedCat, setSelectedCat] = useState<any>(null);
  const [selectedSub, setSelectedSub] = useState<any>(null);
  const [selectedCla, setSelectedCla] = useState<any>(null);
  const [selectedFam, setSelectedFam] = useState<any>(null);

  const [isSubLoad, setSubLoad] = useState<boolean>(false);
  const [isClaLoad, setClaLoad] = useState<boolean>(false);
  const [isFamLoad, setFamLoad] = useState<boolean>(false);

  const [form] = Form.useForm();

  const onSubmit = async () => {
    setLoading(true);
    const {
      name,
      category,
      subCategory,
      itemClass,
      itemFamily,
      stockQuantity,
    } = await form.getFieldsValue();
    const data = {
      name,
      category,
      subCategory,
      itemClass,
      itemFamily,
      isGeneric,
      stockQuantity,
      estimatedPrice: estimates,
    };

    const uri = `api/v1/stockitems`;
    const response = await postDataReq({ data, uri, token: auth?._id });
    if (response.success) {
      message.success("Created Successfully");
      refetch();
      form.resetFields();
      setVisible(false);
    } else {
      message.error(response.message);
    }
    setLoading(false);
  };

  const handleFetchCats = async () => {
    const response = await fetchDataReq({
      uri: "api/v1/categories",
      token: auth?._id,
    });
    if (response?.success) {
      setCats(response?.payload?.records);
    }
  };

  const handleFetchSubs = async (id: any) => {
    const response = await fetchDataReq({
      uri: `api/v1/categories/${id}`,
      token: auth?._id,
    });
    if (response?.success) {
      setSubs(response?.results?.subCategories);
    }
  };

  const handleFetchClas = async (id: any) => {
    const response = await fetchDataReq({
      uri: `api/v1/subcategories/${id}`,
      token: auth?._id,
    });
    if (response?.success) {
      setClas(response?.results?.itemClass);
    }
  };

  const handleFetchFams = async (id: any) => {
    const response = await fetchDataReq({
      uri: `api/v1/classes/${id}`,
      token: auth?._id,
    });
    if (response?.success) {
      setFams(response?.results?.itemFamily);
    }
  };

  const catOptions = cats?.map((item: any, index: number) => (
    <Option value={item?._id} key={index}>
      {item?.name}
    </Option>
  ));

  const subOptions = subs?.map((item: any, index: number) => (
    <Option value={item?._id} key={index}>
      {item?.name}
    </Option>
  ));

  const claOptions = clas?.map((item: any, index: number) => (
    <Option value={item?._id} key={index}>
      {item?.name}
    </Option>
  ));

  const famOptions = fams?.map((item: any, index: number) => (
    <Option value={item?._id} key={index}>
      {item?.name}
    </Option>
  ));

  const clearForm = async () => {
    form.resetFields();
    setLoading(false);
  };

  useEffect(() => {
    form.resetFields();
    handleFetchCats();
  }, []);

  return (
    <Modal
      open={isVisible}
      title="New Stock Item"
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="createStockItem"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="createStockItem"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Please enter item name",
                },
              ]}
            >
              <Input type="text" placeholder="Name" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="category"
              label={"Category"}
              rules={[
                {
                  required: true,
                  message: "Please select category",
                },
              ]}
            >
              <Select
                placeholder="Category"
                onChange={(e: any) => {
                  setSelectedCat(e);
                  handleFetchSubs(e);
                }}
              >
                {catOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="subCategory"
              label={"Subcategory"}
              rules={[
                {
                  required: false,
                  message: "Please select subcategory",
                },
              ]}
            >
              {selectedCat ? (
                <Select
                  placeholder="Subcategory"
                  onChange={(e: any) => {
                    setSelectedSub(e);
                    handleFetchClas(e);
                  }}
                >
                  {subOptions}
                </Select>
              ) : (
                <Select placeholder="Subcategory" disabled>
                  {subOptions}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="itemClass"
              label={"Item Class"}
              rules={[
                {
                  required: false,
                  message: "Please select item class",
                },
              ]}
            >
              {selectedSub ? (
                <Select
                  placeholder="Item Class"
                  onChange={(e: any) => {
                    setSelectedCla(e);
                    handleFetchFams(e);
                  }}
                >
                  {claOptions}
                </Select>
              ) : (
                <Select placeholder="Item Class" disabled>
                  {claOptions}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="itemFamily"
              label={"Item Family"}
              rules={[
                {
                  required: false,
                  message: "Please item family",
                },
              ]}
            >
              {selectedCla ? (
                <Select
                  placeholder="Item Family"
                  onChange={(e: any) => {
                    setSelectedFam(e);
                  }}
                >
                  {famOptions}
                </Select>
              ) : (
                <Select placeholder="Item Family" disabled>
                  {famOptions}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="stockQuantity"
              label={"Quantity"}
              rules={[
                {
                  required: false,
                  message: "Please select quantity",
                },
              ]}
            >
              <Input type="number" placeholder="Quantity" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Checkbox
              onChange={(e: any) => {
                setGeneric(e.target.checked);
              }}
              defaultChecked={isGeneric}
            >
              Is Generic / Consumable
            </Checkbox>
          </Col>
        </Row>
        <div className="border mt-4">
          <div className="border-b p-2 bg-gray-50 flex justify-between items-center">
            <div className="">Estimated Price</div>
            <div className="">
              <Button onClick={() => setEstimateModal(true)}>Add</Button>
            </div>
          </div>
          {estimates?.length === 0 ? (
            <div className="flex justify-center items-center text-gray-500 py-4">
              No Estimated Added
            </div>
          ) : (
            <div className="">
              <div className="flex justify-between px-2 py-1 border-b bg-gray-50">
                <div className="flex">
                  <div className="mr-1">S/N.</div>
                  <div className="">Supplier</div>
                </div>
                <div className="">Amount</div>
              </div>
              {estimates?.map((item: any, index: number) => (
                <div className="flex justify-between px-2 py-1" key={index}>
                  <div className="flex">
                    <div className="mr-1">{index + 1}.</div>
                    <div className="">{item?.name}</div>
                  </div>
                  <div className="">{item?.price}</div>
                </div>
              ))}
            </div>
          )}
        </div>
        <AddEstimate
          isVisible={estimateModal}
          setVisible={setEstimateModal}
          estimates={estimates}
          setEstimates={setEstimates}
        />
      </Form>
    </Modal>
  );
}
