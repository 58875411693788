import { REQUI_BASE_URL } from "../utils/urls";

const fetchDataReq = async (requisitionId: any, token: any) => {
  const response = await fetch(
    `${REQUI_BASE_URL}/api/v1/requisitions/${requisitionId}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const result = await response.json();
  return result;
};

export default fetchDataReq;
