import { Button } from "antd";
import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { useQuery } from "@tanstack/react-query";
import { handleFetchUnits } from "../../../../api/units";
import Something from "../../../../components/shared/Something";
import CreateUnit from "./CreateUnit";
import { NavLink } from "react-router-dom";
import { BsEye } from "react-icons/bs";
import formatShort from "../../../../utils/formatShort";

export default function Units() {
  const [createModal, setCreateModal] = useState<boolean>(false);
  const {
    data: units,
    refetch,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ["units"],
    queryFn: handleFetchUnits,
  });

  return (
    <div className="border bg-white mb-2 px-4">
      <div className="flex justify-between items-center py-4 border-b mb-2">
        <div className="flex font-bold">Business Unit</div>
        <Button onClick={() => setCreateModal(true)}>
          <AiOutlinePlus />
        </Button>
      </div>
      <div className="mb-2">
        {units?.payload?.records?.length === 0 ? (
          <div className="flex justify-center items-center h-14">
            <div className="text-gray-400 text-sm">No Data Found</div>
          </div>
        ) : (
          <div className="">
            {isError ? (
              <Something refetch={refetch} isFetching={isFetching} />
            ) : (
              <div className="-mt-2">
                <div className="flex justify-between pb-2 border-b bg-gray-100 p-2">
                  <div className="flex">
                    <div className="w-8 mr-2">S/N.</div>
                    <div className="w-32">Business Unit</div>
                    <div className="w-24">Cluster</div>
                    <div className="w-24">Segment</div>
                    <div className="w-24">Division</div>
                    <div className="w-24">Capex (TZS)</div>
                    <div className="w-24">Opex (TZS)</div>
                  </div>
                  <div className="w-10 text-right">Action</div>
                </div>
                {units?.payload?.records?.map((item: any, index: number) => (
                  <div
                    className="flex justify-between px-2 py-1 border-t"
                    key={index}
                  >
                    <div className="flex">
                      <div className="w-8 mr-2">{index + 1}.</div>
                      <div className="w-32">{item?.name}</div>
                      <div className="w-24">{item?.cluster?.name}</div>
                      <div className="w-24">{item?.segment?.name}</div>
                      <div className="w-24">{item?.division?.name}</div>
                      <div className="w-24 text-right">
                        {formatShort(item?.budget?.capex) || "-"}
                      </div>
                      <div className="w-24 text-right">
                        {formatShort(item?.budget?.opex) || "-"}
                      </div>
                    </div>
                    <div className="w-10 text-right">
                      <NavLink
                        to={`/manage/structure/units/${item?._id}`}
                        className="flex justify-center items-center bg-blue-100 h-8 w-8 border mx-2 hover:bg-blue-200"
                      >
                        <BsEye />
                      </NavLink>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>

      <CreateUnit isVisible={createModal} setVisible={setCreateModal} />
    </div>
  );
}
