import { message } from "antd";
import AutDetails from "./components/AutDetails";
import OrdDetails from "./components/OrdDetails";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import fetchDataReq from "../../../services/fetchDataReq";
import fetchDataArps from "../../../services/fetchDataArps";
import FotDetails from "./components/FotDetails";
import Invalid from "./components/Invalid";
import Something from "./components/Something";
import Verifying from "./components/Verifying";
import BadQRCode from "./components/BadQRCode";

export default function VerifyLpo({}) {
  const { code } = useParams();
  const [curOrder, setCurOrder] = useState<any>({});
  const [autOrder, setAutOrder] = useState<any>({});
  const [isVerifiying, setVerifiying] = useState(true);
  const [isValid, setValid] = useState(false);
  const [isBadQR, setBadQR] = useState(false);
  const [isError, setError] = useState(false);
  const [isPaid, setPaid] = useState(false);
  const [isPosted, setPosted] = useState(false);

  let lpo = "";
  let url = "";

  const raw = code?.split("Q");
  if (raw !== undefined) {
    lpo = raw[1];
    url = raw[0].substring(0, 30);
  }

  const handleFetchRequisition = async () => {
    const uri = `api/v1/orders/verify/${lpo}`;
    const response = await fetchDataReq({ uri });
    setCurOrder(response?.payload);
    return response;
  };

  const handleFetchAutoline = async () => {
    const uri = `lpo/search/${lpo}`;
    const response = await fetchDataArps(uri);
    setAutOrder(response);
    return response;
  };

  const handleVerification = async () => {
    if (lpo === undefined) {
      message.error("Something went wrong");
      setError(true);
    }
    const [curOrder, autOrder] = await Promise.all([
      handleFetchRequisition(),
      handleFetchAutoline(),
    ]);

    if (curOrder.success && autOrder.success) {
      if (autOrder?.payload?.length === 0) {
        setPosted(false);
        setPaid(false);
        setVerifiying(false);
        setValid(true);
        message.success("LPO Record(s) Found");
        return;
      }
      if (autOrder?.payload[0]?.settled === "X") {
        setPaid(true);
      }
      setValid(true);
      setPosted(true);
      message.success("LPO Record(s) Found");
    } else {
      if (curOrder?.message === "LPO Not Found") {
        setValid(false);
        message.error("LPO Record(s) Not Found");
      } else {
        message.error("Something went wrong");
        setError(true);
      }
    }
    setVerifiying(false);
  };

  useEffect(() => {
    handleVerification();
  }, []);

  return (
    <div className="m-4 bg-white p-4">
      {isVerifiying ? (
        <Verifying />
      ) : (
        <div className="">
          {isBadQR ? (
            <div className="">
              <BadQRCode />
            </div>
          ) : (
            <div className="">
              {isError ? (
                <Something />
              ) : (
                <div className="">
                  {isValid ? (
                    <div className="">
                      <OrdDetails order={curOrder || {}} />
                      <AutDetails order={autOrder || {}} />
                      <FotDetails isPaid={isPaid} isPosted={isPosted} />
                    </div>
                  ) : (
                    <Invalid />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
