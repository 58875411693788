import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { message } from "antd";
import { useParams } from "react-router";
import { FiEdit } from "react-icons/fi";
import BackToolnav from "../../components/toolnav/BackToolnav";
import getRequisition from "../../services/getRequisition";
import formatCurrency from "../../utils/currency";

import fetchDataReq from "../../services/fetchDataReq";
import patchDataReq from "../../services/patchDataReq";
import { BsCheck2Square } from "react-icons/bs";
import { MdApproval } from "react-icons/md";
import { IoSaveOutline } from "react-icons/io5";
import SubmitRequisition from "./components/SubmitRequisition";
import ConvertRequisition from "./components/ConvertRequisition";
import { AuthContext } from "../../contexts/AuthContext";
import ApproveRequisition from "./components/ApproveRequisition";
import { AiOutlineCheck } from "react-icons/ai";
import ReadRequisition from "./components/ReadRequisition";
import ConvRequisition from "./components/ConvRequisition";
import AssignAdviser from "./components/AssignAdviser";
import Something from "../../components/shared/Something";
import AdviseModal from "./components/AdviseModal";
import { pusher } from "../..";

export default function OneRequisition({}) {
  const { id } = useParams();
  const { auth } = useContext(AuthContext);
  const [isConvert, setConvert] = useState<boolean>(false);

  const { data, isError, isFetching, refetch } = useQuery({
    queryKey: ["requisition", id],
    queryFn: () => getRequisition(id, auth?._id),
  });

  const [editable, setEditable] = useState<boolean>(false);
  const [submitModal, setSubmitModal] = useState<boolean>(false);
  const [approveModal, setApproveModal] = useState<boolean>(false);
  const [adviseModal, setAdviseModal] = useState<boolean>(false);
  const [sendModal, setSendModal] = useState<boolean>(false);
  const [convertModal, setConvertModal] = useState<boolean>(false);

  const { data: stockitems } = useQuery({
    queryKey: ["stockitems"],
    queryFn: () => fetchDataReq({ uri: "api/v1/stockitems", token: auth?._id }),
  });

  const convertable = ["Accepted"];
  const submittable = ["Created", "Rejected"];
  const approvable = ["Initiated", "Advise", "Advised"];
  const advisable = ["Initiated"];
  const approverLevels = ["V"];

  const [state, setState] = useState<any>({
    extems: [
      {
        sn: uuidv4(),
        name: "",
        description: "",
        purpose: "",
        estimatedPrice: 0,
        stockReference: null,
        quantity: 1,
      },
    ],
  });

  useEffect(() => {
    const items =
      data?.payload?.items?.length !== 0
        ? data?.payload?.items
        : [
            {
              sn: uuidv4(),
              name: "",
              purpose: "",
              description: "",
              estimatedPrice: 0,
              stockReference: null,
              quantity: 1,
              isGeneric: false,
            },
          ];

    setState({
      extems: items,
    });
    setEditable(data?.payload?.status === "Created" ? true : false);
  }, [data]);

  const handleAddLineItem = (event: any) => {
    setState({
      extems: state.extems.concat([
        {
          sn: uuidv4(),
          name: "",
          purpose: "",
          description: "",
          estimatedPrice: 0,
          stockReference: null,
          quantity: 1,
          isGeneric: false,
        },
      ]),
    });
  };

  const handleLineItemChange = (elementIndex: any) => (event: any) => {
    let extems = state.extems.map((item: any, i: any) => {
      if (elementIndex !== i) return item;
      if (event.target === undefined) {
        const el: any = stockitems?.payload?.records?.filter(
          (prod: any) => prod?._id === event
        );
        return {
          ...item,
          stockReference: el[0]?._id,
          name: el[0]?.name,
          isGeneric: el[0]?.isGeneric,
        };
      }
      return { ...item, [event.target.name]: event.target.value };
    });
    setState({ extems });
  };

  const handleSaveRequisition = async () => {
    const data = { items: state.extems };
    const uri = `api/v1/requisitions/${id}`;
    const response = await patchDataReq({ data, uri, token: auth?._id });
    if (response.success) {
      message.success("Saved Successfully");
    }
  };

  const handleRemoveLineItem = (elementIndex: any) => (event: any) => {
    setState({
      extems: state.extems.filter((item: any, i: any) => {
        return elementIndex !== i;
      }),
    });
  };

  const handleReorderLineItems = (newextems: any) => {
    setState({
      extems: newextems,
    });
  };

  const handleFocusSelect = (event: any) => {
    event.target.select();
  };

  useEffect(() => {
    const book = pusher.subscribe(`${id}`);
    book.bind("conversation", function (data: any) {
      refetch();
    });
    return () => {
      pusher.unsubscribe(`${id}`);
    };
  }, [id]);

  return (
    <div className="flex">
      <div className="h-screen flex flex-1 flex-col">
        <BackToolnav
          title={"Requisitions"}
          actions={
            <div className="flex" style={{ fontSize: 14 }}>
              {submittable.includes(data?.payload?.status) && (
                <>
                  <button
                    className="flex justify-center items-center border px-2 py-1 "
                    onClick={() => handleSaveRequisition()}
                  >
                    <div className="">
                      <IoSaveOutline />
                    </div>
                    <div className="ml-1 mb-1">Save</div>
                  </button>
                  <button
                    className={`flex justify-center items-center ml-2 border px-2 py-1 ${
                      editable ? "bg-yellow-100" : ""
                    }`}
                    onClick={() => setEditable(!editable)}
                  >
                    <div className="flex items-center justify-center">
                      <div className="">
                        <FiEdit color="black" />
                      </div>
                      <div className="ml-1 mb-1">Edit Mode</div>
                    </div>
                  </button>
                </>
              )}
              {!data?.payload?.flags?.isConverted && (
                <div className="">
                  {convertable.includes(data?.payload?.status) && (
                    <div className="">
                      {auth?.level === "C" && (
                        <button
                          onClick={() => setConvert(!isConvert)}
                          className={`flex justify-center items-center border px-2 py-1 ml-2 
                    `}
                        >
                          <div className="w-4 h-4 border">
                            {isConvert ? <AiOutlineCheck /> : null}
                          </div>
                          <div className="ml-1">Convert to LPO</div>
                        </button>
                      )}
                    </div>
                  )}
                </div>
              )}

              {submittable.includes(data?.payload?.status) && (
                <button
                  onClick={() => setSubmitModal(true)}
                  className={`flex justify-center items-center border px-2 py-1 ml-2 bg-red-100
              `}
                >
                  <div className="">
                    <BsCheck2Square />
                  </div>
                  <div className="ml-1 mb-1">Submit</div>
                </button>
              )}

              {data?.payload?.advisor?.email == auth?.email && (
                <div className="">
                  <button
                    onClick={() => setAdviseModal(true)}
                    className={`flex justify-center items-center border px-2 py-1 ml-2 bg-green-100
              `}
                  >
                    <div className="">
                      <BsCheck2Square />
                    </div>
                    <div className="ml-1 mb-1">Advise</div>
                  </button>
                </div>
              )}

              {approvable.includes(data?.payload?.status) && (
                <>
                  {approverLevels.includes(auth?.level) && (
                    <>
                      {advisable?.includes(data?.payload?.status) && (
                        <button
                          onClick={() => setSendModal(true)}
                          className="flex justify-center items-center border px-2 py-1 ml-2 bg-blue-100"
                        >
                          <div className="">
                            <MdApproval />
                          </div>
                          <div className="ml-1 mb-1">Send For Advise</div>
                        </button>
                      )}
                      {data?.payload?.unit?.entity?.approvals?.requisition
                        ?.validator?.email === auth?.email && (
                        <button
                          onClick={() => setApproveModal(true)}
                          className="flex justify-center items-center border px-2 py-1 ml-2 bg-blue-100"
                        >
                          <div className="">
                            <MdApproval />
                          </div>
                          <div className="ml-1 mb-1">Approve</div>
                        </button>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          }
        />

        <>
          {isError ? (
            <Something refetch={refetch} isFetching={isFetching} />
          ) : (
            <div className="">
              {isConvert ? (
                <ConvRequisition
                  items={state?.extems === undefined ? [] : state?.extems}
                  stockitems={
                    stockitems === undefined ? [] : stockitems?.payload?.records
                  }
                  state={state}
                  refetch={refetch}
                  setConvert={setConvert}
                  requisition={data?.payload}
                  currencyFormatter={formatCurrency}
                  addHandler={handleAddLineItem}
                  changeHandler={handleLineItemChange}
                  focusHandler={handleFocusSelect}
                  deleteHandler={handleRemoveLineItem}
                  reorderHandler={handleReorderLineItems}
                />
              ) : (
                <ReadRequisition
                  items={state?.extems === undefined ? [] : state?.extems}
                  stockitems={
                    stockitems === undefined ? [] : stockitems?.payload?.records
                  }
                  state={state}
                  editable={editable}
                  requisition={data?.payload}
                  currencyFormatter={formatCurrency}
                  addHandler={handleAddLineItem}
                  changeHandler={handleLineItemChange}
                  focusHandler={handleFocusSelect}
                  deleteHandler={handleRemoveLineItem}
                  reorderHandler={handleReorderLineItems}
                />
              )}
            </div>
          )}
        </>
        <SubmitRequisition
          isVisible={submitModal}
          setVisible={setSubmitModal}
          refetch={refetch}
          requisition={data?.payload}
          items={state.extems}
        />
        <ApproveRequisition
          isVisible={approveModal}
          setVisible={setApproveModal}
          refetch={refetch}
          requisition={data?.payload}
        />

        <AssignAdviser
          isVisible={sendModal}
          setVisible={setSendModal}
          refetch={refetch}
          requisition={data?.payload}
        />

        <AdviseModal
          isVisible={adviseModal}
          setVisible={setAdviseModal}
          refetch={refetch}
          requisition={data?.payload}
        />

        <ConvertRequisition
          isVisible={convertModal}
          setVisible={setConvertModal}
          refetch={refetch}
          requisition={data?.payload}
        />
      </div>
    </div>
  );
}
