import { useContext, useEffect, useState } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Label,
  LabelList,
  Bar,
  Tooltip,
  BarChart,
} from "recharts";
import { AuthContext } from "../../../contexts/AuthContext";
import fetchDataGate from "../../../services/fetchDataReq";
import { year_dummy } from "../../../utils/contants";

export default function ReMainChart({ dates }: any) {
  const { auth } = useContext(AuthContext);

  const [data, setData] = useState<any>(year_dummy);

  const labelFormatter = (value: any) => {
    return value;
  };

  const handleFetchData = async () => {
    let jan = { name: "Jan", month: "January", count: 0, data: [] };
    let feb = {
      name: "Feb",
      month: "February",
      count: 0,
      data: [],
    };
    let mar = {
      name: "Mar",
      month: "March",
      count: 0,
      data: [],
    };
    let apr = {
      name: "Apr",
      month: "April",
      count: 0,
      data: [],
    };
    let may = {
      name: "May",
      month: "May",
      count: 0,
      data: [],
    };
    let jun = {
      name: "Jun",
      month: "June",
      count: 0,
      data: [],
    };
    let jul = {
      name: "Jul",
      month: "July",
      count: 0,
      data: [],
    };
    let aug = {
      name: "Aug",
      month: "August",
      count: 0,
      data: [],
    };
    let sep = {
      name: "Sep",
      month: "September",
      count: 0,
      data: [],
    };
    let oct = {
      name: "Oct",
      month: "October",
      count: 0,
      data: [],
    };
    let nov = {
      name: "Nov",
      month: "November",
      count: 0,
      data: [],
    };
    let dec = {
      name: "Dec",
      month: "December",
      count: 0,
      data: [],
    };
    const response = await fetchDataGate({
      uri: `api/v1/reports/requisitions/year?start=${dates?.startDate}&end=${dates?.endDate}`,
      token: auth?._id,
    });
    if (response?.success) {
      response?.payload?.forEach((item: any) => {
        switch (item?._id) {
          case "01":
            jan.count = item?.records?.length;
            jan.data = item?.records;
            break;
          case "02":
            feb.count = item?.records?.length;
            feb.data = item?.records;
            break;
          case "03":
            mar.count = item?.records?.length;
            mar.data = item?.records;
            break;
          case "04":
            apr.count = item?.records?.length;
            apr.data = item?.records;
            break;
          case "05":
            may.count = item?.records?.length;
            may.data = item?.records;
            break;
          case "06":
            jun.count = item?.records?.length;
            jun.data = item?.records;
            break;
          case "07":
            jul.count = item?.records?.length;
            jul.data = item?.records;
            break;
          case "08":
            aug.count = item?.records?.length;
            aug.data = item?.records;
            break;
          case "09":
            sep.count = item?.records?.length;
            sep.data = item?.records;
            break;
          case "10":
            oct.count = item?.records?.length;
            oct.data = item?.records;
            break;

          case "11":
            nov.count = item?.records?.length;
            nov.data = item?.records;
            break;
          case "12":
            dec.count = item?.records?.length;
            dec.data = item?.records;
            break;
          default:
        }
      });
    }
    setData([jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec]);
  };

  useEffect(() => {
    handleFetchData();
  }, [dates]);

  return (
    <div style={{ width: "100%" }}>
      <ResponsiveContainer width="100%" height={350}>
        <BarChart
          width={730}
          height={250}
          data={data}
          margin={{ top: 25, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }}>
            <Label offset={0} position="insideBottom" />
          </XAxis>
          <YAxis label={{ value: "", angle: -90, position: "insideLeft" }} />
          <Tooltip />
          <Bar
            dataKey="count"
            fill="#2CB663"
            barSize={20}
            background={{ fill: "#eee" }}
          >
            <LabelList
              dataKey="count"
              position="top"
              formatter={labelFormatter}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
