import Cookies from "js-cookie";
import { REQUI_BASE_URL } from "../utils/urls";

const deleteDataReq = async ({ data, uri }: any) => {
  const token = Cookies.get("token");
  const response = await fetch(`${REQUI_BASE_URL}/${uri}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });
  const result = await response.json();
  return result;
};

export default deleteDataReq;
