import { Button, Modal, Form, Input, message } from "antd";
import { useContext, useEffect, useState } from "react";

import { BsCheck2Circle } from "react-icons/bs";
import { AuthContext } from "../../../contexts/AuthContext";
import getBase64 from "../../../utils/getBase64";
import postDataReq from "../../../services/postDataReq";

export default function AddAttachment({
  isVisible,
  setVisible,
  requisition,
  refetch,
  type,
}: any) {
  const { auth } = useContext(AuthContext);
  const [errMessage, setErrMessage] = useState<string>("");

  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedFile, setSelectedFile]: any = useState(null);
  const [form] = Form.useForm();

  const changeHandler = (event: any) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const onSubmit = async () => {
    setLoading(true);

    if (!selectedFile) {
      message.error("Please select a file");
      return;
    }

    let base64 = await getBase64(selectedFile);
    let filetype = selectedFile?.type?.slice(selectedFile?.type - 3);
    let filesize = selectedFile?.size;

    let uri = "";
    let data = {};

    uri = `api/v1/requisitions/${requisition?._id}/suppliers/${type}/attachment`;
    data = {
      document: base64,
      filedetails: {
        filetype: filetype,
        filesize: filesize,
      },
    };

    const response = await postDataReq({ data, uri, token: auth?._id });
    if (response.success) {
      await refetch();
      clearForm();
      setVisible(false);
    } else {
      await refetch();
      setVisible(false);
      setLoading(false);
      return;
    }

    refetch();
    setVisible(false);
    setLoading(false);
  };

  const clearForm = async () => {
    form.resetFields();
    setSelectedFile(null);
    setIsSelected(false);
    setErrMessage("");
  };

  useEffect(() => {
    form.resetFields();
  }, [requisition]);

  return (
    <Modal
      open={isVisible}
      title={
        <div>
          Add Attachment : <span className="uppercase">{type}</span>
        </div>
      }
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="addAttachment"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="addAttachment"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="flex flex-col justify-center items-center bg-gray-100 h-32 rounded-md border">
          <div className="flex flex-col justify-center items-center">
            <div className="">
              <div className="">
                {isSelected ? (
                  <div className="flex flex-col justify-center items-center">
                    <BsCheck2Circle color={"#16a34a"} size={30} />
                    <div className="text-gray-600 mt-2">Document Selected</div>
                    <div className="text-gray-500">{selectedFile?.name}</div>
                  </div>
                ) : (
                  <div className="">
                    {errMessage && (
                      <div className="flex items-center">
                        <div className="text-red-600">{errMessage}</div>
                      </div>
                    )}

                    <Form.Item
                      name="file"
                      label="Upload Document"
                      rules={[
                        {
                          required: false,
                          message: "Please select file",
                        },
                      ]}
                    >
                      <Input
                        type="file"
                        placeholder="Document"
                        onChange={changeHandler}
                      />
                    </Form.Item>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  );
}
