import { Button, Modal, Form, Row, Col, Select, message } from "antd";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import fetchDataReq from "../../../services/fetchDataReq";
import { handleAssignSupplier } from "../../../api/orders";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const { Option } = Select;

export default function AssignSupplier({
  isVisible,
  setVisible,
  order,
  type,
}: any) {
  const { auth } = useContext(AuthContext);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const handleFetchSuppliers = async () => {
    const response = await fetchDataReq({
      uri: "api/v1/suppliers",
      token: auth?._id,
    });
    if (response?.success) {
      setData(response?.payload?.records);
    }
  };

  const { mutate: assignSupplier } = useMutation({
    mutationFn: handleAssignSupplier,
    onSuccess: () => {
      message.success("Assigned Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["orders", order?._id] });
      setVisible(false);
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const { supplier_id } = form.getFieldsValue();
    const filtered = data?.filter((item: any) => item?._id === supplier_id);
    const body = {
      supplierId: supplier_id,
      supplierName: filtered[0]?.name,
      type: type,
      isSelected: false,
    };
    await assignSupplier({
      data: body,
      _id: order?._id,
      supplier_id,
    });
  };

  const hideModal = () => {
    form.resetFields();
    setVisible(false);
  };

  const supplierOptions = data.map((supplier: any, index: number) => (
    <Option value={supplier?._id} key={index}>
      {supplier?.name}
    </Option>
  ));

  useEffect(() => {
    form.resetFields();
    handleFetchSuppliers();
  }, []);

  return (
    <Modal
      open={isVisible}
      title={
        <div>
          Assign Supplier : <span className="uppercase">{type}</span>
        </div>
      }
      onCancel={hideModal}
      footer={[
        <Button key="back" onClick={hideModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form={`${type}`}
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
        >
          Assign
        </Button>,
      ]}
    >
      <Form
        id={`${type}`}
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="supplier_id"
              label="Supplier"
              rules={[
                {
                  required: true,
                  message: "Please select supplier",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Search and select supplier"
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {supplierOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
