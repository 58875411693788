import QRVerify from "../shared/QRVerify";
import LineItems from "./LineItems";
import { useEffect } from "react";
import Moment from "react-moment";

export default function OrderWrapper({ order }: any) {
  useEffect(() => {}, [order]);

  const company = order?.company?.entity;
  return (
    <div className="book" style={{ fontSize: 13 }}>
      <div className="page bg-white">
        <div className="subpage2">
          <div
            style={{ fontSize: 15 }}
            className="flex justify-between items-center border-b-2 border-black -mt-10 pb-6"
          >
            <div className="w-1/3"></div>
            <div className="w-1/3 font-bold">LOCAL PURCHASE ORDER</div>
            <div className="w-1/3"></div>
          </div>

          {/* ADDRESS */}
          <div className="flex justify-center  items-center mt-4">
            <div className="w-2/5">
              <div className="leading-8 font-bold uppercase">
                {company?.name ? company?.name : "-"}
              </div>
              <div className="leading-relaxed">
                P.O.BOX : {company?.box ? company?.box : "-"}
              </div>
              <div className="leading-relaxed">
                Tel: {company?.telephone ? company?.telephone : "-"}
              </div>
              <div className="leading-relaxed">
                Fax: {company?.fax ? company?.fax : "-"}
              </div>
              <div className="leading-relaxed">
                Telex: {company?.telex ? company?.telex : "-"}
              </div>
              <div className="leading-relaxed">
                VRN: {company?.vrn ? company?.vrn : "-"}
              </div>
              <div className="leading-relaxed">
                TIN: {company?.tin ? company?.tin : "-"}
              </div>
            </div>
            <div className="w-1/5"></div>
            <div className="w-2/5 flex flex-col">
              <img
                src={company?.logo}
                alt=""
                width={100}
                style={{ objectFit: "cover" }}
              />
              <div className="flex ml-2">
                <div className="w-10">No:</div>{" "}
                <span className="border-b border-black">{order?.orderId}</span>
              </div>
              <div className="flex ml-2">
                <div className="w-10">Date:</div>
                <span className="border-b border-black">
                  <Moment format="DD/MM/YYYY">{order?.dates?.created}</Moment>
                </span>
              </div>
            </div>
          </div>

          {/* SUPPLIER */}
          <div className="flex mt-6 mb-2">
            <div className="mr-2">To:</div>
            <div className="">
              <div className="border-b border-black uppercase">
                {order?.supplier?.name}
              </div>
              <div className="">
                <div className="">
                  {order?.supplier?.entity?.address
                    ? order?.supplier?.entity?.address
                    : "-"}
                </div>
                <div className="">
                  {order?.supplier?.entity?.tin
                    ? order?.supplier?.entity?.tin
                    : "-"}
                </div>
              </div>
            </div>
          </div>
          {/* ITEMS */}
          <div className="">
            <div className="my-2">Please supply the following goods:</div>
            <LineItems items={order?.items} isExempted={order?.isExempted} />
          </div>

          {/* SIGNATURE */}
          <div className="flex justify-between my-8">
            <div className="">Invoice No.</div>
            <div className="flex">
              <div className="">Signed:</div>
              {order?.flags?.isCompleted ? (
                <div className="">
                  <img src={order?.signature?.url} alt="" width={80} />
                </div>
              ) : (
                <div className="w-24 ml-1"> _ _ _ _ _ _ _ _ _ _ </div>
              )}
            </div>
          </div>

          {/* PAYMENT */}
          <div className="flex justify-between">
            <div className="">
              Payment Terms:
              <span className="font-bold">{order?.paymentTerms}</span>
            </div>
            <div className="">
              Date:
              {order?.flags?.isCompleted ? (
                <span className="border-b border-black ml-1">
                  <Moment format="DD/MM/YYYY">{order?.dates?.completed}</Moment>
                </span>
              ) : (
                <span className="ml-1"> _ _ _ _ _ _ _ _ _ _</span>
              )}
            </div>
          </div>

          {/* QRCODE */}
          <div className="mt-6">
            <QRVerify value={order?.orderId} size={110} />
          </div>
        </div>
      </div>
    </div>
  );
}
