import {
  Button,
  Form,
  Row,
  Col,
  Input,
  message,
  Space,
  Drawer,
  Select,
} from "antd";
import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { handleFetchDivisions } from "../../../../api/divisions";
import { handleUpdateUnit } from "../../../../api/units";

const { Option } = Select;

export default function UpdateUnit({ isVisible, setVisible, unit }: any) {
  const [isLoading, setLoading] = useState(false);

  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { data: divisions } = useQuery({
    queryKey: ["divisions"],
    queryFn: handleFetchDivisions,
  });

  const { mutate } = useMutation({
    mutationFn: handleUpdateUnit,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["units", unit?._id] });
      setVisible(false);
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    setLoading(true);
    const { name, division, capex, opex } = await form.getFieldsValue();
    const data = {
      name: name,
      division: division,
      budget: { capex: capex, opex: opex },
    };
    await mutate({ _id: unit?._id, data });
    setLoading(false);
  };

  const divisionOptions = divisions?.payload?.records?.map(
    (division: any, index: number) => (
      <Option value={division?._id} key={index}>
        {`${division?.name} ( Segment: ${division?.segment?.name} )`}
      </Option>
    )
  );

  useEffect(() => {
    form.resetFields();
  }, [unit]);

  return (
    <Drawer
      open={isVisible}
      title={`UPDATE UNIT`}
      placement={"right"}
      width={800}
      onClose={() => setVisible(!isVisible)}
      closable={false}
      extra={
        <Space className="flex justify-center items-center">
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <Form
        id="updateUnit"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="name"
              label={"Business Unit"}
              initialValue={unit?.name}
              rules={[
                {
                  required: true,
                  message: "Please enter unit name",
                },
              ]}
            >
              <Input type="text" placeholder="Business unit name" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="division"
              label="Select Division"
              initialValue={unit?.division?._id}
              rules={[
                {
                  required: true,
                  message: "Please select division",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select Division"
                optionFilterProp="children"
                defaultValue={unit?.division?._id}
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {divisionOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="capex"
              label={"Capex Budget"}
              initialValue={unit?.budget?.capex}
              rules={[
                {
                  required: false,
                  message: "Please enter capex budget",
                },
              ]}
            >
              <Input type="number" placeholder="Capex Budget" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="opex"
              label={"Opex Budget"}
              initialValue={unit?.budget?.opex}
              rules={[
                {
                  required: false,
                  message: "Please enter opex budget",
                },
              ]}
            >
              <Input type="number" placeholder="Opex Budget" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button type="primary" htmlType="submit" className="mr-2">
            {isLoading ? "Updating ..." : "Update"}
          </Button>
          <Button htmlType="button" onClick={() => setVisible(false)}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
