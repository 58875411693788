import { Link, useMatch, useResolvedPath } from "react-router-dom";

export default function AdminLink({ children, icon, to }: any) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: false });

  return (
    <div
      className={`border-l-2 ${
        match ? "border-white bg-toyota-dark " : "border-toyota"
      }`}
    >
      <Link
        to={to}
        className={`hover:text-white ${match ? "text-white" : "text-white"}`}
      >
        <div className="flex flex-col py-4 px-2  items-center">
          <div className="">{icon}</div>
          <div className="hidden sm:block text-sm">{children}</div>
        </div>
      </Link>
    </div>
  );
}
