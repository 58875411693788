import { Button, Form, Row, Col, message, Select, Modal } from "antd";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../contexts/AuthContext";
import fetchDataReq from "../../../../services/fetchDataReq";
import postDataReq from "../../../../services/postDataReq";

const { Option } = Select;

export default function UpdateAprv({ isVisible, setVisible, unit, refetch, selected }: any) {
  const { auth } = useContext(AuthContext);
  const [isLoading, setLoading] = useState(false);
  const [users, setUsers] = useState<any>([]);

  const [form] = Form.useForm();

  const onSubmit = async () => {
    setLoading(true);
    const { user } = await form.getFieldsValue();
    const data = {
      user,
      isCapex: selected?.isCapex,
      isLpo: selected?.isLpo,
      isDirect: selected?.isDirect,
      level: selected?.level,
    };
    const uri = `api/v1/units/${unit?._id}/approval`;
    const response = await postDataReq({ data, uri, token: auth?._id });

    if (response.success) {
      setLoading(false);
      message.success("Update Successfully");
      setVisible(false);
      refetch();
      form.resetFields();
    } else {
      message.error(response.message);
    }
    setLoading(false);
  };

  const handleFetchUsers = async () => {
    const uri = `api/v1/users`;
    const response = await fetchDataReq({ uri, token: auth?._id });
    if (response.success) {
      setUsers(response?.payload?.records);
    }
  };

  const userOptions = users.map((user: any, index: number) => (
    <Option value={user?._id} key={index}>
      {user?.name}
    </Option>
  ));

  useEffect(() => {
    form.resetFields();
    handleFetchUsers();
  }, [selected]);

  return (
    <Modal
      open={isVisible}
      title={`UPDATE ${selected?.level}`}
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="updateApprover"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
        >
          Update
        </Button>,
      ]}
    >
      <Form
        id="updateApprover"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="user"
              label="Select User"
              rules={[
                {
                  required: true,
                  message: "Please select user",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Search and select user"
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {userOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
