import formatMoney from "../../../utils/formatMoney";
import formatSize from "../../../utils/formatSize";
import pdf_avatar from "../../../assets/images/pdf.png";
import { FiCheck } from "react-icons/fi";

export default function Comperative({ order }: any) {
  return (
    <div className="">
      <div className="border-b py-2">
        <div className="uppercase">Comperative Quotations</div>
      </div>

      <Supplier1 order={order} />
      <Supplier2 order={order} />
      <Supplier3 order={order} />
    </div>
  );
}

const Supplier1 = ({ order }: any) => {
  const getTotalAmount = ({ items }: any) => {
    let total = 0;
    items?.forEach((item: any) => {
      total =
        total + item?.quantity * item?.l1.price * (1 - item?.discount / 100);
    });
    return isNaN(total) ? 0 : total;
  };

  if (!order?.suppliers?.l1?.id) {
    return <></>;
  }

  const num: any = getTotalAmount({ items: order?.items });
  return (
    <div
      className={`mb-4 ${
        order?.supplier?.name === order?.suppliers?.l1?.name
          ? "bg-green-50"
          : ""
      }`}
    >
      <div className="border border-gray-400">
        <div className="flex justify-between py-1">
          <div className="mx-2">
            <div className="uppercase">
              Supplier : {order?.suppliers?.l1?.name}
            </div>

            <div className="flex items-center justify-start pr-2">
              {order?.supplier?.name === order?.suppliers?.l1?.name && (
                <div className="flex items-center justify-center">
                  <div className="w-4 h-4 border bg-green-50 flex justify-center items-center">
                    <FiCheck />
                  </div>
                  <div className="ml-1 text-green-600">Selected</div>
                </div>
              )}
            </div>
          </div>

          <div className="flex justify-between items-center mx-2 text-xs">
            {order?.suppliers?.l1?.attachment && (
              <a
                href={`${order?.suppliers?.l1?.attachment?.link}`}
                target="_blank"
                className="flex text-gray-700 items-center"
              >
                <div className="mr-2">
                  <img src={pdf_avatar} alt="Logo" height={15} width={20} />
                </div>
                <div className="flex flex-col items-start">
                  <div className="">Quotation</div>
                  {order?.suppliers?.l1?.attachment?.size ? (
                    <div className="text-xs">
                      {formatSize(order?.suppliers?.l1?.attachment?.size)}
                    </div>
                  ) : (
                    <div className="text-xs">-</div>
                  )}
                </div>
              </a>
            )}
          </div>
        </div>
      </div>
      <div className="border border-gray-400 text-xs">
        <div className="grid grid-cols-12 border-b  border-gray-400">
          <div className="col-span-1 py-1 px-2 font-bold border-r border-gray-400  text-center truncate">
            Item
          </div>
          <div className="col-span-1 py-1 px-2  font-bold border-r border-gray-400  text-center">
            Qty
          </div>
          <div className="col-span-4 py-1 px-2  font-bold border-r border-gray-400  text-left">
            Description
          </div>
          <div className="col-span-3 py-1 px-2  font-bold border-r border-gray-400  text-right">
            Unit Price * Qty
          </div>
          <div className="col-span-3 py-1 px-2  font-bold text-left">
            Remarks
          </div>
        </div>
        {order?.items?.map((item: any, index: number) => (
          <div
            key={index}
            className="grid grid-cols-12 border-t border-gray-400 items-center text-gray-700"
          >
            <div className="col-span-1 px-2  border-r border-gray-400  text-center">
              {index + 1}.
            </div>
            <div className="col-span-1 px-2   border-r border-gray-400 truncate text-center">
              {item?.quantity}
            </div>
            <div className="col-span-4 px-2   border-r border-gray-400 truncate">
              {item?.name}
            </div>
            <div className="col-span-3 px-2   border-r border-gray-400 truncate text-right">
              {formatMoney(
                item?.l1.price ? item?.l1.price * item?.quantity : 0
              )}
            </div>
            <div className="col-span-3 px-2 text-right">{item?.remarks}</div>
          </div>
        ))}

        <div className="grid grid-cols-12 items-center border-t border-black">
          <div className="col-span-1 px-1 border-r text-white  border-black  text-center">
            -
          </div>
          <div className="col-span-1 px-1 text-white border-r border-black text-right">
            -
          </div>
          <div className="col-span-4 px-1  border-r border-black text-left">
            Sub-Total
          </div>
          <div className="col-span-3 px-1 text-black border-r border-black text-right">
            {formatMoney(order?.isExempted ? num : num)}
          </div>
          <div className="col-span-3 px-1 text-white text-right">-</div>
        </div>
        {order?.isExempted ? null : (
          <div className="grid grid-cols-12 items-center border-t border-black">
            <div className="col-span-1 px-1 border-r text-white  border-black  text-center">
              -
            </div>
            <div className="col-span-1 px-1 text-white border-r border-black text-right">
              -
            </div>
            <div className="col-span-4 px-1  border-r border-black text-left">
              VAT ( 18% )
            </div>
            <div className="col-span-3 px-1 text-black border-r border-black text-right">
              {formatMoney(order?.isExempted ? 0 : num * 0.18)}
            </div>
            <div className="col-span-3 px-1 text-white text-right">-</div>
          </div>
        )}
        <div className="grid grid-cols-12 items-center border-t-2 border-black">
          <div className="col-span-1 px-1 border-r text-white  border-black  text-center">
            -
          </div>
          <div className="col-span-1 px-1 text-white border-r border-black text-right">
            -
          </div>
          <div className="col-span-4 px-1  border-r border-black text-left">
            Grand Total (TZS)
          </div>

          <div className="col-span-3 px-1 text-black border-r border-black text-right">
            {formatMoney(order?.isExempted ? num : num * 1.18)}
          </div>
          <div className="col-span-3 px-1 text-white text-right">-</div>
        </div>
      </div>
    </div>
  );
};

const Supplier2 = ({ order }: any) => {
  const getTotalAmount = ({ items }: any) => {
    let total = 0;
    items?.forEach((item: any) => {
      total =
        total + item?.quantity * item?.l2.price * (1 - item?.discount / 100);
    });
    return isNaN(total) ? 0 : total;
  };

  if (!order?.suppliers?.l2?.id) {
    return <></>;
  }

  const num: any = getTotalAmount({ items: order?.items });
  return (
    <div
      className={`mb-4 ${
        order?.supplier?.name === order?.suppliers?.l2?.name
          ? "bg-green-50"
          : ""
      }`}
    >
      <div className="border border-gray-400">
        <div className="flex justify-between py-1">
          <div className="mx-2">
            <div className="uppercase">
              Supplier : {order?.suppliers?.l2?.name}
            </div>

            <div className="flex items-center justify-start pr-2">
              {order?.supplier?.name === order?.suppliers?.l2?.name && (
                <div className="flex items-center justify-center">
                  <div className="w-4 h-4 border bg-green-50 flex justify-center items-center">
                    <FiCheck />
                  </div>
                  <div className="ml-1 text-green-600">Selected</div>
                </div>
              )}
            </div>
          </div>

          <div className="flex justify-between items-center mx-2 text-xs">
            {order?.suppliers?.l2?.attachment && (
              <a
                href={`${order?.suppliers?.l2?.attachment?.link}`}
                target="_blank"
                className="flex text-gray-700 items-center"
              >
                <div className="mr-2">
                  <img src={pdf_avatar} alt="Logo" height={15} width={20} />
                </div>
                <div className="flex flex-col items-start">
                  <div className="">Quotation</div>
                  {order?.suppliers?.l2?.attachment?.size ? (
                    <div className="text-xs">
                      {formatSize(order?.suppliers?.l2?.attachment?.size)}
                    </div>
                  ) : (
                    <div className="text-xs">-</div>
                  )}
                </div>
              </a>
            )}
          </div>
        </div>
      </div>
      <div className="border border-gray-400 text-xs">
        <div className="grid grid-cols-12 border-b  border-gray-400">
          <div className="col-span-1 py-1 px-2 font-bold border-r border-gray-400  text-center truncate">
            Item
          </div>
          <div className="col-span-1 py-1 px-2  font-bold border-r border-gray-400  text-center">
            Qty
          </div>
          <div className="col-span-4 py-1 px-2  font-bold border-r border-gray-400  text-left">
            Description
          </div>
          <div className="col-span-3 py-1 px-2  font-bold border-r border-gray-400  text-right">
            Unit Price * Qty
          </div>
          <div className="col-span-3 py-1 px-2  font-bold text-left">
            Remarks
          </div>
        </div>
        {order?.items?.map((item: any, index: number) => (
          <div
            key={index}
            className="grid grid-cols-12 border-t border-gray-400 items-center text-gray-700"
          >
            <div className="col-span-1 px-2  border-r border-gray-400  text-center">
              {index + 1}.
            </div>
            <div className="col-span-1 px-2   border-r border-gray-400 truncate text-center">
              {item?.quantity}
            </div>
            <div className="col-span-4 px-2   border-r border-gray-400 truncate">
              {item?.name}
            </div>
            <div className="col-span-3 px-2   border-r border-gray-400 truncate text-right">
              {formatMoney(
                item?.l2.price ? item?.l2.price * item?.quantity : 0
              )}
            </div>
            <div className="col-span-3 px-2 text-right">{item?.remarks}</div>
          </div>
        ))}

        <div className="grid grid-cols-12 items-center border-t border-gray-400">
          <div className="col-span-1 px-1 border-r text-white  border-gray-400  text-center">
            -
          </div>
          <div className="col-span-1 px-1 text-white border-r border-gray-400 text-right">
            -
          </div>
          <div className="col-span-4 px-1  border-r border-gray-400 text-left">
            Sub-Total
          </div>
          <div className="col-span-3 px-1 text-black border-r border-gray-400 text-right">
            {formatMoney(order?.isExempted ? num : num)}
          </div>
          <div className="col-span-3 px-1 text-white text-right">-</div>
        </div>
        {order?.isExempted ? null : (
          <div className="grid grid-cols-12 items-center border-t border-gray-400">
            <div className="col-span-1 px-1 border-r text-white  border-gray-400  text-center">
              -
            </div>
            <div className="col-span-1 px-1 text-white border-r border-gray-400 text-right">
              -
            </div>
            <div className="col-span-4 px-1  border-r border-gray-400 text-left">
              VAT ( 18% )
            </div>
            <div className="col-span-3 px-1 text-black border-r border-gray-400 text-right">
              {formatMoney(order?.isExempted ? 0 : num * 0.18)}
            </div>
            <div className="col-span-3 px-1 text-white text-right">-</div>
          </div>
        )}
        <div className="grid grid-cols-12 items-center border-t-2 border-gray-400">
          <div className="col-span-1 px-1 border-r text-white  border-gray-400  text-center">
            -
          </div>
          <div className="col-span-1 px-1 text-white border-r border-gray-400 text-right">
            -
          </div>
          <div className="col-span-4 px-1  border-r border-gray-400 text-left">
            Grand Total (TZS)
          </div>

          <div className="col-span-3 px-1 text-black border-r border-gray-400 text-right">
            {formatMoney(order?.isExempted ? num : num * 1.18)}
          </div>
          <div className="col-span-3 px-1 text-white text-right">-</div>
        </div>
      </div>
    </div>
  );
};

const Supplier3 = ({ order }: any) => {
  const getTotalAmount = ({ items }: any) => {
    let total = 0;
    items?.forEach((item: any) => {
      total =
        total + item?.quantity * item?.l3.price * (1 - item?.discount / 100);
    });
    return isNaN(total) ? 0 : total;
  };

  if (!order?.suppliers?.l3?.id) {
    return <></>;
  }

  const num: any = getTotalAmount({ items: order?.items });
  return (
    <div
      className={`mb-4 ${
        order?.supplier?.name === order?.suppliers?.l3?.name
          ? "bg-green-50"
          : ""
      }`}
    >
      <div className="border border-gray-400">
        <div className="flex justify-between py-1">
          <div className="mx-2">
            <div className="uppercase">
              Supplier : {order?.suppliers?.l3?.name}
            </div>

            <div className="flex items-start justify-start pr-2">
              {order?.supplier?.name === order?.suppliers?.l3?.name && (
                <div className="flex items-center justify-center">
                  <div className="w-4 h-4 border bg-green-50 flex justify-center items-center">
                    <FiCheck />
                  </div>
                  <div className="ml-1 text-green-600">Selected</div>
                </div>
              )}
            </div>
          </div>

          <div className="flex justify-between items-center mx-2 text-xs">
            {order?.suppliers?.l3?.attachment && (
              <a
                href={`${order?.suppliers?.l3?.attachment?.link}`}
                target="_blank"
                className="flex text-gray-700 items-center"
              >
                <div className="mr-2">
                  <img src={pdf_avatar} alt="Logo" height={15} width={20} />
                </div>
                <div className="flex flex-col items-start">
                  <div className="">Quotation</div>
                  {order?.suppliers?.l3?.attachment?.size ? (
                    <div className="text-xs">
                      {formatSize(order?.suppliers?.l3?.attachment?.size)}
                    </div>
                  ) : (
                    <div className="text-xs">-</div>
                  )}
                </div>
              </a>
            )}
          </div>
        </div>
      </div>
      <div className="border border-gray-400 text-xs">
        <div className="grid grid-cols-12 border-b  border-gray-400">
          <div className="col-span-1 py-1 px-2 font-bold border-r border-gray-400  text-center truncate">
            Item
          </div>
          <div className="col-span-1 py-1 px-2  font-bold border-r border-gray-400  text-center">
            Qty
          </div>
          <div className="col-span-4 py-1 px-2  font-bold border-r border-gray-400  text-left">
            Description
          </div>
          <div className="col-span-3 py-1 px-2  font-bold border-r border-gray-400  text-right">
            Unit Price * Qty
          </div>
          <div className="col-span-3 py-1 px-2  font-bold text-left">
            Remarks
          </div>
        </div>
        {order?.items?.map((item: any, index: number) => (
          <div
            key={index}
            className="grid grid-cols-12 border-t border-gray-400 items-center text-gray-700"
          >
            <div className="col-span-1 px-2  border-r border-gray-400  text-center">
              {index + 1}.
            </div>
            <div className="col-span-1 px-2   border-r border-gray-400 truncate text-center">
              {item?.quantity}
            </div>
            <div className="col-span-4 px-2   border-r border-gray-400 truncate">
              {item?.name}
            </div>
            <div className="col-span-3 px-2   border-r border-gray-400 truncate text-right">
              {formatMoney(
                item?.l3.price ? item?.l3.price * item?.quantity : 0
              )}
            </div>
            <div className="col-span-3 px-2 text-right">{item?.remarks}</div>
          </div>
        ))}

        <div className="grid grid-cols-12 items-center border-t border-gray-400">
          <div className="col-span-1 px-1 border-r text-white  border-gray-400  text-center">
            -
          </div>
          <div className="col-span-1 px-1 text-white border-r border-gray-400 text-right">
            -
          </div>
          <div className="col-span-4 px-1  border-r border-gray-400 text-left">
            Sub-Total
          </div>
          <div className="col-span-3 px-1 text-black border-r border-gray-400 text-right">
            {formatMoney(order?.isExempted ? num : num)}
          </div>
          <div className="col-span-3 px-1 text-white text-right">-</div>
        </div>
        {order?.isExempted ? null : (
          <div className="grid grid-cols-12 items-center border-t border-gray-400">
            <div className="col-span-1 px-1 border-r text-white  border-gray-400  text-center">
              -
            </div>
            <div className="col-span-1 px-1 text-white border-r border-gray-400 text-right">
              -
            </div>
            <div className="col-span-4 px-1  border-r border-gray-400 text-left">
              VAT ( 18% )
            </div>
            <div className="col-span-3 px-1 text-black border-r border-gray-400 text-right">
              {formatMoney(order?.isExempted ? 0 : num * 0.18)}
            </div>
            <div className="col-span-3 px-1 text-white text-right">-</div>
          </div>
        )}
        <div className="grid grid-cols-12 items-center border-t-2 border-gray-400">
          <div className="col-span-1 px-1 border-r text-white  border-gray-400  text-center">
            -
          </div>
          <div className="col-span-1 px-1 text-white border-r border-gray-400 text-right">
            -
          </div>
          <div className="col-span-4 px-1  border-r border-gray-400 text-left">
            Grand Total (TZS)
          </div>

          <div className="col-span-3 px-1 text-black border-r border-gray-400 text-right">
            {formatMoney(order?.isExempted ? num : num * 1.18)}
          </div>
          <div className="col-span-3 px-1 text-white text-right">-</div>
        </div>
      </div>
    </div>
  );
};
