import { useEffect, useState } from "react";
import { BsCheck2, BsCheck2All } from "react-icons/bs";
import { CgMoreAlt } from "react-icons/cg";
import Moment from "react-moment";
import { VscDash } from "react-icons/vsc";
import ReasonChart from "./components/ReasonChart";
import StatusWidget from "./components/StatusWidget";
import fetchDataReq from "../../services/fetchDataReq";
import ReListMetrics from "./components/ReListMetrics";
import ReWeekChart from "./components/ReWeekChart";
import ReMainChart from "./components/ReMainChart";

export default function ReDashboard({ dates, loading, auth }: any) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [createdReq, setCreatedReq] = useState<any>({
    name: "Created",
    count: 0,
    data: [],
  });
  const [initiatedReq, setInitiatedReq] = useState<any>({
    name: "Initiated",
    count: 0,
    data: [],
  });
  const [rejectedReq, setRejectedReq] = useState<any>({
    name: "Rejected",
    count: 0,
    data: [],
  });
  const [acceptedReq, setAcceptedReq] = useState<any>({
    name: "Rejected",
    count: 0,
    data: [],
  });
  const status = [
    {
      id: 1,
      name: "Created",
      icon_color: "#42d6cc",
      text_color: "#323232",
      background: "#00D0C1",
      count: createdReq?.count,
      data: createdReq?.data,
      icon: <CgMoreAlt color="#FFFFFF" size={30} />,
    },
    {
      id: 1,
      name: "Initiated",
      icon_color: "#6182ed",
      text_color: "#FFFFFF",
      background: "#4C71F0",
      count: initiatedReq?.count,
      data: initiatedReq?.data,
      icon: <VscDash color="#FFFFFF" size={30} />,
    },
    {
      id: 1,
      name: "Rejected",
      icon_color: "#f08e59",
      text_color: "#FFFFFF",
      background: "#EF854B",
      count: rejectedReq?.count,
      data: rejectedReq?.data,
      icon: <BsCheck2 color="#FFFFFF" size={30} />,
    },
    {
      id: 1,
      name: "Accepted",
      icon_color: "#c42dbf",
      text_color: "#FFFFFF",
      background: "#B319AD",
      count: acceptedReq?.count,
      data: acceptedReq?.data,
      icon: <BsCheck2All color="#FFFFFF" size={30} />,
    },
  ];

  const handleFetchStatus = async (dates: any) => {
    setLoading(true);
    const response = await fetchDataReq({
      uri: `api/v1/reports/requisitions?start=${dates?.startDate}&end=${dates?.endDate}`,
      token: auth?._id,
    });
    if (response?.success) {
      const created = response.payload.filter(
        (item: any) => item?._id === "Created"
      );

      setCreatedReq({
        name: "Created",
        count: created[0]?.records?.length || 0,
        data: created[0]?.records || [],
      });

      const initiated = response.payload.filter(
        (item: any) => item?._id === "Initiated"
      );

      setInitiatedReq({
        name: "Initiated",
        count: initiated[0]?.records?.length || 0,
        data: initiated[0]?.records || [],
      });

      const rejected = response.payload.filter(
        (item: any) => item?._id === "Rejected"
      );

      setRejectedReq({
        name: "Rejected",
        count: rejected[0]?.records?.length || 0,
        data: rejected[0]?.records || [],
      });

      const accepted = response.payload.filter(
        (item: any) => item?._id === "Accepted"
      );

      setAcceptedReq({
        name: "Accepted",
        count: accepted[0]?.records?.length || 0,
        data: accepted[0]?.records || [],
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    handleFetchStatus(dates);
  }, [dates]);
  return (
    <div className="min-h-screen w-full mb-4">
      <div className="p-4 h-auto">
        <div className="grid grid-cols-12 gap-2">
          {status?.map((item: any, index: number) => (
            <StatusWidget
              item={item}
              key={index}
              dates={dates}
              isLoading={isLoading}
            />
          ))}
        </div>
        <div className="grid grid-cols-12 gap-x-2 mt-4">
          <div className="col-span-12 sm:col-span-6 border rounded-md bg-white">
            <div className="border-b p-4">Weekly Metrics : Requisitions</div>
            <div className="h-80 w-full">
              <ReWeekChart dates={dates} />
            </div>
          </div>
          <div className="col-span-12 sm:col-span-6 border rounded-md bg-white">
            <div className="border-b p-4">
              <div className="flex items-center text-gray-800">
                <div className="text-xl mr-2">Converion : </div>
                {dates?.isRange ? (
                  <div className="text-gray-400">
                    <Moment format="Do MMM, YYYY">{dates?.startDate}</Moment>
                    <span className="px-2">-</span>
                    <Moment format="Do MMM, YYYY">{dates?.endDate}</Moment>
                  </div>
                ) : (
                  <div className="text-gray-400">
                    <Moment format="Do MMM, YYYY">{dates?.startDate}</Moment>
                  </div>
                )}
              </div>
            </div>
            <div className="h-80 w-full">
              <ReasonChart dates={dates} />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-12 gap-x-2 mt-4">
          <div className="col-span-12 sm:col-span-12 border rounded-md bg-white">
            <div className="border-b p-4">Monthly Metrics : Requisitions</div>
            <div className="h-auto w-full">
              <ReMainChart dates={dates} />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-12 gap-x-2 mt-4">
          <div className="col-span-12 sm:col-span-6 border rounded-md bg-white">
            <div className="border-b p-4">Companies : Requisitions</div>
            <div className="h-auto w-full">
              <ReListMetrics
                dates={dates}
                loading={isLoading}
                type={"company"}
                title={"Company"}
              />
            </div>
          </div>
          <div className="col-span-12 sm:col-span-6 border rounded-md bg-white">
            <div className="border-b p-4">Departments : Requisitions</div>
            <div className="h-auto w-full">
              <ReListMetrics
                dates={dates}
                loading={isLoading}
                type={"department"}
                title={"Department"}
              />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-12 gap-x-2 mt-4">
          <div className="col-span-12 sm:col-span-6 border rounded-md bg-white">
            <div className="border-b p-4">Business Units : Requisitions</div>
            <div className="h-auto w-full">
              <ReListMetrics
                dates={dates}
                loading={isLoading}
                type={"unit"}
                title={"Business Unit"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
