import { BsSearch } from "react-icons/bs";
import { Button, Form, Input, message, Popover, Tooltip } from "antd";
import {
  RiFileExcel2Line,
  RiFilterOffLine,
  RiMailSendLine,
} from "react-icons/ri";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import PageToolnav from "../../../components/toolnav/PageToolnav";
import SideMenu from "../components/SideMenu";
import fetchDataReq from "../../../services/fetchDataReq";
import { BiLoader } from "react-icons/bi";
import {
  AiOutlineCaretDown,
  AiOutlineDelete,
  AiOutlineEdit,
} from "react-icons/ai";
import CreateMake from "./components/CreateMake";
import postDataReq from "../../../services/postDataReq";
import UpdateMake from "./components/UpdateMake";
import UpdateModel from "./components/UpdateModel";
import DeleteMake from "./components/DeleteMake";
import DeleteModel from "./components/DeleteModel";
import { AuthContext } from "../../../contexts/AuthContext";
import { ExportToExcel } from "../../../utils/exportExcel";

export default function Makes() {
  const { auth } = useContext(AuthContext);
  const [makes, setMakes] = useState<any>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isAdding, setAdding] = useState<boolean>(false);
  const [isFetching, setFetching] = useState<boolean>(false);
  const [isMailLoading, setMailLoading] = useState<boolean>(false);
  const [messageModal, toggleMessageModal] = useState(false);

  const [createMakeModal, setCreateMakeModal] = useState<boolean>(false);
  const [updateMakeModal, setUpdateMakeModal] = useState<boolean>(false);
  const [deleteMakeModal, setDeleteMakeModal] = useState<boolean>(false);

  const [updateModelModal, setUpdateModelModal] = useState<boolean>(false);
  const [deleteModelModal, setDeleteModelModal] = useState<boolean>(false);

  const [isSearched, setSearched] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState(null);

  const [selectedMake, setSelectedMake] = useState<any>({});
  const [selectedModel, setSelectedModel] = useState<any>({});
  const [models, setModels] = useState<any>([]);
  const [model, setModel] = useState<any>("");

  const [makeForm] = Form.useForm();
  const [modelForm] = Form.useForm();
  const [emailForm] = Form.useForm();

  const handleFetchMakes = async () => {
    setLoading(true);
    const uri = `api/v1/makes`;
    const response = await fetchDataReq({ uri, token: auth?._id });
    if (response?.success) {
      setMakes(response?.payload?.records);
    }
    setLoading(false);
  };

  const handleAddModel = async () => {
    setAdding(true);
    const uri = `api/v1/models`;
    const data = { name: model, make: selectedMake?._id };
    const response = await postDataReq({ data, uri, token: auth?._id });
    if (response.success) {
      message.success("Added Successfully");
      handleFetchMake(selectedMake?._id);
      setModel(null);
      modelForm.resetFields();
    }
    setAdding(false);
  };

  const handleFetchMake = async (id: any) => {
    setLoading(true);
    const uri = `api/v1/makes/${id}`;
    const response = await fetchDataReq({ uri, token: auth?._id });
    if (response?.success) {
      setModels(response?.results?.models);
    }
    setLoading(false);
  };

  const sendMail = async (values: any) => {
    setMailLoading(true);
    makes?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Name: item?.name === null ? "-" : item?.name,
        Email: item?.email === null ? "-" : item?.email,
        Mobile: item?.mobile === null ? "-" : item?.mobile,
        Website: item?.website === null ? "-" : item?.website,
        Created: moment(item?.createdAt).format("DD/MM/YYYY"),
      };
    });
  };

  const fetchAllSuppliers = async () => {
    const response = await fetchDataReq({
      uri: "api/v1/suppliers",
      token: auth?._id,
    });
    if (response?.success) {
      ExportToExcel(
        response?.payload?.records?.map((item: any, index: number) => {
          return {
            "S/N": index + 1,
            Name: item?.name === null ? "-" : item?.name,
            Email: item?.email === null ? "-" : item?.email,
            Mobile: item?.mobile === null ? "-" : item?.mobile,
            Website: item?.website === null ? "-" : item?.website,
            Created: moment(item?.createdAt).format("DD/MM/YYYY"),
          };
        }),
        "Suppliers.xlsx"
      );
    }
  };

  const handleFetchAll = async () => {
    setFetching(true);
    await fetchAllSuppliers();
    setFetching(false);
  };

  useEffect(() => {
    handleFetchMakes();
  }, []);

  return (
    <div className="flex">
      <SideMenu />
      <div className="flex flex-1 flex-col">
        <PageToolnav
          link={{ name: "Make", to: "/manage/make" }}
          title={"Make"}
          actions={
            <div className="flex">
              <div className="flex mr-2">
                <Form layout="inline" form={makeForm}>
                  <Form.Item name="name">
                    <Input
                      name="searchKey"
                      onChange={(e: any) => {
                        setSearchKey(e.target.value);
                        setSearched(false);
                      }}
                      type="text"
                      placeholder="Search by name"
                    />
                  </Form.Item>
                </Form>
                <Button
                  className="-ml-4 focus:outline-none"
                  onClick={() => {
                    // if (isSearched) {
                    //   setSearched(false);
                    //   form.resetFields();
                    //   setSearchKey(null);
                    //   handleFetchMakes();
                    // } else {
                    //   handleSearchMakes();
                    // }
                  }}
                >
                  {isSearched ? (
                    <RiFilterOffLine color={"#f87171"} />
                  ) : (
                    <BsSearch color={searchKey ? "#16a34a" : "#9ca3af"} />
                  )}
                </Button>
              </div>
              <Popover
                content={
                  <>
                    <Form
                      name="basic"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                      initialValues={{ remember: true }}
                      form={emailForm}
                      onFinish={sendMail}
                      autoComplete="off"
                    >
                      <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                          { required: true, message: "Email is Required" },
                        ]}
                      >
                        <Input placeholder="Email" />
                      </Form.Item>
                      <Form.Item
                        label="Subject"
                        name="subject"
                        rules={[
                          { required: true, message: "Subject is Required" },
                        ]}
                      >
                        <Input placeholder="Email Subject" />
                      </Form.Item>
                      <div className="flex flex-row justify-between">
                        <Form.Item wrapperCol={{ offset: 12, span: 8 }}>
                          <Button type="primary" htmlType="submit">
                            {isMailLoading ? "Sending ..." : "Submit"}
                          </Button>
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 0, span: 8 }}>
                          <Button
                            onClick={() => {
                              toggleMessageModal(false);
                            }}
                          >
                            Cancel
                          </Button>
                        </Form.Item>
                      </div>
                    </Form>
                  </>
                }
                title="Message Content"
                trigger="focus"
                placement="left"
                visible={messageModal}
              >
                <Tooltip title="Send Email">
                  <button
                    className="flex bg-white mr-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                    onClick={async () => {
                      toggleMessageModal(true);
                    }}
                  >
                    <RiMailSendLine size={21} />
                  </button>
                </Tooltip>
              </Popover>
              <Tooltip title="Export To Excel">
                <button
                  color="#E50000"
                  className="flex bg-white mx-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                  onClick={() => handleFetchAll()}
                >
                  {isFetching ? (
                    <BiLoader size={20} />
                  ) : (
                    <RiFileExcel2Line size={20} />
                  )}
                </button>
              </Tooltip>
              <Button
                className="bg-blue-200"
                onClick={() => setCreateMakeModal(true)}
              >
                + New
              </Button>
            </div>
          }
        />
        <div className="h-screen">
          <div className="flex flex-col mx-4 my-2">
            {makes?.map((item: any, index: number) => (
              <div key={index}>
                <div
                  className={` border-b flex justify-between items-center bg-white hover:bg-blue-50 hover:cursor-pointer ${
                    item?._id === selectedMake?._id ? "bg-blue-50" : ""
                  }`}
                >
                  <div
                    className="flex-1 p-2"
                    onClick={() => {
                      if (item?._id === selectedMake?._id) {
                        setSelectedMake({});
                      } else {
                        setSelectedMake(item);
                        handleFetchMake(item?._id);
                      }
                    }}
                  >
                    {index + 1}. {item?.name}
                  </div>
                  <div className="flex p-2">
                    <div
                      className="flex justify-center items-center bg-yellow-100 h-8 w-8 border mx-2"
                      onClick={() => {
                        setSelectedMake(item);
                        setUpdateMakeModal(true);
                      }}
                    >
                      <AiOutlineEdit />
                    </div>
                    <div
                      className="flex justify-center items-center bg-red-100 h-8 w-8 border mr-4"
                      onClick={() => {
                        setSelectedMake(item);
                        setDeleteMakeModal(true);
                      }}
                    >
                      <AiOutlineDelete />
                    </div>
                    <div
                      className="bg-gray-200 h-8 w-8 flex justify-center items-center"
                      onClick={() => {
                        if (item?._id === selectedMake?._id) {
                          setSelectedMake({});
                        } else {
                          setSelectedMake(item);
                          handleFetchMake(item?._id);
                        }
                      }}
                    >
                      <AiOutlineCaretDown />
                    </div>
                  </div>
                </div>
                {selectedMake?._id === item?._id ? (
                  <div className="pl-8 bg-white min-h-max">
                    {models?.length === 0 ? (
                      <div className="py-2">
                        <div className="flex">
                          <Form layout="inline">
                            <Form.Item
                              name="subcategory"
                              rules={[
                                {
                                  required: true,
                                  message: "Name is Required",
                                },
                              ]}
                            >
                              <Input
                                onChange={(e: any) => setModel(e.target.value)}
                                placeholder={`1. New Model`}
                              />
                            </Form.Item>
                            <Form.Item wrapperCol={{ offset: 12, span: 8 }}>
                              <Button
                                onClick={() => handleAddModel()}
                                type="primary"
                                htmlType="submit"
                              >
                                {isAdding ? "Adding ..." : "+ Add"}
                              </Button>
                            </Form.Item>
                          </Form>
                        </div>
                      </div>
                    ) : (
                      <div className="pb-2">
                        {models?.map((item: any, index: number) => (
                          <div
                            className={`p-2 border-b flex justify-between items-center bg-white hover:bg-blue-50 hover:cursor-pointer border-l ${
                              item?._id === selectedMake?._id
                                ? "bg-blue-50"
                                : ""
                            }`}
                            key={index}
                          >
                            <div className="">
                              {index + 1}. {item?.name}
                            </div>
                            <div className="flex">
                              <div
                                className="flex justify-center items-center bg-yellow-100 h-6 w-6 border mx-2"
                                onClick={() => {
                                  setUpdateModelModal(true);
                                  setSelectedModel(item);
                                }}
                              >
                                <AiOutlineEdit />
                              </div>
                              <div
                                className="flex justify-center items-center bg-red-100 h-6 w-6 border"
                                onClick={() => {
                                  setSelectedModel(item);
                                  setDeleteModelModal(true);
                                }}
                              >
                                <AiOutlineDelete />
                              </div>
                            </div>
                          </div>
                        ))}
                        {/* ADD SUBCATEGORY */}
                        <div className="flex mt-2">
                          <Form layout="inline" form={modelForm}>
                            <Form.Item
                              name="model"
                              rules={[
                                {
                                  required: true,
                                  message: "Name is Required",
                                },
                              ]}
                            >
                              <Input
                                onChange={(e: any) => setModel(e.target.value)}
                                placeholder={`${models.length + 1}. New Model`}
                              />
                            </Form.Item>
                            <Form.Item>
                              <Button
                                onClick={() => handleAddModel()}
                                type="primary"
                                htmlType="submit"
                              >
                                + Add
                              </Button>
                            </Form.Item>
                          </Form>
                        </div>
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            ))}
            <CreateMake
              isVisible={createMakeModal}
              setVisible={setCreateMakeModal}
              refetch={handleFetchMakes}
            />
            <UpdateMake
              isVisible={updateMakeModal}
              setVisible={setUpdateMakeModal}
              refetch={handleFetchMakes}
              selected={selectedMake}
            />
            <DeleteMake
              isVisible={deleteMakeModal}
              setVisible={setDeleteMakeModal}
              refetch={handleFetchMakes}
              selected={selectedMake}
            />

            <UpdateModel
              isVisible={updateModelModal}
              setVisible={setUpdateModelModal}
              refetch={handleFetchMake}
              selected={selectedModel}
              make={selectedMake}
            />
            <DeleteModel
              isVisible={deleteModelModal}
              setVisible={setDeleteModelModal}
              refetch={handleFetchMake}
              selected={selectedModel}
              make={selectedMake}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
