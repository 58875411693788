export default function TermsWrapper({ company, supplier }: any) {
  return (
    <div className="book">
      <div className="page" style={{ fontSize: 13 }}>
        <div className="subpage2">
          <div
            style={{ fontSize: 15 }}
            className="flex justify-between items-center -mt-6 pb-4"
          >
            <div className="font-bold">Terms and Condition:</div>
          </div>
          <div className="mb-2">
            <span className="font-bold">1.</span> This Local Purchase Order
            “LPO” is issued by
            <span className="font-bold mx-1 uppercase">{company}</span> to
            <span className="font-bold mx-1 uppercase">{supplier}</span>. The
            Company representative is the Commercial Officer and in his absence
            the person who has signed the LPO.
          </div>
          <div className="mb-2">
            <span className="font-bold">2.</span> Where the LPO is issued for
            goods/services which needs to be customized as per client
            requirements, unless samples have been already provided to the
            Company, then the LPO validity is subject to sample approvals.
            Unless otherwise specified, samples should be produced and verified
            with the Company representative not less than 30 days from date of
            LPO.
          </div>
          <div className="mb-2">
            <span className="font-bold">3.</span> Unless otherwise specified,
            for all advance payments the Seller will need to provide an advance
            payment guarantee in the form of a bank guarantee or an insurance
            bond. The guarantee issuing bank should be acceptable to the Company
            and in all cases the bond/guarantee should have international
            validity.
          </div>
          <div className="mb-2">
            <span className="font-bold">4.</span> If the Work is not correctly
            delivered or performed or the Company has not accepted the work, the
            invoice shall be paid as per payment terms from the date when the
            discrepancy is corrected to the Company’s satisfaction.
          </div>
          <div className="mb-2">
            <span className="font-bold">5.</span> The Seller agrees to permit
            the Company, where feasible, to inspect and test the work any time
            prior to acceptance or delivery as the case may be.
          </div>
          <div className="mb-2">
            <span className="font-bold">6.</span> Where the Company is not
            satisfied with the quality of work or has enough grounds to consider
            that the Seller will not be able to comply with the requirements of
            the LPO, the Company may terminate or suspend the LPO in respect of
            the Work to be delivered in whole or in part any time by giving
            written notice to Seller. Such notice shall state the effective of
            such termination and, upon the receipt of such notice Seller shall
            comply with the directions pertaining to Work stoppage.In such an
            event the Company’s liability will be limited to pay Seller for all
            Work satisfactorily performed up to date of termination or
            suspension specified in such notice unless it is agreed in writing
            between both parties for the Company to pay cancellation charges. In
            case of LPO suspension, the LPO shall be revived after Seller has
            got written confirmation from the Company.
          </div>
          <div className="mb-2">
            <span className="font-bold">7.</span> All patterns, tools, drawings
            or documents, whether in hard copy or otherwise, supplied by Company
            shall remain Company’s property and shall not be used by Seller in
            the service of any other company. All such items shall be returned
            to Company upon completion of the work or at Company’s instruction.
          </div>
        </div>
      </div>
    </div>
  );
}
