import { Button, Modal, Form, Row, Col, Input, message, Select } from "antd";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { handleFetchDivisions } from "../../../../api/divisions";
import { handleCreateUnit } from "../../../../api/units";
const { Option } = Select;

export default function CreateUnit({ isVisible, setVisible, refetch }: any) {
  const [isLoading, setLoading] = useState(false);
  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { data: divisions } = useQuery({
    queryKey: ["divisions"],
    queryFn: handleFetchDivisions,
  });

  const { mutate } = useMutation({
    mutationFn: handleCreateUnit,
    onSuccess: () => {
      message.success("Created Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["units"] });
      setVisible(false);
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const divisionOptions = divisions?.payload?.records?.map(
    (division: any, index: number) => (
      <Option value={division?._id} key={index}>
        {`${division?.name} ( Segment: ${division?.segment?.name} )`}
      </Option>
    )
  );

  const onSubmit = async () => {
    const { name, division, capex, opex } = await form.getFieldsValue();
    const data = { name, division, budget: { capex: capex, opex: opex } };
    await mutate(data);
  };

  return (
    <Modal
      open={isVisible}
      title="New Business Unit"
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="createBusinessUnit"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="createBusinessUnit"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Please enter division name",
                },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="division"
              label="Division"
              rules={[
                {
                  required: true,
                  message: "Please select division",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Search and select division"
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {divisionOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="capex"
              label={"Capex Budget"}
              rules={[
                {
                  required: false,
                  message: "Please enter capex budget",
                },
              ]}
            >
              <Input type="number" placeholder="Capex Budget" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="opex"
              label={"Opex Budget"}
              rules={[
                {
                  required: false,
                  message: "Please enter opex budget",
                },
              ]}
            >
              <Input type="number" placeholder="Opex Budget" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
