import formatMoney from "../../utils/formatMoney";

export default function LineItem({ item, index }: any) {
  return (
    <div className="grid grid-cols-12 border-t border-black items-center text-gray-700">
      <div className="col-span-1 px-2  border-r border-black  text-center">
        {index + 1}.
      </div>
      <div className="col-span-1 px-2   border-r border-black truncate text-center">
        {item?.quantity}
      </div>
      <div className="col-span-4 px-2   border-r border-black truncate">
        {item?.name}
      </div>
      <div className="col-span-2 px-2   border-r border-black truncate text-right">
        {formatMoney(item?.price ? item?.price : 0)}
      </div>
      <div className="col-span-2 px-2  border-r border-black  text-center">
        {item?.discount ? item?.discount : 0}
      </div>
      <div className="col-span-2 px-2 text-right">
        {formatMoney(
          item?.price
            ? item?.price * item?.quantity * (1 - item?.discount / 100)
            : 0
        )}
      </div>
    </div>
  );
}
