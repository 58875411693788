import { Button, Modal, Form, Row, Col, Input, Select } from "antd";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../contexts/AuthContext";
import fetchDataReq from "../../../../services/fetchDataReq";

const { Option } = Select;

export default function AddEstimate({
  isVisible,
  setVisible,
  estimates,
  setEstimates,
}: any) {
  const { auth } = useContext(AuthContext);
  const [isLoading, setLoading] = useState(false);
  const [suppliers, setSuppliers] = useState<any>([]);

  const [form] = Form.useForm();

  const onSubmit = async () => {
    const { id, price } = await form.getFieldsValue();
    const supplier = suppliers?.filter((item: any) => item?._id == id);
    const data = { supplier: supplier[0]?._id, name: supplier[0]?.name, price };
    estimates.push(data);
    setEstimates(estimates);
    console.log(estimates);
    setVisible(false);
    form.resetFields();
  };

  const handleFetchSuppliers = async () => {
    const response = await fetchDataReq({
      uri: "api/v1/suppliers",
      token: auth?._id,
    });
    if (response?.success) {
      setSuppliers(response?.payload?.records);
    }
  };

  const supplierOptions = suppliers.map((supplier: any, index: number) => (
    <Option value={supplier?._id} key={index}>
      {supplier?.name}
    </Option>
  ));

  useEffect(() => {
    form.resetFields();
    handleFetchSuppliers();
  }, []);

  return (
    <Modal
      open={isVisible}
      title="Add Estimate Price"
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="addEst"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
        >
          Add
        </Button>,
      ]}
    >
      <Form
        id="addEst"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="id"
              label="Supplier"
              rules={[
                {
                  required: true,
                  message: "Please select supplier",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Search and select supplier"
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {supplierOptions}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="price"
              label={"price"}
              rules={[
                {
                  required: true,
                  message: "Please enter price",
                },
              ]}
            >
              <Input type="number" placeholder="Price" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
