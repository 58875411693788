import {
  deleteData,
  fetchData,
  patchData,
  postData,
} from "../services/requisition";

const handleFetchClusters = async () => {
  const uri = `api/v1/business/clusters`;
  const response = fetchData({ uri });
  return response;
};

const handleCreateCluster = async (data: any) => {
  const uri = `api/v1/business/clusters`;
  const response = postData({ data, uri });
  return response;
};
const handleUpdateCluster = async ({ data, _id }: any) => {
  const uri = `api/v1/business/clusters/${_id}`;
  const response = patchData({ data, uri });
  return response;
};

const handleDeleteCluster = async ({ _id }: any) => {
  const uri = `api/v1/business/clusters/${_id}`;
  const response = deleteData({ uri });
  return response;
};

export {
  handleFetchClusters,
  handleCreateCluster,
  handleUpdateCluster,
  handleDeleteCluster,
};
