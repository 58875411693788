import { Button, Modal, Form, Row, Col, message, Select, Input } from "antd";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../contexts/AuthContext";
import fetchDataReq from "../../../../services/fetchDataReq";
import fetchDataTrace from "../../../../services/fetchDataTrace";
import patchDataReq from "../../../../services/patchDataReq";
import { useQuery } from "@tanstack/react-query";
import { handleFetchUnits } from "../../../../api/units";
import { handleFetchSegments } from "../../../../api/segments";
import { handleFetchDivisions } from "../../../../api/divisions";
import { handleFetchCompanies } from "../../../../api/companies";

const { Option } = Select;

export default function UpdateUser({
  isVisible,
  setVisible,
  user,
  refetch,
}: any) {
  const { auth } = useContext(AuthContext);
  const [isLoading, setLoading] = useState(false);

  const [clusters, setClusters] = useState<any>([]);
  const [segments, setSegments] = useState<any>([]);
  const [divisions, setDivisions] = useState<any>([]);
  const [units, setUnits] = useState<any>([]);
  const [departments, setDepartments] = useState<any>([]);

  const [selectedLega, setSelectedLega] = useState<any>(null);
  const [selectedClus, setSelectedClus] = useState<any>(null);
  const [selectedDivi, setSelectedDivi] = useState<any>(null);
  const [selectedSegm, setSelectedSegm] = useState<any>(null);
  const [selectedUnit, setSelectedUnit] = useState<any>(null);

  const [selected, setSelected] = useState<any>(null);
  const [users, setUsers] = useState<any>([]);

  const [form] = Form.useForm();

  const onSubmit = async () => {
    setLoading(true);
    const { name, level, company, mobile, department, unit, flag, kjlID } =
      await form.getFieldsValue();
    const data = {
      name: name,
      email: user?.email,
      mobile,
      mobile2: user?.mobile2,
      level,
      company,
      department,
      unit,
      flag,
      kjlID,
    };

    const uri = `api/v1/users/${user?._id}`;
    const response = await patchDataReq({ data, uri, token: auth?._id });
    if (response.success) {
      message.success("Update Successfully");
      refetch();
      form.resetFields();
      setVisible(false);
    } else {
      message.error(response.message);
    }
    setLoading(false);
  };

  const levels = [
    { id: 1, name: "I" },
    { id: 2, name: "V" },
    { id: 3, name: "C" },
    { id: 4, name: "CH" },
    { id: 5, name: "M1" },
    { id: 6, name: "M2" },
    { id: 7, name: "M3" },
    { id: 8, name: "M4" },
  ];

  const flags = [
    { id: 1, name: "CFO" },
    { id: 2, name: "MD" },
    { id: 3, name: "TAX" },
    { id: 4, name: "USER" },
    { id: 5, name: "ADMIN" },
    { id: 6, name: "SUPER" },
    { id: 7, name: "SUPPORT" },
  ];

  const handleFetchUsers = async () => {
    const uri = `users`;
    const response = await fetchDataTrace({ uri });
    if (response.success) {
      setUsers(response?.payload);
    }
  };

  const { data: companies } = useQuery({
    queryKey: ["companies"],
    queryFn: handleFetchCompanies,
  });

  const { data: rawSegments } = useQuery({
    queryKey: ["segments"],
    queryFn: handleFetchSegments,
  });

  const { data: rawDivisions } = useQuery({
    queryKey: ["divisions"],
    queryFn: handleFetchDivisions,
  });

  const { data: rawUnits } = useQuery({
    queryKey: ["units"],
    queryFn: handleFetchUnits,
  });
  const handleSelectedCompany = async (e: any) => {
    const response = await fetchDataReq({
      uri: `api/v1/companies/${e}`,
      token: auth?._id,
    });
    if (response.success) {
      if (response.payload.departments) {
        setDepartments(response.payload.departments);
        setSelected(e);
      }
    }
  };

  const handleSelectCompany = async (e: any) => {
    setSelectedLega(e);
    const selected = await companies?.payload?.records?.filter(
      (item: any) => item?._id === e
    );
    setClusters(selected[0]?.clusters || []);
    setSelectedClus(null);
    setSelectedSegm(null);
    setSelectedDivi(null);
    setSelectedUnit(null);
    form.setFieldsValue({ segment: null });
    form.setFieldsValue({ division: null });
    form.setFieldsValue({ unit: null });
    form.setFieldsValue({ department: null });
    await handleSelectedCompany(selectedLega);
  };

  const handleSelectCluster = async (e: any) => {
    setSelectedClus(e);
    const selected = rawSegments?.payload?.records?.filter(
      (item: any) => item?.cluster?._id === e
    );
    setSegments(selected || []);
    setSelectedSegm(null);
    setSelectedDivi(null);
    setSelectedUnit(null);
    form.setFieldsValue({ segment: null });
    form.setFieldsValue({ division: null });
    form.setFieldsValue({ unit: null });
    form.setFieldsValue({ department: null });
  };

  const handleSelectSegment = async (e: any) => {
    setSelectedSegm(e);
    const selected = rawDivisions?.payload?.records?.filter(
      (item: any) => item?.segment?._id === e
    );
    setDivisions(selected || []);
    setSelectedDivi(null);
    setSelectedUnit(null);
    form.setFieldsValue({ division: null });
    form.setFieldsValue({ unit: null });
    form.setFieldsValue({ department: null });
  };

  const handleSelectDivision = async (e: any) => {
    setSelectedDivi(e);
    const selected = rawUnits?.payload?.records?.filter(
      (item: any) => item?.division?._id === e
    );
    setUnits(selected || []);
    setSelectedUnit(null);
    form.setFieldsValue({ unit: null });
    form.setFieldsValue({ department: null });
  };

  const handleSelectUnit = async (e: any) => {
    setSelectedUnit(e);
  };

  const unitOptions = units?.map((item: any, index: number) => (
    <Option value={item?._id} key={index}>
      {item?.name}
    </Option>
  ));

  const clusterOptions = clusters?.map((item: any, index: number) => (
    <Option value={item?._id} key={index}>
      {item?.name}
    </Option>
  ));

  const segmentOptions = segments?.map((item: any, index: number) => (
    <Option value={item?._id} key={index}>
      {item?.name}
    </Option>
  ));

  const divisionOptions = divisions?.map((item: any, index: number) => (
    <Option value={item?._id} key={index}>
      {item?.name}
    </Option>
  ));

  const levelOptions = levels?.map((level: any, index: number) => (
    <Option value={level?.name} key={index}>
      {level?.name}
    </Option>
  ));

  const flagOptions = flags?.map((flag: any, index: number) => (
    <Option value={flag?.name} key={index}>
      {flag?.name}
    </Option>
  ));

  const companyOptions = companies?.payload?.records?.map(
    (company: any, index: number) => (
      <Option value={company?._id} key={index}>
        {company?.name}
      </Option>
    )
  );

  const departmentOptions = departments?.map(
    (department: any, index: number) => (
      <Option value={department?._id} key={index}>
        {department?.name}
      </Option>
    )
  );

  const clearForm = async () => {
    form.resetFields();
    setSelectedLega(null);
    setSelectedClus(null);
    setSelectedSegm(null);
    setSelectedDivi(null);
    setSelectedUnit(null);
    setLoading(false);
  };

  useEffect(() => {
    form.resetFields();
    handleFetchCompanies();
    handleSelectedCompany(user?.company?._id);
    handleFetchUsers();
  }, [user]);

  return (
    <Modal
      open={isVisible}
      title="Update User"
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="updateUser"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="updateUser"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="name"
              label={"Full name"}
              initialValue={user?.name}
              rules={[
                {
                  required: false,
                  message: "Please enter name",
                },
              ]}
            >
              <Input type="text" placeholder="Full name" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="kjlID"
              label={"KJL ID"}
              initialValue={user?.kjlID}
              rules={[
                {
                  required: false,
                  message: "Please enter KJL ID",
                },
              ]}
            >
              <Input placeholder="KJL ID" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="level"
              label={"Level"}
              initialValue={user?.level}
              rules={[
                {
                  required: false,
                  message: "Please select level",
                },
              ]}
            >
              <Select placeholder="Level">{levelOptions}</Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="role"
              label={"Role"}
              initialValue={user?.flag}
              rules={[
                {
                  required: false,
                  message: "Please select role",
                },
              ]}
            >
              <Select placeholder="Role">{flagOptions}</Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="company"
              label={"Legal Entity"}
              rules={[
                {
                  required: false,
                  message: "Please select legal entity",
                },
              ]}
            >
              <Select
                placeholder="Legal Entity"
                onChange={(e: any) => {
                  handleSelectCompany(e);
                }}
              >
                {companyOptions}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="cluster"
              label={"Cluster"}
              rules={[
                {
                  required: false,
                  message: "Please select cluster",
                },
              ]}
            >
              {selectedLega ? (
                <Select
                  placeholder="Cluster"
                  onChange={(e: any) => {
                    handleSelectCluster(e);
                  }}
                >
                  {clusterOptions}
                </Select>
              ) : (
                <Select placeholder="Cluster" disabled>
                  {clusterOptions}
                </Select>
              )}
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="segment"
              label={"Segment"}
              rules={[
                {
                  required: false,
                  message: "Please select segment",
                },
              ]}
            >
              {selectedClus ? (
                <Select
                  placeholder="Segment"
                  onChange={(e: any) => {
                    handleSelectSegment(e);
                  }}
                >
                  {segmentOptions}
                </Select>
              ) : (
                <Select placeholder="Segment" disabled>
                  {segmentOptions}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="division"
              label={"Division"}
              rules={[
                {
                  required: false,
                  message: "Please select division",
                },
              ]}
            >
              {selectedSegm ? (
                <Select
                  placeholder="Division"
                  onChange={(e: any) => {
                    handleSelectDivision(e);
                  }}
                >
                  {divisionOptions}
                </Select>
              ) : (
                <Select placeholder="Division" disabled>
                  {divisionOptions}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="unit"
              label={"Business Unit"}
              rules={[
                {
                  required: false,
                  message: "Please select business unit",
                },
              ]}
            >
              {selectedDivi ? (
                <Select
                  placeholder="Business Unit"
                  onChange={(e: any) => {
                    handleSelectUnit(e);
                  }}
                >
                  {unitOptions}
                </Select>
              ) : (
                <Select placeholder="Business Unit" disabled>
                  {unitOptions}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="department"
              label={"Department"}
              rules={[
                {
                  required: false,
                  message: "Please select department",
                },
              ]}
            >
              {selectedLega ? (
                <Select placeholder="Department">{departmentOptions}</Select>
              ) : (
                <Select placeholder="Department" disabled>
                  {departmentOptions}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
