import { GrStatusCriticalSmall } from "react-icons/gr";
import { colors } from "../../../utils/data";
import LineItems from "./LineItems";
import ReadItems from "./ReadItems";
import Moment from "react-moment";
import avatar from "../../../assets/user.png";
import { RiSendPlane2Line } from "react-icons/ri";
import { useContext, useState } from "react";
import { FiLoader } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import { AuthContext } from "../../../contexts/AuthContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { handleSendMessage } from "../../../api/requisitions";

export default function ReadRequisition({
  editable,
  requisition,
  currencyFormatter,
  stockitems,
  state,
  addHandler,
  focusHandler,
  changeHandler,
  deleteHandler,
  reorderHandler,
}: any) {
  const { auth } = useContext(AuthContext);
  const [isSearching, setSearching] = useState<boolean>(false);
  const [sms, setSms] = useState<string>("");
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: handleSendMessage,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["requisition", requisition?._id],
      });
      setSms("");
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSubmitMessage();
    }
  };

  const handleSubmitMessage = async () => {
    await mutate({
      _id: requisition?._id,
      data: { user: auth?._id, message: sms },
    });
  };
  return (
    <div className="my-2 mx-4 border">
      <div className="grid grid-cols-12 gap-x-0">
        <div className="col-span-10 sm:col-span-10 md:col-span-10 border-r border-b  bg-white">
          <div className="border-b p-3 w-full flex flex-col justify-start items-start">
            <div className="">{requisition?.company?.name}</div>
            <div className="">
              <div className="flex">
                <div className="font-bold">Description:</div>
                <div className="mx-2">{requisition?.description}</div>
              </div>
              <div className="flex">
                <div className="font-bold">Reason:</div>
                <div className="mx-2">{requisition?.reason}</div>
              </div>
            </div>
          </div>
          <div className="">
            {editable ? (
              <LineItems
                items={state?.extems === undefined ? [] : state?.extems}
                stockitems={stockitems === undefined ? [] : stockitems}
                currencyFormatter={currencyFormatter}
                addHandler={addHandler}
                changeHandler={changeHandler}
                focusHandler={focusHandler}
                deleteHandler={deleteHandler}
                reorderHandler={reorderHandler}
              />
            ) : (
              <ReadItems
                items={state?.extems === undefined ? [] : state?.extems}
                stockitems={
                  stockitems === undefined ? [] : stockitems?.payload?.records
                }
                currencyFormatter={currencyFormatter}
                addHandler={addHandler}
                changeHandler={changeHandler}
                focusHandler={focusHandler}
                deleteHandler={deleteHandler}
                reorderHandler={reorderHandler}
              />
            )}
          </div>
        </div>
        <div className="col-span-2 sm:col-span-2 md:col-span-2 bg-white">
          <div className="border-b p-3 w-full flex justify-between">
            <div className="">Comments</div>
            <div className="text-sm flex items-center justify-center">
              <div className="">
                <GrStatusCriticalSmall
                  color={`${colors[requisition?.status]}`}
                />
              </div>
              <div
                className="ml-1"
                style={{ color: `${colors[requisition?.status]}` }}
              >
                {requisition?.status}
              </div>
            </div>
          </div>
          {requisition?.advisor?.name && (
            <div className="">
              <div className="border-b">
                <div className="p-2 bg-gray-50">
                  {" "}
                  Advisor : {requisition?.advisor?.name}{" "}
                  <div className="">
                    <div className="border border-blue-200 pb-2 px-2">
                      <div className="">Remarks:</div>
                      {requisition?.advisor?.remarks || (
                        <span
                          className="text-gray-400"
                          style={{ fontSize: 12 }}
                        >
                          No Remarks yet
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="m-2">
                {requisition?.conversation?.map((item: any, index: number) => (
                  <div className="flex mb-1 items-start" key={index}>
                    <div className="w-1/6">
                      <div className="w-8 h-8 rounded-full border mr-2 flex justify-center items-center">
                        <img src={avatar} className="w-6 h-6 rounded-full" />
                      </div>
                    </div>
                    <div className="flex-col w-5/6">
                      <div className="">{item?.from?.name}</div>
                      <div className="">{item?.message}</div>
                      <div className="text-gray-600" style={{ fontSize: 11 }}>
                        <Moment fromNow>{item?.date}</Moment>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="flex items-center justify-center  mx-4 p-2 bg-gray-100  mb-2">
                <input
                  // type="text"
                  name="sms"
                  // multiple={true}
                  value={sms}
                  onKeyDown={handleKeyDown}
                  onChange={(e: any) => setSms(e.target.value)}
                  className="ml-2 mb-1 bg-gray-100 focus:outline-none text-black w-full"
                  placeholder="Message ..."
                />
                {sms && (
                  <>
                    {isSearching ? (
                      <div className="hover:text-red-800 hover:cursor-pointer text-green-600 mr-2">
                        <FiLoader size={14} onClick={() => setSms("")} />
                      </div>
                    ) : (
                      <div className="hover:text-red-800 hover:cursor-pointer text-red-600 mr-2">
                        <AiOutlineClose size={14} onClick={() => setSms("")} />
                      </div>
                    )}
                  </>
                )}
                {sms ? (
                  <div
                    onClick={() => handleSubmitMessage()}
                    className="hover:cursor-pointer hover:text-green-600 text-black border-l border-gray-400 pl-2"
                  >
                    <RiSendPlane2Line size={15} />
                  </div>
                ) : (
                  <RiSendPlane2Line size={15} color="gray" />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
