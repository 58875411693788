import {
  deleteData,
  fetchData,
  patchData,
  postData,
} from "../services/requisition";

const handleFetchCompanies = async () => {
  const uri = `api/v1/companies`;
  const response = fetchData({ uri });
  return response;
};


const handleFetchCompany = async (_id: any) => {
  const uri = `api/v1/companies/${_id}`;
  const response = fetchData({ uri });
  return response;
};

const handleCreateCompany = async (data: any) => {
  const uri = `api/v1/companies`;
  const response = postData({ data, uri });
  return response;
};
const handleUpdateCompany = async ({ data, _id }: any) => {
  const uri = `api/v1/companies/${_id}`;
  const response = patchData({ data, uri });
  return response;
};

const handleUpdateCompanyClusters = async ({ data, _id }: any) => {
  const uri = `api/v1/companies/${_id}/clusters`;
  const response = patchData({ data, uri });
  return response;
};

const handleDeleteCompanyCluster = async ({ _id, cluster_id }: any) => {
  const uri = `api/v1/companies/${_id}/clusters/${cluster_id}`;
  const response = deleteData({ uri });
  return response;
};

const handleDeleteCompany = async ({ _id }: any) => {
  const uri = `api/v1/companies/${_id}`;
  const response = deleteData({ uri });
  return response;
};

export {
  handleFetchCompanies,
  handleFetchCompany,
  handleCreateCompany,
  handleUpdateCompany,
  handleDeleteCompany,
  handleUpdateCompanyClusters,
  handleDeleteCompanyCluster,
};
