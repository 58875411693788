import { Button, Form, Row, Col, Input, message, Space, Drawer } from "antd";
import { useContext, useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { AuthContext } from "../../../contexts/AuthContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handleUpdateContact } from "../../../api/suppliers";
import formatMobile from "../../../utils/formatMobile";

export default function UpdateContact({
  isVisible,
  setVisible,
  supplier,
  contact,
}: any) {
  const [isLoading, setLoading] = useState(false);
  const { auth } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { mutate, isPending } = useMutation({
    mutationFn: handleUpdateContact,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({
        queryKey: ["suppliers", supplier?._id],
      });
      setVisible(false);
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    setLoading(true);
    const { title, name, email, mobile } = await form.getFieldsValue();

    const { mob, inValid } = await formatMobile(mobile);
    if (inValid || mob === null) {
      message.error("Please provide a valid number");
      return;
    }

    const data = {
      title,
      name,
      email,
      mobile: mob,
    };

    await mutate({ _id: supplier?._id, contact_id: contact?._id, data });
    setLoading(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [contact]);

  return (
    <Drawer
      open={isVisible}
      title={`UPDATE CONTACT PERSON`}
      placement={"right"}
      width={800}
      onClose={() => setVisible(!isVisible)}
      closable={false}
      extra={
        <Space className="flex justify-center items-center">
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <Form
        id="updateContact"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="name"
              label="Full Name"
              initialValue={contact?.name}
              rules={[
                {
                  required: true,
                  message: "Please enter full name",
                },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="title"
              label="Job Title"
              initialValue={contact?.title}
              rules={[
                {
                  required: true,
                  message: "Please enter job title",
                },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="email"
              label="Email"
              initialValue={contact?.email}
              rules={[
                {
                  required: true,
                  message: "Please enter email address",
                },
              ]}
            >
              <Input placeholder="Email address" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="mobile"
              label="Phone"
              initialValue={contact?.mobile}
              rules={[
                {
                  required: true,
                  message: "Please enter phone number",
                },
              ]}
            >
              <Input placeholder="Phone number" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="mr-2">
            {isPending ? "Submiting ..." : "Update"}
          </Button>
          <Button htmlType="button" onClick={() => setVisible(false)}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
