import { useState } from "react";
import { IoCashOutline } from "react-icons/io5";
import Spinner from "../../../components/shared/Spinner";
import formatShort from "../../../utils/formatShort";
import OrListPanel from "./OrListPanel";

export default function OrStatusWidget({ item, dates, isLoading }: any) {
  const [isVisible, setVisible] = useState<boolean>(false);

  return (
    <div
      className="col-span-12 md:col-span-6 sm:col-span-2 lg:col-span-2 rounded-lg cursor-pointer"
      style={{
        borderLeftColor: `${item?.icon_color}`,
        borderBottomColor: `${item?.icon_color}`,
        backgroundColor: `${item?.background}`,
      }}
    >
      <div className="p-4" onClick={() => setVisible(true)}>
        <div className="flex justify-between">
          <div className="flex flex-col text-white">
            <div className="">
              {item?.name} : {item?.data?.records?.length || 0}
            </div>

            <div className="font-bold text-3xl">
              {isLoading ? (
                <Spinner />
              ) : (
                <div className="">{formatShort(item?.count)}</div>
              )}
            </div>
          </div>
          <div
            className="flex justify-center items-center border border-gray-100 rounded-lg h-12 w-12"
            style={{ backgroundColor: `${item?.icon_color}` }}
          >
            <IoCashOutline size={30} color={"#FFFFFF"} />
          </div>
        </div>
      </div>
      <OrListPanel
        isVisible={isVisible}
        setVisible={setVisible}
        data={item?.data}
        title={item?.name}
        dates={dates}
      />
    </div>
  );
}
