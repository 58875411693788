import { Button, Modal, Form, Row, Col,  message } from "antd";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { useMutation,  useQueryClient } from "@tanstack/react-query";
import { handleProvideAdvise } from "../../../api/requisitions";
import TextArea from "antd/lib/input/TextArea";


export default function AdviseModal({
  isVisible,
  setVisible,
  requisition,
  type,
}: any) {
  const [isLoading, setLoading] = useState(false);
  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { mutate } = useMutation({
    mutationFn: handleProvideAdvise,
    onSuccess: () => {
      message.success("Sent Successfully");
      form.resetFields();
      queryClient.invalidateQueries({
        queryKey: ["requisition", requisition?._id],
      });
      setVisible(false);
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const { remarks } = form.getFieldsValue();
    await mutate({ data: { remarks }, _id: requisition?._id });
  };

  const hideModal = () => {
    form.resetFields();
    setVisible(false);
  };


  return (
    <Modal
      open={isVisible}
      title={<div>Advice</div>}
      onCancel={hideModal}
      footer={[
        <Button key="back" onClick={hideModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form={`${type}`}
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
        >
          Send
        </Button>,
      ]}
    >
      <Form
        id={`${type}`}
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="remarks"
              label="Remarks"
              rules={[
                {
                  required: true,
                  message: "Please enter remarks",
                },
              ]}
            >
              <TextArea  placeholder="Remarks"/> 
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
