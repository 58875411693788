import { Button, Drawer, Space } from "antd";
import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { MdCorporateFare } from "react-icons/md";
import CreateContact from "./CreateContact";
import UpdateContact from "./UpdateContact";
import UpdateSupplier from "./UpdateSupplier";
import { useQuery } from "@tanstack/react-query";
import { handleFetchSupplier } from "../../../api/suppliers";

export default function DetailsPanel({
  setVisible,
  isVisible,
  selected,
  isEditable,
}: any) {
  const [updatePanel, setUpdatePanel] = useState<boolean>(false);
  const [createPanel, setCreatePanel] = useState<boolean>(false);
  const [updateContactPanel, setUpdateContactPanel] = useState<boolean>(false);
  const [selectedContact, setSelectedContact] = useState<any>({});

  const {
    data: { payload: supplier } = {} as any,
    isError,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ["suppliers", selected?.id],
    queryFn: () => handleFetchSupplier({ _id: selected?.id }),
  });

  return (
    <Drawer
      open={isVisible}
      title={`SUPPLIER DETAILS`}
      placement={"right"}
      width={800}
      onClose={() => setVisible(!isVisible)}
      closable={false}
      extra={
        <Space className="flex justify-center items-center">
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <div className="flex justify-between pb-2">
        <div className="flex">
          <div className="flex justify-center items-center w-20 h-20 bg-gray-200 rounded-lg">
            {supplier?.isCompany ? (
              <div className="flex flex-col justify-center items-center">
                <MdCorporateFare size={40} />
              </div>
            ) : (
              <div className="flex flex-col justify-center items-center">
                <MdCorporateFare size={40} />
              </div>
            )}
          </div>
          <div className="ml-4">
            <div className="text-lg ">
              <span className="font-bold mr-1">Supplier :</span>
              <span>{supplier?.name}</span>
            </div>
            <div className="">
              <div className="flex items-center">
                <div className="">
                  Email :<span className="ml-1">{supplier?.email}</span>
                </div>
              </div>
              <div className="">
                <div className="">
                  Mobile :
                  <span className="ml-1">
                    {supplier?.mobile ? supplier?.mobile : "-"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isEditable && (
          <div className="">
            <button
              onClick={() => setUpdatePanel(true)}
              className="flex items-center px-2 py-1 hover:bg-gray-100"
            >
              <FiEdit />
              <span className="ml-1">Edit</span>
            </button>
          </div>
        )}
      </div>
      <div className="mt-2">
        <div className="flex justify-between items-center border-b pb-2">
          <div className="">CONTACT PERSON</div>
          <Button onClick={() => setCreatePanel(true)}>+ Add</Button>
        </div>
        <div className="ml-4">
          {supplier?.contactPerson?.map((item: any, index: number) => (
            <div className="flex justify-between my-2" key={index}>
              <div className="">
                <div className="w-36">{item?.name}</div>
                <div className="w-36">{item?.mobile}</div>
                <div className="w-44">{item?.email}</div>
              </div>
              {isEditable && (
                <div className="">
                  <button
                    onClick={() => {
                      setSelectedContact({ index, ...item });
                      setUpdateContactPanel(true);
                    }}
                    className="flex items-center px-2 py-1 hover:bg-gray-100"
                  >
                    <FiEdit />
                    <span className="ml-1">Edit</span>
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <UpdateSupplier
        isVisible={updatePanel}
        setVisible={setUpdatePanel}
        refetch={refetch}
        supplier={supplier}
        fetch={handleFetchSupplier}
      />
      <CreateContact
        isVisible={createPanel}
        setVisible={setCreatePanel}
        supplier={supplier}
        refetch={refetch}
        fetch={handleFetchSupplier}
      />
      <UpdateContact
        isVisible={updateContactPanel}
        setVisible={setUpdateContactPanel}
        refetch={refetch}
        supplier={supplier}
        contact={selectedContact}
      />
    </Drawer>
  );
}
