import formatMoney from "../../../../utils/formatMoney";

const AutDetails = ({ order }: any) => {
  const getTotalAmount = ({ items }: any) => {
    let total = 0;
    items?.forEach((item: any) => {
      total = total + item?.item * item?.baseValue;
    });
    let res = isNaN(total) ? 0 : total;
    return res;
  };

  const num: any = getTotalAmount({ items: order?.payload });

  if (order?.payload?.length === 0) {
    return (
      <div className="flex justify-center text-gray-600">
        No Autoline Data Found
      </div>
    );
  }
  return (
    <div className={`mb-4`}>
      <div className="border border-gray-400">
        <div className="flex justify-between py-1">
          <div className="mx-2">
            <div className="uppercase">Autoline System</div>
          </div>
        </div>
      </div>
      <div className="border border-gray-400 text-xs">
        <div className="grid grid-cols-12 border-b  border-gray-400">
          <div className="col-span-1 py-1 font-bold text-center">Qty</div>
          <div className="col-span-6 py-1 px-2  font-bold border-r border-l border-gray-400  text-left">
            Description
          </div>
          <div className="col-span-5 py-1 px-2  font-bold border-r border-gray-400  text-right">
            Total Price
          </div>
        </div>
        {order?.payload?.map((item: any, index: number) => (
          <div
            key={index}
            className="grid grid-cols-12 border-t border-gray-400 items-center text-gray-700"
          >
            <div className="col-span-1  truncate text-center">{item?.item}</div>
            <div className="col-span-6 px-2   border-r border-l border-gray-400">
              {item?.narrative}
            </div>
            <div className="col-span-5 px-2   border-r border-gray-400 truncate text-right">
              {formatMoney(item?.baseValue ? item?.item * item?.baseValue : 0)}
            </div>
          </div>
        ))}

        <div className="grid grid-cols-12 items-center border-t border-gray-400">
          <div className="col-span-1 px-1 text-white text-right">-</div>
          <div className="col-span-6 px-1  border-r border-l border-gray-400 text-left">
            Sub-Total
          </div>
          <div className="col-span-5 px-2 text-black border-r border-gray-400 text-right">
            {formatMoney(order?.isExempted ? num : num)}
          </div>
        </div>
        {order?.isExempted ? null : (
          <div className="grid grid-cols-12 items-center border-t border-gray-400">
            <div className="col-span-1 px-1 text-white text-right">-</div>
            <div className="col-span-6 px-1  border-r border-l border-gray-400 text-left">
              VAT ( 18% )
            </div>
            <div className="col-span-5 px-2 text-black border-r border-gray-400 text-right">
              {formatMoney(order?.isExempted ? 0 : num * 0.18)}
            </div>
          </div>
        )}
        <div className="grid grid-cols-12 items-center border-t-2 border-black">
          <div className="col-span-1 px-1 text-white text-right">-</div>
          <div className="col-span-6 px-1  border-r border-l border-gray-400 text-left">
            Grand Total (TZS)
          </div>

          <div className="col-span-5 px-2 text-black border-r border-gray-400 text-right">
            {formatMoney(order?.isExempted ? num : num * 1.18)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutDetails;
