import {
  deleteData,
  fetchData,
  patchData,
  postData,
} from "../services/requisition";

const handleFetchRequisitions = async () => {
  const uri = `api/v1/users`;
  const response = fetchData({ uri });
  return response;
};

const handleCreateRequisition = async (data: any) => {
  const uri = `api/v1/requisitions`;
  const response = postData({ data, uri });
  return response;
};
const handleUpdateRequisition = async ({ data, _id }: any) => {
  const uri = `api/v1/requisitions/${_id}`;
  const response = patchData({ data, uri });
  return response;
};

const handleAcceptRequisition = async ({ data, _id }: any) => {
  const uri = `api/v1/requisitions/${_id}/accept`;
  const response = patchData({ data, uri });
  return response;
};

const handleRejectRequisition = async ({ data, _id }: any) => {
  const uri = `api/v1/requisitions/${_id}/reject`;
  const response = patchData({ data, uri });
  return response;
};

const handleAssignAdvisor = async ({ data, _id }: any) => {
  const uri = `api/v1/requisitions/${_id}/advise`;
  const response = patchData({ data, uri });
  return response;
};


const handleProvideAdvise = async ({ data, _id }: any) => {
  const uri = `api/v1/requisitions/${_id}/advise/reply`;
  const response = patchData({ data, uri });
  return response;
};

const handleSendMessage = async ({ data, _id }: any) => {
  const uri = `api/v1/requisitions/${_id}/advise/message`;
  const response = patchData({ data, uri });
  return response;
};

const handleDeleteRequisition = async ({ _id }: any) => {
  const uri = `api/v1/users/${_id}`;
  const response = deleteData({ uri });
  return response;
};

export {
  handleFetchRequisitions,
  handleCreateRequisition,
  handleUpdateRequisition,
  handleDeleteRequisition,
  handleAssignAdvisor,
  handleProvideAdvise,
  handleSendMessage,
  handleAcceptRequisition,
  handleRejectRequisition,
};
