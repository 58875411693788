import { useEffect, useState } from "react";
import { BsChevronRight } from "react-icons/bs";
import fetchDataReq from "../../../services/fetchDataReq";
import ReListPanel from "./ReListPanel";
import { NumberDomain } from "recharts/types/util/types";

export default function ReListMetrics({ dates, type, title }: any) {
  const [isVisible, setVisible] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>({});
  const [data, setData] = useState<any>([]);
  const handleFetchData = async () => {
    const response = await fetchDataReq({
      uri: `api/v1/reports/requisitions/${type}?start=${dates?.startDate}&end=${dates?.endDate}`,
    });
    if (response?.success) {
      setData(response?.payload);
    }
  };

  const getTotal = () => {
    let count = 0;
    let sum = 0;
    data?.forEach((item: any) => {
      count = item?.records?.length + count;
    });

    return { count: isNaN(count) ? 0 : count, sum: isNaN(sum) ? 0 : sum };
  };

  const { count, sum } = getTotal();

  useEffect(() => {
    handleFetchData();
  }, [dates]);
  return (
    <>
      <div className="px-4">
        {data?.map((item: any, index: number) => (
          <div
            className="flex justify-between border-b p-1 cursor-pointer"
            onClick={() => {
              setSelected(item);
              setVisible(true);
            }}
            key={index}
          >
            <div className="">{item?._id}</div>
            <div className="flex items-center">
              <div className="">{item?.records?.length}</div>
              <div className="ml-2">
                <BsChevronRight />
              </div>
            </div>
          </div>
        ))}

        <div className="flex justify-between border-t-2 border-gray-700 p-1">
          <div className="">Total</div>
          <div className="flex items-center">
            <div className="mr-6">{count}</div>
          </div>
        </div>
      </div>
      <ReListPanel
        isVisible={isVisible}
        setVisible={setVisible}
        dates={dates}
        data={selected}
        title={title}
      />
    </>
  );
}
