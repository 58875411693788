import {
  Button,
  Modal,
  Form,
  Row,
  Col,
  Input,
  message,
  Select,
  Checkbox,
} from "antd";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { AuthContext } from "../../../contexts/AuthContext";
import postDataReq from "../../../services/postDataReq";
import { handleFetchSegments } from "../../../api/segments";
import { useQuery } from "@tanstack/react-query";
import { handleFetchDivisions } from "../../../api/divisions";
import { handleFetchUnits } from "../../../api/units";
import { handleFetchCompanies } from "../../../api/companies";

const { Option } = Select;
const { TextArea } = Input;

export default function CreateRequisition({
  isVisible,
  setVisible,
  refetch,
}: any) {
  const [isImpersonate, setImpersonate] = useState<boolean>(false);
  const [clusters, setClusters] = useState<any>([]);
  const [segments, setSegments] = useState<any>([]);
  const [divisions, setDivisions] = useState<any>([]);
  const [units, setUnits] = useState<any>([]);
  const { auth } = useContext(AuthContext);

  const [isLoading, setLoading] = useState(false);

  const [selectedLega, setSelectedLega] = useState<any>(null);
  const [selectedClus, setSelectedClus] = useState<any>(null);
  const [selectedDivi, setSelectedDivi] = useState<any>(null);
  const [selectedSegm, setSelectedSegm] = useState<any>(null);
  const [selectedUnit, setSelectedUnit] = useState<any>(null);

  const [form] = Form.useForm();

  const navigation = useNavigate();

  const onSubmit = async () => {
    setLoading(true);
    const { description, reason } = await form.getFieldsValue();
    const data = {
      user: auth?._id,
      description,
      reason,
      items: [],
      isImpersonated: isImpersonate,
      unit: isImpersonate ? selectedUnit : auth?.unit?._id,
      cluster: isImpersonate ? selectedClus : null,
      company: isImpersonate ? selectedLega : auth?.company?._id,
    };

    const uri = `api/v1/requisitions`;
    const response = await postDataReq({ data, uri, token: auth?._id });
    if (response.success) {
      message.success("Created Successfully");
      refetch();
      form.resetFields();
      navigation(`/requisitions/${response?.payload?._id}`);
      setVisible(false);
    } else {
      message.error(response.message);
    }
    setLoading(false);
  };

  const { data: companies } = useQuery({
    queryKey: ["companies"],
    queryFn: handleFetchCompanies,
  });

  const { data: rawSegments } = useQuery({
    queryKey: ["segments"],
    queryFn: handleFetchSegments,
  });

  const { data: rawDivisions } = useQuery({
    queryKey: ["divisions"],
    queryFn: handleFetchDivisions,
  });

  const { data: rawUnits } = useQuery({
    queryKey: ["units"],
    queryFn: handleFetchUnits,
  });

  const companyOptions = companies?.payload?.records?.map(
    (item: any, index: number) => (
      <Option value={item?._id} key={index}>
        {item?.name}
      </Option>
    )
  );

  const unitOptions = units?.map((item: any, index: number) => (
    <Option value={item?._id} key={index}>
      {item?.name}
    </Option>
  ));

  const clusterOptions = clusters?.map((item: any, index: number) => (
    <Option value={item?._id} key={index}>
      {item?.name}
    </Option>
  ));

  const segmentOptions = segments?.map((item: any, index: number) => (
    <Option value={item?._id} key={index}>
      {item?.name}
    </Option>
  ));

  const divisionOptions = divisions?.map((item: any, index: number) => (
    <Option value={item?._id} key={index}>
      {item?.name}
    </Option>
  ));

  const handleSelectCompany = async (e: any) => {
    setSelectedLega(e);
    const selected = await companies?.payload?.records?.filter(
      (item: any) => item?._id === e
    );
    setClusters(selected[0]?.clusters || []);
    setSelectedClus(null);
    setSelectedSegm(null);
    setSelectedDivi(null);
    setSelectedUnit(null);
    form.setFieldsValue({ segment: null });
    form.setFieldsValue({ division: null });
    form.setFieldsValue({ unit: null });
  };

  const handleSelectCluster = async (e: any) => {
    setSelectedClus(e);
    const selected = rawSegments?.payload?.records?.filter(
      (item: any) => item?.cluster?._id === e
    );
    setSegments(selected || []);
    setSelectedSegm(null);
    setSelectedDivi(null);
    setSelectedUnit(null);
    form.setFieldsValue({ segment: null });
    form.setFieldsValue({ division: null });
    form.setFieldsValue({ unit: null });
    form.setFieldsValue({ department: null });
  };

  const handleSelectSegment = async (e: any) => {
    setSelectedSegm(e);
    const selected = rawDivisions?.payload?.records?.filter(
      (item: any) => item?.segment?._id === e
    );
    setDivisions(selected || []);
    setSelectedDivi(null);
    setSelectedUnit(null);
    form.setFieldsValue({ division: null });
    form.setFieldsValue({ unit: null });
    form.setFieldsValue({ department: null });
  };

  const handleSelectDivision = async (e: any) => {
    setSelectedDivi(e);
    const selected = rawUnits?.payload?.records?.filter(
      (item: any) => item?.division?._id === e
    );
    setUnits(selected || []);
    setSelectedUnit(null);
    form.setFieldsValue({ unit: null });
    form.setFieldsValue({ department: null });
  };

  const handleSelectUnit = async (e: any) => {
    setSelectedUnit(e);
  };

  const clearForm = async () => {
    form.resetFields();
    setSelectedLega(null);
    setSelectedClus(null);
    setSelectedSegm(null);
    setSelectedDivi(null);
    setLoading(false);
  };

  useEffect(() => {
    form.resetFields();
    handleFetchCompanies();
  }, []);

  return (
    <Modal
      open={isVisible}
      title="New Requisition"
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="createReq"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
        >
          Next
        </Button>,
      ]}
    >
      <Form
        id="createReq"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="description"
              label={"Description"}
              rules={[
                {
                  required: true,
                  message: "Please enter description",
                },
              ]}
            >
              <Input placeholder="Description" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="reason"
              label={"Reason"}
              rules={[
                {
                  required: true,
                  message: "Please enter reason",
                },
              ]}
            >
              <TextArea placeholder="Reason for purchase requisition" />
            </Form.Item>
          </Col>
        </Row>
        <div className="mb-4">
          <Checkbox
            onChange={(e: any) => {
              setImpersonate(e.target.checked);
            }}
            defaultChecked={isImpersonate}
          >
            Impersonate
          </Checkbox>
        </div>

        {isImpersonate && (
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="company"
                label={"Legal Entity"}
                rules={[
                  {
                    required: true,
                    message: "Please select legal entity",
                  },
                ]}
              >
                <Select
                  placeholder="Legal Entity"
                  onChange={(e: any) => {
                    handleSelectCompany(e);
                  }}
                >
                  {companyOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="cluster"
                label={"Cluster"}
                rules={[
                  {
                    required: true,
                    message: "Please select cluster",
                  },
                ]}
              >
                {selectedLega ? (
                  <Select
                    placeholder="Cluster"
                    onChange={(e: any) => {
                      handleSelectCluster(e);
                    }}
                  >
                    {clusterOptions}
                  </Select>
                ) : (
                  <Select placeholder="Cluster" disabled>
                    {clusterOptions}
                  </Select>
                )}
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="segment"
                label={"Segment"}
                rules={[
                  {
                    required: true,
                    message: "Please select segment",
                  },
                ]}
              >
                {selectedClus ? (
                  <Select
                    placeholder="Segment"
                    onChange={(e: any) => {
                      handleSelectSegment(e);
                    }}
                  >
                    {segmentOptions}
                  </Select>
                ) : (
                  <Select placeholder="Segment" disabled>
                    {segmentOptions}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="division"
                label={"Division"}
                rules={[
                  {
                    required: true,
                    message: "Please select division",
                  },
                ]}
              >
                {selectedSegm ? (
                  <Select
                    placeholder="Division"
                    onChange={(e: any) => {
                      handleSelectDivision(e);
                    }}
                  >
                    {divisionOptions}
                  </Select>
                ) : (
                  <Select placeholder="Division" disabled>
                    {divisionOptions}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="unit"
                label={"Business Unit"}
                rules={[
                  {
                    required: true,
                    message: "Please select business unit",
                  },
                ]}
              >
                {selectedDivi ? (
                  <Select
                    placeholder="Business Unit"
                    onChange={(e: any) => {
                      handleSelectUnit(e);
                    }}
                  >
                    {unitOptions}
                  </Select>
                ) : (
                  <Select placeholder="Business Unit" disabled>
                    {unitOptions}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </Modal>
  );
}
