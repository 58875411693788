import { BsSearch } from "react-icons/bs";
import { Button, Form, Input, message, Popover, Tooltip } from "antd";
import {
  RiFileExcel2Line,
  RiFilterOffLine,
  RiMailSendLine,
} from "react-icons/ri";
import moment from "moment";
import { useContext, useState } from "react";
import PageToolnav from "../../../components/toolnav/PageToolnav";
import SideMenu from "../components/SideMenu";
import fetchDataReq from "../../../services/fetchDataReq";
import { AuthContext } from "../../../contexts/AuthContext";
import { ExportToExcel } from "../../../utils/exportExcel";
import Clusters from "./components/Clusters";
import Segments from "./components/Segments";
import Divisions from "./components/Divisions";
import Units from "./components/Units";
import Companies from "./components/Companies";

export default function Structure() {
  const { auth } = useContext(AuthContext);
  const [categories, setCategories] = useState<any>([]);
  const [selected, setSelected] = useState<any>({});
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isAdding, setAdding] = useState<boolean>(false);
  const [isFetching, setFetching] = useState<boolean>(false);
  const [isMailLoading, setMailLoading] = useState<boolean>(false);
  const [messageModal, toggleMessageModal] = useState(false);
  const [createCatModal, setCreateCatModal] = useState<boolean>(false);

  const [isSearched, setSearched] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState(null);

  const [form] = Form.useForm();

  const [emailForm] = Form.useForm();

  const sendMail = async (values: any) => {
    setMailLoading(true);
    categories?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Name: item?.name === null ? "-" : item?.name,
        Email: item?.email === null ? "-" : item?.email,
        Mobile: item?.mobile === null ? "-" : item?.mobile,
        Website: item?.website === null ? "-" : item?.website,
        Created: moment(item?.createdAt).format("DD/MM/YYYY"),
      };
    });
  };

  const fetchAllSuppliers = async () => {
    const response = await fetchDataReq({
      uri: "api/v1/suppliers",
      token: auth?._id,
    });
    if (response?.success) {
      ExportToExcel(
        response?.payload?.records?.map((item: any, index: number) => {
          return {
            "S/N": index + 1,
            Name: item?.name === null ? "-" : item?.name,
            Email: item?.email === null ? "-" : item?.email,
            Mobile: item?.mobile === null ? "-" : item?.mobile,
            Website: item?.website === null ? "-" : item?.website,
            Created: moment(item?.createdAt).format("DD/MM/YYYY"),
          };
        }),
        "Suppliers.xlsx"
      );
    }
  };

  const handleFetchAll = async () => {
    setFetching(true);
    await fetchAllSuppliers();
    setFetching(false);
  };

  return (
    <div className="flex">
      <SideMenu />
      <div className="flex flex-1 flex-col">
        <PageToolnav
          link={{ name: "Structure", to: "/manage/structure" }}
          title={"Categories"}
          actions={<div className="flex"></div>}
        />
        <div className="min-h-screen">
          <div className="flex flex-col mx-4 my-2">
            <div className="grid grid-cols-12 gap-2">
              <div className="col-span-12 sm:col-span-6">
                <Companies />
              </div>
              <div className="col-span-12 sm:col-span-6">
                <Clusters />
                <Segments />
                <Divisions />
                <Units />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
