import { Button, Select } from "antd";
import { IoCloseOutline } from "react-icons/io5";

const { Option } = Select;

function LineItem(props: any) {
  const { index, item, stockitems, changeHandler } = props;

  const itemOptions = stockitems?.map((item: any, index: number) => (
    <Option value={item?._id} key={index} name="stockReference">
      {item?.name}
    </Option>
  ));

  return (
    <div className="grid grid-cols-12 border-t justify-center items-center">
      <div className="col-span-1 p-2  border-r text-center ">{index + 1}</div>
      <div className="col-span-3 p-2  border-r text-left ">
        <Select
          showSearch
          placeholder="Search and select item"
          style={{ width: "100%" }}
          optionFilterProp="children"
          id="stockReference"
          value={item?.stockReference}
          onChange={changeHandler(index)}
          filterOption={(input: any, option: any) =>
            option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {itemOptions}
        </Select>
      </div>
      <div className="col-span-4 p-2  border-r text-left ">
        <input
          name="description"
          className="p-2 border rounded-sm focus:outline-none"
          placeholder="Description"
          style={{ width: "100%" }}
          type="text"
          value={item?.description}
          onChange={props.changeHandler(index)}
        />
      </div>
      <div className="col-span-2 p-2  border-r ">
        <input
          name="purpose"
          className="p-2 border rounded-sm focus:outline-none"
          placeholder="Purpose"
          style={{ width: "100%" }}
          type="text"
          value={item?.purpose}
          onChange={props.changeHandler(index)}
        />
      </div>
      <div className="col-span-1 p-2  border-r text-center ">
        <input
          name="quantity"
          className="p-2 border rounded-md"
          style={{ width: "100%" }}
          type="number"
          step="1"
          min="0"
          value={item?.quantity}
          onChange={props.changeHandler(index)}
          onFocus={props.focusHandler}
        />
      </div>
      <div className="col-span-1 p-2 font-bold text-center">
        <Button
          size="small"
          className="flex justify-center items-center"
          danger
          onClick={props.deleteHandler(index)}
        >
          <IoCloseOutline />
        </Button>
      </div>
    </div>
  );
}

export default LineItem;
