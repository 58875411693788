import { BsSearch } from "react-icons/bs";
import { Button, Form, Input, Popover, Table, Tooltip, message } from "antd";
import {
  RiFileExcel2Line,
  RiFilterOffLine,
  RiMailSendLine,
} from "react-icons/ri";
import { TbSignature } from "react-icons/tb";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import PageToolnav from "../../../components/toolnav/PageToolnav";
import SideMenu from "../components/SideMenu";
import fetchDataReq from "../../../services/fetchDataReq";
import DetailsPanel from "../../suppliers/components/DetailsPanel";
import { BiLoader } from "react-icons/bi";
import CreateUser from "./components/CreateUser";
import { FiEdit } from "react-icons/fi";
import { IoCheckmarkDoneOutline, IoTrashBinOutline } from "react-icons/io5";
import UpdateUser from "./components/UpdateUser";
import DeleteUser from "./components/DeleteUser";
import { AuthContext } from "../../../contexts/AuthContext";
import { ExportToExcel } from "../../../utils/exportExcel";
import UploadSign from "./components/UploadSign";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handleUpdateOverride } from "../../../api/users";

export default function Users() {
  const { auth } = useContext(AuthContext);
  const [users, setUsers] = useState<any>([]);
  const [selected, setSelected] = useState<any>({});
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isFetching, setFetching] = useState<boolean>(false);
  const [isMailLoading, setMailLoading] = useState<boolean>(false);
  const [messageModal, toggleMessageModal] = useState(false);
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [detailPanel, setDetailPanel] = useState<boolean>(false);
  const [signModal, setSignModal] = useState<boolean>(false);
  const [isSearched, setSearched] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState(null);

  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const [emailForm] = Form.useForm();

  const initial = {
    current: 1,
    pageSize: 10,
    limit: 10,
  };
  const [pagination, setPagination] = useState(initial);

  const handleFetchUsers = async ({ pagination }: any) => {
    setLoading(true);
    const { current, limit } = pagination;
    const skip = current * limit - limit;
    const uri = `api/v1/users?skip=${skip}&limit=${limit}`;
    const response = await fetchDataReq({ uri, token: auth?._id });
    if (response) {
      setUsers(response?.payload?.records);
      setPagination({ total: response?.payload?.count, ...pagination });
    }
    setLoading(false);
  };

  const handleSearchUsers = async ({ pagination }: any) => {
    if (!searchKey) return;
    setSearched(true);
    setLoading(true);
    const { current, limit } = pagination;
    const skip = current * limit - limit;
    const uri = `api/v1/users?skip=${skip}&limit=${limit}&name=${searchKey}`;
    const response = await fetchDataReq({ uri, token: auth?._id });
    if (response?.success) {
      setUsers(response?.payload?.records);
      setPagination({ total: response?.payload?.count, ...pagination });
    }
    setLoading(false);
  };

  const handleRefetch = () => {
    handleFetchUsers({
      pagination,
    });
  };


  const { mutate: updateOverride } = useMutation({
    mutationFn: handleUpdateOverride,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      handleRefetch()
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const handleOverride = async (user: any) => {
    await updateOverride({_id: user?._id, data: {canOverride: !user?.canOverride}})
  }

  const handlePageChange = (pagination: any) => {
    handleFetchUsers({
      pagination,
    });
  };

  const columns = [
    {
      title: "S/N",
      width: 40,
      render: (record: any, text: any, index: any) => (
        <span className="text-gray-700 ml-2">{index + 1}</span>
      ),
    },
    {
      title: "Name",
      width: 120,
      render: (record: any) => {
        return (
          <span className="text-gray-700">
            {record?.name ? record?.name : "-"}
          </span>
        );
      },
    },
    {
      title: "Email",
      width: 140,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.email ? record?.email : "-"}
        </span>
      ),
    },
    {
      title: "Mobile",
      width: 90,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.mobile ? record?.mobile : "-"}
        </span>
      ),
    },
    {
      title: "Legal Entity",
      width: 100,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.company?.name ? record?.company?.name : "-"}
        </span>
      ),
    },

    {
      title: "Department",
      width: 80,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.department?.name ? record?.department?.name : "-"}
        </span>
      ),
    },

    {
      title: "Business Unit",
      width: 100,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.unit?.name ? record?.unit?.name : "-"}
        </span>
      ),
    },
    {
      title: "KJL ID",
      width: 50,
      render: (record: any) => (
        <span className="text-gray-700">{record?.kjlID || "-"}</span>
      ),
    },

    {
      title: "Level",
      width: 50,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.level ? record?.level : "-"}
        </span>
      ),
    },

    {
      title: "Access",
      width: 100,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.flag ? record?.flag : "-"}
        </span>
      ),
    },

    {
      title: "Override",
      width: 100,
      render: (record: any) => (
        <button onClick={() => handleOverride(record)} className="text-gray-700">
          {record?.canOverride ? <div className="flex items-center">
            <IoCheckmarkDoneOutline color="#22c55e"/> <span className="mx-1">YES</span>
          </div>:
          <div className="flex items-center">
             NO
          </div>}
        </button>
      ),
    },

    {
      title: "Signature",
      width: 100,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.signature && <img src={record?.signature} alt="" />}
        </span>
      ),
    },

    {
      title: "Action",
      width: 100,
      render: (record: any) => (
        <div className="flex justify-end">
          <button
            onClick={() => {
              setSelected({
                ...record,
              });
              setSignModal(true);
            }}
            className="flex justify-center items-center text-gray-700 border rounded-md p-2 bg-gray-100 hover:bg-gray-200 cursor-pointer"
          >
            <TbSignature />
          </button>
          <button
            onClick={() => {
              setSelected({
                ...record,
              });
              setUpdateModal(true);
            }}
            className="flex justify-center items-center ml-1 text-gray-700 border rounded-md p-2 bg-gray-100 hover:bg-gray-200 cursor-pointer"
          >
            <FiEdit />
          </button>

          <button
            onClick={() => {
              setSelected({
                ...record,
              });
              setDeleteModal(true);
            }}
            className="flex justify-center items-center ml-1 text-gray-700 border rounded-md p-2 bg-red-100 hover:bg-red-200 cursor-pointer"
          >
            <IoTrashBinOutline />
          </button>
        </div>
      ),
    },
  ];

  const sendMail = async (values: any) => {
    setMailLoading(true);
    users?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Name: item?.name === null ? "-" : item?.name,
        Email: item?.email === null ? "-" : item?.email,
        Mobile: item?.mobile === null ? "-" : item?.mobile,
        Website: item?.website === null ? "-" : item?.website,
        Created: moment(item?.createdAt).format("DD/MM/YYYY"),
      };
    });
  };

  const fetchAllUsers = async () => {
    const response = await fetchDataReq({
      uri: "api/v1/users",
      token: auth?._id,
    });
    if (response?.success) {
      ExportToExcel(
        response?.payload?.records?.map((item: any, index: number) => {
          return {
            "S/N": index + 1,
            Name: item?.name === null ? "-" : item?.name,
            Email: item?.email === null ? "-" : item?.email,
            Mobile: item?.mobile === null ? "-" : item?.mobile,
            Website: item?.website === null ? "-" : item?.website,
            Created: moment(item?.createdAt).format("DD/MM/YYYY"),
          };
        }),
        "Users.xlsx"
      );
    }
  };

  const handleFetchAll = async () => {
    setFetching(true);
    await fetchAllUsers();
    setFetching(false);
  };

  useEffect(() => {
    handleFetchUsers({
      pagination,
    });
  }, []);

  return (
    <div className="flex">
      <SideMenu />
      <div className="flex flex-1 flex-col">
        <PageToolnav
          link={{ name: "Users", to: "/manage/users" }}
          title={"Users"}
          actions={
            <div className="flex">
              <div className="flex mr-2">
                <Form layout="inline" form={form}>
                  <Form.Item name="name">
                    <Input
                      name="searchKey"
                      onChange={(e: any) => {
                        setSearchKey(e.target.value);
                        setSearched(false);
                      }}
                      type="text"
                      placeholder="Search by name"
                    />
                  </Form.Item>
                </Form>
                <Button
                  className="-ml-4 focus:outline-none"
                  onClick={() => {
                    if (isSearched) {
                      setSearched(false);
                      form.resetFields();
                      setSearchKey(null);
                      handleFetchUsers({ pagination: initial });
                    } else {
                      handleSearchUsers({
                        pagination: initial,
                      });
                    }
                  }}
                >
                  {isSearched ? (
                    <RiFilterOffLine color={"#f87171"} />
                  ) : (
                    <BsSearch color={searchKey ? "#16a34a" : "#9ca3af"} />
                  )}
                </Button>
              </div>
              <Popover
                content={
                  <>
                    <Form
                      name="basic"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                      initialValues={{ remember: true }}
                      form={emailForm}
                      onFinish={sendMail}
                      autoComplete="off"
                    >
                      <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                          { required: true, message: "Email is Required" },
                        ]}
                      >
                        <Input placeholder="Email" />
                      </Form.Item>
                      <Form.Item
                        label="Subject"
                        name="subject"
                        rules={[
                          { required: true, message: "Subject is Required" },
                        ]}
                      >
                        <Input placeholder="Email Subject" />
                      </Form.Item>
                      <div className="flex flex-row justify-between">
                        <Form.Item wrapperCol={{ offset: 12, span: 8 }}>
                          <Button type="primary" htmlType="submit">
                            {isMailLoading ? "Sending ..." : "Submit"}
                          </Button>
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 0, span: 8 }}>
                          <Button
                            onClick={() => {
                              toggleMessageModal(false);
                            }}
                          >
                            Cancel
                          </Button>
                        </Form.Item>
                      </div>
                    </Form>
                  </>
                }
                title="Message Content"
                trigger="focus"
                placement="left"
                visible={messageModal}
              >
                <Tooltip title="Send Email">
                  <button
                    className="flex bg-white mr-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                    onClick={async () => {
                      toggleMessageModal(true);
                    }}
                  >
                    <RiMailSendLine size={21} />
                  </button>
                </Tooltip>
              </Popover>
              <Tooltip title="Export To Excel">
                <button
                  color="#E50000"
                  className="flex bg-white mx-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                  onClick={() => handleFetchAll()}
                >
                  {isFetching ? (
                    <BiLoader size={20} />
                  ) : (
                    <RiFileExcel2Line size={20} />
                  )}
                </button>
              </Tooltip>
              <Button
                className="bg-blue-200"
                onClick={() => setCreateModal(true)}
              >
                + New
              </Button>
            </div>
          }
        />
        <div className="h-screen flex">
          <div className="flex flex-col mx-4 my-2">
            <div className="flex flex-col w-full">
              <div className="grid gap-x-0 grid-cols-12">
                <div className="col-span-12 sm:col-span-12 md:col-span-12 border bg-white">
                  <Table
                    size="middle"
                    dataSource={isLoading ? [] : users}
                    columns={columns}
                    pagination={pagination}
                    scroll={{ y: 660, x: 0 }}
                    onChange={handlePageChange}
                    rowKey="id"
                    loading={isLoading}
                  />
                  <CreateUser
                    isVisible={createModal}
                    setVisible={setCreateModal}
                    refetch={handleRefetch}
                  />

                  <UpdateUser
                    isVisible={updateModal}
                    setVisible={setUpdateModal}
                    refetch={handleRefetch}
                    user={selected}
                  />

                  <UploadSign
                    isVisible={signModal}
                    setVisible={setSignModal}
                    selected={selected}
                    refetch={handleRefetch}
                  />

                  <DeleteUser
                    isVisible={deleteModal}
                    setVisible={setDeleteModal}
                    refetch={handleRefetch}
                    user={selected}
                  />

                  <DetailsPanel
                    isVisible={detailPanel}
                    setVisible={setDetailPanel}
                    selected={selected}
                    refetch={handleFetchUsers}
                    isEditable={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
