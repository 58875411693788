import { Button, Modal, Form, message } from "antd";
import { useContext, useEffect, useState } from "react";

import { AuthContext } from "../../../contexts/AuthContext";
import deleteDataReq from "../../../services/deleteDataReq";

export default function DeleteAttachment({
  isVisible,
  setVisible,
  requisition,
  refetch,
  type,
}: any) {
  const { auth } = useContext(AuthContext);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  const onSubmit = async () => {
    setLoading(true);

    let uri = `api/v1/requisitions/${requisition?._id}/suppliers/${type}/attachment`;
    let data = {};

    const response = await deleteDataReq({ data, uri, token: auth?._id });
    if (response.success) {
      await refetch();
      message.success("Deleted successfully");
      setVisible(false);
    } else {
      await refetch();
      setVisible(false);
      setLoading(false);
      return;
    }

    refetch();
    setVisible(false);
    setLoading(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [requisition]);

  return (
    <Modal
      open={isVisible}
      title={
        <div>
          Delete Attachment : <span className="uppercase">{type}</span>
        </div>
      }
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="deleteAttachment"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Delete
        </Button>,
      ]}
    >
      <Form
        id="deleteAttachment"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Form id="deleteContact" form={form} onFinish={onSubmit}>
          <div>
            <p className="text-center font-bold">
              Are you sure you want to delete this
            </p>
            <p className="text-center text-red-500 font-bold ml-1">
              Attachment
            </p>
          </div>
        </Form>
      </Form>
    </Modal>
  );
}
