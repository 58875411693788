import { useContext, useEffect, useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { GrStatusCriticalSmall } from "react-icons/gr";
import { AuthContext } from "../../../contexts/AuthContext";
import fetchDataReq from "../../../services/fetchDataReq";
import { colors } from "../../../utils/data";
import pdf_avatar from "../../../assets/images/pdf.png";
import { Button, Checkbox, Col, Form, Input, Row, message } from "antd";
import { useNavigate } from "react-router";
import postDataReq from "../../../services/postDataReq";
import { BsCheck2, BsDownload } from "react-icons/bs";
import formatMoney from "../../../utils/formatMoney";
import formatSize from "../../../utils/formatSize";
import { IoCloudUploadOutline } from "react-icons/io5";
import AssignSupplier from "./AssignSupplier";
import AddAttachment from "./AddAttachment";
import { RiDeleteBin4Line } from "react-icons/ri";
import DeleteAttachment from "./DeleteAttachment";
import { FiEdit } from "react-icons/fi";
import patchDataReq from "../../../services/patchDataReq";

export default function ConvRequisition({
  requisition,
  items,
  setConvert,
  refetch,
}: any) {
  const { auth } = useContext(AuthContext);
  const [isAllSelected, setAllSelected] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>(null);
  const [supplierModal, setSupplierModal] = useState<boolean>(false);
  const [uploadFileModal, setUploadFileModal] = useState<boolean>(false);
  const [deleteFileModal, setDeleteFileModal] = useState<boolean>(false);
  const [suppliers, setSuppliers] = useState<any>([]);
  const [type, setType] = useState<string>("");
  const [editMode, setEditMode] = useState<boolean>(false);

  const [totals, setTotals] = useState<any>({});

  const [expType, setExpType] = useState<any>(null);
  const [opxType, setOpxType] = useState<any>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isExempted, setExempted] = useState<boolean>(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const handleAddItem = (item: any) => {
    setSelected([...selected, item]);
  };

  const handleFetchSuppliers = async () => {
    const response = await fetchDataReq({
      uri: "api/v1/suppliers",
      token: auth?._id,
    });
    if (response?.success) {
      setSuppliers(response?.payload?.records);
    }
  };

  const handleAssignSupplier = async ({
    type,
    supplier_id,
    supplier_name,
  }: any) => {
    const data = { type, supplier_id, supplier_name };
    const uri = `api/v1/requisitions/${requisition?._id}/suppliers/${supplier_id}/assign`;
    const response = await patchDataReq({ data, uri, token: auth?._id });
    if (response?.success) {
      message.success("Supplier Assigned");
      await refetch();
      handleSelected();
    } else {
      message.error("Something went wrong");
    }
  };

  const handleRemoveItem = (item: any) => {
    var filtered = selected.filter(function (value: any, index: any, arr: any) {
      return value._id !== item?._id;
    });
    setSelected(filtered);
  };

  const handleAddAll = () => {
    var filtered = items.filter(function (value: any, index: any, arr: any) {
      return value.isConverted === false;
    });

    setSelected(filtered);
    setAllSelected(true);
  };

  const handleRemoveAll = () => {
    setSelected([]);
    setAllSelected(false);
  };
  const setInitialState = () => {
    setSelected([]);
  };

  const handleFetchState = async () => {
    setInitialState();
  };

  const onSubmit = async () => {
    if (requisition?.supplier?.entity === null) {
      message.error("Please assign supplier on L1 , L2 or L3");
      return;
    }

    if (!requisition?.supplier?.name) {
      message.error("Please select Supplier");
      return;
    }
    if (selected?.length === 0) {
      message.error("Please select at least one item to convert");
      return;
    }
    if (!expType) {
      message.error("Please select Expense type");
      return;
    }

    if (expType.id === 1) {
      if (!opxType) {
        message.error("Please select Opex type");
        return;
      }
    }

    setLoading(true);
    const { terms } = await form.getFieldsValue();
    const data = {
      user: auth?._id,
      isCapex: expType?.isCapex,
      isDirect: expType?.isCapex === true ? false : opxType?.isDirect,
      exemption: isExempted,
      supplier: requisition?.supplier?.entity,
      items: selected,
      terms,
    };

    const uri = `api/v1/requisitions/${requisition?._id}/convert`;
    const response = await postDataReq({ data, uri, token: auth?._id });
    if (response.success) {
      message.success("Submited Successfully");
      navigate(
        `/orders/${response?.payload?.order?._id}?redirect="/requisitions"`
      );
      setLoading(false);
      // setConvert(false);
    } else {
      message.error(response?.message);
    }
    setLoading(false);
  };

  const selectItem = async ({ item }: any) => {
    const uri = `api/v1/requisitions/${requisition?._id}/items/${item?._id}/select`;
    const response = await patchDataReq({
      data: { item },
      uri,
      token: auth?._id,
    });
    if (response.success) {
      setLoading(false);
      // setConvert(false);
    } else {
      message.error(response?.message);
    }
  };

  const updatePrice = async ({ item_id, type, price }: any) => {
    const uri = `api/v1/requisitions/${requisition?._id}/items/${item_id}/price`;
    const response = await patchDataReq({
      data: { type, price },
      uri,
      token: auth?._id,
    });
    if (response.success) {
      setLoading(false);
      // setConvert(false);
      await refetch();
    } else {
      message.error(response?.message);
    }
  };

  const updateRemarks = async ({ item_id, remarks }: any) => {
    const uri = `api/v1/requisitions/${requisition?._id}/items/${item_id}/remarks`;
    const response = await patchDataReq({
      data: { remarks },
      uri,
      token: auth?._id,
    });
    if (response.success) {
      setLoading(false);
      // setConvert(false);
    } else {
      message.error(response?.message);
    }
  };

  const getTotalAmount = (filtered: any) => {
    let nettotal = 0;
    let vat = 0;
    let grandtotal = 0;
    if (filtered) {
      filtered?.forEach((item: any) => {
        if (!item.isConverted) {
          nettotal =
            nettotal +
            item?.quantity * Number(item?.price) * (1 - item?.discount / 100);
        }
      });
      vat = isExempted ? 0 : nettotal * 0.18;
      grandtotal = nettotal + vat;

      return { nettotal, vat, grandtotal };
    }
  };

  const handleGetTotals = (filtered: any) => {
    const num = getTotalAmount(filtered);
    setTotals({ net: num?.nettotal, vat: num?.vat, grand: num?.grandtotal });
  };

  const expensetypes = [
    { id: 1, name: "Convert to Opex LPO", isCapex: false },
    { id: 2, name: "Conver to Capex LPO", isCapex: true },
  ];

  const opextypes = [
    { id: 1, name: "Direct", isDirect: true },
    { id: 2, name: "Indirect", isDirect: false },
  ];

  const handleSelected = () => {
    var filtered = items.filter(function (value: any) {
      return value.isSelected === true && value.isConverted === false;
    });

    handleGetTotals(filtered);
    setSelected(filtered);
  };

  useEffect(() => {
    handleSelected();
  }, [isExempted]);

  useEffect(() => {
    handleFetchSuppliers();
    handleFetchState();
    handleSelected();
  }, [items, requisition]);

  return (
    <div className="my-2 mx-4 border">
      <div className="grid grid-cols-12 gap-x-0">
        <div className="col-span-12 sm:col-span-12 md:col-span-12 border-r border-b bg-white">
          <div className="border-b p-3 w-full flex justify-between items-center">
            <div className="">
              <div className="">{requisition?.company?.name}</div>

              <div className="">
                <div className="flex">
                  <div className="font-bold">Description:</div>
                  <div className="mx-2">{requisition?.description}</div>
                </div>
                <div className="flex">
                  <div className="font-bold">Reason:</div>
                  <div className="mx-2">{requisition?.reason}</div>
                </div>
              </div>
            </div>
            <div className="text-sm flex items-center justify-center">
              <div className="">
                <GrStatusCriticalSmall
                  color={`${colors[requisition?.status]}`}
                />
              </div>
              <div
                className="ml-1"
                style={{ color: `${colors[requisition?.status]}` }}
              >
                {requisition?.status}
              </div>
            </div>
          </div>
          <div className="">
            <div className="text-xs">
              <div className="grid grid-cols-12 border-b-2">
                <div className="col-span-1 p-2 font-bold border-r flex justify-center">
                  {isAllSelected ? (
                    <button
                      className="flex justify-center items-center h-4 w-4 border border-gray-400 hover:bg-green-600 bg-green-500"
                      onClick={() => handleRemoveAll()}
                    >
                      <AiOutlineCheck color="#FFFFFF" />
                    </button>
                  ) : (
                    <button
                      className="flex justify-center items-center h-4 w-4 border border-gray-400 hover:bg-green-600"
                      onClick={() => handleAddAll()}
                    >
                      <AiOutlineCheck color="#FFFFFF" />
                    </button>
                  )}
                </div>
                <div className="col-span-1 p-2 font-bold border-r  text-center">
                  S/n
                </div>
                <div className="col-span-3 p-2 font-bold border-r  text-left">
                  Item Name / Code
                </div>
                <div className="col-span-3 p-2 font-bold border-r  text-left">
                  Item Description
                </div>
                <div className="col-span-2 p-2 font-bold border-r  text-left">
                  Purpose
                </div>
                <div className="col-span-1 p-2 font-bold border-r text-center">
                  Qty
                </div>
                <div className="col-span-1 p-2 font-bold text-center">LPO</div>
              </div>

              {items?.map((item: any, index: number) => (
                <div key={index}>
                  {item?.isConverted ? (
                    <div className="grid grid-cols-12 border-b justify-center items-center bg-gray-100 text-gray-400">
                      <div className="col-span-1 p-2  border-r flex justify-center">
                        <button
                          className="flex justify-center items-center h-4 w-4 border border-gray-200 bg-gray-100"
                          onClick={() => handleRemoveItem(item)}
                        >
                          <AiOutlineCheck color="#C9C9C9" />
                        </button>
                      </div>
                      <div className="col-span-1 p-2  border-r text-center">
                        {index + 1}
                      </div>
                      <div className="col-span-3 p-2  border-r">
                        {item?.name}
                      </div>
                      <div className="col-span-3 p-2  border-r">
                        {item?.description}
                      </div>
                      <div className="col-span-2 p-2  border-r">
                        {item?.purpose}
                      </div>
                      <div className="col-span-1 p-2 border-r  text-center ">
                        {item?.quantity}
                      </div>
                      <div className="col-span-1 p-2  text-center ">
                        {item?.isConverted ? (
                          <span className="">Yes</span>
                        ) : (
                          <span>No</span>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div
                      className="grid grid-cols-12 border-b justify-center items-center"
                      onClick={() => {
                        if (selected?.includes(item)) {
                          // handleRemoveItem(item);
                          selectItem({ item });
                          setAllSelected(false);
                        } else {
                          // handleAddItem(item);
                          selectItem({ item });
                          setAllSelected(false);
                        }
                      }}
                    >
                      <div className="col-span-1 p-2  border-r flex justify-center">
                        {selected?.includes(item) ? (
                          <button
                            className="flex justify-center items-center h-4 w-4 border border-gray-400 hover:bg-green-600 bg-green-500"
                            onClick={() => handleRemoveItem(item)}
                          >
                            <AiOutlineCheck color="#FFFFFF" />
                          </button>
                        ) : (
                          <button
                            className="flex justify-center items-center h-4 w-4 border border-gray-400 hover:bg-gray-500"
                            onClick={() => handleAddItem(item)}
                          >
                            <AiOutlineCheck color="#FFFFFF" />
                          </button>
                        )}
                      </div>
                      <div className="col-span-1 p-2  border-r text-center">
                        {index + 1}
                      </div>
                      <div className="col-span-3 p-2  border-r">
                        {item?.name}
                      </div>
                      <div className="col-span-3 p-2  border-r">
                        {item?.description}
                      </div>
                      <div className="col-span-2 p-2  border-r">
                        {item?.purpose}
                      </div>
                      <div className="col-span-1 p-2  border-r text-center ">
                        {item?.quantity}
                      </div>
                      <div className="col-span-1 p-2  text-center ">
                        <span className="text-red-600">No</span>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>

            <div className="col-span-12 sm:col-span-12 md:col-span-12 bg-white">
              <div className="flex justify-between mt-2 w-full px-4">
                <div className="w-1/2 flex  bg-gray-50">
                  <SupplierItem
                    type={"l1"}
                    editMode={editMode}
                    handleAssignSupplier={handleAssignSupplier}
                    setSupplierModal={setSupplierModal}
                    setUploadFileModal={setUploadFileModal}
                    setDeleteFileModal={setDeleteFileModal}
                    setType={setType}
                    supplier={requisition?.suppliers?.l1}
                  />
                </div>
                <div className="w-1/2 flex bg-gray-50 mx-2">
                  <SupplierItem
                    type={"l2"}
                    editMode={editMode}
                    handleAssignSupplier={handleAssignSupplier}
                    setSupplierModal={setSupplierModal}
                    setUploadFileModal={setUploadFileModal}
                    setDeleteFileModal={setDeleteFileModal}
                    setType={setType}
                    supplier={requisition?.suppliers?.l2}
                  />
                </div>
                <div className="w-1/2 flex  bg-gray-50">
                  <SupplierItem
                    type={"l3"}
                    editMode={editMode}
                    handleAssignSupplier={handleAssignSupplier}
                    setSupplierModal={setSupplierModal}
                    setUploadFileModal={setUploadFileModal}
                    setDeleteFileModal={setDeleteFileModal}
                    setType={setType}
                    supplier={requisition?.suppliers?.l3}
                  />
                </div>
              </div>
            </div>

            <div className="px-4 flex items-center">
              <div className="font-bold text-xl text-toyota mt-2">
                SUPPLIER{" "}
              </div>
              <div className="text-xl ml-1">
                :
                {requisition?.supplier?.name
                  ? requisition?.supplier?.name
                  : "-"}
              </div>
            </div>

            {/* COMPARATIVE FORM */}
            <div className="grid grid-cols-12 gap-x-0">
              <div className="col-span-12">
                <div className="px-4 bg-white">
                  <Form
                    id="converReq"
                    form={form}
                    onFinish={onSubmit}
                    layout="vertical"
                  >
                    <div className="flex flex-col justify-center items-cente mt-4 bg-white">
                      <div className="flex w-full">
                        {expensetypes?.map((item: any, index: number) => (
                          <div
                            className={`border border-gray-300 px-2 py-1 mx-1 w-full hover:cursor-pointer ${
                              expType?.id === item?.id ? "bg-green-50" : ""
                            }`}
                            key={index}
                            onClick={() => {
                              if (expType?.id === item?.id) {
                                setExpType(null);
                              } else {
                                setExpType(item);
                              }
                            }}
                          >
                            <div className="flex items-center">
                              <div className="w-4 h-4 border border-gray-400 flex justify-center items-center">
                                {expType?.id === item?.id ? (
                                  <BsCheck2 size={18} color={"green"} />
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="ml-2">{item?.name}</div>
                            </div>
                          </div>
                        ))}
                      </div>
                      {expType?.id === 1 ? (
                        <div className="flex w-full mt-3">
                          {opextypes?.map((item: any, index: number) => (
                            <div
                              className={`border border-gray-300 px-2 py-1 mx-1 w-full hover:cursor-pointer ${
                                opxType?.id === item?.id ? "bg-green-50" : ""
                              }`}
                              key={index}
                              onClick={() => {
                                if (opxType?.id === item?.id) {
                                  setOpxType(null);
                                } else {
                                  setOpxType(item);
                                }
                              }}
                            >
                              <div className="flex items-center">
                                <div className="w-4 h-4 border border-gray-400 flex justify-center items-center">
                                  {opxType?.id === item?.id ? (
                                    <BsCheck2 size={18} color={"green"} />
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="ml-2">{item?.name}</div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : null}
                    </div>
                    <Row gutter={[16, 0]}>
                      <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Form.Item
                          name="terms"
                          label={"Payment Terms"}
                          initialValue={"100% Advance Payment"}
                          rules={[
                            {
                              required: false,
                              message: "Please enter payment terms",
                            },
                          ]}
                        >
                          <Input placeholder="Payment Terms" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <div className="mb-4">
                      <Checkbox
                        onChange={(e: any) => {
                          setExempted(e.target.checked);
                        }}
                        defaultChecked={isExempted}
                      >
                        VAT Exempted
                      </Checkbox>
                    </div>
                    <div className="">
                      <div className="grid grid-cols-12 border border-black font-bold">
                        <div className="col-span-2  border-r border-black px-1 flex">
                          <div className="border-r border-black px-1 w-10">
                            S/N.
                          </div>
                          <div className="flex-1 pl-1">Item Code</div>
                        </div>
                        <div className="col-span-3  border-r border-black px-1">
                          Item Description
                        </div>
                        <div className="col-span-1  border-r border-black px-1 text-center">
                          Qty
                        </div>
                        <div className="col-span-1  border-r border-black px-1 text-center">
                          L1
                        </div>
                        <div className="col-span-1  border-r border-black px-1 text-center">
                          L2
                        </div>
                        <div className="col-span-1  border-r border-black px-1 text-center">
                          L3
                        </div>
                        <div className="col-span-1  border-r border-black px-1 text-center">
                          Selected
                        </div>
                        <div className="col-span-2 px-1">Remarks</div>
                      </div>
                      {selected?.map((item: any, index: number) => (
                        <div
                          key={index}
                          className="grid grid-cols-12 border border-black"
                        >
                          <div className="col-span-2  border-r border-black px-1 flex">
                            <div className="border-r border-black px-1 w-10 text-center">
                              {index + 1}.
                            </div>
                            <div className="flex-1 pl-1"> {item?.name}</div>
                          </div>
                          <div className="col-span-3  border-r border-black p-1">
                            {item?.description}
                          </div>
                          <div className="col-span-1  border-r border-black p-1 text-center">
                            {item?.quantity}
                          </div>
                          <div className="col-span-1  border-r border-black p-1 text-center">
                            <SupplierPrice
                              type={"l1"}
                              item={item.l1}
                              item_id={item?._id}
                              exists={
                                requisition?.suppliers?.l1?.name ? true : false
                              }
                              updatePrice={updatePrice}
                            />
                          </div>
                          <div className="col-span-1  border-r border-black p-1 text-center">
                            <SupplierPrice
                              type={"l2"}
                              item={item.l2}
                              item_id={item?._id}
                              exists={
                                requisition?.suppliers?.l2?.name ? true : false
                              }
                              updatePrice={updatePrice}
                            />
                          </div>
                          <div className="col-span-1  border-r border-black p-1 text-center">
                            <SupplierPrice
                              type={"l3"}
                              item={item.l3}
                              item_id={item?._id}
                              exists={
                                requisition?.suppliers?.l3?.name ? true : false
                              }
                              updatePrice={updatePrice}
                            />
                          </div>
                          <div className="col-span-1  border-r border-black p-1 text-center uppercase">
                            {item?.supplier}
                          </div>
                          <div className="col-span-2 p-1 text-right">
                            <SupplierRemark
                              item={item}
                              updateRemarks={updateRemarks}
                            />
                          </div>
                        </div>
                      ))}
                      <div className="grid grid-cols-12 border border-black">
                        <div className="col-span-1"></div>
                        <div className="col-span-1"></div>
                        <div className="col-span-4"></div>
                        <div className="col-span-1"></div>
                        <div className="col-span-2 border-r border-black"></div>
                        <div className="col-span-1 border-r border-black px-1"></div>
                        <div className="col-span-2 text-right px-1 text-white">
                          -
                        </div>
                      </div>
                      <div className="grid grid-cols-12 border border-black">
                        <div className="col-span-1"></div>
                        <div className="col-span-1"></div>
                        <div className="col-span-4"></div>
                        <div className="col-span-1"></div>
                        <div className="col-span-2 border-r border-black"></div>
                        <div className="col-span-1 border-r border-black px-1">
                          Net Total
                        </div>
                        <div className="col-span-2 text-right px-1">
                          {formatMoney(isNaN(totals?.net) ? 0 : totals?.net)}
                        </div>
                      </div>
                      {!isExempted && (
                        <div className="grid grid-cols-12 border-l border-r border-b border-black">
                          <div className="col-span-1"></div>
                          <div className="col-span-4"></div>
                          <div className="col-span-2"></div>
                          <div className="col-span-2 border-r border-black"></div>
                          <div className="col-span-1 border-r border-black px-1">
                            Add VAT
                          </div>
                          <div className="col-span-2 text-right px-1">
                            {formatMoney(isNaN(totals?.vat) ? 0 : totals?.vat)}
                          </div>
                        </div>
                      )}
                      <div className="grid grid-cols-12 border border-black">
                        <div className="col-span-1 p-1"></div>
                        <div className="col-span-4 p-1"></div>
                        <div className="col-span-2 p-1"></div>
                        <div className="col-span-2 p-1 border-r border-black"></div>
                        <div className="col-span-1 border-r border-black p-1 font-bold">
                          Grand Total
                        </div>
                        <div className="col-span-2 p-1 text-right">
                          {formatMoney(
                            isNaN(totals?.grand) ? 0 : totals?.grand
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between mt-4">
                      <Form.Item>
                        <Button type="primary" htmlType="submit">
                          Convert
                        </Button>
                        <Button
                          htmlType="button"
                          className="ml-2"
                          onClick={() => setConvert(false)}
                        >
                          Cancel
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AssignSupplier
        isVisible={supplierModal}
        setVisible={setSupplierModal}
        requisition={requisition}
        refetch={refetch}
        type={type}
      />
      <AddAttachment
        isVisible={uploadFileModal}
        setVisible={setUploadFileModal}
        requisition={requisition}
        refetch={refetch}
        type={type}
      />
      <DeleteAttachment
        isVisible={deleteFileModal}
        setVisible={setDeleteFileModal}
        requisition={requisition}
        refetch={refetch}
        type={type}
      />
    </div>
  );
}

const SupplierItem = ({
  type,
  supplier,
  setType,
  setSupplierModal,
  setUploadFileModal,
  setDeleteFileModal,
  handleAssignSupplier,
}: any) => {
  return (
    <div
      className={`px-4 py-2 mb-2 w-full border ${
        supplier?.isSelected ? "bg-green-50" : ""
      }`}
    >
      <div className="flex justify-between border-b pb-2">
        <button
          onClick={() =>
            handleAssignSupplier({
              type,
              supplier_id: supplier?.id,
              supplier_name: supplier?.name,
            })
          }
          className="flex items-center flex-1"
        >
          <div className="w-4 h-4 border border-gray-400 flex justify-center items-center">
            {supplier?.isSelected ? <BsCheck2 size={18} color={"green"} /> : ""}
          </div>
          <div className="flex ml-1 items-center">
            <span className="uppercase">{type}:</span>{" "}
            <span>{supplier?.name || ""}</span>
          </div>
        </button>
        <Button
          onClick={() => {
            setType(type);
            setSupplierModal(true);
          }}
        >
          <FiEdit />
        </Button>
      </div>
      {supplier?.name ? (
        <div className="mt-2">
          {supplier?.attachment ? (
            <div className="flex justify-between">
              <button
                onClick={() => {
                  setType(type);
                  setUploadFileModal(true);
                }}
                className="flex"
              >
                <div className="mr-2">
                  <img src={pdf_avatar} alt="Logo" height={15} width={28} />
                </div>
                <div className="flex flex-col items-start">
                  <div className="">Quotation</div>
                  {supplier?.attachment?.size ? (
                    <div className="text-xs">
                      {formatSize(supplier?.attachment?.size)}
                    </div>
                  ) : (
                    <div className="text-xs">-</div>
                  )}
                </div>
              </button>
              <div className="flex justify-end items-center">
                <a
                  href={`${supplier?.attachment?.link}`}
                  target="_blank"
                  className="ml-4 hover:text-green-600 text-black"
                >
                  <BsDownload />
                </a>

                <div className="ml-2">
                  <button
                    className="flex items-center rounded-none h-5 hover:bg-red-50"
                    style={{ fontSize: 12 }}
                    onClick={() => {
                      setType(type);
                      setDeleteFileModal(true);
                    }}
                  >
                    <RiDeleteBin4Line size={17} color="#FF251A" />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <button
              onClick={() => {
                setType(type);
                setUploadFileModal(true);
              }}
              className="px-2 py-2 flex items-center w-40 text-xs mt-1"
            >
              <div className="">
                <IoCloudUploadOutline />
              </div>
              <div className="ml-1">Upload Quote</div>
            </button>
          )}
        </div>
      ) : (
        <div className=""></div>
      )}
    </div>
  );
};

const SupplierPrice = ({ item, item_id, updatePrice, exists, type }: any) => {
  return (
    <div className="flex flex-col">
      {exists && (
        <div className="flex items-center justify-between">
          <div className="">
            <input
              type="number"
              placeholder=""
              defaultValue={item?.price}
              onChange={(e) =>
                setTimeout(() => {
                  updatePrice({
                    item_id: item_id,
                    type,
                    price: e.target.value,
                  });
                }, 1000)
              }
              style={{ width: "100%" }}
              className="border border-gray-300 rounded-sm focus:outline-none text-right"
            />
          </div>
        </div>
      )}
    </div>
  );
};

const SupplierRemark = ({ item, updateRemarks }: any) => {
  return (
    <div className="">
      <input
        type="text"
        placeholder=""
        defaultValue={item?.remarks}
        onChange={(e) =>
          setTimeout(() => {
            updateRemarks({
              item_id: item?._id,
              remarks: e.target.value,
            });
          }, 1000)
        }
        style={{ width: "100%" }}
        className="border border-gray-300 rounded-sm focus:outline-none"
      />
    </div>
  );
};
