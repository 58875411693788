import { Button, Modal, Form, Row, Col, Input, message } from "antd";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../contexts/AuthContext";
import patchDataReq from "../../../../services/patchDataReq";

export default function UpdateModel({
  isVisible,
  setVisible,
  selected,
  make,
  refetch,
}: any) {
  const [isLoading, setLoading] = useState(false);
  const { auth } = useContext(AuthContext);

  const [form] = Form.useForm();

  const onSubmit = async () => {
    setLoading(true);
    const { name } = await form.getFieldsValue();
    const data = {
      name,
      make: make?._id,
    };
    const uri = `api/v1/models/${selected?._id}`;
    const response = await patchDataReq({ data, uri, token: auth?._id });
    if (response.success) {
      message.success("Updated Successfully");
      refetch(make?._id);
      form.resetFields();

      setVisible(false);
    } else {
      message.error(response.message);
    }
    setLoading(false);
  };

  const clearForm = async () => {
    form.resetFields();
    setLoading(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [selected]);

  return (
    <Modal
      open={isVisible}
      title="Update Model"
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="updateModel"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
        >
          Update
        </Button>,
      ]}
    >
      <Form
        id="updateModel"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="name"
              label="Name"
              initialValue={selected?.name}
              rules={[
                {
                  required: true,
                  message: "Please enter model name",
                },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
