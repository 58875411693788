import { useContext, useEffect, useState } from "react";
import {
  Button,
  Modal,
  Form,
  message,
  Select,
  Input,
  Checkbox,
  Col,
  Row,
} from "antd";
import postDataReq from "../../../services/postDataReq";
import { BsCheck2 } from "react-icons/bs";
import { AuthContext } from "../../../contexts/AuthContext";
import fetchDataReq from "../../../services/fetchDataReq";
import formatMoney from "../../../utils/formatMoney";
import { useNavigate } from "react-router";

const { Option } = Select;

export default function ConvertRequisition({
  isVisible,
  setVisible,
  requisition,
}: any) {
  const { auth } = useContext(AuthContext);
  const [expType, setExpType] = useState<any>(null);
  const [opxType, setOpxType] = useState<any>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isExempted, setExempted] = useState<boolean>(false);
  const [suppliers, setSuppliers] = useState<any>([]);
  const [items, setItems] = useState<any>([]);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onSubmit = async () => {
    if (!expType) {
      message.error("Please select Expense type");
      return;
    }

    if (expType.id === 1) {
      if (!opxType) {
        message.error("Please select Opex type");
        return;
      }
    }
    setLoading(true);
    const { supplier, terms } = await form.getFieldsValue();
    const data = {
      user: auth?._id,
      isCapex: expType?.isCapex,
      isDirect: expType?.isCapex === true ? false : opxType?.isDirect,
      exemption: isExempted,
      supplier,
      items,
      terms,
    };

    const uri = `api/v1/requisitions/${requisition?._id}/convert`;
    const response = await postDataReq({ data, uri, token: auth?._id });
    if (response.success) {
      message.success("Submited Successfully");
      navigate(
        `/orders/${response?.payload?.order?._id}?redirect="/requisitions"`
      );
      setLoading(false);
    } else {
      message.error(response?.message);
    }
    setVisible(false);
    setLoading(false);
  };

  const handleFetchSuppliers = async () => {
    const response = await fetchDataReq({
      uri: "api/v1/suppliers",
      token: auth?._id,
    });
    if (response?.success) {
      setSuppliers(response?.payload?.records);
    }
  };

  const supplierOptions = suppliers?.map((supplier: any, index: number) => (
    <Option value={supplier?._id} key={index}>
      {supplier?.name}
    </Option>
  ));
  const hideModal = async () => {
    setVisible(false);
  };

  const getTotalAmount = () => {
    let nettotal = 0;
    let vat = 0;
    let grandtotal = 0;
    if (items) {
      items?.forEach((item: any) => {
        nettotal =
          nettotal +
          item?.quantity * Number(item?.price) * (1 - item?.discount / 100);
      });
      vat = nettotal * 0.18;
      grandtotal = nettotal + vat;

      return { nettotal, vat, grandtotal };
    }
  };

  const expensetypes = [
    { id: 1, name: "Convert to Opex LPO", isCapex: false },
    { id: 2, name: "Conver to Capex LPO", isCapex: true },
  ];

  const opextypes = [
    { id: 1, name: "Direct", isDirect: true },
    { id: 2, name: "Indirect", isDirect: false },
  ];

  const num: any = getTotalAmount();

  const changeHandler = (elementIndex: any) => (event: any) => {
    let newitems = items.map((item: any, i: any) => {
      if (elementIndex !== i) return item;
      if (event.target === undefined) {
        const el: any = suppliers?.payload?.records?.filter(
          (prod: any) => prod?._id === event
        );
        return {
          ...item,
        };
      }
      return { ...item, [event.target.name]: event.target.value };
    });
    setItems(newitems);
  };

  useEffect(() => {
    setItems(requisition?.items);
    handleFetchSuppliers();
  }, [requisition]);

  return (
    <Modal
      open={isVisible}
      title="Convert To LPO"
      width={"900px"}
      onCancel={hideModal}
      footer={[
        <Button danger key="back" onClick={hideModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="converReq"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
        >
          Convert
        </Button>,
      ]}
    >
      <Form id="converReq" form={form} onFinish={onSubmit} layout="vertical">
        <div className="flex flex-col justify-center items-center">
          <div className="flex w-full">
            {expensetypes?.map((item: any, index: number) => (
              <div
                className={`border border-black px-2 py-1 w-full hover:cursor-pointer ${
                  expType?.id === item?.id ? "bg-green-100" : ""
                }`}
                key={index}
                onClick={() => {
                  if (expType?.id === item?.id) {
                    setExpType(null);
                  } else {
                    setExpType(item);
                  }
                }}
              >
                <div className="flex items-center">
                  <div className="w-6 h-6 border border-black flex justify-center items-center">
                    {expType?.id === item?.id ? (
                      <BsCheck2 size={18} color={"green"} />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="ml-2">{item?.name}</div>
                </div>
              </div>
            ))}
          </div>
          {expType?.id === 1 ? (
            <div className="flex w-full mt-3">
              {opextypes?.map((item: any, index: number) => (
                <div
                  className={`border border-black px-2 py-1 w-full hover:cursor-pointer ${
                    opxType?.id === item?.id ? "bg-green-100" : ""
                  }`}
                  key={index}
                  onClick={() => {
                    if (opxType?.id === item?.id) {
                      setOpxType(null);
                    } else {
                      setOpxType(item);
                    }
                  }}
                >
                  <div className="flex items-center">
                    <div className="w-6 h-6 border border-black flex justify-center items-center">
                      {opxType?.id === item?.id ? (
                        <BsCheck2 size={18} color={"green"} />
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="ml-2">{item?.name}</div>
                  </div>
                </div>
              ))}
            </div>
          ) : null}
        </div>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="supplier"
              label="Supplier"
              rules={[
                {
                  required: true,
                  message: "Please select supplier",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Search and select supplier"
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {supplierOptions}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="terms"
              label={"Payment Terms"}
              initialValue={"100% Advance Payment"}
              rules={[
                {
                  required: false,
                  message: "Please enter payment terms",
                },
              ]}
            >
              <Input placeholder="Payment Terms" />
            </Form.Item>
          </Col>
        </Row>
        <div className="mb-4">
          <Checkbox
            onChange={(e: any) => {
              setExempted(e.target.checked);
            }}
            defaultChecked={isExempted}
          >
            VAT Exempted
          </Checkbox>
        </div>
        <div className="">
          <div className="grid grid-cols-12 border border-black font-bold">
            <div className="col-span-1 border-r border-black px-1"></div>
            <div className="col-span-1 border-r border-black px-1">S/n</div>
            <div className="col-span-3  border-r border-black px-1">
              Description
            </div>
            <div className="col-span-1  border-r border-black px-1">Qty</div>
            <div className="col-span-2  border-r border-black px-1">
              Price Each
            </div>
            <div className="col-span-2  border-r border-black px-1">
              Discount (%)
            </div>
            <div className="col-span-2 px-1">Amount</div>
          </div>
          {items?.map((item: any, index: number) => (
            <div className="grid grid-cols-12 border border-black" key={index}>
              <div className="col-span-1 border-r border-black p-1">
                <Checkbox />
              </div>
              <div className="col-span-1 border-r border-black p-1">
                {index + 1}
              </div>
              <div className="col-span-3  border-r border-black p-1">
                {item?.description}
              </div>
              <div className="col-span-1  border-r border-black p-1">
                {item?.quantity}
              </div>
              <div className="col-span-2  border-r border-black p-1">
                <input
                  name={"price"}
                  value={item?.price}
                  style={{ width: "130px" }}
                  className="border"
                  type={"number"}
                  onChange={changeHandler(index)}
                />
              </div>
              <div className="col-span-2  border-r border-black p-1">
                <input
                  name={"discount"}
                  min={0}
                  max={100}
                  type="number"
                  value={item?.discount}
                  style={{ width: "130px" }}
                  className="border"
                  onChange={changeHandler(index)}
                />
              </div>
              <div className="col-span-2 p-1 text-right">
                {formatMoney(
                  item?.price * item?.quantity * (1 - item?.discount / 100)
                )}
              </div>
            </div>
          ))}
          <div className="grid grid-cols-12 border border-black">
            <div className="col-span-1"></div>
            <div className="col-span-1"></div>
            <div className="col-span-3"></div>
            <div className="col-span-1"></div>
            <div className="col-span-2 border-r border-black"></div>
            <div className="col-span-2 border-r border-black px-1">
              Net Total
            </div>
            <div className="col-span-2 text-right">
              {formatMoney(isNaN(num?.nettotal) ? 0 : num?.nettotal)}
            </div>
          </div>
          <div className="grid grid-cols-12 border-l border-r border-b border-black">
            <div className="col-span-1"></div>
            <div className="col-span-3"></div>
            <div className="col-span-2"></div>
            <div className="col-span-2 border-r border-black"></div>
            <div className="col-span-2 border-r border-black px-1">Add VAT</div>
            <div className="col-span-2 text-right">
              {formatMoney(isNaN(num?.vat) ? 0 : num?.vat)}
            </div>
          </div>
          <div className="grid grid-cols-12 border border-black">
            <div className="col-span-1 p-1"></div>
            <div className="col-span-3 p-1"></div>
            <div className="col-span-2 p-1"></div>
            <div className="col-span-2 p-1 border-r border-black"></div>
            <div className="col-span-2 border-r border-black p-1 font-bold">
              Grand Total
            </div>
            <div className="col-span-2 p-1 text-right">
              {formatMoney(isNaN(num?.grandtotal) ? 0 : num?.grandtotal)}
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  );
}
