import { Button, Modal, Form, Row, Col, Input, message, Select } from "antd";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { handleCreateSegment } from "../../../../api/segments";
import { handleFetchClusters } from "../../../../api/clusters";
const { Option } = Select;

export default function CreateSegment({ isVisible, setVisible, refetch }: any) {
  const [isLoading, setLoading] = useState(false);
  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { data: clusters } = useQuery({
    queryKey: ["clusters"],
    queryFn: handleFetchClusters,
  });

  const { mutate } = useMutation({
    mutationFn: handleCreateSegment,
    onSuccess: () => {
      message.success("Created Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["segments"] });
      setVisible(false);
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const clusterOptions = clusters?.payload?.records?.map(
    (cluster: any, index: number) => (
      <Option value={cluster?._id} key={index}>
        {cluster?.name}
      </Option>
    )
  );

  const onSubmit = async () => {
    const values = await form.getFieldsValue();
    const data = { ...values };
    await mutate(data);
  };

  return (
    <Modal
      open={isVisible}
      title="New Segment"
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="createSegment"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="createSegment"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Please enter category name",
                },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="cluster"
              label="Cluster"
              rules={[
                {
                  required: true,
                  message: "Please select cluster",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Search and select cluster"
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {clusterOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
