const TRACE_BASE_URL = "https://api.kjl.co.tz:4000";
const REQUI_BASE_URL = "https://api.kjl.co.tz:5000";
const ARPS_BASE_URL = "https://api.kjl.co.tz:3000";
const EMAIL_BASE_URL = "https://api.kjl.co.tz:8080";
const DMS_BASE_URL = "http://dms.toyotatz.com/dms/uploaded/tmp/jobcards/";

// const TRACE_BASE_URL = "http://192.168.100.195:4000";
// const REQUI_BASE_URL = "http://192.168.100.195:8080";
// const ARPS_BASE_URL = "http://192.168.100.195:8080";
// const EMAIL_BASE_URL = "http://192.168.100.195:8080";
// const DMS_BASE_URL = "http://dms.toyotatz.com/dms/uploaded/tmp/jobcards/";

export {
  TRACE_BASE_URL,
  ARPS_BASE_URL,
  DMS_BASE_URL,
  REQUI_BASE_URL,
  EMAIL_BASE_URL,
};
