import { Button, Modal, Form, Row, Col, Input, message, Select } from "antd";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { handleCreateDivision } from "../../../../api/divisions";
import { handleFetchSegments } from "../../../../api/segments";
const { Option } = Select;

export default function CreateDivision({ isVisible, setVisible }: any) {
  const [isLoading, setLoading] = useState(false);
  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { data: segments } = useQuery({
    queryKey: ["segments"],
    queryFn: handleFetchSegments,
  });

  const { mutate } = useMutation({
    mutationFn: handleCreateDivision,
    onSuccess: () => {
      message.success("Created Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["divisions"] });
      setVisible(false);
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const segmentOptions = segments?.payload?.records?.map(
    (segment: any, index: number) => (
      <Option value={segment?._id} key={index}>
        {`${segment?.name} ( Cluster: ${segment?.cluster?.name} )`}
      </Option>
    )
  );

  const onSubmit = async () => {
    const values = await form.getFieldsValue();
    const data = { ...values };
    await mutate(data);
  };

  return (
    <Modal
      open={isVisible}
      title="New Division"
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="createDivision"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="createDivision"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Please enter division name",
                },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="segment"
              label="Segment"
              rules={[
                {
                  required: true,
                  message: "Please select segment",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Search and select segment"
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {segmentOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
