import { Button, Form, message, Space, Drawer } from "antd";
import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handleDeleteSegment } from "../../../../api/segments";

export default function DeleteSegment({
  isVisible,
  setVisible,
  selected,
}: any) {
  const [isLoading, setLoading] = useState(false);

  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { mutate } = useMutation({
    mutationFn: handleDeleteSegment,
    onSuccess: () => {
      message.success("Deleted Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["segments"] });
      setVisible(false);
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    setLoading(true);
    await mutate({ _id: selected?._id });
    setLoading(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [selected]);

  return (
    <Drawer
      open={isVisible}
      title={`DELETE SEGMENT`}
      placement={"right"}
      width={800}
      onClose={() => setVisible(!isVisible)}
      closable={false}
      extra={
        <Space className="flex justify-center items-center">
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <Form
        id="deleteSegment"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div>
          <p className="text-start font-bold">
            Are you sure you want to delete this segment
          </p>
          <p className="text-start text-red-500 font-bold ml-1">
            {selected?.name}
          </p>
        </div>

        <Form.Item>
          <Button danger type="primary" htmlType="submit" className="mr-2">
            {isLoading ? "Deleting ..." : "Delete"}
          </Button>
          <Button danger htmlType="button" onClick={() => setVisible(false)}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
