import { BsCheck2Circle } from "react-icons/bs";
import Moment from "react-moment";
import { colors } from "../../../utils/data";

export default function Timeline({ data, last }: any) {
  return (
    <div className="flex justify-between items-start">
      <div className="w-12 flex flex-col justify-center items-center">
        <div
          className="flex justify-center items-center w-8 h-8 rounded-full"
          style={{
            backgroundColor: `${colors[data?.status] || "#9ca3af"}`,
          }}
        >
          <BsCheck2Circle color="#FFFFFF" />
        </div>
        <div className="">
          <div className={`border-l ${last ? "border-white" : ""} ${data?.remarks ? "h-24": "h-12"}`}></div>
          <div className=""></div>
        </div>
      </div>
      <div className="bg-white mb-2 flex flex-col w-full -mt-2">
        <div className="border-b flex justify-between p-2">
          <div className=" font-bold">{data?.user?.name}</div>
        </div>
        <div className="px-2 pb-2 pt-1 flex flex-col justify-between text-xs">
          <div className="">{data?.status === "NEW" ? "-" : data?.status}</div>
          {data?.confirmedDate ? (
            <div className="flex justify-between text-gray-600 mr-1">
              <Moment format={"Do, MMM, YYYY"}>{data?.confirmedDate}</Moment>
            </div>
          ) : (
            ""
          )}
        </div>
        {data?.remarks && (
          <div className="" style={{fontSize: 12}}>Remarks: {data?.remarks}</div>
        )}
        <div className=""></div>
      </div>
    </div>
  );
}
