import {
  Button,
  Form,
  Row,
  Col,
  Input,
  message,
  Space,
  Drawer,
  Select,
} from "antd";
import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { handleUpdateSegment } from "../../../../api/segments";
import { handleFetchClusters } from "../../../../api/clusters";

const { Option } = Select;

export default function UpdateSegment({
  isVisible,
  setVisible,
  selected,
}: any) {
  const [isLoading, setLoading] = useState(false);

  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { data: clusters } = useQuery({
    queryKey: ["clusters"],
    queryFn: handleFetchClusters,
  });

  const { mutate } = useMutation({
    mutationFn: handleUpdateSegment,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["segments"] });
      setVisible(false);
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    setLoading(true);
    const { name, cluster } = await form.getFieldsValue();
    const data = {
      name,
      cluster,
    };
    await mutate({ _id: selected?._id, data });
    setLoading(false);
  };

  const clusterOptions = clusters?.payload?.records?.map(
    (cluster: any, index: number) => (
      <Option value={cluster?._id} key={index}>
        {cluster?.name}
      </Option>
    )
  );

  useEffect(() => {
    form.resetFields();
  }, [selected]);

  return (
    <Drawer
      open={isVisible}
      title={`UPDATE SEGMENT`}
      placement={"right"}
      width={800}
      onClose={() => setVisible(!isVisible)}
      closable={false}
      extra={
        <Space className="flex justify-center items-center">
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <Form
        id="updateSegment"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="name"
              label={"Name"}
              initialValue={selected?.name}
              rules={[
                {
                  required: true,
                  message: "Please enter name",
                },
              ]}
            >
              <Input type="text" placeholder="Name" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="cluster"
              label="Select Cluster"
              initialValue={selected?.cluster?._id}
              rules={[
                {
                  required: true,
                  message: "Please select cluster",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select Cluster"
                optionFilterProp="children"
                defaultValue={selected?.cluster?._id}
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {clusterOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button type="primary" htmlType="submit" className="mr-2">
            {isLoading ? "Updating ..." : "Update"}
          </Button>
          <Button htmlType="button" onClick={() => setVisible(false)}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
