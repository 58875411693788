import {
  deleteData,
  fetchData,
  patchData,
  postData,
} from "../services/requisition";

const handleFetchSegments = async () => {
  const uri = `api/v1/business/segments`;
  const response = fetchData({ uri });
  return response;
};

const handleCreateSegment = async (data: any) => {
  const uri = `api/v1/business/segments`;
  const response = postData({ data, uri });
  return response;
};
const handleUpdateSegment = async ({ data, _id }: any) => {
  const uri = `api/v1/business/segments/${_id}`;
  const response = patchData({ data, uri });
  return response;
};

const handleDeleteSegment = async ({ _id }: any) => {
  const uri = `api/v1/business/segments/${_id}`;
  const response = deleteData({ uri });
  return response;
};

export {
  handleFetchSegments,
  handleCreateSegment,
  handleUpdateSegment,
  handleDeleteSegment,
};
