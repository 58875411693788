import { useState } from "react";
import { Button, Modal, Form, message } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handleAmendOrder } from "../../../api/orders";

export default function ConfirmAmend({ isVisible, setVisible, order }: any) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { mutate: amendOrder } = useMutation({
    mutationFn: handleAmendOrder,
    onSuccess: () => {
      message.success("Approved Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["orders", order?._id] });
      setVisible(false);
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    setLoading(true);
    await amendOrder({ _id: order?._id });
    setVisible(false);
    setLoading(false);
  };

  const hideModal = async () => {
    setVisible(false);
  };

  return (
    <Modal
      open={isVisible}
      title="Submit Changes"
      onCancel={hideModal}
      footer={[
        <Button danger key="back" onClick={hideModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="submitOrderChanges"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="submitOrderChanges"
        form={form}
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="px-4">
          <div className="flex justify-between items-center mb-4">
            Are you sure you want to submit the Purchase Order for Review?
          </div>
        </div>
      </Form>
    </Modal>
  );
}
