const startsWith = (str: any, word: any) => {
  return str.lastIndexOf(word, 0) === 0;
};
const formatMobile = async (num: any) => {
  let inValid = false;
  let mob = null;
  if (num) {
    const trimmed = num.replace(/\s/g, "");
    if (trimmed.toString().length === 10) {
      let zero = startsWith(`${trimmed}`, "0");
      if (zero) {
        let num = trimmed.substring(1);
        mob = `+255${num}`;
      }
    } else if (trimmed.toString().length === 12) {
      let two = startsWith(`${trimmed}`, "2");
      if (two) {
        mob = `+${trimmed}`;
      }
    } else if (trimmed.toString().length === 13) {
      let plus = startsWith(`${trimmed}`, "+");
      if (plus) {
        mob = `${trimmed}`;
      }
    } else {
      if (trimmed.toString().length < 9 || trimmed.toString().length > 9) {
        inValid = true;
      }
      mob = `+255${trimmed}`;
    }
  }
  return { mob, inValid };
};

export default formatMobile;
